import { ConfigurationType } from '@server/parser';

export enum DocumentationTabTypes {
  RECORD_TYPES = 'Record Types',
  SF_AUTOMATIONS = 'Salesforce Automations',
  APEX = 'Apex',
  FIELDS = 'Fields',
  VALIDATION_RULES = 'Validation Rules',
  SWEEP_AUTOMATIONS = 'Sweep Automations',
  PLAYBOOK_ALERTS = 'Alerts',
  MATCHING_DEDUPE = 'Dedupe & Matching',
  ASSIGNMENTS = 'Assignments',
  SCHEDULED_ASSIGNMENTS = 'Scheduled Assignments',
  CPQ_DATA = 'CPQ Configuration',
  CARDS_LIST = 'elements', //main view
  LAYOUTS = 'Layouts',
  RELATED_OBJECTS = 'Related objects',
}

export const SWEEP_ELEMENTS_TAB_TYPES = [
  DocumentationTabTypes.MATCHING_DEDUPE,
  DocumentationTabTypes.PLAYBOOK_ALERTS,
  DocumentationTabTypes.SWEEP_AUTOMATIONS,
  DocumentationTabTypes.SCHEDULED_ASSIGNMENTS,
  DocumentationTabTypes.ASSIGNMENTS,
];

export const ConfigurationTypeToDocumentationTabType = {
  [ConfigurationType.validationRules]: DocumentationTabTypes.VALIDATION_RULES,
  [ConfigurationType.apexTriggers]: DocumentationTabTypes.APEX,
  [ConfigurationType.apexClasses]: DocumentationTabTypes.APEX,
  [ConfigurationType.approvalProcesses]: DocumentationTabTypes.SF_AUTOMATIONS,
  [ConfigurationType.workflowRules]: DocumentationTabTypes.SF_AUTOMATIONS,
  [ConfigurationType.processBuilderFlows]: DocumentationTabTypes.SF_AUTOMATIONS,
  [ConfigurationType.layouts]: DocumentationTabTypes.LAYOUTS,
  [ConfigurationType.flows]: DocumentationTabTypes.SF_AUTOMATIONS,
  [ConfigurationType.recordTypes]: DocumentationTabTypes.RECORD_TYPES,
  [ConfigurationType.fields]: DocumentationTabTypes.FIELDS,
};
