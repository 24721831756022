import { Box, Card, Stack } from '@mui/material';
import AgentInfoSmall from './AgentInfoSmall';
import { colors, Typography } from '@sweep-io/sweep-design';
import NewProcessAgentIcon from './svg/newProcessAgentIcon.svg?react';

const CreateNewAgentCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <Card onClick={onClick} sx={{ borderColor: '#E4F2F8', overflow: 'visible' }}>
      <Stack p={2} gap={2}>
        <AgentInfoSmall
          icon={<NewProcessAgentIcon />}
          name="Create new process optimization agent"
          iconSize="40px"
        />
        <Box maxWidth="85%">
          <Typography variant="body" color={colors.grey[700]}>
            Helps revenue teams detect strategic optimization opportunities for business processes
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default CreateNewAgentCard;
