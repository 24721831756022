import { Stack } from '@mui/material';
import { AgentFile, AgentFileInvalid } from '../aiAgentsConsts';
import { ValidFileListItem, InvalidFileListItem } from './FileListItems';

const FilesList = ({
  files,
  invalidFiles,
  onRemove,
  onRemoveInvalidFile,
}: {
  files: AgentFile[];
  invalidFiles: AgentFileInvalid[];
  onRemove: (fileId: string) => void;
  onRemoveInvalidFile: (fileId: string) => void;
}) => {
  return (
    <Stack>
      {files.map((file) => (
        <ValidFileListItem key={file.id} file={file} onRemove={() => onRemove(file.id)} />
      ))}
      {invalidFiles.map((file) => (
        <InvalidFileListItem
          key={file.id}
          file={file}
          onRemove={() => onRemoveInvalidFile(file.id)}
        />
      ))}
    </Stack>
  );
};

export default FilesList;
