import { Box, Stack } from '@mui/material';
import {
  WorkflowActionEditRecordDto,
  WorkflowWhenFilters,
  WorkflowWhenFiltersType,
  WorkflowWhenType,
} from '../types';
import { HubspotFieldsRuleBuilder } from '../hubspot-rule-builder/HubspotFieldsRuleBuilder';
import isEmpty from 'lodash/isEmpty';
import { SelectorValueTypes } from '../../../../../types/enums/SelectorValueTypes';
import { uniqueId } from '../../../../../lib/uniqueId';
import { UpdateContactProperty } from './UpdateContactProperty';
import { Button, Typography } from '@sweep-io/sweep-design';

interface HubspotUpdateRecordFilterAndActionProps {
  action: WorkflowActionEditRecordDto;
  onChange: (action: WorkflowActionEditRecordDto) => void;
  isDisabled?: boolean;
}

export const getNewAndThenUpdateRecordActionCondition = (): WorkflowWhenFilters => ({
  _id: uniqueId(),
  whenType: WorkflowWhenType.FILTERS,
  filterType: WorkflowWhenFiltersType.PROPERTY,
  filter: {
    criteria: [
      {
        criterionId: uniqueId(),
        valueType: SelectorValueTypes.LITERAL,
        _fieldIds: [],
        fieldType: '',
        operator: '',
        value: '',
      },
    ],
    criteriaLogic: '1',
  },
});

export const HubspotUpdateRecordFilterAndAction = ({
  action,
  onChange,
  isDisabled,
}: HubspotUpdateRecordFilterAndActionProps) => {
  const maybeRenderRuleBuilder = () => {
    if (action.condition) {
      return (
        <HubspotFieldsRuleBuilder
          key={action._id}
          onChange={(filter) => {
            const newAction = { ...action };
            if (!filter.criteria || filter.criteria.length === 0) {
              newAction.condition = undefined;
              onChange(newAction);
              return;
            }
            newAction.condition = getNewAndThenUpdateRecordActionCondition();
            const _filter = !isEmpty(filter)
              ? filter
              : getNewAndThenUpdateRecordActionCondition().filter;
            newAction.condition.filter = _filter as any;

            onChange(newAction);
          }}
          sweepCondition={action.condition?.filter}
          readOnly={isDisabled}
        />
      );
    }
  };
  const maybeRenderAddConditionsButton = () => {
    if (!action.condition) {
      return (
        <Box pt={1} pb={1}>
          <Button
            startIconName="Plus"
            variant="link"
            onClick={() => {
              onChange({
                ...action,
                condition: {
                  ...(action.condition || getNewAndThenUpdateRecordActionCondition()),
                  filter: {
                    criteria: [
                      {
                        criterionId: uniqueId(),
                        valueType: SelectorValueTypes.LITERAL,
                        _fieldIds: [],
                        fieldType: '',
                        operator: '',
                        value: '',
                      },
                    ],
                    criteriaLogic: '1',
                  },
                },
              });
            }}
          >
            Add conditions
          </Button>
        </Box>
      );
    }
  };
  return (
    <Stack spacing={2}>
      {maybeRenderAddConditionsButton()}
      {maybeRenderRuleBuilder()}
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <Box
          sx={{
            marginRight: '12px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
          }}
        >
          <Typography variant="body">Set these contact field(s)</Typography>
        </Box>
        <Stack spacing={1} alignItems="center">
          {action.properties.map((property, idx) => (
            <UpdateContactProperty
              key={property._id}
              property={property}
              onChange={(_property) => {
                const properties = [...action.properties];
                properties[idx] = _property;
                onChange({ ...action, properties });
              }}
              onAddProperty={() => {
                const newProperty = {
                  _id: uniqueId(),
                  name: '',
                  value: '',
                };
                onChange({ ...action, properties: [...action.properties, newProperty] });
              }}
              onRemoveProperty={() => {
                const properties = action.properties.filter((item) => item._id !== property._id);
                if (properties.length === 0) {
                  properties.push({
                    _id: uniqueId(),
                    name: '',
                    value: '',
                  });
                }
                onChange({ ...action, properties });
              }}
            />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};
