import { Box, Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import AiAgentListItemLayout from './AiAgentListItemLayout';
import { styled } from '@mui/material';
import ThreeAvatars from './svg/threeAvatars.svg?react';
import CollapsibleSectionLayout from './CollapsibleSectionLayout';
import useAiAgentChats from './useAiAgentChats';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import useAiAgents from './useAiAgents';

const FlexBoxItem = styled(Box)({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

const AiAgentsList = () => {
  const { setActiveChatId, createNewGhostChat, activeIsEmptyChat, activeChat } = useAiAgentChats();
  const { agents, activeAgent } = useAiAgents();

  return (
    <Stack gap={3}>
      <AiAgentListItemLayout
        isActive={!activeChat}
        onClick={() => {
          setActiveChatId(undefined);
        }}
      >
        <FlexBoxItem gap="14px">
          <ThreeAvatars />
          <Typography variant="body">All Agents</Typography>
        </FlexBoxItem>
      </AiAgentListItemLayout>
      <CollapsibleSectionLayout title="Agents">
        {agents?.map((agent) => {
          const { icon } = AI_AGENTS_INFO[agent.type];
          return (
            <AiAgentListItemLayout
              key={agent.id}
              isActive={activeAgent?.id === agent.id && activeIsEmptyChat}
              onClick={() => {
                createNewGhostChat(agent.id, agent.type);
              }}
            >
              <FlexBoxItem gap="8px">
                <Box flex="0 0 12px">{icon}</Box>
                <Typography variant="body">{agent.name}</Typography>
              </FlexBoxItem>
            </AiAgentListItemLayout>
          );
        })}
      </CollapsibleSectionLayout>
    </Stack>
  );
};

export default AiAgentsList;
