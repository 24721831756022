import { Box, Tooltip } from '@mui/material';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

const BoxSelectionLayer = forwardRef(
  (
    {
      children,
      showCursorTooltip,
    }: {
      showCursorTooltip: boolean;
      children?: ReactNode;
    },
    ref: ForwardedRef<HTMLElement>,
  ) => {

    return (
      <Tooltip
        title={showCursorTooltip ? 'Click and drag to select stages to optimize' : undefined}
        followCursor={true}
      >
        <Box
          ref={ref}
          sx={{
            cursor: 'crosshair',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            position: 'absolute',
            zIndex: 1,
          }}
        >
          {children}
        </Box>
      </Tooltip>
    );
  },
);

export default BoxSelectionLayer;
