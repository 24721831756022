import { useDispatch, useSelector } from 'react-redux';
import { Campaign, CampaignBase, CampaignKeys } from './campaignTypes';
import { useCampaignsApiFacade } from '../../../../../apis/facades/useCampaignsApiFacade';
import {
  loadAllCampaignFields,
  loadCampaignFieldNames,
  loadCampaignObjectFields,
  loadCampaigns,
  removeCampaign,
  setCampaignsLoadingError,
  setLoadedCampaignObjectFieldsError,
  setLoadingCampaignFieldNamesError,
  startLoadingCampaignFieldNames,
  startLoadingCampaignObjectFields,
  startLoadingCampaigns,
} from '../../../../../reducers/hubspotReducer';
import { useCallback } from 'react';
import { pick } from 'lodash';
import { useSweepFieldsApiFacade } from '../../../../../apis/facades/useSweepFieldsApiFacade';
import { calculateFormFieldsBuilder } from './useCalculateCampaignFormFields';
import { selectDefaultCreationCrmOrgId } from '../../../../../reducers/userInfoReducer';

const cleanCampaignProperties = (campaign: Partial<Campaign>): Partial<Campaign> =>
  pick(campaign, CampaignKeys);

export const useCampaigns = () => {
  const dispatch = useDispatch();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  if (!crmOrgId) {
    throw new Error('crmOrgId is required');
  }

  const { post_campaign, patch_campaign, delete_campaign, get_campaigns, get_campaign_fields } =
    useCampaignsApiFacade();
  const { get_sweepFields } = useSweepFieldsApiFacade();

  const getCampaigns = useCallback(async () => {
    dispatch(startLoadingCampaigns());
    try {
      const campaigns = await get_campaigns();
      dispatch(loadCampaigns(campaigns));
      return campaigns;
    } catch (error) {
      dispatch(setCampaignsLoadingError(error));
      throw error;
    }
  }, [dispatch, get_campaigns]);

  const deleteCampaign = async (campaignId: string): Promise<void> => {
    await delete_campaign(campaignId);
    dispatch(removeCampaign({ campaignId }));
  };

  const getCampaignObjectFields = useCallback(async () => {
    dispatch(startLoadingCampaignObjectFields());
    try {
      const fields = await get_sweepFields({
        crmOrgId,
        objectType: ['Campaign'],
        includeDescriptionValueSetNameRequired: true,
      });
      const campaignFields = fields.sweepFields.find(
        (field) => field.objectType === 'Campaign',
      )?.fields;
      if (campaignFields) {
        dispatch(loadCampaignObjectFields(campaignFields));
      }

      return campaignFields || [];
    } catch (error) {
      dispatch(setLoadedCampaignObjectFieldsError(error));
      throw error;
    }
  }, [crmOrgId, dispatch, get_sweepFields]);

  const getCampaignFields = useCallback(async () => {
    dispatch(startLoadingCampaignFieldNames());
    try {
      const campaignFields = await get_campaign_fields();
      dispatch(loadCampaignFieldNames(campaignFields));
      return campaignFields;
    } catch (error) {
      dispatch(setLoadingCampaignFieldNamesError(error));
      throw error;
    }
  }, [dispatch, get_campaign_fields]);

  const loadCampaignsWithFields = useCallback(async () => {
    const [campaigns, campaignSfdcFields, campaignObjectFields] = await Promise.all([
      getCampaigns(),
      getCampaignFields(),
      getCampaignObjectFields(),
    ]);

    const calculateFormFields = calculateFormFieldsBuilder({
      campaignObjectFields,
      campaignSfdcFields,
    });

    const newCampaigns = campaigns.map(calculateFormFields);

    dispatch(loadCampaigns(campaigns));
    dispatch(loadAllCampaignFields(newCampaigns));
  }, [dispatch, getCampaignFields, getCampaignObjectFields, getCampaigns]);

  const updateCampaign = useCallback(
    async (campaignId: string, campaign: Partial<Campaign>): Promise<void> => {
      const _campaign = cleanCampaignProperties(campaign);
      await patch_campaign(campaignId, _campaign);
      await loadCampaignsWithFields();
    },
    [loadCampaignsWithFields, patch_campaign],
  );

  const createCampaign = useCallback(
    async (newCampaign: CampaignBase): Promise<Campaign> => {
      const campaign = await post_campaign(newCampaign);
      const _campaign = cleanCampaignProperties(campaign) as Campaign;
      await loadCampaignsWithFields();
      return _campaign;
    },
    [loadCampaignsWithFields, post_campaign],
  );

  return {
    createCampaign,
    updateCampaign,
    deleteCampaign,
    getCampaigns,
    getCampaignFields,
    getCampaignObjectFields,
    loadCampaignsWithFields,
  };
};
