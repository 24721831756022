import RightPanel from '../common/RightPanel';
import { Close, Minimize, Expand } from '@sweep-io/sweep-design/dist/icons';
import { IconButton, Tooltip } from '@sweep-io/sweep-design';
import AiAgentCenterExpanded from './AiAgentCenterExpanded';
import AiAgentCenterCollapsed from './AiAgentCenterCollapsed';
import InitialFetchNoticeDialog from './InitialFetchNoticeDialog';
import useAiAgentCenter from './useAiAgentCenter';
import { useCallback, useState } from 'react';
import ProcessSelectionDialog from './ProcessSelectionDialog';
import CreateFunnelDialog from './CreateFunnelDialog';
import useSweepNavigate from '../common/useSweepNavigate';
import { appRoutes } from '../../constants/appRoutes';
import { useLocation } from 'react-router';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';
import { useFunnelMaps } from '../pages/funnel-maps/useFunnelMaps';
import { useSelector } from 'react-redux';
import { selectFunnelMapsIsLoading, selectFunnelMapsList } from '../../reducers/funnelMapsReducer';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import ConnectToSfDialog from '../pages/get-started/connect-org/ConnectToSfDialog';
import { Permission } from '@server/permissions';
import usePermission from '../common/permissions/usePermission';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';

const AiAgentCenter = ({ closePanel, isOpened }: { closePanel: () => void; isOpened: boolean }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const { isInitialFetch, noConnectedOrg } = useUnhappyStates();
  const [isProcessSelectionDialogOpened, setIsProcessSelectionDialogOpened] = useState(false);
  const [isCreateFunnelDialogOpened, setIsCreateFunnelDialogOpened] = useState(false);
  const [isConnectSfDialogOpen, setIsConnectSfDialogOpen] = useState(false);
  const { isExpanded, toggleIsExpanded, setIsAgentCreateActive } = useAiAgentCenter();
  const { navigate } = useSweepNavigate();
  const location = useLocation();
  const { pathname } = location;
  const { fetchFunnelMaps } = useFunnelMaps();
  const funnelMaps = useSelector(selectFunnelMapsList);
  const isLoadingFunnelMaps = useSelector(selectFunnelMapsIsLoading);
  const connectSfPermissionStr: Permission[] = ['create:orgs'];
  const [isAllowedConnectSf] = usePermission(connectSfPermissionStr);
  const { openConfirm, onCancel } = useConfirm();

  useRunOnceWhenTruthy(
    () => {
      fetchFunnelMaps({ includeFunnelsData: true });
    },
    !isLoadingFunnelMaps && funnelMaps.length === 0 && !!crmOrgId,
  );

  const navigateAndActivate = useCallback(
    (funnelMapId: string) => {
      setIsAgentCreateActive(true);
      navigate(`${appRoutes.canvasUnited.route}/${funnelMapId}`);
    },
    [navigate, setIsAgentCreateActive],
  );

  const handleNewAgentClick = useCallback(async () => {
    const isInCanvas = pathname.startsWith(appRoutes.canvasUnited.route);
    if (isInCanvas) {
      setIsAgentCreateActive(true);
      return;
    }
    if (noConnectedOrg) {
      if (isAllowedConnectSf) {
        setIsConnectSfDialogOpen(true);
      }
      return;
    }
    if (isInitialFetch) {
      await openConfirm(<InitialFetchNoticeDialog closeDialog={onCancel} />);
      return;
    }
    if (!isLoadingFunnelMaps && funnelMaps.length === 0) {
      setIsCreateFunnelDialogOpened(true);
      return;
    }
    setIsProcessSelectionDialogOpened(true);
  }, [
    pathname,
    setIsAgentCreateActive,
    funnelMaps.length,
    isLoadingFunnelMaps,
    noConnectedOrg,
    isAllowedConnectSf,
    isInitialFetch,
    onCancel,
    openConfirm,
  ]);

  const closeConnectSfDialog = useCallback(() => {
    setIsConnectSfDialogOpen(false);
  }, []);

  const toggleExpandButton = (
    <Tooltip placement="top" title={isExpanded ? 'Collapse' : 'Expand'}>
      <IconButton variant="flat" onClick={toggleIsExpanded} size="small">
        {isExpanded ? <Minimize /> : <Expand />}
      </IconButton>
    </Tooltip>
  );

  const closeButton = (
    <IconButton onClick={closePanel} variant="flat" size="small">
      <Close />
    </IconButton>
  );

  return (
    <>
      <RightPanel isOpened={isOpened} isExpanded={isExpanded}>
        {isExpanded && (
          <AiAgentCenterExpanded
            toggleExpandButton={toggleExpandButton}
            closeButton={closeButton}
            onNewAgentClick={handleNewAgentClick}
          />
        )}
        {!isExpanded && (
          <AiAgentCenterCollapsed
            toggleExpandButton={toggleExpandButton}
            closeButton={closeButton}
            onNewAgentClick={handleNewAgentClick}
          />
        )}
      </RightPanel>
      {isProcessSelectionDialogOpened && (
        <ProcessSelectionDialog
          closeDialog={() => setIsProcessSelectionDialogOpened(false)}
          onSelect={navigateAndActivate}
        />
      )}
      {isCreateFunnelDialogOpened && (
        <CreateFunnelDialog closeDialog={() => setIsCreateFunnelDialogOpened(false)} />
      )}
      {isConnectSfDialogOpen && (
        <ConnectToSfDialog
          onFinish={closeConnectSfDialog}
          closeDialog={closeConnectSfDialog}
          forceProduction={false}
        />
      )}
    </>
  );
};

export default AiAgentCenter;
