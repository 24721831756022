import { SearchSelect, SearchSelectItem } from '../../../../common/search-select/SearchSelect';
import { selectHubspotFields } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { HubspotField } from '../hubspot.types';

interface HubspotFieldSelectorProps {
  onChange: (field: SearchSelectItem<HubspotField>) => void;
  value?: string;
  disabled?: boolean;
  removeBorders?: boolean;
  filterBy?: (field: HubspotField) => boolean;
  disabledTooltip?: string;
}

export const HubspotFieldSelector = ({
  onChange,
  value,
  disabled,
  removeBorders,
  filterBy,
  disabledTooltip,
}: HubspotFieldSelectorProps) => {
  let hubspotFields = useSelector(selectHubspotFields);
  if (filterBy) {
    hubspotFields = hubspotFields.filter(filterBy);
  }

  const items: SearchSelectItem<HubspotField>[] = useMemo(() => {
    return (
      hubspotFields?.map((field) => {
        const tooltip = disabled ? (disabledTooltip ?? field.description) : field.description;
        return {
          label: field.label,
          value: field.name,
          data: field,
          tooltip,
        };
      }) || []
    );
  }, [disabled, disabledTooltip, hubspotFields]);

  return (
    <SearchSelect
      items={items}
      FormControlProps={{ fullWidth: true }}
      onChange={onChange}
      SelectProps={{
        value,
      }}
      removeBorders={removeBorders}
      disabled={disabled}
    />
  );
};
