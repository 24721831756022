import { ButtonSize } from '@sweep-io/sweep-design/dist/components/Button/types';
import { CreateEditDialogButtons } from './CreateEditDialogButtons';
import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';
import IconButton from '@sweep-io/sweep-design/dist/components/IconButton';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';

export interface CreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  buttonContent?: React.ReactNode;
  buttonStartIcon?: string;
  onCancelClick?: () => any;
  onButtonClick?: (any: any) => any;
  isButtonLoading?: boolean;
  buttonDisabled?: boolean;
  showCancelButton?: boolean;
  rightSideContent?: React.ReactNode;
  confirmButtonSize?: ButtonSize;
  noCreateEditButtons?: boolean;
}

export const CreateEditDialogHeader = ({
  buttonContent,
  buttonStartIcon,
  onButtonClick,
  buttonDisabled,
  isButtonLoading,
  confirmButtonSize,
  showCancelButton,
  onCancelClick,
  rightSideContent,
  noCreateEditButtons,
  ...restProps
}: CreateEditDialogHeaderProps) => {
  return (
    <CreateEditDialogHeaderBase
      {...restProps}
      rightSideContent={
        <>
          {rightSideContent}
          {!noCreateEditButtons && onButtonClick && (
            <CreateEditDialogButtons
              confirmButtonContent={buttonContent}
              confirmButtonStartIcon={buttonStartIcon}
              onConfirm={onButtonClick}
              confirmButtonDisabled={buttonDisabled}
              showCancelButton={showCancelButton}
              onCancel={onCancelClick}
              isButtonLoading={isButtonLoading}
              confirmButtonSize={confirmButtonSize}
            />
          )}
          {noCreateEditButtons && showCancelButton && (
            <IconButton
              variant="flat"
              size={confirmButtonSize}
              onClick={onCancelClick}
              data-dd-action-name="close"
            >
              <CloseIcon />
            </IconButton>
          )}
        </>
      }
    />
  );
};
