import { MenuItem, ListItemIcon, Box, Stack } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

interface IntegrationOptionProps {
  name: string;
  logo: ReactNode;
  description: string;
  actionButton: ReactNode;
  additionalContent?: ReactNode;
  isHide?: boolean; // TODO: remove when removing cloudingo integration
}

export const IntegrationOption = ({
  name,
  logo,
  description,
  actionButton,
  additionalContent,
  isHide,
}: IntegrationOptionProps) => {
  return (
    <MenuItem sx={{ display: isHide ? 'none' : 'flex' }}>
      <Stack width="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <ListItemIcon>{logo}</ListItemIcon>
          <Box
            sx={{
              flex: '1',
              textWrap: 'wrap',
              gap: 0.5,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography variant="body-medium">{name}</Typography>
            <Typography variant="body" color={colors.grey[700]}>
              {description}
            </Typography>
          </Box>
          <Box>{actionButton}</Box>
        </Box>
        <Box pl={7}>{additionalContent}</Box>
      </Stack>
    </MenuItem>
  );
};
