import useAiAgentCenter from './useAiAgentCenter';
import { useCallback } from 'react';

export const useCloseAgentCenterFirst = () => {
  const { toggleIsOpened, isOpened } = useAiAgentCenter();

  const closeAgentCenterFirst = useCallback(
    (cb: any) => {
      if (isOpened) {
        toggleIsOpened();
      }
      cb && cb();
    },
    [isOpened, toggleIsOpened],
  );

  return closeAgentCenterFirst;
};
