import { useCallback, useContext, useMemo } from 'react';
import _keyBy from 'lodash/keyBy';
import _maxBy from 'lodash/maxBy';
import _minBy from 'lodash/minBy';
import { Node as ReactFlowNode, useReactFlow } from 'reactflow';
import {
  SweepCanvasNode,
  CanvasElementType,
  SweepCanvasEdge,
  NodeChangeMoveNode,
  SweepNodesChangeType,
  SweepNodesChangeEscapeFromShadowStep,
  SweepCanvasNodeTransformations,
  RFNodeData,
} from './canvasTypes';
import { SweepCanvasReactFlowEdgeDataType } from './edges/SweepCanvasReactFlowEdgeDataType';
import {
  SweepCanvasNodePosition,
  _canvasIndexPositionToReactFlowPosition,
  reactFlowPositionToCanvasIndexPosition,
} from './helpers/calculateHandlePositionsBasedOnCoords';
import { isNbNode } from './helpers/isNbNode';
import { shiftStepsRight } from './helpers/sweepCanvasHelper';
import { GroupDataReactFlowNode } from './canvasTypes/nodeTypesData';
import { useCalculateDragAndDrop } from './useCalculateDragAndDrop';
import { HighlightNodeData } from './useHighlightNode';
import { uniqueId } from '../../lib/uniqueId';
import { useStageMetadata } from '../../hooks/useStageMetadata';
import { NODE_HEIGHT, NODE_WIDTH } from './const';
import { InternalCanvasCtx } from './internal-context/InternalCanvasCtx';
import { SweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';

interface UseReactFlowProps {
  groupNodes: GroupDataReactFlowNode[];
  highlightedNodeData?: HighlightNodeData;
  withAnimation: ({
    nodeTransformations,
    onComplete,
  }: {
    nodeTransformations: SweepCanvasNodeTransformations;
    onComplete: () => any;
  }) => any;
  draggingNode?: ReactFlowNode<any>;
}

const findSweepNodeById = (sweepNodes: SweepCanvasNode[], id: string) =>
  sweepNodes.find((node) => node.id === id);

export const useReactFlowNodes = ({
  groupNodes,
  withAnimation,
  highlightedNodeData,
  draggingNode,
}: UseReactFlowProps) => {
  const {
    sweepNodes,
    onSweepNodesChange,
    onNodeNameChange,
    sweepEdges,
    onNodeClick,
    onPillClick,
    onConnectClick,
    readonly,
    selectedNodeId,
    moveGroups,
    holdNodeHighlighted,
    disableGroups,
    disableNodeHighlight,
  } = useContext(SweepCanvasPropsCtx);
  const isInGroupMouseMoveMode = !!moveGroups;
  const reactFlowInstance = useReactFlow();
  const { temporaryTransformations, setTemporaryTransformations } = useContext(InternalCanvasCtx);

  // external sweep edges plus the shadow nodes
  const internalSweepNodes = useMemo(() => {
    const nodes = temporaryTransformations?.newNode
      ? sweepNodes.concat(temporaryTransformations?.newNode)
      : sweepNodes;
    return nodes.map((sweepNode) =>
      temporaryTransformations?.nodeTransformations[sweepNode.id]
        ? { ...temporaryTransformations?.nodeTransformations[sweepNode.id] }
        : sweepNode,
    );
  }, [
    temporaryTransformations?.newNode,
    temporaryTransformations?.nodeTransformations,
    sweepNodes,
  ]);

  const { getDropInvalidReason } = useCalculateDragAndDrop({ internalSweepNodes });

  const { mapForecastCategoryValueToLabel } = useStageMetadata();
  const filterSweepNodesByParentId = useCallback(
    (parentId?: string) => sweepNodes.filter((node) => node.parentId === parentId),
    [sweepNodes],
  );

  const calculateColumnNumber = (currentColumn: number, isNurturingBucketType: boolean) => {
    const moveByColumnNumber = isNurturingBucketType ? 0 : 1;
    return currentColumn + moveByColumnNumber;
  };

  const onlyCurrentColumn = (columnIdx: number, sweepNodes: SweepCanvasNode[]) => {
    return sweepNodes.filter((sN) => sN.position.column === columnIdx);
  };

  const onAddNodeClick = useCallback(
    (nodeId: string, branchType: 'branch' | 'nb') => {
      const node = findSweepNodeById(sweepNodes, nodeId);

      if (node) {
        const _sweepNodesFilteredByParentId = filterSweepNodesByParentId(node.parentId);
        const isBranchType = branchType === 'branch';
        const isNurturingBucketType = branchType === 'nb';

        const columnNumber = calculateColumnNumber(node.position.column, isNurturingBucketType);

        const chosenNodes = isNurturingBucketType
          ? onlyCurrentColumn(columnNumber, _sweepNodesFilteredByParentId)
          : _sweepNodesFilteredByParentId;

        const newRow = isBranchType
          ? (_maxBy(_sweepNodesFilteredByParentId, 'position.row')?.position.row || 0) + 1
          : (_minBy(chosenNodes, 'position.row')?.position.row || 0) - 1;

        const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];
        const newNode: SweepCanvasNode = {
          id: newNodeId,
          parentId: node.parentId,
          position: {
            column: columnNumber,
            row: newRow,
          },
          type: isNurturingBucketType ? CanvasElementType.EDIT_NB : CanvasElementType.EDIT,
          name: '',
          objectType: node.objectType,
          originStepName: node.name,
        };

        const newEdge: SweepCanvasEdge = {
          id: newEdgeId,
          source: node.id,
          target: newNodeId,
          data: {
            label: '',
            type: SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE,
          },
        };

        setTemporaryTransformations({
          newNode,
          newEdge,
          nodeTransformations: {},
          edgeTransformations: {},
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterSweepNodesByParentId, sweepNodes],
  );

  const onAddSourceClick = useCallback(
    (nodeId: string) => {
      const node = findSweepNodeById(sweepNodes, nodeId);

      if (node) {
        const _sweepNodesFilteredByParentId = filterSweepNodesByParentId(node.parentId);
        const nodeTransformations = shiftStepsRight({
          startingNode: node,
          sweepNodes: _sweepNodesFilteredByParentId,
          includeOwn: true,
        });

        const onAnimationComplete = () => {
          const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];
          const newNode: SweepCanvasNode = {
            id: newNodeId,
            position: {
              column: node.position.column,
              row: node.position.row,
            },
            parentId: node.parentId,
            type: CanvasElementType.EDIT,
            name: '',
            objectType: node.objectType,
            originStepName: node.name,
          };

          const newEdge: SweepCanvasEdge = {
            id: newEdgeId,
            source: newNode.id,
            target: node.id,
            data: {
              label: '',
              type: SweepCanvasReactFlowEdgeDataType.DASHED_CIRCLE,
            },
          };

          setTemporaryTransformations({
            newNode,
            newEdge,
            nodeTransformations: _keyBy(nodeTransformations, 'id'),
            edgeTransformations: { [newEdge.id]: newEdge },
          });
        };
        withAnimation({
          nodeTransformations,
          onComplete: onAnimationComplete,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterSweepNodesByParentId, sweepNodes, withAnimation],
  );

  const onAddTargetClick = useCallback(
    (nodeId: string) => {
      const node = findSweepNodeById(sweepNodes, nodeId);
      if (node) {
        const _sweepNodesFilteredByParentId = filterSweepNodesByParentId(node.parentId);
        const nodeTransformations = shiftStepsRight({
          startingNode: node,
          sweepNodes: _sweepNodesFilteredByParentId,
        });

        const onAnimationComplete = () => {
          const [newNodeId, newEdgeId] = [uniqueId(), uniqueId()];
          const newNode: SweepCanvasNode = {
            id: newNodeId,
            position: {
              column: node.position.column + 1,
              row: node.position.row,
            },
            parentId: node.parentId,
            type: CanvasElementType.EDIT,
            name: '',
            objectType: node.objectType,
            originStepName: node.name,
          };

          const newEdge: SweepCanvasEdge = {
            id: newEdgeId,
            source: node.id,
            target: newNode.id,
            data: {
              label: '',
              type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
            },
          };
          setTemporaryTransformations({
            newNode,
            newEdge,
            nodeTransformations: _keyBy(nodeTransformations, 'id'),
            edgeTransformations: { [newEdge.id]: newEdge },
          });
        };
        withAnimation({
          nodeTransformations,
          onComplete: onAnimationComplete,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterSweepNodesByParentId, sweepNodes, withAnimation],
  );

  const onEditNameConfirm = useCallback(
    async (id: string, nodeName: string) => {
      if (nodeName.length === 0) {
        setTemporaryTransformations(undefined);
        return;
      }
      const shadowNode = temporaryTransformations?.newNode;
      if (shadowNode) {
        shadowNode.name = nodeName;
        shadowNode.type =
          shadowNode.type === CanvasElementType.EDIT_NB
            ? CanvasElementType.NURTURING_BUCKET
            : CanvasElementType.REGULAR;
      }
      const shadowEdge = temporaryTransformations?.newEdge;
      if (shadowEdge) {
        shadowEdge.data.type = SweepCanvasReactFlowEdgeDataType.SIMPLE;
        shadowEdge.data.label = '0';
      }
      setTemporaryTransformations(undefined);
      if (onSweepNodesChange && shadowNode) {
        const nodesToMove: NodeChangeMoveNode[] = temporaryTransformations?.nodeTransformations
          ? Object.keys(temporaryTransformations?.nodeTransformations).map((key) => {
              const nt = temporaryTransformations?.nodeTransformations[key];
              return {
                nodeId: nt.id,
                newPosition: nt.position,
                oldPosition: findSweepNodeById(sweepNodes, nt.id)
                  ?.position as SweepCanvasNodePosition,
              };
            })
          : [];
        const pos =
          shadowNode?.parentId && reactFlowInstance?.getNode(shadowNode.parentId)?.position;

        await onSweepNodesChange({
          type: SweepNodesChangeType.AddNode,
          change: {
            newNode: shadowNode,
            originNodeId: temporaryTransformations?.originNode?.id,
            newEdge: shadowEdge,
            edgeTransformations: temporaryTransformations?.edgeTransformations,
            nodesToMove,
            newGroup: temporaryTransformations.newGroup,
            parentCanvasNodePosition: reactFlowPositionToCanvasIndexPosition(pos || { x: 0, y: 0 }),
          },
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      temporaryTransformations?.edgeTransformations,
      temporaryTransformations?.newEdge,
      temporaryTransformations?.newGroup,
      temporaryTransformations?.newNode,
      temporaryTransformations?.nodeTransformations,
      temporaryTransformations?.originNode?.id,
    ],
  );

  const onEditLabelCancel = useCallback(
    (nodeId: string, parentId: string, wasValidatedOnBlur?: boolean) => {
      if (wasValidatedOnBlur) return;
      const change: SweepNodesChangeEscapeFromShadowStep = {
        type: SweepNodesChangeType.EscapeFromShadowStep,
        change: { nodeId, parentId },
      };
      onSweepNodesChange && onSweepNodesChange(change);
      setTemporaryTransformations(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSweepNodesChange],
  );

  const editNameValidateClbk = useCallback(
    (parentId: string) => (value: string) => {
      if (onNodeNameChange) {
        const res = onNodeNameChange(value, parentId as string);
        if (!res.isValid) {
          return {
            isValid: false,
            error: res.error,
          };
        }
      }
      return {
        isValid: true,
      };
    },
    [onNodeNameChange],
  );
  const { contextZoomType, visibilityMap } = useContext(InternalCanvasCtx);

  const reactFlowNodes = useMemo(() => {
    // New Step

    const groupNodeIds = new Set(groupNodes.map((group) => group.id));

    const _internalReactFlowNodes: ReactFlowNode<RFNodeData>[] = internalSweepNodes
      .filter((node) => disableGroups || groupNodeIds.has(node.parentId || '')) // Filters nodes with no parents
      .map((internalNode) => {
        const dropInvalidReason = getDropInvalidReason(
          _canvasIndexPositionToReactFlowPosition(internalNode.position),
          internalNode.id,
        );

        const hasTargetButton = () => {
          const connectionsTo = sweepEdges.filter((edge) => edge.source === internalNode.id);
          const onlyConnectionWithinFunnel = connectionsTo.filter(
            (connection) => connection?.data?.type !== SweepCanvasReactFlowEdgeDataType.REMOVABLE,
          );

          if (onlyConnectionWithinFunnel.length === 0) {
            return true;
          }

          const sourceNode = findSweepNodeById(sweepNodes, internalNode.id);
          if (isNbNode(sourceNode)) {
            return false;
          }
          return !onlyConnectionWithinFunnel.find((connection) => {
            return !isNbNode(findSweepNodeById(sweepNodes, connection.target));
          });
        };

        const hasSourceButton = () => {
          const connectionsTo = sweepEdges.filter((edge) => edge.target === internalNode.id);
          if (connectionsTo.length === 0) {
            return true;
          }
          return !connectionsTo.find(
            (connection) =>
              (findSweepNodeById(sweepNodes, connection.source)?.position.row || 0) >= 0,
          );
        };

        const sweepNode = internalNode;
        const draggingNodeId = draggingNode?.id;
        const {
          id,
          position: { column, row },
          name,
          objectType,
          metadata,
          parentId,
          halfNode,
        } = sweepNode;
        const isHighlighted =
          !disableNodeHighlight &&
          !!(
            highlightedNodeData?.highlightedNodeId && id === highlightedNodeData.highlightedNodeId
          );
        const showButtonsOnHighlight = highlightedNodeData?.showButtons;
        const isDragging = draggingNodeId && id === draggingNodeId;
        const isNurturingBucket = isNbNode(sweepNode);

        let nodeType: CanvasElementType;
        let draggable = true;

        switch (sweepNode.type) {
          case CanvasElementType.EDIT:
            nodeType = CanvasElementType.EDIT;
            break;
          case CanvasElementType.EDIT_NB:
            nodeType = CanvasElementType.EDIT_NB;
            break;
          case CanvasElementType.GHOST_NODE:
            nodeType = CanvasElementType.GHOST_NODE;
            draggable = false;
            break;
          case CanvasElementType.NURTURING_BUCKET:
          case CanvasElementType.REGULAR:
          default:
            nodeType = isNurturingBucket
              ? CanvasElementType.NURTURING_BUCKET
              : CanvasElementType.REGULAR;
            break;
        }

        const node: ReactFlowNode<RFNodeData> = {
          id,
          position: _canvasIndexPositionToReactFlowPosition({ column, row }),
          className: draggingNodeId === id ? 'dragging' : '',
          selected: id === selectedNodeId,
          parentNode: disableGroups ? undefined : parentId,
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
          data: {
            name,
            objectType,
            onAddNodeClick,
            onAddSourceClick,
            onAddTargetClick,
            onEditNameConfirm,
            onEditNameCancel: onEditLabelCancel,
            onNodeClick,
            onPillClick,
            onConnectClick,
            readonly,
            dropInvalidReason,
            isHighlighted,
            hasSourceButton: hasSourceButton(),
            hasTargetButton: hasTargetButton(),
            showButtonsOnHighlight,
            editNameValidateClbk,
            metadata,
            isInGroupMouseMoveMode,
            holdNodeHighlighted,
            parentId: parentId || '',
            mapForecastCategoryValueToLabel,
            halfNode,
            pills: sweepNode.pills,
            disableStepClick: disableNodeHighlight,
            contextZoomType,
            visibilityMap,
            hideNbButton: sweepNode.hideNbButton,
            showSparkleIcon: sweepNode.showSparkleIcon,
          },
          type: nodeType,
          dragHandle: '.drag-handle',
          zIndex: isHighlighted || isDragging ? 2 : 1,
          draggable,
        };
        return node;
      });
    return _internalReactFlowNodes;
  }, [
    contextZoomType,
    disableGroups,
    disableNodeHighlight,
    draggingNode?.id,
    editNameValidateClbk,
    getDropInvalidReason,
    groupNodes,
    highlightedNodeData?.highlightedNodeId,
    highlightedNodeData?.showButtons,
    holdNodeHighlighted,
    internalSweepNodes,
    isInGroupMouseMoveMode,
    mapForecastCategoryValueToLabel,
    onAddNodeClick,
    onAddSourceClick,
    onAddTargetClick,
    onConnectClick,
    onEditLabelCancel,
    onEditNameConfirm,
    onNodeClick,
    onPillClick,
    readonly,
    selectedNodeId,
    sweepEdges,
    sweepNodes,
    visibilityMap,
  ]);

  return { reactFlowNodes };
};
