import { Box } from '@mui/material';
import { EditableLabel } from '../../../../common/EditableLabel';
import SweepStagesModel from '../../../../../models/stagesModel';
import { colors, IconButton, Tooltip } from '@sweep-io/sweep-design';
import { ConfigurationCanvasPanelHeader } from '../../../configuration-canvas-panel';
import { AiAgent } from '../../../../aiAgentCenter/aiAgentsConsts';
import { Sparkle } from '@sweep-io/sweep-design/dist/icons';
import usePopover from '../../../../../hooks/usePopover';
import AiAgentsMenu from './AiAgentsMenu';

interface StageDialogHeaderProps {
  stageName: string;
  readonly?: boolean;
  funnelDetails: FunnelDetails;
  onStageNameConfirm: (newName: string) => void;
  closeDialog: () => void;
  aiAgents?: AiAgent[];
  onAiAgentSelection: () => void;
}

export const StageDialogHeader = ({
  stageName,
  readonly,
  funnelDetails,
  onStageNameConfirm,
  closeDialog,
  aiAgents,
  onAiAgentSelection,
}: StageDialogHeaderProps) => {
  const { openPopover, closePopover, isPopoverOpen, anchorRef, anchorElement } = usePopover();
  const hasAgents = aiAgents && aiAgents.length > 0;

  return (
    <ConfigurationCanvasPanelHeader onClose={closeDialog}>
      <Box sx={{ display: 'flex', ml: '-10px', gap: 1, alignItems: 'center' }}>
        <EditableLabel
          readonly={readonly}
          value={stageName ?? ''}
          onValueConfirm={onStageNameConfirm}
          maxCharLength={40}
          height={36}
          padding={'8px'}
          inputSx={{
            fontSize: '16px',
            fontWeight: 600,
            borderRadius: '6px',
            color: colors.black,
          }}
          errorSx={{
            fontWeight: 500,
            fontSize: '12px',
            lineHeight: '15px',
          }}
          validateClbk={(value) => {
            const stagesModel = new SweepStagesModel(funnelDetails?.stages ?? []);
            const validationResult = stagesModel.validateStageName(value.trim());
            if (!validationResult.isValid) {
              return {
                isValid: false,
                error: validationResult.error,
              };
            }
            return {
              isValid: true,
            };
          }}
        />
        {hasAgents && (
          <Tooltip title="AI Agent activated" placement="top">
            <IconButton size="small" ref={anchorRef} onClick={openPopover}>
              <Sparkle />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {hasAgents && (
        <AiAgentsMenu
          isOpen={isPopoverOpen}
          aiAgents={aiAgents}
          anchorElement={anchorElement}
          closeMenu={closePopover}
          onItemSelect={onAiAgentSelection}
        />
      )}
    </ConfigurationCanvasPanelHeader>
  );
};
