import { useCallback } from 'react';
import { useFunnelMapFlowPageApi } from './useFunnelMapFlowPageApi';
import { useSelector } from 'react-redux';
import { selectFunnelMap } from '../../../reducers/united-canvas/unitedCanvasReducer';
import { useFunnelSettingsDialog } from './dialogs/funnel-settings-dialog/useFunnelSettingsDialog';
import useSweepNavigate from '../../common/useSweepNavigate';
import { useDispatch } from 'react-redux';
import { useFunnelMapReadOnly } from './read-only/useFunnelMapReadOnly';
import { openFieldsDialog } from '../../../reducers/funnel-flow-page/fieldsReducer';
import { usePanels } from '../../panels/PanelsContext';
import {
  clearDependencies,
  clearDocumentationDialog,
} from '../../../reducers/documentationReducer';
import { UI_EVENTS } from '../../../services/events';
import { documentationPanelType } from '../../documentation/types';
import { automationsPanelType, playbookAlertsPanelType } from '../../Automations/panelTypes';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { usePageHelpers } from '../canvas-pages/united-canvas/usePageHelpers';
import { aiAgentCenterPanelType } from '../../dashboard/aiAgents/aiAgentCenterPanelType';
import useChatsEntryPoint from '../../aiAgentCenter/useChatsEntryPoint';

export const useFunnelMapPage = () => {
  const sendBiEvent = useSendBiEvent();
  const { saveAndDeployFunnelDetail } = useFunnelMapFlowPageApi();
  const { openFunnelSettingsDialog } = useFunnelSettingsDialog();

  const { deploymentId, enableRestore } = usePageHelpers();
  const { funnelMapName: readOnlyName } = useFunnelMapReadOnly();

  const { goBack } = useSweepNavigate();
  const dispatch = useDispatch();
  const { maybeOpenPanel } = usePanels();

  const funnelMap = useSelector(selectFunnelMap);
  const funnelsData = funnelMap.funnelsData;
  const isFunnelMapEmpty = funnelsData && Object.keys(funnelsData).length === 0;

  const getFunnelName = () => {
    return deploymentId || enableRestore ? readOnlyName : funnelMap.name;
  };

  const deployFunnelDetail = useCallback(
    async ({
      orgId,
      funnelId,
      onFailedSaveCallback,
    }: {
      orgId: string;
      funnelId?: string;
      onFailedSaveCallback: () => void;
    }) => {
      if (!funnelId) {
        throw new Error('funnelId not set on deployment callback');
      }
      if (funnelId && funnelMap.funnelsData[funnelId]) {
        const ret = await saveAndDeployFunnelDetail({
          funnelId,
          funnelDetail: funnelMap.funnelsData[funnelId].funnelDetails,
          orgId,
          onFailedSaveCallback,
        });
        return ret;
      }
    },
    [funnelMap.funnelsData, saveAndDeployFunnelDetail],
  );

  const getOnSettingsClickPayload = useCallback(() => {
    if (funnelMap) {
      const { funnelsData } = funnelMap;

      return {
        id: funnelMap.id,
        name: funnelMap.name,
        funnels: Object.values(funnelsData),
      };
    }
  }, [funnelMap]);

  const onSettingsClick = useCallback(() => {
    const payload = getOnSettingsClickPayload();
    if (payload) {
      openFunnelSettingsDialog(payload);
    }
  }, [getOnSettingsClickPayload, openFunnelSettingsDialog]);

  const onBackCallback = () => goBack();

  const onAutomationsClick = useCallback(() => {
    sendBiEvent({ name: UI_EVENTS.canvasOpenAutomation });
    if (funnelMap.funnelsData) {
      maybeOpenPanel({
        panelType: automationsPanelType,
      });
    }
  }, [funnelMap.funnelsData, maybeOpenPanel, sendBiEvent]);

  const onAlertsClick = useCallback(() => {
    sendBiEvent({ name: UI_EVENTS.canvasOpenAlerts });
    if (funnelMap.funnelsData) {
      maybeOpenPanel({
        panelType: playbookAlertsPanelType,
      });
    }
  }, [funnelMap.funnelsData, maybeOpenPanel, sendBiEvent]);

  const { isOpened, toggleIsOpened } = useChatsEntryPoint(); //TODO use directly useAiAgentCenter SWEEP-5978
  const onAiChatClick = useCallback(() => {
    maybeOpenPanel({
      panelType: aiAgentCenterPanelType,
      //should mark as "opened" in the aiAgentsCenter reducer too
      onOpen: () => {
        if (!isOpened) {
          toggleIsOpened();
        }
      },
    });
  }, [maybeOpenPanel, toggleIsOpened, isOpened]);

  const onFieldsClick = useCallback(() => {
    const funnelMapId = funnelMap.id;
    if (funnelMapId) {
      const funnelsSelectData = Object.values(funnelMap.funnelsData).map(
        ({
          id,
          name,
          funnelDetails: {
            leadingObject: { objectName },
          },
        }) => ({
          id,
          name,
          objectName,
        }),
      );
      dispatch(openFieldsDialog({ funnelMapId, funnelsSelectData }));
    }
  }, [funnelMap.id, funnelMap.funnelsData, dispatch]);

  const onDocumentationClick = useCallback(() => {
    sendBiEvent({ name: UI_EVENTS.canvasOpenDocumentation });
    maybeOpenPanel({
      panelType: documentationPanelType,
      onOpen: () => {
        dispatch(clearDocumentationDialog());
        dispatch(clearDependencies());
      },
    });
  }, [dispatch, maybeOpenPanel, sendBiEvent]);

  return {
    funnelMap,
    isFunnelMapEmpty,
    funnelMapName: getFunnelName(),
    deployFunnelDetail,
    onSettingsClick,
    onBackCallback,
    onAutomationsClick,
    onAlertsClick,
    onFieldsClick,
    getOnSettingsClickPayload,
    onDocumentationClick,
    onAiChatClick,
  };
};
