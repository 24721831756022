export enum ChatCompletionRequestMessageRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}
export type SalesforceConfigurationType = string;

export type AiChatMessageContent = string;

export interface ConfigurationDescription {
  data: string;
  error: string;
}

export interface ChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole;
  content: AiChatMessageContent;
}

export enum AiChatContextType {
  CONFIGURATION = 'configuration',
  FIELD = 'field',
}

export interface AiChatConfigurationContext {
  type: AiChatContextType.CONFIGURATION;
  filename: string;
}

export interface AiChatFieldContext {
  type: AiChatContextType.FIELD;
  fieldName: string;
  objectName: string;
}

export type AiChatContextProperties = AiChatConfigurationContext | AiChatFieldContext;

export type AiChatMetadata = {
  id: string;
  name: string;
  isFavorite: boolean;
  agentId: string;
  agentType: AgentType;
  updatedAt: Date;
};

interface AiChatSystemMessage extends TimedChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole.SYSTEM;
}

export interface AiChatUserMessage extends TimedChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole.USER;
}

export interface AiChatAssistantMessage extends TimedChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole.ASSISTANT;
  isError: boolean;
}

export type AiChatMessage = AiChatSystemMessage | AiChatUserMessage | AiChatAssistantMessage;

export interface AiChatDetails {
  messages: AiChatMessage[];
}

export interface TimedChatGptPromptMessage extends ChatGptPromptMessage {
  createdAt: Date;
}

export type NewChatResponse = {
  id: string;
  name: string;
  message: AiChatAssistantMessage;
};

export enum AgentType {
  Documentation = 'Documentation',
  ProcessOptimization = 'Process Optimization',
}

export enum AgentFileAllowedExtension {
  txt = 'txt',
  json = 'json',
  xml = 'xml',
  doc = 'doc',
  docx = 'docx',
  xls = 'xls',
  xlsx = 'xlsx',
  csv = 'csv',
  tsv = 'tsv',
  log = 'log',
  ini = 'ini',
  env = 'env',
  md = 'md',
  rst = 'rst',
  tex = 'tex',
  toml = 'toml',
  nacl = 'nacl',
  config = 'config',
  plist = 'plist',
}

export interface AgentFileUpdatableProperties {
  name: string;
  type: AgentFileAllowedExtension;
  content: string;
}

export interface AgentFileProperties {
  id: string;
  name: string;
  type: AgentFileAllowedExtension;
  createdById: string;
  createdAt: Date;
}

export interface AgentContextComponent {
  funnelId: string;
  stagesIds: string[];
}

export interface AiAgentProperties {
  id: string;
  name: string;
  type: AgentType;
  contextComponents: AgentContextComponent[] | null;
  instructions: string | null;
  files: AgentFileProperties[];
  crmOrgId: string;
  createdAt: Date;
  updatedAt: Date;
}

export const AI_PROCESS_OPTIMIZATION_AGENT_RECOMMENDATIONS_USER_PROMPT =
  'Highlight process optimization opportunities';

export const AGENT_FILE_BYTES_MAX_SIZE = 102400; // max size of agent file is 100kb
