import { Button } from '@sweep-io/sweep-design';
import { CampaignField } from '../campaignTypes';
import { Menu } from '@mui/material';
import { SearchMenuPanel } from '../../../../../common/search-select/SearchMenuPanel';
import { selectCampaignObjectFieldsData } from '../../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { longCampaignFieldName, shortCampaignFieldName } from './helper';
import { keyBy, uniq } from 'lodash';
import { SearchSelectItem } from '../../../../../common/search-select/SearchSelect';
import usePopover from '../../../../../../hooks/usePopover';

export const AddCampaignFieldsButton = ({
  campaignFields,
  onChange,
}: {
  campaignFields: CampaignField[];
  onChange: (campaignFields: CampaignField[]) => void;
}) => {
  const { anchorElement, anchorRef, closePopover, isPopoverOpen, openPopover } = usePopover();

  const fields = useSelector(selectCampaignObjectFieldsData);
  const fieldsById = keyBy(fields, 'id');

  const usedFields = uniq(
    campaignFields.map((field) => {
      const lfn = longCampaignFieldName(field.name);
      return fieldsById[lfn].compoundFieldId ? fieldsById[lfn].compoundFieldId : lfn;
    }),
  );

  const _filteredFields = fields
    .filter((field) => !usedFields.includes(field.id || ''))
    .filter((field) => field.properties.updateable && field.properties.createable)
    .filter((field) => !Boolean(field.compoundFieldId));

  const items: SearchSelectItem<SweepFieldInterface>[] = _filteredFields.map((field) => ({
    label: field.sweepFieldName || '',
    value: field.id || '',
    data: field,
  }));

  const onItemClicked = (item: SearchSelectItem) => {
    const field = item.data as SweepFieldInterface;
    if (!field) return;
    if (field.isCompound) {
      const newFields: CampaignField[] = fields
        .filter((f) => f.compoundFieldId === field.id)
        .map((f) => ({
          isRequired: Boolean(f.isRequired),
          name: f.id || '',
          type: f.fieldType as any,
          value: null,
        }));
      onChange([...campaignFields, ...newFields]);
    } else {
      const newField: CampaignField = {
        isRequired: Boolean(field.isRequired),
        name: shortCampaignFieldName(field.id || ''),
        type: field.fieldType as any,
        value: null,
      };
      onChange([...campaignFields, newField]);
    }

    closePopover();
  };

  return (
    <>
      <Button onClick={openPopover} ref={anchorRef} startIconName="Plus" variant="flat">
        Add fields to form
      </Button>
      <Menu
        open={isPopoverOpen}
        anchorEl={anchorElement}
        onClose={closePopover}
        sx={{
          maxHeight: '600px',
        }}
        anchorOrigin={{
          vertical: -20,
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <SearchMenuPanel items={items} onItemClicked={onItemClicked} />
      </Menu>
    </>
  );
};
