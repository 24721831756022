import { CHAT_AREA_MAX_WIDTH } from './aiAgentsConsts';
import { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import AgentCard from './AgentCard';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import useAiAgents from './useAiAgents';
import CreateNewAgentCard from './CreateNewAgentCard';
import usePermission from '../common/permissions/usePermission';
import { AgentType } from '@server/ai';

const AgentsCardsExpanded = ({
  onSelect,
  toggleExpandButton,
  closeButton,
  onNewAgentClick,
}: {
  onSelect: (agentId: string, agentType: AgentType) => void;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  onNewAgentClick: () => void;
}) => {
  const { agents, agentsIsLoading } = useAiAgents();
  const [isAllowedCreate] = usePermission(['create:ai-agent']);

  return (
    <Stack
      alignItems="center"
      width="100%"
      height="100%"
      sx={{
        backgroundColor: '#ECF8FF',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '24px',
          right: '12px',
        }}
      >
        {toggleExpandButton}
        {closeButton}
      </Box>

      <Stack
        sx={{
          width: '100%',
          overflow: 'auto',
          alignItems: 'center',
        }}
      >
        <Stack
          p="40px 16px"
          gap={3}
          maxWidth={CHAT_AREA_MAX_WIDTH}
          sx={{
            width: `min(${CHAT_AREA_MAX_WIDTH}, 100%)`,
          }}
        >
          <Typography variant="h1-bold" color={colors.grey[800]}>
            Welcome to your AI Agent Center
          </Typography>
          {agentsIsLoading && (
            <Stack pt={5}>
              <CenteredCircularProgress />
            </Stack>
          )}
          {!agentsIsLoading && (
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: '24px',
              }}
            >
              {agents?.map((agent) => (
                <AgentCard
                  key={agent.id}
                  agent={agent}
                  onClick={() => onSelect(agent.id, agent.type)}
                />
              ))}
              {isAllowedCreate && <CreateNewAgentCard key="new" onClick={onNewAgentClick} />}
            </Box>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgentsCardsExpanded;
