import { ConfigurationType, OpenAIConfigurationKeys } from './parser';

export interface PotentialIssue {
  configurationName: string;
  objectName: string;
  configurationType: OpenAIConfigurationKeys;
  apexClassParentId?: string;

  title: string;
  description: string;
  severity: PotentialIssueSeverity;
}

export type PotentialIssueSeverity = 'Low' | 'Medium' | 'High';

export enum ConfigurationWithCounters {
  customFields = 'customFields',
  apexClasses = ConfigurationType.apexClasses,
  apexTriggers = ConfigurationType.apexTriggers,
  recordTypes = ConfigurationType.recordTypes,
  flows = ConfigurationType.flows,
  validationRules = ConfigurationType.validationRules,
  approvalProcesses = ConfigurationType.approvalProcesses,
}

export type OrgConfigurationCounts = Record<ConfigurationWithCounters, number>;

export const AI_QUESTIONS_LIMIT = 5;
