import { Box, SxProps } from '@mui/material';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';
import {
  BooleanSelector,
  BooleanSelectorProps,
} from '../common/rule-builder/selectors/BooleanSelector';
import { DateSelector, DateSelectorProps } from '../common/rule-builder/selectors/DateSelector';
import {
  DatetimeSelector,
  DatetimeSelectorProps,
} from '../common/rule-builder/selectors/DatetimeSelector';
import {
  PicklistSelector,
  PicklistSelectorProps,
} from '../common/rule-builder/selectors/PicklistSelector';
import {
  TextFieldSelector,
  TextFieldSelectorProps,
} from '../common/rule-builder/selectors/TextFieldSelector';
import { TimeSelector, TimeSelectorProps } from '../common/rule-builder/selectors/TimeSelector';
import { SelectValuesFromRecord } from '../common/rule-builder/SelectValuesFromRecord';
import { ApiSweepFieldOperator } from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { useState } from 'react';
import { SELECTOR_VALUE_SEPARATOR } from '../../constants';
import {
  TerritoryListSelector,
  TerritoryListSelectorProps,
} from '../common/rule-builder/selectors/TerritoryListSelector';
import { ListSelector, ListSelectorProps } from '../common/rule-builder/selectors/ListSelector';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { DateTime } from 'luxon';
import { getParentFieldTypeByOperator } from './utils';
import { FieldContext } from '../../types/enums/FieldContext';

interface SweepFieldsValueSelectorProps {
  parentFieldType: string;
  initialValue?: string | string[] | ValueFromAnotherObject;
  onChange: (value: string) => void;
  onSelectValuesFromRecordChange?: (props: OnChangeProps) => void;
  shouldBeDisabled?: boolean;
  field?: SweepField;
  fieldIds?: string[];
  crmOrgId: string;
  sx?: SxProps;
  textFieldSx?: SxProps; // Only inside other components, if passing directly to TextField pls use sx
  placeholder?: string;
  isPicklistMultiple?: boolean;
  showSelectValuesFromRecord?: boolean;
  _operator?: ApiSweepFieldOperator | '';
  objectType?: string;
  valueType: string;
  valueReferencedFieldIds?: string[];
  _valueLabels?: string[];
  removeBorders?: boolean;
  referenceObjectType?: string;
  disableLookupItemsResolve?: boolean;
  disableResolvePolymorphic?: boolean;
  selectValuesFromRecordCustomButtonText?: string;
  valueContext?: FieldContext;
  displayValueContextMenu?: boolean;
}

export interface OnChangeProps {
  _value: string;
  valueReferencedFieldIds?: string[];
  _valueLabels?: string[];
  valueType: SweepCriterionValueTypes;
  valueContext?: FieldContext;
}

const SweepFieldsValueSelector = ({
  parentFieldType,
  initialValue,
  onChange,
  shouldBeDisabled,
  field,
  fieldIds,
  crmOrgId,
  sx,
  textFieldSx,
  placeholder,
  isPicklistMultiple,
  _operator,
  objectType,
  showSelectValuesFromRecord,
  valueType,
  _valueLabels,
  onSelectValuesFromRecordChange,
  removeBorders,
  referenceObjectType = objectType,
  disableLookupItemsResolve,
  disableResolvePolymorphic,
  selectValuesFromRecordCustomButtonText,
  valueContext,
  displayValueContextMenu,
}: SweepFieldsValueSelectorProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const getTextContext = (val: string) => {
    if (valueContext === FieldContext.PRIOR_VALUE) {
      return `Prior value of ${val}`;
    }
    return val;
  };

  const getComponentFromFieldType = () => {
    parentFieldType = getParentFieldTypeByOperator(parentFieldType, _operator);

    if (valueType === SelectorValueTypes.REFERENCE) {
      //display modified textfield for valueType 'Reference'
      return renderTextFieldSelector({
        initialValue: getTextContext(_valueLabels?.join(SELECTOR_VALUE_SEPARATOR) ?? ''),
        onChange,
        shouldBeDisabled: true,
        isReadOnly: shouldBeDisabled,
        sx,
        placeholder,
        isReferencedValue: true,
        onSelectValuesFromRecordChange: (props: OnChangeProps) => {
          onSelectValuesFromRecordChange && onSelectValuesFromRecordChange(props);
        },
        removeBorders,
      });
    }
    if (valueType === SelectorValueTypes.WEBHOOK_RESULT) {
      return renderTextFieldSelector({
        initialValue,
        onChange,
        shouldBeDisabled,
        sx,
        placeholder,
        removeBorders,
      });
    }

    if (typeof initialValue !== 'string') {
      //Arrays mustn't be passed to below components
      initialValue = '';
    }

    if (
      _operator === ApiSweepFieldOperator.IN_LIST ||
      _operator === ApiSweepFieldOperator.NOT_IN_LIST
    ) {
      //display multi selection in autocomplete with tags
      showSelectValuesFromRecord = false;
      return renderListSelector({
        initialValue,
        onChange,
        shouldBeDisabled,
        removeBorders,
      });
    }
    switch (parentFieldType) {
      case SweepFieldTypes.FormulaCheckbox:
      case SweepFieldTypes.Checkbox:
        return renderBooleanSelector({
          initialValue,
          onChange,
          shouldBeDisabled,
          sx,
          placeholder,
          removeBorders,
        });

      case SweepFieldTypes.FormulaNumber:
      case SweepFieldTypes.Number:
      case SweepFieldTypes.FormulaPercent:
      case SweepFieldTypes.Percent:
      case SweepFieldTypes.FormulaCurrency:
      case SweepFieldTypes.Currency:
        return renderNumberSelector({
          initialValue,
          onChange,
          selectorType: 'number',
          shouldBeDisabled,
          sx,
          placeholder,
          removeBorders,
        });

      case SweepFieldTypes.Picklist:
      case SweepFieldTypes.MultiselectPicklist:
      case SweepFieldTypes.Combobox:
        return renderPicklistSelector({
          onChange,
          field,
          shouldBeDisabled,
          chosenValues: initialValue as string,
          fieldIds,
          sx,
          placeholder,
          isMultiple: isPicklistMultiple,
          removeBorders,
          crmOrgId,
        });

      case SweepFieldTypes.FormulaText:
      case SweepFieldTypes.Text:
      case SweepFieldTypes.Email:
      case SweepFieldTypes.Html:
      case SweepFieldTypes.EncryptedText:
      case SweepFieldTypes.Url:
      case SweepFieldTypes.Phone:
        return renderTextFieldSelector({
          initialValue,
          onChange,
          shouldBeDisabled,
          sx,
          placeholder,
          removeBorders,
        });

      case SweepFieldTypes.LongTextArea:
        return renderTextFieldSelector({
          initialValue,
          onChange,
          selectorType: 'textarea',
          shouldBeDisabled,
          sx,
          placeholder,
          removeBorders,
        });

      case SweepFieldTypes.FormulaDate:
      case SweepFieldTypes.Date:
        return renderDateSelector({
          initialValue,
          onChange,
          shouldBeDisabled,
          textFieldSx,
          placeholder,
          removeBorders,
        });

      case SweepFieldTypes.FormulaDateTime:
      case SweepFieldTypes.DateTime:
        return renderDateTimeSelector({
          value: initialValue ? DateTime.fromSQL(initialValue) : undefined,
          onChange: (value) => {
            onChange(value?.toSQL({ includeOffset: false }) ?? '');
          },
          shouldBeDisabled,
          textFieldSx,
          placeholder,
          removeBorders,
        });

      case SweepFieldTypes.FormulaTime:
      case SweepFieldTypes.Time:
        return renderTimeSelector({
          initialValue,
          onChange,
          shouldBeDisabled,
          textFieldSx,
          placeholder,
          removeBorders,
          fullWidth: true,
          viewRenderers: true,
        });

      case SweepFieldTypes.Address:
        showSelectValuesFromRecord = false;
        return renderTerritoryListSelector({
          initialValue,
          onChange,
          shouldBeDisabled,
          sx,
          placeholder,
          removeBorders,
        });

      default:
        return renderTextFieldSelector({
          initialValue,
          onChange,
          shouldBeDisabled,
          sx,
          placeholder,
          removeBorders,
        });
    }
  };

  return (
    <Box
      onMouseEnter={() => showSelectValuesFromRecord && setIsHovered(true)}
      onMouseLeave={() => showSelectValuesFromRecord && setIsHovered(false)}
    >
      {getComponentFromFieldType()}

      {crmOrgId && (
        <SelectValuesFromRecord
          operator={_operator}
          shouldBeDisabled={shouldBeDisabled}
          crmOrgId={crmOrgId}
          objectType={referenceObjectType ?? ''}
          showSelectValuesFromRecord={showSelectValuesFromRecord}
          displayLeadingObjectName={displayValueContextMenu ? false : true}
          __onChange={(props: OnChangeProps) =>
            onSelectValuesFromRecordChange && onSelectValuesFromRecordChange(props)
          }
          valueFieldIds={!Array.isArray(initialValue) ? [] : initialValue}
          _valueLabels={_valueLabels}
          isHovered={isHovered}
          parentFieldType={parentFieldType}
          disableLookupItemsResolve={disableLookupItemsResolve}
          disableResolvePolymorphic={disableResolvePolymorphic}
          selectValuesFromRecordCustomButtonText={selectValuesFromRecordCustomButtonText}
          valueContext={valueContext}
          displayValueContextMenu={displayValueContextMenu}
        />
      )}
    </Box>
  );
};

const renderTextFieldSelector = (props: TextFieldSelectorProps) => {
  return <TextFieldSelector {...props} fullWidth />;
};

const renderPicklistSelector = (props: PicklistSelectorProps) => {
  return <PicklistSelector {...props} />;
};

const renderDateSelector = (props: DateSelectorProps) => {
  return <DateSelector {...props} />;
};

const renderNumberSelector = (props: TextFieldSelectorProps) => {
  return <TextFieldSelector {...props} fullWidth />;
};

const renderBooleanSelector = (props: BooleanSelectorProps) => {
  return <BooleanSelector {...props} />;
};

const renderDateTimeSelector = (props: DatetimeSelectorProps) => {
  return <DatetimeSelector {...props} />;
};

const renderTimeSelector = (props: TimeSelectorProps) => {
  return <TimeSelector {...props} />;
};

const renderTerritoryListSelector = (props: TerritoryListSelectorProps) => {
  return <TerritoryListSelector {...props} />;
};

const renderListSelector = (props: ListSelectorProps) => {
  return <ListSelector {...props} />;
};

export { SweepFieldsValueSelector };
