import useQueryParams from '../../../hooks/useQueryParams';
import { useDispatch, useSelector } from 'react-redux';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import { openPredefinedConfigurationCard } from '../../../reducers/documentationReducer';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { selectParserInfo } from '../../../reducers/global/globalReducer';
import useParser from '../../parser/useParser';

export const useSetDataFromQueryParams = (crmOrgId: string) => {
  const query = useQueryParams();
  const dispatch = useDispatch();

  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const { parseObjectOnDemand } = useParser({ crmOrgId });

  const parserInfo = useSelector(selectParserInfo(crmOrgId));

  const configurationName = query.get('configurationName');
  const objectName = query.get('objectName');
  const apexClassParentId = query.get('apexClassParentId');
  const configurationType = query.get('configurationType');
  const objectType = objectTypesByName[objectName ?? ''];
  const parsedObjectNames = parserInfo?.parserObjects?.map((objectTypeName) => objectTypeName.name);

  useRunOnceWhenTruthy(
    () => {
      if (objectType && configurationName && configurationType) {
        if (!parsedObjectNames?.includes(objectType.objectType)) {
          parseObjectOnDemand(objectType.objectType);
        }

        dispatch(
          openPredefinedConfigurationCard({
            objectType,
            configurationName,
            configurationType,
            apexClassParentId,
          }),
        );
      }
    },
    !!(configurationName && objectType && configurationType),
  );
  return !!(configurationName && objectType && configurationType);
};
