import { Box, Stack } from '@mui/material';
import AgentInfoSmall from './AgentInfoSmall';
import AiAgentChat from './AiAgentChat';
import AgentInfoBig from './AgentInfoBig';
import { AI_AGENTS_INFO, CHAT_AREA_MAX_WIDTH } from './aiAgentsConsts';
import { ReactNode, useCallback } from 'react';
import useAiAgentChats from './useAiAgentChats';
import AiAgentMenu from './AiAgentMenu';
import useAiAgents from './useAiAgents';
import { Button } from '@sweep-io/sweep-design';
import useAgentSettings from './agentSettings/useAgentSettings';
import { Permission } from '@server/permissions';
import usePermission from '../common/permissions/usePermission';

const ChatWithAgentExpanded = ({
  toggleExpandButton,
  closeButton,
}: {
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
}) => {
  const { activeChat, onConfirmMessage, searchText, disableInput } = useAiAgentChats();
  const { renameAgent, activeAgent } = useAiAgents();
  const { openInstructionsDialog, openFilesDialog, dialogs } = useAgentSettings(activeAgent);
  const permissions: Permission[] = ['edit:ai-agent'];
  const [isAllowedToEditAgent] = usePermission(permissions);

  const _onConfirm = useCallback(
    async (message: string) => {
      if (!activeAgent) {
        return;
      }
      await onConfirmMessage({ message, agentId: activeAgent.id, agentType: activeAgent.type });
    },
    [onConfirmMessage, activeAgent],
  );

  if (!activeAgent) {
    return null;
  }

  const { icon, persona, description } = AI_AGENTS_INFO[activeAgent.type];

  return (
    <>
      {isAllowedToEditAgent && dialogs}
      <Stack
        flex={1}
        width="100%"
        sx={{
          overflow: 'hidden',
        }}
      >
        <Stack direction="row" justifyContent="space-between" width="100%" p={2}>
          <AgentInfoSmall
            icon={icon}
            persona={persona}
            name={activeAgent.name}
            onEditName={(name) => renameAgent(activeAgent.id, name)}
            iconSize="40px"
          />
          <Box display="flex" gap={1} alignItems="center">
            {isAllowedToEditAgent && (
              <Button
                variant="outlined"
                size="small"
                startIconName="Files"
                onClick={openFilesDialog}
              >
                Files
              </Button>
            )}
            {isAllowedToEditAgent && (
              <Button
                variant="outlined"
                size="small"
                startIconName="Wrench"
                onClick={openInstructionsDialog}
              >
                Instructions
              </Button>
            )}
            <AiAgentMenu />
            {toggleExpandButton}
            {closeButton}
          </Box>
        </Stack>

        <AiAgentChat
          messages={activeChat?.data?.aiChatDetails?.messages ?? []}
          onConfirm={_onConfirm}
          isLoadingChat={!!activeChat?.isLoading}
          isLoadingResponse={!!activeChat?.data?.isLoading}
          disableInput={disableInput}
          noteAlignment="center"
          maxWidth={CHAT_AREA_MAX_WIDTH}
          searchTermToHighlight={searchText}
          addToChatBeginning={
            <AgentInfoBig
              icon={icon}
              persona={persona}
              name={activeAgent.name}
              description={description}
            />
          }
        />
      </Stack>
    </>
  );
};

export default ChatWithAgentExpanded;
