import { combineReducers } from 'redux';
import environmentsReducer, {
  EnvironmentsState,
} from '../components/pages/environments/environmentsReducer';
import debugInfoReducer, { DebugInfoState } from './debugInfoReducer';
import userInfoReducer, { UserInfoState } from './userInfoReducer';
import notificationsReducer, { NotificationsState } from './notificationsReducer';
import deploymentsReducer, { DeploymentsState } from './deploymentsReducer';
import snapshotsReducer, { SnapshotsState } from './snapshotsReducer';
import canvasLayersReducer, { CanvasLayersState, canvasLayers } from './canvasLayersReducer';
import reportsReducer, { ReportsState } from './reportsReducer';
import assignmentGroupsPageReducer, {
  AssignmentGroupsPageState,
} from './assignmentGroupsPageReducer';
import integrationsReducer, { IntegrationsState } from './integrationsReducer';
import multiFunnelFlowPageReducer, {
  MultiFunnelFlowPageNoHistoryState,
} from './multiFunnelFlowNoHistoryReducer';
import unitedCanvasReducer, {
  UnitedCanvasWithHistoryState,
} from './united-canvas/unitedCanvasReducer';
import commentsReducer, { CommentsState } from './commentsReducer';
import accountUsersReducer, { AccountUsersState } from './accountUsersReducer';
import stageDialogReducer, { StageDialogProps } from './funnel-flow-page/stageDialogReducer';
import fieldsReducer, { FieldsManagementState } from './funnel-flow-page/fieldsReducer';
import newFunnelDialogReducer, { NewFunnelDialogReducerState } from './newFunnelReducer';
import { undoable } from './undoable';
import funnelMapsReducer, { FunnelMapsState } from './funnelMapsReducer';
import territoriesReducer, { TerritoriesState } from './territoriesReducer';
import { DeploymentChangesState, deploymentChangesUi } from './deploymentChangesUiReducer';
import { RequestChangesApiState, requestChangesApi } from './requestChangesApiReducer';
import { notificationsCenter, NotificationsCenterState } from './notificationsCenterReducers';
import sfChangeFeedReducer, { SfChangeFeedState } from './sfChangeFeedReducer';
import { CrmOrgUsersState, crmOrgUsersSlice } from './crmOrgUsersReducer';
import documentationReducer, { DocumentationState } from './documentationReducer';
import sweepFieldsReducer, {
  SweepFieldsReducerState,
  sweepFieldsLabelsSlice,
} from '../sweep-fields/sweepFieldsLabelsReducer';
import { ImportFunnelState, importFunnelSlice } from './importFunnelReducer';
import { hubspotSlice, HubspotState } from './hubspotReducer';
import {
  templatesDialogSlice,
  TemplatesDialogState,
} from '../components/pages/funnel-map-flow/templates/dialog/templatesDialogReducer';
import {
  templateFieldsMappingSlice,
  TemplateFieldsMappingState,
} from '../components/pages/funnel-map-flow/templates/templates-tab/templateFieldsMappingReducer';
import { DevOpsCenterState, devOpsCenterSlice } from './devOpsCenterReducer';
import { globalSlice } from './global/globalReducer';
import { GlobalState } from './global/globalReducerTypes';
import { CanvasPillsState, canvasPillsSlice } from '../components/canvas-pills/canvasPillsReducer';
import {
  CanvasFiltersState,
  canvasFiltersSlice,
} from '../components/pages/configuration-canvas-filters/canvasFiltersReducer';

import { leftPanelSlice, LeftPanelState } from './leftPanelReducer';
import {
  FunnelSettingsDialogProps,
  funnelSettingsDialogSlice,
} from '../components/pages/funnel-map-flow/dialogs/funnel-settings-dialog/funnelSettingsReducer';
import { rollupsPageSlice, RollupsPageState } from './rollupsPageReducer';
import { slackSlice, SlackState } from './slackReducer';
import { roleGroupsSlice, RoleGroupState } from './roleGroupsReducer';
import { tagsSlice, TagsState } from './tagsReducer';
import {
  thirdPartyFunnelsSlice,
  ThirdPartyFunnelsReducerState,
} from '../components/third-party-funnels/thirdPartyFunnelsReducer';
import {
  copilotChatsSlice,
  CopilotChatsState,
} from '../components/copilotChats/copilotChatsReducer';
import { aiChatsSlice, AiChatsState } from '../components/aiAgentCenter/aiChatsReducer';
import { aiAgentsSlice, AiAgentsState } from '../components/aiAgentCenter/aiAgentsReducer';
import {
  aiAgentCenterSlice,
  AiAgentCenterState,
} from '../components/aiAgentCenter/aiAgentCenterReducer';
import { connectOrgSlice, ConnectOrgState } from './connectOrgReducer';

export type RootState = {
  [canvasLayers.name]: CanvasLayersState;
  environments: EnvironmentsState;
  debugInfo: DebugInfoState;
  userInfo: UserInfoState;
  notifications: NotificationsState;
  deployments: DeploymentsState;
  snapshots: SnapshotsState;
  reports: ReportsState;
  assignmentGroupsPage: AssignmentGroupsPageState;
  integrations: IntegrationsState;
  multiFunnelFlowPage: MultiFunnelFlowPageNoHistoryState;
  unitedCanvas: UnitedCanvasWithHistoryState;
  comments: CommentsState;
  accountUsers: AccountUsersState;
  stageDialog: StageDialogProps;
  fieldsManagement: FieldsManagementState;
  newFunnelDialog: NewFunnelDialogReducerState;
  funnelMaps: FunnelMapsState;
  territories: TerritoriesState;
  sfChangeFeed: SfChangeFeedState;
  documentation: DocumentationState;
  [requestChangesApi.name]: RequestChangesApiState;
  [deploymentChangesUi.name]: DeploymentChangesState;
  [notificationsCenter.name]: NotificationsCenterState;
  [crmOrgUsersSlice.name]: CrmOrgUsersState;
  [templatesDialogSlice.name]: TemplatesDialogState;
  [templateFieldsMappingSlice.name]: TemplateFieldsMappingState;
  [sweepFieldsLabelsSlice.name]: SweepFieldsReducerState;
  [importFunnelSlice.name]: ImportFunnelState;
  [hubspotSlice.name]: HubspotState;
  [devOpsCenterSlice.name]: DevOpsCenterState;
  [globalSlice.name]: GlobalState;
  [canvasPillsSlice.name]: CanvasPillsState;
  [canvasFiltersSlice.name]: CanvasFiltersState;
  [leftPanelSlice.name]: LeftPanelState;
  [funnelSettingsDialogSlice.name]: FunnelSettingsDialogProps;
  [rollupsPageSlice.name]: RollupsPageState;
  [slackSlice.name]: SlackState;
  [roleGroupsSlice.name]: RoleGroupState;
  [tagsSlice.name]: TagsState;
  [thirdPartyFunnelsSlice.name]: ThirdPartyFunnelsReducerState;
  [copilotChatsSlice.name]: CopilotChatsState;
  [aiChatsSlice.name]: AiChatsState;
  [aiAgentsSlice.name]: AiAgentsState;
  [aiAgentCenterSlice.name]: AiAgentCenterState;
  [connectOrgSlice.name]: ConnectOrgState;
};

const rootReducer = combineReducers<RootState>({
  [canvasLayers.name]: canvasLayersReducer,
  environments: environmentsReducer,
  debugInfo: debugInfoReducer,
  userInfo: userInfoReducer,
  notifications: notificationsReducer,
  deployments: deploymentsReducer,
  snapshots: snapshotsReducer,
  reports: reportsReducer,
  assignmentGroupsPage: assignmentGroupsPageReducer,
  integrations: integrationsReducer,
  multiFunnelFlowPage: multiFunnelFlowPageReducer,
  unitedCanvas: unitedCanvasReducer,
  comments: undoable(commentsReducer),
  accountUsers: accountUsersReducer,
  stageDialog: stageDialogReducer,
  fieldsManagement: fieldsReducer,
  newFunnelDialog: newFunnelDialogReducer,
  funnelMaps: funnelMapsReducer,
  territories: territoriesReducer,
  sfChangeFeed: sfChangeFeedReducer,
  documentation: documentationReducer,
  [requestChangesApi.name]: requestChangesApi.reducer,
  [deploymentChangesUi.name]: deploymentChangesUi.reducer,
  [notificationsCenter.name]: notificationsCenter.reducer,
  [crmOrgUsersSlice.name]: crmOrgUsersSlice.reducer,
  [templatesDialogSlice.name]: templatesDialogSlice.reducer,
  [templateFieldsMappingSlice.name]: templateFieldsMappingSlice.reducer,
  [sweepFieldsLabelsSlice.name]: sweepFieldsReducer,
  [importFunnelSlice.name]: importFunnelSlice.reducer,
  [hubspotSlice.name]: hubspotSlice.reducer,
  [devOpsCenterSlice.name]: devOpsCenterSlice.reducer,
  [globalSlice.name]: globalSlice.reducer,
  [canvasPillsSlice.name]: canvasPillsSlice.reducer,
  [canvasFiltersSlice.name]: canvasFiltersSlice.reducer,
  [leftPanelSlice.name]: leftPanelSlice.reducer,
  [funnelSettingsDialogSlice.name]: funnelSettingsDialogSlice.reducer,
  [rollupsPageSlice.name]: rollupsPageSlice.reducer,
  [slackSlice.name]: slackSlice.reducer,
  [roleGroupsSlice.name]: roleGroupsSlice.reducer,
  [tagsSlice.name]: tagsSlice.reducer,
  [thirdPartyFunnelsSlice.name]: thirdPartyFunnelsSlice.reducer,
  [copilotChatsSlice.name]: copilotChatsSlice.reducer,
  [aiChatsSlice.name]: aiChatsSlice.reducer,
  [aiAgentsSlice.name]: aiAgentsSlice.reducer,
  [aiAgentCenterSlice.name]: aiAgentCenterSlice.reducer,
  [connectOrgSlice.name]: connectOrgSlice.reducer,
});

export default rootReducer;
