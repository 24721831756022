import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { EditableLabel } from '../common/EditableLabel';
import usePermission from '../common/permissions/usePermission';

const AgentInfoSmall = ({
  icon,
  name,
  persona,
  onEditName,
  isTiny,
  iconSize,
}: {
  icon: ReactNode;
  name: string;
  persona?: string;
  onEditName?: (name: string) => void;
  isTiny?: boolean;
  iconSize: string;
}) => {
  const [isAllowedRename] = usePermission(['edit:ai-agent']);
  return (
    <Stack direction="row" alignItems="center" gap={1} flex={1}>
      <Box sx={{ flex: `0 0 ${iconSize}`, display: 'flex' }}>{icon}</Box>
      <Stack gap={0.5}>
        {onEditName && isAllowedRename ? (
          <Box sx={{ position: 'relative', left: '-4px' }}>
            <EditableLabel
              onValueConfirm={onEditName}
              value={name}
              padding={'2px 4px'}
              height={24}
              inputSx={{ fontSize: isTiny ? '15px' : '18px', fontWeight: 500 }}
            />
          </Box>
        ) : (
          <Typography variant={isTiny ? 'h5' : 'h4'} color={colors.grey[900]}>
            {name}
          </Typography>
        )}
        {persona && (
          <Typography variant="caption" color={colors.grey[700]}>
            {persona}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default AgentInfoSmall;
