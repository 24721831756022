import { Outlet } from 'react-router';
import useHandleCrmOrgInRoute from '../../hooks/useHandleCrmOrgInRoute';
import useGlobalDataFetcherListener from '../../hooks/global-reducer/useGlobalDataFetcherListener';
import { useFetchUponCrmOrgChange } from '../../hooks/useFetchUponCrmOrgChange';

const HomeInitialized = () => {
  //Here goes initializations that DO require "crmOrgs" or "hubspot" to be initialized:
  useGlobalDataFetcherListener();
  useHandleCrmOrgInRoute();
  useFetchUponCrmOrgChange();

  return <Outlet />;
};

export default HomeInitialized;
