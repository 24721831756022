import { BaseDialog } from '../../../common/dialogs/BaseDialog';
import { IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import { GetEventToSend } from './useConnectToSf';
import ConnectToSfInDialog from './ConnectToSfInDialog';

interface ConnectToSfDialogProps {
  onFinish: () => void;
  forceProduction: boolean;
  closeDialog: () => void;
  getEventToSend?: GetEventToSend;
}

const ConnectToSfDialog = ({
  onFinish,
  forceProduction,
  closeDialog,
  getEventToSend,
}: ConnectToSfDialogProps) => {
  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          display: 'flex',
          width: '726px',
          height: '396px',
          overflow: 'hidden',
        },
      }}
    >
      <Box sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}>
        <IconButton variant="flat" size={'large'} onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>

      <ConnectToSfInDialog
        onFinish={onFinish}
        forceProduction={forceProduction}
        getEventToSend={getEventToSend}
      />
    </BaseDialog>
  );
};

export default ConnectToSfDialog;
