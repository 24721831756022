import ChevronRight from '@mui/icons-material/ChevronRight';
import { MenuItem, Box, ListItemIcon, Menu, Divider } from '@mui/material';
import * as React from 'react';
import { StyledSwitch } from '../StyledSwitch';
import { Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { ActionsMenuAction } from './ActionsMenu';
import { Check } from '@sweep-io/sweep-design/dist/icons';

export interface ActionsMenuItemProps {
  action: ActionsMenuAction;
  handleClose: () => any;
  onClick?: (action: ActionsMenuAction) => any;
}

export const ActionsMenuItem = ({ action, handleClose, onClick }: ActionsMenuItemProps) => {
  const [subAnchorEl, setSubAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(subAnchorEl);
  const handleSubMenuClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setSubAnchorEl(event.currentTarget);
  };
  const handleSubItemClose = () => {
    setSubAnchorEl(null);
    handleClose();
  };

  return (
    <>
      <Tooltip title={action.disabled && action.disabledTooltip} placement="right">
        <Box>
          <MenuItem
            sx={{ display: 'flex', ...action.actionSx }}
            key={action.value}
            onClick={(e) => {
              e.stopPropagation();
              if (action.isNested) {
                if (!open) {
                  handleSubMenuClick(e);
                }
              } else if (!action.isToggle) {
                onClick && onClick(action);
                handleClose();
              }
            }}
            disabled={action.disabled}
          >
            {action.isNested ? (
              <>
                <Box flex={1} display="flex" alignItems="center">
                  {action.actionIcon && <ListItemIcon>{action.actionIcon}</ListItemIcon>}
                  {action.label}
                </Box>
                <ChevronRight />
                <Menu
                  disableAutoFocusItem
                  open={open}
                  anchorEl={subAnchorEl}
                  onClose={handleSubItemClose}
                  id="nested-menu"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  {action.nestedTitle && (
                    <Box sx={{ padding: '8px 12px 16px', width: '300px' }}>
                      <Typography variant="caption-bold" color={colors.black}>
                        {action.nestedTitle}
                      </Typography>
                    </Box>
                  )}
                  {action.nestedActions?.map((nestedElem) => {
                    return (
                      <MenuItem
                        sx={nestedElem.actionSx}
                        key={nestedElem.value}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (nestedElem?.value === 'DISABLE_CLICK') {
                            return;
                          }
                          onClick && onClick(nestedElem);
                          handleClose();
                        }}
                        disabled={nestedElem.disabled}
                      >
                        {nestedElem.label}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </>
            ) : (
              <>
                {action.actionIcon && <ListItemIcon>{action.actionIcon}</ListItemIcon>}
                {action?.isSelected ? (
                  <Box
                    sx={{
                      display: 'flex',
                      fontWeight: '600',
                      alignItems: 'center',
                      width: '100%',
                      gap: 3,
                    }}
                  >
                    {action.label}
                    <Check />
                  </Box>
                ) : (
                  action.label
                )}
                {action.isToggle && (
                  <Box sx={{ ml: '50px' }}>
                    <StyledSwitch
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        action.onToggleChange && action.onToggleChange(event.target.checked);
                      }}
                      checked={action.isToggleChecked}
                      disabled={action.disabled}
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Box>
                )}
              </>
            )}
          </MenuItem>
        </Box>
      </Tooltip>
      {action.addBottomDivider && <Divider sx={{ borderColor: colors.grey[200], mt: 1, mb: 1 }} />}
    </>
  );
};
