import { SearchSelect, SearchSelectItem } from '../search-select/SearchSelect';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { partition } from 'lodash';
import { uniqueId } from '../../../lib/uniqueId';
import { useState, useEffect } from 'react';

export const SIMPLE_FIELD_SELECTOR_SUGGESTED = '__suggested';
export const SIMPLE_FIELD_SELECTOR_ALL = '__all';

export const injectSectionsIntoNestedItems = (items: SearchSelectItem<any>[]) => {
  let [suggestedItems, allItems] = partition(items, 'data.isLeadingCandidate');

  if (suggestedItems.length > 0) {
    suggestedItems = [
      { label: 'Suggested', value: SIMPLE_FIELD_SELECTOR_SUGGESTED, isSection: true },
      ...suggestedItems,
    ];
  }
  if (allItems.length > 0) {
    allItems = [{ label: 'All', value: SIMPLE_FIELD_SELECTOR_ALL, isSection: true }, ...allItems];
  }

  return [...suggestedItems, ...allItems];
};
export type SimpleFieldSelectorRef = {
  setValue: (value: string) => void;
};

export const defaultFieldForObjects: ObjectLiteral = {
  Lead: 'Lead.Status',
  Case: 'Case.Status',
  Opportunity: 'Opportunity.StageName',
};

export interface SimpleFieldSelectorProps {
  crmOrgId: string;
  value?: string;
  onChange: (value: string, field: SweepField) => void;
  objectType?: string;
  groupByLeadingCandidates?: boolean;
  filterBy?: (field: SweepField) => boolean;
  disabled?: boolean;
  autoSelectDefaultFieldForObjectType?: boolean;
  showApiNameTooltip?: boolean;
}

export const SimpleFieldSelector = ({
  crmOrgId,
  onChange,
  value,
  objectType,
  groupByLeadingCandidates,
  filterBy,
  disabled,
  autoSelectDefaultFieldForObjectType,
  showApiNameTooltip,
}: SimpleFieldSelectorProps) => {
  const { searchSweepFields } = useSweepFields();

  const [items, setItems] = useState<SearchSelectItem<SweepField>[]>([]);
  useEffect(() => {
    const fetchFields = async () => {
      if (!objectType) return;
      const _objectTypes = await searchSweepFields({
        crmOrgId,
        objectType: [objectType],
        detectLeadingCandidates: groupByLeadingCandidates,
      });
      const fields = _objectTypes.sweepFields[objectType].fields.filter(filterBy || (() => true));

      const items: SearchSelectItem<SweepField>[] = fields.map((field) => ({
        label: field.sweepFieldName || field.sfFieldName || '',
        value: field.id || uniqueId(),
        data: field,
        tooltip: showApiNameTooltip ? <div>{field.sfFieldName}</div> : undefined,
      }));

      if (autoSelectDefaultFieldForObjectType) {
        const defaultField = defaultFieldForObjects[objectType];

        const item = defaultField
          ? items.find((item) => item.data?.sfFieldName === defaultField) // Finds the default field if it exists
          : items.find((item) => item.data?.isLeadingCandidate); // Otherwise, finds the first leading candidate

        item && onChange(item.value, item.data as SweepField);
      }
      setItems(groupByLeadingCandidates ? injectSectionsIntoNestedItems(items) : items);
    };

    objectType && fetchFields();
  }, [
    autoSelectDefaultFieldForObjectType,
    crmOrgId,
    filterBy,
    groupByLeadingCandidates,
    objectType,
    onChange,
    searchSweepFields,
    showApiNameTooltip,
  ]);

  return (
    <SearchSelect
      items={items}
      onChange={(item) => onChange(item.value, item.data as SweepField)}
      dataTestId="simple-field-selector"
      SelectProps={{
        value,
        placeholder: 'Choose field',
      }}
      valueRenderer={(item) => item?.label}
      disabled={disabled}
    />
  );
};
