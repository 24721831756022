import { ConfirmDialog } from '../../common/dialogs/ConfirmDialog';
import { Stack, TextField } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { useState } from 'react';

const AgentInstructionsDialog = ({
  closeDialog,
  onConfirm,
  initialValue,
}: {
  closeDialog: () => void;
  onConfirm: (instructions: string) => Promise<void>;
  initialValue: string;
}) => {
  const [text, setText] = useState(initialValue);

  return (
    <ConfirmDialog
      isOpen={true}
      onCancel={closeDialog}
      handleClose={closeDialog}
      title="Instructions"
      titleVariant="h3"
      dialogContentSx={{ pt: 1, pb: 0 }}
      PaperProps={{
        sx: {
          maxWidth: '950px',
        },
      }}
      onConfirm={async () => {
        await onConfirm(text);
      }}
    >
      <Stack gap={2}>
        <Typography variant="body">
          Add instruction to your AI agent to give it more context about your configuration, ask it
          to focus on certain topics, or to set tone or format for responses.
        </Typography>
        <TextField
          multiline
          fullWidth
          sx={{ textarea: { minHeight: '124px' } }}
          value={text}
          placeholder={`e.g., "Focus on conversion of MQL --> Demo Booked. Keep answers short an focused. Elaborate on the issues your recommendations address."`}
          onChange={(event) => setText(event.target.value)}
        />
      </Stack>
    </ConfirmDialog>
  );
};

export default AgentInstructionsDialog;
