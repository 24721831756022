import { Box, TextField } from '@mui/material';
import { InputWithVariables } from './InputWithVariables';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { Delete } from '@sweep-io/sweep-design/dist/icons';
import { onKeyDownDisableSpaces } from './WebhookActionWrapper';

interface KeyValueTableRowProps {
  readonly?: boolean;
  keyValueSet?: KeyValueStringTemplate;
  onChange: (keyValueSet: KeyValueStringTemplate) => any;
  onDelete?: () => any;
  crmOrgId: string;
  objectName: string;
}
export const KeyValueTableRow = ({
  keyValueSet,
  onChange,
  crmOrgId,
  objectName,
  onDelete,
}: KeyValueTableRowProps) => {
  return (
    <Box
      className="key-value-row"
      sx={{ display: 'flex', alignItems: 'center', mt: '0 !important' }}
    >
      <TextField
        sx={{
          flex: '0 0 135px',
        }}
        value={keyValueSet?.key ?? ''}
        onChange={(event) => {
          onChange({ ...keyValueSet, key: event.target.value } as KeyValueStringTemplate);
        }}
        InputProps={{
          sx: {
            padding: '8px 6px',
            height: '39px',
            borderRadius: 0,
            borderRightWidth: 0,
            '&.Mui-focused fieldset': {
              border: `1px solid ${colors.blue[500]} !important`,
            },
            '& fieldset': {
              borderRightWidth: 0,
              borderBottomWidth: 0,
            },
            '&:hover fieldset': {
              border: `1px solid ${colors.blue[500]}`,
            },
            '& fieldset:focus': {
              border: `1px solid ${colors.blue[500]}`,
            },
          },
        }}
        placeholder={'Key'}
      />
      <Box
        sx={{
          flexGrow: 1,
          minWidth: 0,
          mr: 1,
          '& .quill .ql-container .ql-editor': {
            borderBottomWidth: '0px',
            '&:focus': {
              border: `1px solid ${colors.blue[500]}`,
            },
          },
          '&:hover .quill .ql-editor': {
            border: `1px solid ${colors.blue[500]}`,
          },
        }}
      >
        <InputWithVariables
          isPreventLineBreaks={true}
          customOnKeyDown={onKeyDownDisableSpaces}
          crmOrgId={crmOrgId}
          objectName={objectName}
          withBorder={true}
          data={keyValueSet?.value ?? { template: '', variables: [] }}
          onChange={(newMsg) => {
            onChange({ ...keyValueSet, value: newMsg } as KeyValueStringTemplate);
          }}
        />
      </Box>
      <Box sx={{ flex: '0 0 25px' }}>
        <IconButton onClick={onDelete} size="tiny" variant="flat">
          <Delete />
        </IconButton>
      </Box>
    </Box>
  );
};
