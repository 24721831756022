import { Box, MenuItem } from '@mui/material';
import { Sections, RoleLevel, RoleLevels } from '@server/role-group-interface';
import SweepSelect from '../../../common/SweepSelect';
import {
  featureTranslations,
  roleTranslations,
  temporarilyFilterHubspotRequestToDeploy,
} from '../utils';
import { colors, Typography } from '@sweep-io/sweep-design';

interface AccountBasedRoleLevelsProps {
  roleLevels: RoleLevels;
  isEditable: boolean;
  onPermissionChange: (key: string, value: string | RoleLevels) => void;
}

export const AccountBasedRoleLevels = ({
  roleLevels,
  isEditable,
  onPermissionChange,
}: AccountBasedRoleLevelsProps) => {
  return (
    <Box>
      {Sections.map((roleLevel) => {
        const groupValue = roleLevels?.[roleLevel];

        return (
          <Box
            key={roleLevel}
            display="flex"
            alignItems="center"
            padding="10px"
            pl="2px"
            height="52px"
            sx={{
              '&:hover': {
                backgroundColor: isEditable ? colors.grey[100] : 'transparent',
              },
            }}
          >
            <Box minWidth="166px">
              <Typography variant="caption">
                {featureTranslations[roleLevel as keyof typeof featureTranslations]}
              </Typography>
            </Box>

            <Box width="100%">
              {isEditable && (
                <SweepSelect
                  FormControlProps={{ sx: { width: '100%' } }}
                  SelectProps={{
                    value: groupValue ?? '',
                    placeholder: 'Select',
                    onChange: (event) => {
                      onPermissionChange('levels', {
                        ...roleLevels,
                        [roleLevel]: event.target.value as RoleLevel,
                      });
                    },
                  }}
                >
                  {Object.keys(RoleLevel)
                    .filter(temporarilyFilterHubspotRequestToDeploy(roleLevel))
                    .map((roleKey) => (
                      <MenuItem key={roleKey} value={RoleLevel[roleKey as keyof typeof RoleLevel]}>
                        {roleTranslations[roleKey as keyof typeof RoleLevel]}
                      </MenuItem>
                    ))}
                </SweepSelect>
              )}

              {!isEditable && (
                <Typography variant="caption" color={colors.grey[800]}>
                  {roleTranslations[groupValue as keyof typeof RoleLevel]}
                </Typography>
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
