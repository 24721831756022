import { DateTime } from 'luxon';
import { BooleanSelector } from '../../../../common/rule-builder/selectors/BooleanSelector';
import { DateSelector } from '../../../../common/rule-builder/selectors/DateSelector';
import { DatetimeSelector } from '../../../../common/rule-builder/selectors/DatetimeSelector';
import { TextFieldSelector } from '../../../../common/rule-builder/selectors/TextFieldSelector';
import {
  HsPropertyTypes,
  HsFilterOperatorsOperators,
  HsOption,
  HsPropertyFieldTypes,
} from '../hubspot.types';
import { TagItem, TextFieldWithTags } from '../../../../common/TextFieldWithTags';
import { MenuItem } from '@mui/material';
import SweepSelect from '../../../../common/SweepSelect';
import { InlineAutocomplete } from '../../../../common/InlineAutocomplete/InlineAutocomplete';

interface HubspotFieldsOperatorSelectorProps {
  hsPropertyType: HsPropertyTypes;
  hsPropertyFieldType?: HsPropertyFieldTypes;
  operator: HsFilterOperatorsOperators;
  value?: string | number | boolean | string[];
  onChange: (value: string | number | boolean | string[]) => void;
  disabled?: boolean;
  options: HsOption[];
  removeBorders?: boolean;
}

export const HubspotFieldsValueSelector = ({
  value,
  onChange,
  operator,
  hsPropertyType,
  hsPropertyFieldType,
  disabled,
  options,
  removeBorders,
}: HubspotFieldsOperatorSelectorProps) => {
  if (
    [HsFilterOperatorsOperators.IS_KNOWN, HsFilterOperatorsOperators.IS_UNKNOWN].includes(operator)
  ) {
    return null;
  }

  switch (hsPropertyType) {
    // case HsPropertyTypes.JSON:
    // case HsPropertyTypes.OBJECT_COORDINATES:
    // case HsPropertyTypes.PHONE_NUMBER:
    case HsPropertyTypes.STRING: {
      return (
        <TextFieldSelector
          initialValue={value as string}
          fullWidth
          removeBorders={removeBorders}
          onChange={(data) => {
            onChange(data);
          }}
          shouldBeDisabled={disabled}
        />
      );
    }
    case HsPropertyTypes.NUMBER: {
      return (
        <TextFieldSelector
          initialValue={value as number}
          fullWidth
          removeBorders={removeBorders}
          selectorType="number"
          onChange={(value) => {
            onChange(parseInt(value));
          }}
          shouldBeDisabled={disabled}
        />
      );
    }
    case HsPropertyTypes.BOOL: {
      return (
        <BooleanSelector
          initialValue={value ? 'true' : 'false'}
          removeBorders={removeBorders}
          onChange={(_value: string) => {
            onChange(_value === 'true');
          }}
          shouldBeDisabled={disabled}
        />
      );
    }
    case HsPropertyTypes.DATE: {
      return (
        <DateSelector
          initialValue={value as string}
          shouldBeDisabled={disabled}
          onChange={onChange}
          removeBorders={removeBorders}
        />
      );
    }
    case HsPropertyTypes.DATE_TIME: {
      return (
        <DatetimeSelector
          value={value ? DateTime.fromSQL(value as string) : undefined}
          shouldBeDisabled={Boolean(disabled)}
          onChange={(value) => {
            onChange(value?.toSQL({ includeOffset: false }) ?? '');
          }}
          removeBorders={removeBorders}
        />
      );
    }
    case HsPropertyTypes.ENUMERATION: {
      if (
        hsPropertyFieldType === HsPropertyFieldTypes.SELECT &&
        operator === HsFilterOperatorsOperators.IS_EXACTLY
      ) {
        return (
          <SweepSelect
            SelectProps={{
              onChange: (event: any) => onChange(event.target.value),
              value: value as string,
            }}
            FormControlProps={{
              fullWidth: true,
            }}
            removeBorders={removeBorders}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SweepSelect>
        );
      }
      const _value: string[] = JSON.parse((value as string) || '[]');
      return (
        <InlineAutocomplete
          items={options || []}
          selectedItemValues={_value}
          headerTxt={''}
          onSelectItem={(item) => {
            onChange([..._value, item.value]);
          }}
          onDeleteItem={(item) => {
            onChange(_value.filter((i) => i !== item.value));
          }}
          disableCreate
          fullWidth
          width={600}
          maxHeight={500}
        />
      );
    }

    case HsPropertyTypes.MULTISTRING: {
      const items: string[] = JSON.parse((value as string) || '[]');
      const tags: TagItem[] = items.map((value) => ({
        value,
        label: value,
        onDelete: (value) => {
          const itemsNew = items.filter((item) => item !== value);
          onChange(JSON.stringify(itemsNew));
        },
      }));

      return (
        <TextFieldWithTags
          sx={{ width: '100%' }}
          tags={tags}
          isValueValid={(value) => items.indexOf(value) === -1}
          onAddValue={(value) => {
            const itemsNew = [...items, value];
            onChange(JSON.stringify(itemsNew));
          }}
          placeholder="Type to add value"
          readonly={disabled}
          removeBorders={removeBorders}
        />
      );
    }
  }
};
