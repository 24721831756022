import { Box } from '@mui/material';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import { ApiSweepFieldOperator } from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { NestedFieldsField, NestedFieldsSelector } from '../fieldsSelectors/NestedFieldsSelector';
import { colors } from '@sweep-io/sweep-design';
import { getNestedPath } from '../../Automations/helper';
import { useCallback } from 'react';

type SelectValuesFromRecordProps = {
  showSelectValuesFromRecord?: boolean;
  operator?: ApiSweepFieldOperator | '';
  shouldBeDisabled?: boolean;
  crmOrgId?: string;
  objectType: string;
  displayLeadingObjectName?: boolean;
  __onChange: Function;
  _valueLabels?: string[];
  valueFieldIds: string[];
  isHovered?: boolean;
  parentFieldType: string;
  disableLookupItemsResolve?: boolean;
  disableResolvePolymorphic?: boolean;
  selectValuesFromRecordCustomButtonText?: string;
  valueContext?: FieldContext;
  displayValueContextMenu?: boolean;
};

export const SelectValuesFromRecord = ({
  operator,
  shouldBeDisabled,
  crmOrgId,
  objectType,
  showSelectValuesFromRecord,
  displayLeadingObjectName,
  __onChange,
  _valueLabels,
  valueFieldIds,
  isHovered,
  parentFieldType,
  disableLookupItemsResolve,
  disableResolvePolymorphic,
  selectValuesFromRecordCustomButtonText = 'Refer to a value from this record',
  valueContext,
  displayValueContextMenu,
}: SelectValuesFromRecordProps) => {
  const _onChange = useCallback(
    async (sweepField: NestedFieldsField) => {
      __onChange({
        _valueLabels: sweepField.fieldLabels,
        _value: sweepField.fieldIds,
        valueType: SelectorValueTypes.REFERENCE,
        valueContext: sweepField.valueContext,
      });
    },
    [__onChange],
  );
  if (!showSelectValuesFromRecord || shouldBeDisabled || !operator) {
    return <></>;
  }

  return (
    <Box sx={{ position: 'absolute', paddingTop: '5px', zIndex: 5 }}>
      <NestedFieldsSelector
        nestedPath={getNestedPath(valueFieldIds, _valueLabels)}
        displayLeadingObjectName={displayLeadingObjectName}
        objectType={objectType}
        crmOrgId={crmOrgId ?? ''}
        useCustomButton
        customButtonText={selectValuesFromRecordCustomButtonText}
        customButtonSx={{
          textTransform: 'unset',
          color: colors.blue[500],
          transition: '.5s',
          display: isHovered ? 'block' : 'none',
          border: 'none',
        }}
        onChange={_onChange}
        isReferencedValue
        parentFieldType={parentFieldType as SweepFieldTypes}
        disableLookupItemsResolve={disableLookupItemsResolve}
        disableResolvePolymorphic={disableResolvePolymorphic}
        valueContext={valueContext}
        displayValueContextMenu={displayValueContextMenu}
      />
    </Box>
  );
};
