import { DialogContent, DialogActions, SxProps, PaperProps, Box } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import useEventListener from '../useEventListener';
import { DialogLoader } from './DialogLoader';
import { BaseDialog } from './BaseDialog';
import { Typography, Button, IconButton } from '@sweep-io/sweep-design';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { ArrowLeft, Close } from '@sweep-io/sweep-design/dist/icons';

interface ConfirmDialogProps {
  title?: string;
  titleVariant?: SweepTypographyVariants;
  titleComponent?: React.ReactNode;
  onCancel?: () => any;
  onConfirm?: (v?: any) => any;
  handleClose?: (event: any, reason?: string) => any;
  handleBack?: (event: any) => any;
  isOpen: boolean;
  cancelText?: string;
  confirmText?: string;
  PaperProps?: PaperProps;
  children: React.ReactNode;
  dialogContentSx?: SxProps;
  dialogSx?: SxProps;
  confirmButtonType?: 'primary' | 'error';
  dialogActionsSx?: SxProps;
  disableOnBackdrop?: boolean;
  disableConfirmButton?: boolean;
  hideBackdrop?: boolean;
  showCloseButton?: boolean;
  showBackButton?: boolean;
  enableKeyListener?: boolean;
  maxWidth?: any;
  confirmButtonDataTestId?: string;
  disableHandleCloseOnConfirm?: boolean;
  readonly?: boolean;
  showLoader?: boolean;
  disableKeyBindings?: boolean;
  showOnlyConfirm?: boolean;
  disableAutoFocus?: boolean;
  disableEnforceFocus?: boolean;
  confirmButtonIconName?: string;
}

const ConfirmDialog = ({
  //dialog state
  isOpen,
  onCancel,
  onConfirm,
  handleClose,
  handleBack,
  disableOnBackdrop,
  hideBackdrop,
  enableKeyListener,
  disableHandleCloseOnConfirm,
  disableAutoFocus,
  disableEnforceFocus,

  //dialog content
  title,
  titleVariant = 'h4',
  titleComponent,
  PaperProps,
  children,
  cancelText,
  confirmText,
  confirmButtonIconName,
  confirmButtonType = 'primary',
  disableConfirmButton,
  showCloseButton,
  showBackButton,
  showOnlyConfirm,

  //dialog CSS
  maxWidth,
  dialogSx,
  dialogContentSx,
  dialogActionsSx,

  //other
  confirmButtonDataTestId = 'confirm-dialog-confirm-button',
  readonly,
  showLoader,
  disableKeyBindings,
}: ConfirmDialogProps) => {
  const handleConfirm = useCallback(
    async (e: any, reason?: string) => {
      setIsWaiting(true);
      try {
        onConfirm && (await onConfirm());

        if (!disableHandleCloseOnConfirm) {
          handleClose && handleClose(e, reason);
        }

        setIsWaiting(false); //If onConfirm leaves dialog open eg. when errors
      } catch (e) {
        setIsWaiting(false);
      }
    },
    [disableHandleCloseOnConfirm, onConfirm, handleClose],
  );

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (enableKeyListener) {
        switch (event.key) {
          case 'Enter':
            !disableKeyBindings && handleConfirm && handleConfirm(event);
            break;
        }
      }
    },
    [enableKeyListener, disableKeyBindings, handleConfirm],
  );

  useEventListener('keydown', handleKeyPress);

  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (!isOpen) setIsWaiting(false);
  }, [isOpen]);

  return (
    <BaseDialog
      disableBackdropClickClose={disableOnBackdrop}
      maxWidth={maxWidth}
      open={isOpen}
      onClose={handleClose}
      PaperProps={PaperProps}
      hideBackdrop={hideBackdrop}
      sx={dialogSx}
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={disableEnforceFocus}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={3}
        pb={1}
        className="confirm-dialog-header"
      >
        <Box display="flex" alignItems="center" gap={1} width="100%">
          {showBackButton && (
            <IconButton variant="flat" onClick={handleBack}>
              <ArrowLeft />
            </IconButton>
          )}
          {(title || titleComponent) && (
            //DialogTitle holds typography component itself, they don't provide the way of overwritting variant (so if this piece of code would be inside we get a typo in typo warning)
            <Box data-testid="confirm-dialog-title" width="100%">
              {titleComponent && titleComponent}
              {title && <Typography variant={titleVariant}>{title}</Typography>}
            </Box>
          )}
        </Box>
        {showCloseButton && (
          <Box>
            <IconButton
              onClick={(ev) => handleClose && handleClose(ev, 'closeClick')}
              variant="flat"
              size="small"
            >
              <Close />
            </IconButton>
          </Box>
        )}
      </Box>

      <DialogContent sx={dialogContentSx}>
        {typeof children === 'string' ? (
          <Typography variant="body">{children}</Typography>
        ) : (
          children
        )}
      </DialogContent>

      {!readonly && (
        <DialogActions sx={{ zIndex: 10, ...dialogActionsSx }}>
          {!showOnlyConfirm && (
            <Button
              variant="outlined"
              dataTestId="confirm-dialog-cancel-button"
              onClick={(e: any) => {
                onCancel && onCancel();
                handleClose && handleClose(e);
              }}
              disabled={isWaiting}
              size="small"
            >
              {cancelText ?? 'Cancel'}
            </Button>
          )}

          <Button
            endIconName={confirmButtonIconName}
            type={confirmButtonType}
            dataTestId={confirmButtonDataTestId}
            loading={isWaiting}
            disabled={disableConfirmButton}
            onClick={handleConfirm}
            size="small"
          >
            {confirmText ?? 'Done'}
          </Button>
        </DialogActions>
      )}
      <DialogLoader visible={showLoader} />
    </BaseDialog>
  );
};

export { ConfirmDialog };
