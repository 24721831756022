import { useDispatch, useSelector } from 'react-redux';
import {
  ChildRelationshipObjectResponse,
  CrmOrgCreateDto,
  CrmOrgEmailTemplatesResponse,
  CrmOrgOAuthDto,
  CrmOrgPlatforms,
  useCrmOrgsApiFacade,
} from '../../../apis/facades/useCrmOrgsApiFacade';

import {
  setCrmOrgs,
  setCrmOrg,
  addCrmOrg,
  deleteCrmOrg,
  addFetchingToOrgId,
  updatePermissionSetGroupAssigned,
  selectDefaultCreationEnvironment,
} from './environmentsReducer';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { clearSweepFieldsCache } from '../../../sweep-fields/useCachedSweepFields';
import { setCrmOrgUsers, setCrmOrgUsersBase } from '../../../reducers/crmOrgUsersReducer';
import { useCallback } from 'react';
import { EnvironmentTypes } from './EnvironmentTypeEnum';
import {
  CrmOrgConnectingError,
  getCrmOrgTransientResult,
  setCrmOrgTransientData,
} from './connectCrmSessionHelper';
import { waitForWindowToClose } from '../helper';
import { ACTIONS_EVENTS, BiEvent } from '../../../services/events';
import { telemetry } from '../../../telemetry';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { retry } from 'ts-retry-promise';
import useUserInfo from '../../../hooks/useUserInfo';

interface ConnectErrorResultAbstract {
  result: 'error' | 'success';
  crmOrg: CrmOrg;
}

interface ConnectErrorResult extends ConnectErrorResultAbstract {
  result: 'error';
  error: CrmOrgConnectingError;
}

interface ConnectSuccessResult extends ConnectErrorResultAbstract {
  result: 'success';
}

type ConnectResult = ConnectErrorResult | ConnectSuccessResult;

const waitForSalesforceOAuthWindowResponse = async (_window: Window, crmOrgId: string) => {
  await waitForWindowToClose(_window);
  const result = getCrmOrgTransientResult(crmOrgId);
  return result;
};

const useCrmOrgs = () => {
  const dispatch = useDispatch();
  const {
    get_crmOrgs,
    get_crmOrg,
    delete_crmOrg,
    post_crmOrg,
    get_crmOrgUsers,
    get_crmOrgUsersBase,
    patch_crmOrg,
    post_crmOrgSalesforceOauthCode,
    post_crmOrgFetch,
    get_crmOrgEmailTemplates,
    get_crmOrgsQueues,
    get_crmOrgsObjectTypesChildRelationshipNames,
    get_crmOrgSalesforceOAuthUrl,
    post_assignPermissionSetGroup,
  } = useCrmOrgsApiFacade();
  const sendBiEvent = useSendBiEvent();

  const defaultCreationEnv = useSelector(selectDefaultCreationEnvironment);
  const { updateDefaultCreationCrmOrgIdIfNecessary } = useUserInfo();

  const getCrmOrgUsers = useCallback(
    async (includeInactive?: boolean) => {
      if (!defaultCreationEnv) {
        throw new Error('No Default Creation Environment');
      }
      const responseData = await get_crmOrgUsers({
        orgId: defaultCreationEnv.id,
        includeInactive,
      });
      dispatch(
        setCrmOrgUsers({ crmOrgId: defaultCreationEnv.id, crmOrgUsers: responseData.users }),
      );
      return responseData.users;
    },
    [dispatch, get_crmOrgUsers, defaultCreationEnv],
  );

  const getCrmOrgUsersBase = useCallback(
    async (includeInactive?: boolean) => {
      if (!defaultCreationEnv) {
        throw new Error('No Default Creation Environment');
      }
      try {
        const responseData = await get_crmOrgUsersBase({
          orgId: defaultCreationEnv.id,
          includeInactive,
        });
        dispatch(
          setCrmOrgUsersBase({
            crmOrgId: defaultCreationEnv.id,
            crmOrgUsersBase: responseData.users,
          }),
        );
        return responseData.users;
      } catch (err: any) {
        telemetry.captureError(err, {
          message: 'Failed to fetch users.',
        });
        dispatch(setCrmOrgUsersBase({ crmOrgId: defaultCreationEnv.id, crmOrgUsersBase: [] }));
        return [];
      }
    },
    [dispatch, get_crmOrgUsersBase, defaultCreationEnv],
  );

  const getCrmOrgQueues = async (objectType: string) => {
    if (!defaultCreationEnv) {
      throw new Error('No Default Creation Environment');
    }
    const responseData = await get_crmOrgsQueues({ orgId: defaultCreationEnv.id, objectType });

    return responseData.queues;
  };

  const fetchOrg = async (crmOrg: CrmOrg, withReset = false) => {
    const crmOrgId = crmOrg.id;
    dispatch(addFetchingToOrgId({ crmOrgId }));
    clearSweepFieldsCache();
    try {
      await fetchCrmOrg(crmOrg.id, withReset);
    } catch (err: any) {}
  };

  const createCrmOrg = useCallback(
    async ({ name, platform, isMain, isSandbox }: CrmOrgCreateDto) => {
      const crmOrg = await post_crmOrg({ payload: { name, platform, isMain, isSandbox } });
      dispatch(addCrmOrg({ crmOrg }));
      return crmOrg;
    },
    [dispatch, post_crmOrg],
  );

  const createSalesforceCrmOrg = useCallback(
    async ({ name, isMain, isSandbox }: { name?: string; isMain: boolean; isSandbox: boolean }) =>
      createCrmOrg({
        name: name || (Math.random() + 1).toString(36).substring(7),
        platform: CrmOrgPlatforms.Salesforce,
        isMain,
        isSandbox,
      }),
    [createCrmOrg],
  );

  const renameCrmOrg = useCallback(
    async (orgId: string, name: string) => {
      const crmOrg = await patch_crmOrg({ orgId, payload: { name } });
      dispatch(setCrmOrg({ crmOrg }));
    },
    [dispatch, patch_crmOrg],
  );

  const setSalesforceOauthCode = async ({
    crmOrgId,
    authCode,
    isSandbox,
    isOnboardingFastFetch,
  }: CrmOrgOAuthDto) => {
    await post_crmOrgSalesforceOauthCode({
      payload: { crmOrgId, authCode, isSandbox, isOnboardingFastFetch },
    });
  };

  const getConnectedCrmOrgs = useCallback(async () => {
    const crmOrgs = await get_crmOrgs({ connectedOnly: true });
    dispatch(setCrmOrgs({ crmOrgs }));
    return crmOrgs;
  }, [dispatch, get_crmOrgs]);

  const getCrmOrg = useCallback(
    async (orgId: string) => {
      const crmOrg = await get_crmOrg({ orgId });
      dispatch(setCrmOrg({ crmOrg }));
      return crmOrg;
    },
    [dispatch, get_crmOrg],
  );

  const removeCrmOrg = useCallback(
    async (orgId: string, biEvent?: BiEvent) => {
      await delete_crmOrg({ orgId });
      dispatch(deleteCrmOrg({ crmOrgId: orgId }));

      if (biEvent) {
        sendBiEvent(biEvent);
      }
    },
    [delete_crmOrg, dispatch, sendBiEvent],
  );

  const getSalesforceRedirectToOAuthUrl = useCallback(
    async (crmOrgId: string) => {
      const { url } = await get_crmOrgSalesforceOAuthUrl({ crmOrgId });
      return url;
    },
    [get_crmOrgSalesforceOAuthUrl],
  );

  const fetchCrmOrg = async (orgId: string, withReset = false) =>
    post_crmOrgFetch({ orgId, withReset });

  const fetchCrmOrgEmailTemplates = async (
    orgId: string,
  ): Promise<CrmOrgEmailTemplatesResponse> => {
    const { emailTemplates } = await get_crmOrgEmailTemplates({ orgId });
    return {
      emailTemplates,
    };
  };

  const fetchChildRelationshipNames = async (
    orgId: string,
    objectType: string,
  ): Promise<ChildRelationshipObjectResponse> => {
    const { childRelationships } = await get_crmOrgsObjectTypesChildRelationshipNames({
      orgId,
      objectType: objectType as SFDCObjectType,
    });

    return {
      childRelationships,
    };
  };

  const verifyThatOrgIsConnected = useCallback(
    async (crmOrgId: string) => {
      const getConnectedCrmOrg = async () => {
        try {
          const _crmOrg = await getCrmOrg(crmOrgId);
          if (_crmOrg.isConnected) {
            return _crmOrg;
          } else {
            return null;
          }
        } catch (err) {
          telemetry.captureError(err);
          return null;
        }
      };
      try {
        // Retries up to 6 seconds until the the org returns isConnected
        // Fails if timeout
        const _crmOrg = await retry(getConnectedCrmOrg, {
          until: (t) => t !== null,
          timeout: 20000,
          delay: 2000,
        });

        if (_crmOrg) {
          return _crmOrg;
        }
      } catch (err) {
        telemetry.captureError(err);
      }
    },
    [getCrmOrg],
  );

  const connectOrg = useCallback(
    async ({
      crmOrgToReconnect,
      type,
      forceMain = false,
    }: {
      crmOrgToReconnect?: CrmOrg;
      type: EnvironmentTypes;
      forceMain?: boolean;
    }): Promise<ConnectResult> => {
      let crmOrgToConnect = crmOrgToReconnect;
      const isSandbox = type === EnvironmentTypes.Sandbox;
      if (!crmOrgToConnect) {
        crmOrgToConnect = await createSalesforceCrmOrg({
          isMain: type === EnvironmentTypes.Production || forceMain,
          isSandbox,
        });
      }
      setCrmOrgTransientData({
        crmOrgId: crmOrgToConnect.id,
        isSandbox,
      });
      const sfWindow = window.open(
        await getSalesforceRedirectToOAuthUrl(crmOrgToConnect.id),
        '_blank',
      );
      if (!sfWindow) {
        return {
          result: 'error',
          error: CrmOrgConnectingError.ERROR_CONNECTING,
          crmOrg: crmOrgToConnect,
        };
      }
      const result = await waitForSalesforceOAuthWindowResponse(sfWindow, crmOrgToConnect.id);

      if (!result.success) {
        return {
          result: 'error',
          error: result.error,
          crmOrg: crmOrgToConnect,
        };
      }

      const crmOrg = await verifyThatOrgIsConnected(crmOrgToConnect.id);

      if (crmOrg?.isConnected) {
        updateDefaultCreationCrmOrgIdIfNecessary(crmOrg.id);
        return {
          result: 'success',
          crmOrg,
        };
      } else {
        return {
          result: 'error',
          error: CrmOrgConnectingError.ERROR_CONNECTING,
          crmOrg: crmOrgToConnect,
        };
      }
    },
    [
      getSalesforceRedirectToOAuthUrl,
      verifyThatOrgIsConnected,
      createSalesforceCrmOrg,
      updateDefaultCreationCrmOrgIdIfNecessary,
    ],
  );

  const assignPermissionSetGroup = useCallback(
    async ({ crmOrgId, assignToAll }: { crmOrgId: string; assignToAll: boolean }) => {
      try {
        await post_assignPermissionSetGroup({ crmOrgId, assignToAll });
        dispatch(updatePermissionSetGroupAssigned({ crmOrgId }));
        sendBiEvent({
          name: ACTIONS_EVENTS.assignSweepPsg,
          props: { type: assignToAll ? 'All' : 'Single' },
        });
      } catch (e) {
        telemetry.captureError(e);
        throw e;
      }
    },
    [post_assignPermissionSetGroup, dispatch, sendBiEvent],
  );

  return {
    getCrmOrg,
    getConnectedCrmOrgs,
    setSalesforceOauthCode,
    getSalesforceRedirectToOAuthUrl,
    removeCrmOrg,
    renameCrmOrg,
    fetchOrg,
    fetchCrmOrgEmailTemplates,
    fetchChildRelationshipNames,
    createSalesforceCrmOrg,
    getCrmOrgUsers,
    getCrmOrgUsersBase,
    getCrmOrgQueues,
    connectOrg,
    assignPermissionSetGroup,
  };
};

export { useCrmOrgs };
