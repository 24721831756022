import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { ConnectCrmOrgState } from '../components/pages/get-started/connect-org/useConnectToSf';
import { ConnectCrmOrgStateEnum } from '../components/pages/environments/ConnectCrmOrgStateEnum';

export interface ConnectOrgState {
  connectionState: ConnectCrmOrgState;
}

const initialState: ConnectOrgState = {
  connectionState: {
    type: 'connectState',
    connectState: ConnectCrmOrgStateEnum.NotConnected,
  },
};

export const connectOrgSlice = createSlice({
  name: 'connectOrg',
  initialState,
  reducers: {
    setConnectionState: (state, action: PayloadAction<{ connectionState: ConnectCrmOrgState }>) => {
      state.connectionState = action.payload.connectionState;
    },
  },
});

export const { setConnectionState } = connectOrgSlice.actions;

export const selectConnectionState = (state: RootState) => state.connectOrg.connectionState;

export default connectOrgSlice.reducer;
