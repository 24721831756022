import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import {
  deleteChatsForAgent as deleteChatsForAgentAction,
  selectActiveChatId,
  selectActiveIsEmptyChat,
  selectChatsDetails,
  setActiveChat,
} from './aiChatsReducer';
import { useAiAgentApiFacade } from '../../apis/facades/useAiAgentApiFacade';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { telemetry } from '../../telemetry';
import {
  addAgent,
  deleteAgent as deleteAgentAction,
  renameAgent as renameAgentAction,
  selectAgents,
  selectAgentsIsLoading,
  addAgentFiles as addAgentFilesAction,
  setAgentInstructions,
  setAgents,
  setAgentsLoading,
  deleteAgentFile,
} from './aiAgentsReducer';
import { AgentType, AgentContextComponent } from '@server/ai';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';
import { uniqueId } from '../../lib/uniqueId';
import { NewAgentFile } from './aiAgentsConsts';

const useAiAgents = () => {
  const {
    get_agents,
    rename_agent,
    delete_agent,
    create_agent,
    edit_agent_instructions,
    post_agent_files,
    delete_agent_file,
  } = useAiAgentApiFacade();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const _crmOrgId = crmOrgId ?? undefined;
  const chatsDetails = useSelector(selectChatsDetails(_crmOrgId));
  const activeChatId = useSelector(selectActiveChatId(_crmOrgId));
  const agents = useSelector(selectAgents(_crmOrgId));
  const agentsIsLoading = useSelector(selectAgentsIsLoading(_crmOrgId));
  const activeIsEmptyChat = useSelector(selectActiveIsEmptyChat(_crmOrgId));
  const { noConnectedOrg } = useUnhappyStates();
  const isNoOrgs = noConnectedOrg && !agents;

  const activeChat = activeChatId ? chatsDetails?.[activeChatId] : undefined;

  const activeAgent = useMemo(
    () => agents?.find((agent) => agent.id === activeChat?.data?.agentId),
    [agents, activeChat?.data?.agentId],
  );

  const dispatch = useDispatch();

  const fetchAiAgents = useCallback(async () => {
    if (crmOrgId) {
      try {
        dispatch(setAgentsLoading({ crmOrgId }));
        const agents = await get_agents();
        const orderedAgents = agents.toSorted((a) => (a.type === AgentType.Documentation ? -1 : 1));
        dispatch(
          setAgents({
            crmOrgId,
            agents: orderedAgents,
          }),
        );
      } catch (e) {
        telemetry.captureError(e);
      }
      return;
    }
    if (isNoOrgs) {
      dispatch(
        setAgents({
          agents: [
            {
              id: uniqueId(),
              name: 'Documentation Agent',
              type: AgentType.Documentation,
              updatedAt: new Date().toISOString(),
              contextComponents: [],
              files: [],
              instructions: null,
            },
          ],
        }),
      );
    }
  }, [crmOrgId, dispatch, get_agents, isNoOrgs]);

  const renameAgent = useCallback(
    async (agentId: string, name: string) => {
      if (!crmOrgId) return;
      try {
        await rename_agent(agentId, { name });
        dispatch(renameAgentAction({ crmOrgId, agentId, name }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, dispatch, rename_agent],
  );
  const editAgentInstructions = useCallback(
    async (agentId: string, instructions: string) => {
      if (!crmOrgId) return;
      try {
        await edit_agent_instructions(agentId, { instructions });
        dispatch(setAgentInstructions({ crmOrgId, agentId, instructions }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, dispatch, edit_agent_instructions],
  );
  const addAgentFiles = useCallback(
    async (agentId: string, files: NewAgentFile[]) => {
      if (!crmOrgId) return;
      try {
        const res = await post_agent_files(agentId, { files });
        dispatch(addAgentFilesAction({ crmOrgId, agentId, files: res }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, dispatch, post_agent_files],
  );
  const deleteFile = useCallback(
    async (agentId: string, fileId: string) => {
      if (!crmOrgId) return;
      try {
        await delete_agent_file(agentId, fileId);
        dispatch(deleteAgentFile({ crmOrgId, agentId, fileId }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, delete_agent_file, dispatch],
  );
  const deleteAgent = useCallback(
    async (agentId: string) => {
      if (!crmOrgId) return;
      try {
        dispatch(deleteAgentAction({ crmOrgId, agentId }));
        dispatch(deleteChatsForAgentAction({ crmOrgId, agentId }));
        dispatch(setActiveChat({ crmOrgId, chatId: undefined }));
        await delete_agent(agentId);
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, dispatch, delete_agent],
  );

  const createAgent = useCallback(
    async ({
      name,
      type,
      contextComponents,
    }: {
      name: string;
      type: AgentType;
      contextComponents: AgentContextComponent[];
    }) => {
      if (!crmOrgId) return;
      try {
        const agent = await create_agent({ name, type, contextComponents });
        dispatch(
          addAgent({
            crmOrgId,
            agent: { ...agent, contextComponents },
          }),
        );
        return agent;
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [crmOrgId, create_agent, dispatch],
  );

  return {
    agents,
    activeAgent,
    fetchAiAgents,
    activeIsEmptyChat,
    agentsIsLoading,
    renameAgent,
    deleteAgent,
    createAgent,
    editAgentInstructions,
    addAgentFiles,
    deleteFile,
  };
};

export default useAiAgents;
