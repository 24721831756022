import { AccountBasedRoleLevels } from './AccountBasedRoleLevels';
import { Fragment, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectCrmOrgs } from '../../../pages/environments/environmentsReducer';
import { Box, Button as MuiButton } from '@mui/material';
import { MultiAutocomplete } from '../../../common/MultiAutocomplete';
import { Checkbox, colors, Typography } from '@sweep-io/sweep-design';
import { MenuItem } from '@mui/material';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import { MandatoryCircle, Plus } from '@sweep-io/sweep-design/dist/icons';
import {
  createRoleGroupAccountBasedStructure,
  translateOrgBasedRoleLevelsIntoMultiOrgsStructure,
} from '../utils';
import { RemoveOrgBasedRoleLevelGroup } from './RemoveOrgBasedRoleLevelsGroup';
import { RoleGroupForMultipleOrgs, useRoleManagerContext } from '../RoleManagerContext';
import { RoleLevels } from '@server/role-group-interface';
import differenceBy from 'lodash/differenceBy';

interface OrgBasedRoleLevelsGroupProps {
  roleGroupLevels: RoleGroupForMultipleOrgs[];
  isEditable: boolean;
}

export const OrgBasedRoleLevelsGroup = ({
  isEditable,
  roleGroupLevels,
}: OrgBasedRoleLevelsGroupProps) => {
  const { setOrgBasedMultiGroupLevels } = useRoleManagerContext();
  const crmOrgs = useSelector(selectCrmOrgs);
  const crmOrgOptions = crmOrgs.map((org) => ({ value: org.id, label: org.name }));
  const ref = useRef<HTMLDivElement>();

  const onAddGroup = useCallback(() => {
    const newOrg = translateOrgBasedRoleLevelsIntoMultiOrgsStructure([
      createRoleGroupAccountBasedStructure(''),
    ]);
    const newRoleGroupLevels = [...roleGroupLevels, ...newOrg];
    setOrgBasedMultiGroupLevels(newRoleGroupLevels);
  }, [roleGroupLevels, setOrgBasedMultiGroupLevels]);

  const onChangeSelectedValues = useCallback(
    (values: { label: string; value: string }[], groupIdx: number) => {
      const withUpdatedCrmOrgIds = roleGroupLevels.map((group, idx) =>
        idx === groupIdx ? { ...group, crmOrgIds: values.map((item) => item.value) } : group,
      );
      setOrgBasedMultiGroupLevels(withUpdatedCrmOrgIds);
    },
    [roleGroupLevels, setOrgBasedMultiGroupLevels],
  );

  const onRemoveSelectedValue = useCallback(
    (crmOrgId: string, groupIdx: number) => {
      const withUpdatedCrmOrgIds = roleGroupLevels.map((group, idx) =>
        idx === groupIdx
          ? { ...group, crmOrgIds: group.crmOrgIds.filter((itemId) => itemId !== crmOrgId) }
          : group,
      );
      setOrgBasedMultiGroupLevels(withUpdatedCrmOrgIds);
    },
    [roleGroupLevels, setOrgBasedMultiGroupLevels],
  );

  const onRemoveMultiGroup = useCallback(
    (groupIdx: number) => {
      const withUpdatedCrmOrgIds = roleGroupLevels.filter((group, idx) => idx !== groupIdx);
      setOrgBasedMultiGroupLevels(withUpdatedCrmOrgIds);
    },
    [roleGroupLevels, setOrgBasedMultiGroupLevels],
  );

  const renderGroup = (multiOrgRoleGroup: RoleGroupForMultipleOrgs, groupIdx: number) => {
    const usedCrmOrgIds = roleGroupLevels.map((groupLevel) => groupLevel.crmOrgIds).flat();
    const selectedCrmOrgs = crmOrgs
      .filter((org) => multiOrgRoleGroup.crmOrgIds.includes(org.id))
      .map((org) => ({ value: org.id, label: org.name }));

    const selectedCrmOrgsIds = selectedCrmOrgs.map((crmOrg) => crmOrg.value);
    const disabledCrmOrgsIds = differenceBy(usedCrmOrgIds, selectedCrmOrgsIds);

    return (
      <Box key={'multiGroup_' + groupIdx}>
        <Box display="flex" gap={1}>
          <MandatoryCircle />
          <Typography variant="caption-medium">Permissions for environments</Typography>
        </Box>

        <Box pl={3} display="flex" pt={1} flexDirection="column" gap={2}>
          <Box display="flex" gap={1} alignItems="center">
            <Box width="100%">
              <MultiAutocomplete
                ref={ref}
                onSelect={(props) => onChangeSelectedValues(props, groupIdx)}
                options={crmOrgOptions}
                onDelete={(id) => onRemoveSelectedValue(id, groupIdx)}
                selectedOptions={selectedCrmOrgs}
                renderOption={(props, option) => {
                  const { key, ...optionProps } = props;
                  return (
                    <MenuItem
                      key={key}
                      value={option.value}
                      {...optionProps}
                      disabled={disabledCrmOrgsIds.includes(option.value)}
                      sx={{
                        padding: '10px 16px !important',
                        marginBottom: '4px',
                        minHeight: '40px',
                      }}
                    >
                      <Checkbox
                        checked={
                          !!multiOrgRoleGroup?.crmOrgIds.includes(option.value) ||
                          disabledCrmOrgsIds.includes(option.value)
                        }
                        disabled={disabledCrmOrgsIds.includes(option.value)}
                      />
                      <TruncatedTextTooltip variant="body">{option.label}</TruncatedTextTooltip>
                    </MenuItem>
                  );
                }}
              />
            </Box>

            <RemoveOrgBasedRoleLevelGroup
              showButton={roleGroupLevels.length > 1}
              onDelete={() => onRemoveMultiGroup(groupIdx)}
            />
          </Box>

          <AccountBasedRoleLevels
            key={multiOrgRoleGroup?.crmOrgIds[0]}
            roleLevels={multiOrgRoleGroup?.levels}
            isEditable={isEditable}
            onPermissionChange={(key, value) => {
              const newMultiGroupLevels = roleGroupLevels.map((group, idx) =>
                idx === groupIdx ? { ...group, levels: value as RoleLevels } : group,
              );
              setOrgBasedMultiGroupLevels(newMultiGroupLevels);
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} height="100%" overflow="auto" pr={1}>
      {roleGroupLevels.map((multiOrgRoleGroup, idx) => (
        <Fragment key={`multiRoleGroup_${idx}`}>{renderGroup(multiOrgRoleGroup, idx)}</Fragment>
      ))}
      <MuiButton
        sx={{
          background: colors.grey[100],
          border: 'none',
          height: '40px',
          '&:hover': {
            background: colors.grey[200],
          },
        }}
        startIcon={<Plus color={colors.blue[500]} />}
        variant="squared"
        onClick={() => onAddGroup()}
      >
        <Box component="span" pl={1}>
          <Typography variant="body-medium" color={colors.blue[500]}>
            Set permissions for another environment
          </Typography>
        </Box>
      </MuiButton>
    </Box>
  );
};
