import { WEBSOCKET_MESSAGES } from '@server/websocket-messages';
import { useDispatch, useSelector } from 'react-redux';
import { useSweepNotifications } from '../components/notifications/useSweepNotifications';
import { useSfChangeFeed } from '../components/pages/sf-change-feed/useSfChangeFeed';
import { setDeploying, showDeploymentReport } from '../reducers/devOpsCenterReducer';
import {
  selectCrmOrgs,
  selectDefaultCreationEnvironment,
  setCrmOrg,
} from '../components/pages/environments/environmentsReducer';
import { setSlackIntegrationStatus, SlackConnectionStatus } from '../reducers/integrationsReducer';
import { setNotificationsHasUpdates } from '../reducers/notificationsCenterReducers';
import { SweepNotificationVariant } from '../reducers/notificationsReducer';
import { updateReport } from '../reducers/reportsReducer';
import { displayChangedRows } from '../reducers/sfChangeFeedReducer';
import { requestRefreshGlobal } from '../reducers/global/globalReducer';
import { useCallback } from 'react';
import { deploymentCompleted } from '../reducers/deploymentsReducer';
import { AutomationDeploySuccessEventMap } from '../components/Automations/helper';
import { ACTIONS_EVENTS } from '../services/events';
import useSendBiEvent from '../hooks/useSendBiEvent';
import { setConnectionState } from '../reducers/connectOrgReducer';

const DEPLOY_SUCCESS = 'Successfully deployed';
const activationCompleteMessages = [WEBSOCKET_MESSAGES.AUTOMATIONS_TOGGLE_ACTIVATION_COMPLETED];

const useSocketHandlers = () => {
  const sendBiEvent = useSendBiEvent();
  const dispatch = useDispatch();
  const defaultEnv = useSelector(selectDefaultCreationEnvironment);
  const environments = useSelector(selectCrmOrgs);
  const { addNotification } = useSweepNotifications();
  const { compareOldFeedWithNewData } = useSfChangeFeed();

  const getCrmOrgById = useCallback(
    (crmOrgId: string) => environments.find((env) => env.id === crmOrgId),
    [environments],
  );

  const fetchCompletedHandler = (payload: any) => {
    const { data, success } = payload;
    const { crmOrg, notifyUser } = data as { crmOrg: CrmOrg; notifyUser: boolean };
    const _message = success
      ? `${crmOrg.name} environment was successfully refreshed`
      : `${crmOrg.name} environment has failed to refresh`;

    if (notifyUser) {
      addNotification({
        variant: success ? SweepNotificationVariant.Success : SweepNotificationVariant.Error,
        message: _message,
      });
    }

    dispatch(setCrmOrg({ crmOrg }));
    dispatch(
      setConnectionState({
        connectionState: { type: 'fetchStatus', fetchStatus: crmOrg.fetchStatus },
      }),
    );
    dispatch(requestRefreshGlobal({ crmOrgId: crmOrg.id }));
  };

  const slackConnectionCompletedHandler = (message: any) => {
    if (message.success) {
      addNotification({
        variant: SweepNotificationVariant.Success,
        message: 'Slack integration is connected now',
      });
      dispatch(setSlackIntegrationStatus(SlackConnectionStatus.Connected));
    } else {
      addNotification({
        variant: SweepNotificationVariant.Error,
        message: 'Slack integration failed to connect',
      });
      dispatch(setSlackIntegrationStatus(SlackConnectionStatus.Error));
    }
  };

  const slackDisconnectionCompletedHandler = (message: any) => {
    if (message.success) {
      addNotification({
        variant: SweepNotificationVariant.Error,
        message: 'Slack integration is no longer connected',
      });
      dispatch(setSlackIntegrationStatus(SlackConnectionStatus.NotInitialized));
    } else {
      addNotification({
        variant: SweepNotificationVariant.Error,
        message: 'Failed to disconnect Slack integration',
      });
      dispatch(setSlackIntegrationStatus(SlackConnectionStatus.Error));
    }
  };

  const reportingActivationCompletedHandler = (response: any) => {
    const report = response.data;
    const message = response.success
      ? `${report?.name || 'Report'} is active in Salesforce`
      : `Activation failed`;

    report && dispatch(updateReport({ newReport: report }));

    addNotification({
      variant: response.success ? SweepNotificationVariant.Success : SweepNotificationVariant.Error,
      message,
    });
  };

  const sfChangeFeedNotificationReceived = async () => {
    if (await compareOldFeedWithNewData()) {
      addNotification({
        variant: SweepNotificationVariant.Default,
        message: `Recent changes are ready to review`,
        action: {
          reduxAction: {
            type: displayChangedRows.type,
          },
          actionText: 'Show changes',
        },
      });
    }
  };

  const notificationsNewHandler = () => {
    dispatch(setNotificationsHasUpdates());
  };

  const automationsDeployCompletedHandler = useCallback(
    (
      results: {
        success: true;
        error_message?: string;
        data: {
          crmOrgId: string;
          isActive?: boolean;
          automationType?: AutomationType;
        };
      }[],
      msgName: WEBSOCKET_MESSAGES,
    ) => {
      if (!defaultEnv) {
        return;
      }
      dispatch(requestRefreshGlobal({ crmOrgId: defaultEnv.id }));
      const allSuccess = results.every((res) => res.success);
      const allFailed = results.every((res) => !res.success);
      const isActivation = activationCompleteMessages.includes(msgName) && results[0].data.isActive;
      const isDeactivation =
        activationCompleteMessages.includes(msgName) && !results[0].data.isActive;

      if (allSuccess) {
        let message = DEPLOY_SUCCESS;
        if (isActivation || isDeactivation) {
          message = `Successfully ${isActivation ? 'activated' : 'deactivated'}`;
        } else {
          if (results[0].data.automationType) {
            sendBiEvent({ name: AutomationDeploySuccessEventMap[results[0].data.automationType] });
          }
        }
        return addNotification({
          keepOpen: false,
          variant: SweepNotificationVariant.Success,
          message,
        });
      }
      if (allFailed) {
        let message = `Failed to deploy due to technical issues. Changes were saved`;
        if (isActivation || isDeactivation) {
          message = `Failed to ${
            isActivation ? 'activated' : 'deactivated'
          } due to technical issues`;
        }
        return addNotification({
          keepOpen: true,
          variant: SweepNotificationVariant.Error,
          message,
        });
      }
      results.forEach((res) => {
        const { data } = res;
        const crmOrg = getCrmOrgById(data.crmOrgId);
        const isDefault = defaultEnv.id === crmOrg?.id;
        const crmOrgName = crmOrg?.name;
        let message = res.success
          ? `${DEPLOY_SUCCESS} to ${crmOrgName}`
          : `Failed to deploy to ${crmOrgName} due to technical issues${isDefault ? '. Changes were saved' : ''}`;

        if (isActivation || isDeactivation) {
          message = res.success
            ? `Successfully ${isActivation ? 'activated' : 'deactivated'} in ${crmOrgName}`
            : `Failed to ${
                isActivation ? 'activate' : 'deactivate'
              } in ${crmOrgName} due to technical issues`;
        }
        addNotification({
          keepOpen: !res.success,
          variant: res.success ? SweepNotificationVariant.Success : SweepNotificationVariant.Error,
          message,
        });
      });
    },
    [defaultEnv, dispatch, addNotification, sendBiEvent, getCrmOrgById],
  );

  const rollupsDeployCompletedHandler = useCallback(
    (
      results: {
        success: true;
        error_message?: string;
        data: {
          crmOrgId: string;
        };
      }[],
    ) => {
      if (!defaultEnv) {
        return;
      }
      dispatch(requestRefreshGlobal({ crmOrgId: defaultEnv.id }));
      const allSuccess = results.every((res) => res.success);

      if (allSuccess) {
        sendBiEvent({ name: ACTIONS_EVENTS.rollupsDeploySuccess });
        const message = DEPLOY_SUCCESS;
        return addNotification({
          keepOpen: false,
          variant: SweepNotificationVariant.Success,
          message,
        });
      }

      results.forEach((res) => {
        const { data } = res;
        const crmOrg = getCrmOrgById(data.crmOrgId);
        const isDefault = defaultEnv.id === crmOrg?.id;
        const crmOrgName = crmOrg?.name;
        const message = res.success
          ? `${DEPLOY_SUCCESS} to ${crmOrgName}`
          : `Failed to deploy to ${crmOrgName}${isDefault ? '. Changes were saved' : ''}`;

        addNotification({
          keepOpen: !res.success,
          variant: res.success ? SweepNotificationVariant.Success : SweepNotificationVariant.Error,
          message,
          details: res.success ? undefined : res.error_message,
        });
      });
    },
    [defaultEnv, dispatch, sendBiEvent, addNotification, getCrmOrgById],
  );

  const automationsDeleteCompletedHandler = useCallback(
    (
      results: {
        success: true;
        error_message?: string;
        data: {
          crmOrgId: string;
        };
      }[],
    ) => {
      if (!defaultEnv) {
        return;
      }
      dispatch(requestRefreshGlobal({ crmOrgId: defaultEnv.id }));
      const resultOfDefault = results.find((res) => res.data.crmOrgId === defaultEnv.id);

      //product is to show error only in case it wasn't deleted from default creation env
      if (!resultOfDefault || resultOfDefault.success) {
        return addNotification({
          keepOpen: false,
          variant: SweepNotificationVariant.Success,
          message: 'Successfully deleted',
        });
      } else {
        return addNotification({
          keepOpen: true,
          variant: SweepNotificationVariant.Error,
          message: `Failed to delete from ${defaultEnv.name} due to technical issues`,
        });
      }
    },
    [addNotification, defaultEnv, dispatch],
  );

  const devOpsCenterElementsDeployCompletedHandler = (
    results: {
      success: boolean;
      data: { rollupId?: string; fieldId?: string; automationId?: string; crmOrgId: string };
    }[],
  ) => {
    dispatch(setDeploying(false));
    const allSuccess = results.every((res) => res.success);
    const allFailed = results.every((res) => !res.success);
    if (allSuccess) {
      sendBiEvent({ name: ACTIONS_EVENTS.devopsCenterDeploySuccess });
      return addNotification({
        keepOpen: false,
        variant: SweepNotificationVariant.Success,
        message: `Selected elements have been successfully deployed`,
      });
    }
    if (allFailed) {
      return addNotification({
        keepOpen: true,
        variant: SweepNotificationVariant.Error,
        message: `All elements failed to deploy due to technical issues`,
      });
    }
    if (results.length !== 0) {
      return addNotification({
        keepOpen: true,
        variant: SweepNotificationVariant.Warning,
        message: `Deployment partially succeeded.`,
        action: {
          reduxAction: showDeploymentReport(results),
          actionText: 'See deployment report',
        },
      });
    }
  };

  const deploymentCompletedHandler = useCallback(
    (payload: { success: boolean; error_message: string; data: FunnelDeployment }) => {
      if (payload.success) {
        sendBiEvent({ name: ACTIONS_EVENTS.funnelsDeploySuccess });
      }
      dispatch(deploymentCompleted(payload));
      dispatch(requestRefreshGlobal({ crmOrgId: payload.data.crmOrgId }));
    },
    [dispatch, sendBiEvent],
  );

  const parseCompletedHandler = (response: any) => {
    if (response.success) {
      dispatch(requestRefreshGlobal({ crmOrgId: response.data.crmOrgId }));
    }
  };

  return {
    fetchCompletedHandler,
    slackConnectionCompletedHandler,
    slackDisconnectionCompletedHandler,
    reportingActivationCompletedHandler,
    notificationsNewHandler,
    sfChangeFeedNotificationReceived,
    automationsDeployCompletedHandler,
    automationsDeleteCompletedHandler,
    rollupsDeployCompletedHandler,
    devOpsCenterElementsDeployCompletedHandler,
    deploymentCompletedHandler,
    parseCompletedHandler,
  };
};
export default useSocketHandlers;
