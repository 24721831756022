import { Box, Menu, MenuItem } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ChevronRight, Settings } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import { AutomationPermissionMode, AutomationTypes } from '../../types/enums/AutomationTypes';
import { useAutomationsContext } from './AutomationsContext';
import AutomationOrderDialog from './AutomationOrderDialog';

export const AUTOMATION_PERMISSION_MODES_TRANSLATION = {
  [AutomationPermissionMode.UserMode]: 'User Mode',
  [AutomationPermissionMode.SystemMode]: 'System mode',
};

export const AutomationUserSettings = ({
  crmOrgId,
  readonly,
}: {
  crmOrgId: string;
  readonly: boolean;
}) => {
  const { automationJson, setAutomationJson } = useAutomationsContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);
  const [subAnchorEl, setSubAnchorEl] = useState<null | HTMLElement>(null);
  const subOpen = Boolean(subAnchorEl);
  const [isOrderDialogOpen, setIsOrderDialogOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  const handleSubMenuClick = (event: React.MouseEvent<HTMLLIElement>) => {
    setSubAnchorEl(event.currentTarget);
  };
  const handleSubItemClose = () => {
    setSubAnchorEl(null);
    handleClose();
  };

  const itemLayout = (isSelected: boolean) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      width: '345px',
      gap: '4px',
      color: colors.grey[800],
      whiteSpace: 'normal',
      '& >span:first-of-type': {
        color: isSelected ? colors.blue[500] : 'initial',
        fontWeight: isSelected ? '600' : 'initial',
      },
    };
  };

  const options = [
    {
      value: AutomationPermissionMode.UserMode,
      title: AUTOMATION_PERMISSION_MODES_TRANSLATION[AutomationPermissionMode.UserMode],
      subTitle: 'Run as the triggering user, following their permissions and security settings.',
      isSelected:
        !automationJson?.automationDetails?.permissionMode ||
        automationJson?.automationDetails?.permissionMode === AutomationPermissionMode.UserMode,
    },
    {
      value: AutomationPermissionMode.SystemMode,
      title: AUTOMATION_PERMISSION_MODES_TRANSLATION[AutomationPermissionMode.SystemMode],
      subTitle: 'Run with system-level access, bypassing user-specific security restrictions.',
      isSelected:
        automationJson?.automationDetails?.permissionMode === AutomationPermissionMode.SystemMode,
    },
  ];

  const executionOrderMenu = options.map((option) => (
    <MenuItem
      sx={{
        borderRadius: '4px',
        '&.MuiMenuItem-root.Mui-selected': {
          backgroundColor: colors.grey[100],
          '&::after': {
            backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 4.5L6.5 11.5L3 8" stroke="rgb(17 152 255)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>')`,
          },
        },
      }}
      key={option.value}
      selected={option.isSelected}
      onClick={() => {
        setAutomationJson({
          ...automationJson,
          automationDetails: {
            ...automationJson.automationDetails,
            permissionMode: option.value,
          },
        });
        handleSubItemClose();
      }}
    >
      <Box sx={{ ...itemLayout(option.isSelected) }}>
        <Typography variant="body">{option.title}</Typography>
        <Typography variant="caption">{option.subTitle}</Typography>
      </Box>
    </MenuItem>
  ));

  const menuItemSx = subOpen
    ? {
        backgroundColor: colors.grey[100],
        color: colors.blue[500],
        fontWeight: '600',
      }
    : {};

  const isOrderTriggerInvalid = () => {
    if (!automationJson?.automationDetails?.triggerType) {
      return true;
    }
    if (
      [
        AutomationTypes.DateArrive,
        AutomationTypes.FieldUpdate,
        AutomationTypes.OnCreate,
        AutomationTypes.OnUpdate,
        AutomationTypes.OnUpsert,
        AutomationTypes.Scheduled,
      ].includes(automationJson?.automationDetails?.triggerType)
    ) {
      return false;
    }
    if (automationJson?.automationDetails?.triggerType === AutomationTypes.StageEnter) {
      if (
        automationJson?.automationDetails?.automationParams?.stageEnter?.toStage &&
        automationJson?.automationDetails?.funnelId
      ) {
        return false;
      }
    }
    if (automationJson?.automationDetails?.triggerType === AutomationTypes.StageExit) {
      if (
        automationJson?.automationDetails?.automationParams?.stageExit?.fromStage &&
        automationJson?.automationDetails?.funnelId
      ) {
        return false;
      }
    }
    if (automationJson?.automationDetails?.triggerType === AutomationTypes.TimeInStep) {
      if (
        automationJson?.automationDetails?.automationParams?.timeInStep?.stage &&
        automationJson?.automationDetails?.funnelId
      ) {
        return false;
      }
    }
    return true;
  };

  return (
    <>
      <Box sx={{ ml: -1 }}>
        <IconButton onClick={handleClick} variant="flat">
          <Settings />
        </IconButton>
        <Menu
          disableAutoFocusItem
          id="long-menu"
          anchorEl={menuAnchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              style: {
                width: '232px',
              },
            },
          }}
        >
          <MenuItem
            sx={menuItemSx}
            onClick={(e) => {
              e.stopPropagation();
              if (!subOpen) {
                handleSubMenuClick(e);
              }
            }}
          >
            <Box flex={1} display="flex" alignItems="center">
              Run as
            </Box>
            <ChevronRight color={subOpen ? colors.blue[500] : colors.black} />
            <Menu
              disableAutoFocusItem
              open={subOpen}
              anchorEl={subAnchorEl}
              onClose={handleSubItemClose}
              id="nested-menu"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {executionOrderMenu}
            </Menu>
          </MenuItem>
          <MenuItem
            disabled={isOrderTriggerInvalid()}
            onClick={(e) => {
              e.stopPropagation();
              setIsOrderDialogOpen(true);
              handleClose();
            }}
          >
            Order of execution
          </MenuItem>
        </Menu>
      </Box>
      {isOrderDialogOpen && (
        <AutomationOrderDialog
          readonly={readonly}
          crmOrgId={crmOrgId}
          automation={automationJson}
          closeDialog={() => {
            setIsOrderDialogOpen(false);
            handleClose();
          }}
        />
      )}
    </>
  );
};
