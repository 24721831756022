export enum Commands {
  Edit = 'Edit',
  Duplicate = 'Duplicate',
  Delete = 'Delete',
  Deactivate = 'Deactivate',
  Active = 'Activate',
  Slack_Activity = 'Slack Activity',
  Deploy = 'Deploy',
  Order = 'Set order of execution',
}
