import { ConfigurationType } from '@server/parser';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { DependenciesContentViewType } from '../types';
import { useDependencies } from './useDependencies';
import { ConfigurationItemMap } from '../../configuration-item-tables/shared/helpers';

export const useHandleApexClassRedirect = (
  crmOrgId: string,
  dependsOnItems: ConfigurationItemMap,
) => {
  const { handleSetConfigurationItem, dependenciesData, configurationItem } =
    useDependencies(crmOrgId);

  useRunOnceWhenTruthy(() => {
    if (dependenciesData?.renderApexClassName) {
      const apexClass = dependsOnItems?.apexClasses.find(
        (apexClass) => apexClass.name === dependenciesData?.renderApexClassName,
      );

      if (!apexClass) {
        return;
      }

      handleSetConfigurationItem({
        id: apexClass.id,
        name: apexClass.name,
        parentType: ConfigurationType.apexClasses,
        objectName: '',
        contentType: DependenciesContentViewType.default,
      });
    }
  }, !!dependenciesData?.renderApexClassName && !!dependsOnItems?.apexClasses?.length);

  const isNavigatingToApexClass =
    !!dependenciesData?.renderApexClassName &&
    dependenciesData?.id === configurationItem?.id &&
    dependsOnItems?.apexClasses?.length === 0;

  return isNavigatingToApexClass;
};
