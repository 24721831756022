import { Box } from '@mui/material';
import { Copy, Enrollment, Edit } from '@sweep-io/sweep-design/dist/icons';
import pluralize from 'pluralize';
import { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { filterItemsBySearch } from '../../../../../../lib/filterItemsBySearch';
import {
  selectAllCampaignFields,
  selectCampaignWorkflows,
} from '../../../../../../reducers/hubspotReducer';
import { Commands } from '../../../../../../types/enums/Common';
import { FlexBox } from '../../../../../common/FlexBox';
import ListItemActionCard from '../../../../../common/ListItemActionCard';
import RestrictedTooltip from '../../../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../../../common/permissions/usePermission';
import { SearchInput } from '../../../../../common/SearchInput';
import { SweepSortOption, SweepSort, SortDisplayTypes } from '../../../../../common/SweepSort';
import { useCanvasFilters } from '../../../../configuration-canvas-filters/useCanvasFilters';
import { CampaignMemberStatusProvider } from '../../../campaign-member-status/CampaignMemberStatusProvider';
import { newSearchSx } from '../../../canvasPanelConsts';
import { HubspotFilters } from '../../HubspotFilters';
import { useLoadSfCampaignFieldsForHubspot } from '../../useLoadSfCampaignFieldsForHubspot';
import { CampaignFields } from '../campaignTypes';
import { HubspotCampaignContent } from './HubspotCampaignContent';
import { HubspotCampaignCtxProvider } from '../HubspotCampaignCtx';
import { HubspotCampaignDialog } from '../HubspotCampaignDialog';
import { HubspotFilterTypes } from './HubspotCanvasPanel';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { cloneDeep, keyBy } from 'lodash';
import { campaignFieldsToCampaign } from './helper';
import { Loader } from '../../../../../common/Loader';
import { useAreCampaignsReady } from '../useAreCampaignsReady';

const actionsMenuItems = [
  {
    label: Commands.Edit,
    value: Commands.Edit,
    actionIcon: <Edit />,
    // addBottomDivider: true,
  },
  // {
  //   label: Commands.Delete,
  //   value: Commands.Delete,
  //   actionIcon: <Delete color={colors.blush[600]} />,
  //   actionSx: { color: colors.blush[600] },
  // },
  {
    label: Commands.Duplicate,
    value: Commands.Duplicate,
    actionIcon: <Copy />,
  },
];

const disabledActionsMenuItems: any = [];

const sortOptions: SweepSortOption<'LastModifiedDate' | 'CreatedDate' | 'Name'>[] = [
  {
    label: 'Campaign name',
    value: 'Name',
  },
  {
    label: 'Last modified date',
    value: 'LastModifiedDate',
  },
  {
    label: 'Create date',
    value: 'CreatedDate',
  },
];

const EnrolmentRulesByCampaign = ({ campaignId }: { campaignId: string }) => {
  const campaignActiveWorkflows = useSelector(selectCampaignWorkflows(campaignId)).length;
  if (campaignActiveWorkflows === 0) {
    return null;
  }
  return (
    <FlexBox alignItems="center" gap={1} mt={'2px'}>
      <Enrollment color={colors.mint[500]} />
      <Typography variant="body" color={colors.grey[700]}>
        {campaignActiveWorkflows} Active Enrolment {pluralize('Rule', campaignActiveWorkflows)}
      </Typography>
    </FlexBox>
  );
};
export const PanelCampaignsTab = ({ crmOrgId, hsOrgId }: { crmOrgId: string; hsOrgId: string }) => {
  const areCampaignsReady = useAreCampaignsReady();
  const allCampaigns = useSelector(selectAllCampaignFields);
  const [campaignDialog, setCampaignDialog] = useState<{
    open: boolean;
    campaignFields?: CampaignFields;
  }>();

  const [sortBy, setSortBy] = useState<'LastModifiedDate' | 'CreatedDate' | 'Name'>('Name');

  const { campaignStatusOptions, campaignTypeOptions, campaignObjectFields } =
    useLoadSfCampaignFieldsForHubspot();

  const { filterValues: canvasFilterValues } = useCanvasFilters();

  const closeCampaignDialog = useCallback(() => setCampaignDialog(undefined), []);

  const newCampaign = useCallback(() => setCampaignDialog({ open: true }), []);

  const editCampaign = useCallback(
    (campaignFields: CampaignFields) => () => {
      setCampaignDialog({ open: false });
      setTimeout(() => {
        setCampaignDialog({ open: true, campaignFields });
      }, 0);
    },
    [],
  );

  const duplicateCampaign = useCallback((campaignFields: CampaignFields) => {
    setCampaignDialog({ open: false });
    // We need a timeout here to ensure that the dialog is reloaded.
    // The dialog is uncontrolled, witch means it's not sensitive to changes in the props,
    // So it need to be remounted. We achieve this my adding a timeout with 0 ms.

    setTimeout(() => {
      const newCampaignFields = cloneDeep(campaignFields);
      const campaignNameIdx = newCampaignFields.findIndex((field) => field.name === 'Name');
      if (campaignNameIdx !== -1) {
        newCampaignFields[campaignNameIdx].value =
          `${newCampaignFields[campaignNameIdx].value} (Copy)`;
      }
      // deletes ID
      const campaignIdIdx = campaignFields.findIndex((field) => field.name === 'Id');
      if (campaignIdIdx !== -1) {
        newCampaignFields.splice(campaignIdIdx, 1);
      }
      setCampaignDialog({
        open: true,
        campaignFields: newCampaignFields,
      });
    }, 0);
  }, []);

  const [search, setSearch] = useState('');
  const canvasFiltersCampaigns = allCampaigns?.filter((campaign) => {
    const _campaign = campaignFieldsToCampaign(campaign);

    const activityFilter = canvasFilterValues[HubspotFilterTypes.ACTIVITY];
    if (activityFilter && activityFilter.selectedValues.length > 0) {
      if (_campaign.IsActive) {
        if (!activityFilter.selectedValues.includes('active')) {
          return false;
        }
      } else {
        if (!activityFilter.selectedValues.includes('inactive')) {
          return false;
        }
      }
    }
    const statusFilter = canvasFilterValues[HubspotFilterTypes.CAMPAIGN_STATUS];
    if (statusFilter && statusFilter.selectedValues.length > 0) {
      if (!statusFilter.selectedValues.includes(_campaign.Status || '')) {
        return false;
      }
    }

    const typeFilter = canvasFilterValues[HubspotFilterTypes.CAMPAIGN_TYPE];
    if (typeFilter && typeFilter.selectedValues.length > 0) {
      if (!typeFilter.selectedValues.includes(_campaign.Type || '')) {
        return false;
      }
    }

    return true;
  });
  const filteredCampaigns = filterItemsBySearch(
    canvasFiltersCampaigns || [],
    search,
    (item) => item.find((field) => field.name === 'Name')?.value as string,
  );

  const filteredAndSortedCampaigns = filteredCampaigns.sort((campaignA, campaignB) => {
    const _campaignA = campaignFieldsToCampaign(campaignA);
    const _campaignB = campaignFieldsToCampaign(campaignB);

    switch (sortBy) {
      case 'Name':
        return _campaignA.Name.localeCompare(_campaignB.Name);
      case 'LastModifiedDate':
      case 'CreatedDate':
        return new Date(_campaignB[sortBy]).getTime() - new Date(_campaignA[sortBy]).getTime();
    }
  });

  const [isCreateCampaignsAllowed] = usePermission(['create:campaigns']);

  const maybeRenderLoader = () => {
    if (!areCampaignsReady) {
      return <Loader />;
    }
  };

  const maybeRenderCampaignsList = () => {
    if (areCampaignsReady) {
      return (
        <Box mt={0} id="panel-campaigns-tab" height="calc(100% - 115px)" flex={1}>
          <FlexBox
            sx={{
              ml: 0,
              mr: 0,
              mb: 1,
              ...newSearchSx,
            }}
          >
            <Box
              display="flex"
              gap={1}
              flex={1}
              sx={{
                button: {
                  pl: 3,
                  pr: 3,
                },
                alignItems: 'center',
              }}
            >
              <SearchInput
                TextFieldProps={{
                  value: search,
                  placeholder: 'Search',
                  onChange: (e) => setSearch(e.target.value),
                  fullWidth: true,
                }}
                withFixedMagnifyingGlassIcon={true}
                onClearButtonClick={() => setSearch('')}
                variant="small"
              />
              <RestrictedTooltip
                to={['create:campaigns']}
                notAllowedTitle={'To create Hubspot campaigns, please contact your admin'}
                component={(isAllowed) => (
                  <Button
                    dataTestId="create-new-campaign-button"
                    startIconName="Plus"
                    size="small"
                    onClick={newCampaign}
                    disabled={!isAllowed}
                  >
                    New
                  </Button>
                )}
              />
            </Box>
          </FlexBox>
          <FlexBox
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              m: '8px 16px',
              mt: 0,
            }}
          >
            <Typography variant="body" color={colors.grey[700]}>
              {pluralize('Active Campaign', filteredAndSortedCampaigns.length, true)}
            </Typography>
            <Box display="flex" gap={1}>
              <HubspotFilters withPanelWrapper />
              <SweepSort
                selectedValue={sortBy}
                onChange={setSortBy}
                options={sortOptions}
                displayType={SortDisplayTypes.IconAndLabel}
              />
            </Box>
          </FlexBox>
          <Box sx={{ height: '100%', overflow: 'scroll' }}>
            {filteredAndSortedCampaigns?.map((campaignFields) => {
              const _campaign = keyBy(campaignFields, 'name');
              const campaignId = _campaign['Id'].value as string;

              return (
                <ListItemActionCard
                  key={campaignId}
                  actionsMenuIconSize="tiny"
                  title={_campaign['Name'].value as string}
                  onLineClick={editCampaign(campaignFields)}
                  content={<EnrolmentRulesByCampaign campaignId={campaignId} />}
                  actionsMenu={
                    isCreateCampaignsAllowed ? actionsMenuItems : disabledActionsMenuItems
                  }
                  isAllowedBtn={false}
                  onActionMenuClick={(action) => {
                    switch (action) {
                      case Commands.Edit:
                        editCampaign(campaignFields)();
                        break;
                      case Commands.Delete:
                        // if (campaignDialog?.campaignFields?.Id === campaignId) {
                        //   closeCampaignDialog();
                        // }
                        // deleteCampaign(campaignId);
                        break;
                      case Commands.Duplicate:
                        duplicateCampaign(campaignFields);
                        break;
                    }
                  }}
                />
              );
            })}
          </Box>
        </Box>
      );
    }
  };

  const maybeRenderCampaignForm = () => {
    if (campaignDialog?.open) {
      return (
        <HubspotCampaignCtxProvider
          campaignStatusOptions={campaignStatusOptions}
          campaignTypeOptions={campaignTypeOptions}
          campaignObjectFields={campaignObjectFields}
          crmOrgId={crmOrgId}
          hsOrgId={hsOrgId}
          objectType="Contact"
        >
          <HubspotCampaignDialog isOpen={true} onClose={closeCampaignDialog}>
            <CampaignMemberStatusProvider>
              <HubspotCampaignContent initialCampaignFields={campaignDialog.campaignFields} />
            </CampaignMemberStatusProvider>
          </HubspotCampaignDialog>
        </HubspotCampaignCtxProvider>
      );
    }
  };

  return (
    <>
      {maybeRenderLoader()}
      {maybeRenderCampaignsList()}
      {maybeRenderCampaignForm()}
    </>
  );
};
