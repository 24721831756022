import { Button, Tag, Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { Box } from '@mui/material';
import { useState } from 'react';
import { InfoDialog } from '../common/dialogs/InfoDialog';
import { useRunOnce } from '../common/useRunOnce';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { ObjectTypeChip } from '../common/ObjectTypeChip';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { DataTableDraggableColumn, DataTableVariant } from '../common/table/TableTypes';
import { DividerLighter } from './helper';
import { useSelector } from 'react-redux';
import { selectEnvironments } from '../../reducers/global/globalReducer';
import pick from 'lodash/pick';
import flatten from 'lodash/flatten';
import { AutomationTypes } from '../../types/enums/AutomationTypes';
import { useSweepApi } from '../../apis/sweep';
import buildURLQuery from '../../lib/buildURLQuery';
import OrderArrowIcon from './images/order_arrow.svg?react';
import { Info } from '@sweep-io/sweep-design/dist/icons/Info';
import { DataTable } from '../common/table/DataTable';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { telemetry } from '../../telemetry';
import { useAutomationsContext } from './AutomationsContext';
import { useSweepNotifications } from '../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../reducers/notificationsReducer';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { AutomationType } from '../../types/enums/AutomationType';

export type AutomationOrderElement = {
  acceptsQueryObject?: boolean;
  orderGroupId: string;
  label?: string;
  items: string[];
  automationList?: DeepPartial<AutomationStructureNew>[];
};

const columns: DataTableDraggableColumn[] = [
  { field: 'handle', headerName: '', showDragHandle: true, width: '40px' },
  { field: 'priority', headerName: 'Priority', width: '115px' },
  { field: 'name', headerName: 'Name', width: '620px' },
  { field: 'status', headerName: 'Status' },
];

const GHOST_ELEM_ID = '__11__';

const AutomationOrderDialog = ({
  closeDialog,
  automation,
  crmOrgId,
  readonly,
}: {
  closeDialog: () => void;
  automation: DeepPartial<AutomationStructureNew>;
  crmOrgId: string;
  readonly: boolean;
}) => {
  const sweepApi = useSweepApi();
  const [isLoading, setIsLoading] = useState(true);
  const { setAutomationJson } = useAutomationsContext();
  const { addNotification } = useSweepNotifications();
  const { openConfirm } = useConfirm();
  const [displayListOfOrder, setDisplayListOfOrder] = useState<AutomationOrderElement[]>([]);
  const [changedListsIds, setChangedListsIds] = useState<string[]>([]);
  const [objectFunnels, setObjectFunnels] = useState<ShortFunnel[]>([]);
  const globalEnvironments = useSelector(selectEnvironments);
  const globalData = globalEnvironments?.[crmOrgId]?.data;
  const globalAutomationElements = pick(
    globalData,
    'automations',
    'alerts',
    'scheduledAssignments',
    'assignments',
    'dedup',
    'matching',
    'scheduledReports',
  );
  const flattenAutomationElements = flatten(
    Object.entries(globalAutomationElements).map(([, children]) => children) as [
      AutomationStructureNew[],
    ],
  );

  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });
  const { getObjectFunnelsAndRecordTypes } = useCrmOrgsApiFacade();

  useRunOnce(async () => {
    try {
      const parsedDataForObject = await getObjectFunnelsAndRecordTypes({
        crmOrgId,
        objectName: automation?.objectName ?? '',
      });
      setObjectFunnels(parsedDataForObject?.funnels ?? []);

      const searchParams = buildURLQuery({
        triggerType: automation?.automationDetails?.triggerType,
        objectType: automation?.objectName,
        funnelId: automation?.automationDetails?.funnelId,
        enterStep: automation?.automationDetails?.automationParams?.stageEnter?.toStage?.stageName,
        exitStep: automation?.automationDetails?.automationParams?.stageExit?.fromStage?.stageName,
        inStep: automation?.automationDetails?.automationParams?.timeInStep?.stage?.stageName,
      });
      const queryString = searchParams ? '?' + searchParams : '';
      const response = await sweepApi.get(`/automations/order/${automation.type}${queryString}`);

      const data = response?.data as AutomationOrderElement[];
      const enrichedData: AutomationOrderElement[] = [...data];
      data.forEach((orderGroup, idx) => {
        const automationList = orderGroup?.items
          ?.map((itemId) => {
            return flattenAutomationElements?.find((el) => el.automationId === itemId);
          })
          .filter((item) => item) as DeepPartial<AutomationStructureNew>[];
        //  if ghost elem and addHere then add ghost
        if (!automation?.automationId && orderGroup?.acceptsQueryObject) {
          automationList.push({ ...automation, automationId: GHOST_ELEM_ID });
        }
        enrichedData[idx] = { ...enrichedData[idx], automationList: automationList };
      });
      setDisplayListOfOrder(enrichedData);
    } catch (e) {
      setDisplayListOfOrder([]);
      telemetry.captureError(e);
    }
    setIsLoading(false);
  });

  const getTriggerOrderLabel = (automation: DeepPartial<AutomationStructureNew>) => {
    let triggerText = '';
    const _funnelElem = objectFunnels?.find(
      (el) => el.id === automation?.automationDetails?.funnelId,
    );
    const { stageEnter, stageExit, timeInStep } =
      automation?.automationDetails?.automationParams || {};
    switch (automation.automationDetails?.triggerType) {
      case AutomationTypes.OnCreate:
        triggerText = 'Record is created';
        break;
      case AutomationTypes.OnUpdate:
        triggerText = 'Record is updated';
        break;
      case AutomationTypes.OnUpsert:
        triggerText = 'Record is created or updated';
        break;
      case AutomationTypes.FieldUpdate:
        triggerText = 'Field is updated';
        break;
      case AutomationTypes.Scheduled:
        triggerText = 'Scheduled time arrives';
        break;
      case AutomationTypes.DateArrive:
        triggerText = 'Date arrives';
        break;
      case AutomationTypes.StageEnter:
        triggerText = `Entering ${stageEnter?.toStage?.stageName} in funnel ${_funnelElem?.name}`;
        break;
      case AutomationTypes.StageExit:
        triggerText = `Entering ${stageExit?.fromStage?.stageName} in funnel ${_funnelElem?.name}`;
        break;
      case AutomationTypes.TimeInStep:
        const { stage } = timeInStep || {};
        triggerText = `Record is stuck in step ${stage?.stageName} in funnel ${_funnelElem?.name}`;
        break;
    }

    return triggerText;
  };

  const getRows = (order: AutomationOrderElement) => {
    let rows: any[] = [];
    const currentList = order?.automationList ?? [];
    rows = currentList?.map((item, idx) => {
      const isCurrentItem = item?.automationId === automation?.automationId;
      const isGhostElem = item?.automationId === GHOST_ELEM_ID;
      const priority =
        isCurrentItem || isGhostElem ? (
          <Box sx={{ display: 'flex', gap: 3 }}>
            {idx + 1}
            <Tag color={colors.blue[500]} label="Current" />
          </Box>
        ) : (
          '' + (idx + 1)
        );
      return {
        id: item.automationId,
        orderGroupId: order.orderGroupId,
        priority,
        name: item.name,
        status: isGhostElem ? 'In progress' : item.isActive ? 'Active' : 'Inactive',
        isRowActive: isCurrentItem || isGhostElem,
      };
    });
    return rows;
  };

  const onOrderChange = (
    sourceIndex: number,
    destinationIndex: number,
    order: AutomationOrderElement,
  ) => {
    const newElem = order;
    const oldOrder = [...(newElem.automationList ?? [])];
    const newOrder = [...(newElem.automationList ?? [])];
    newOrder.splice(sourceIndex, 1);
    newOrder.splice(destinationIndex, 0, oldOrder[sourceIndex]);

    const elemToChange = { ...newElem, automationList: newOrder };
    const newList = displayListOfOrder.map((el) =>
      el.orderGroupId === order.orderGroupId ? elemToChange : el,
    );
    setDisplayListOfOrder(newList);
    setChangedListsIds([...changedListsIds, order?.orderGroupId]);
  };

  const getInnerOrderLabel = (label: string) => {
    if (label === 'After') {
      return 'Run After insert';
    }
    return 'Run before insert';
  };
  const getInnerTooltipLabel = (label: string) => {
    if (label === 'After') {
      return 'These automations run after the record is saved to the database in Salesforce.';
    }
    return 'These automations run before the record is saved to the database in Salesforce.';
  };

  const buildListElements = (currentElem: AutomationOrderElement, index: number) => {
    const isShowSections = displayListOfOrder?.length > 1;
    const sx = isShowSections
      ? { borderLeft: `1px solid ${colors.grey[300]}`, mb: 2, pl: 2, pr: 2 }
      : {};
    return (
      <>
        <Box sx={sx}>
          {isShowSections && (
            <Box sx={{ mt: 1, mb: 1, display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="body-medium">
                {`${index + 1}.  ${getInnerOrderLabel(currentElem?.label ?? '')}`}
              </Typography>
              <Tooltip title={getInnerTooltipLabel(currentElem?.label ?? '')}>
                <Info />
              </Tooltip>
            </Box>
          )}
          <DataTable
            variant={DataTableVariant.narrow}
            allowReorder={!readonly}
            columns={columns}
            rows={getRows(currentElem)}
            onOrderChange={({ sourceIndex, destinationIndex }) =>
              onOrderChange(sourceIndex, destinationIndex, currentElem)
            }
            tableEmptyStateJsx={
              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Typography variant="caption" color={colors.grey[800]}>
                  No automations found
                </Typography>
              </Box>
            }
          />
        </Box>
        {isShowSections && index < displayListOfOrder?.length - 1 && (
          <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
            <OrderArrowIcon />
          </Box>
        )}
      </>
    );
  };

  const onSave = async () => {
    const _changedIds = new Set(changedListsIds);
    const _itemsToSend: AutomationOrderElement[] = [];
    displayListOfOrder?.forEach((el) => {
      if (_changedIds?.has(el?.orderGroupId)) {
        const listToReturn = el?.automationList?.map((el) => el.automationId ?? '') ?? [];
        // check if it has the ghost element - if yes  remove it and save the id of the element before and the group id in the automation in edit
        const ghostIndex = listToReturn.findIndex((el) => el === GHOST_ELEM_ID);
        if (el?.acceptsQueryObject && ghostIndex !== -1) {
          // if the ghost element has an automation after it - save the id and order on the dto
          if (listToReturn[ghostIndex + 1]) {
            setAutomationJson({
              ...automation,
              orderHint: {
                orderGroupId: el?.orderGroupId,
                insertBeforeAutomationId: listToReturn[ghostIndex + 1],
              },
            });
          }
          listToReturn?.splice(ghostIndex, 1);
        }
        const elemToReturn = { items: listToReturn, orderGroupId: el.orderGroupId };

        _itemsToSend.push(elemToReturn);
      }
    });
    try {
      await sweepApi.post(`/automations/order/${automation.type}`, _itemsToSend);
    } catch (error) {
      addNotification({
        variant: SweepNotificationVariant.Error,
        message: 'There was an error saving the order of execution',
      });
      telemetry.captureError(error);
    }
    closeDialog();
  };

  const closeOrConfirmClose = async () => {
    if (changedListsIds?.length > 0) {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (!isConfirmed) {
        return;
      }
    }
    closeDialog();
  };

  return (
    <InfoDialog
      handleClose={(e) => {
        e.stopPropagation();
        closeOrConfirmClose();
      }}
      open={true}
      PaperPropsSx={{ width: '988px' }}
      showCloseButton
      titleJsx="Order of execution"
      titleTypographyVariant="h2"
      dialogContentSx={{ padding: '24px 24px 24px' }}
    >
      <Box>
        {isLoading && (
          <Box m={2}>
            <CenteredCircularProgress />
          </Box>
        )}
        {!isLoading && (
          <>
            <Box sx={{ mb: 2 }}>
              <Box display="flex" gap={1} mb={1}>
                <Typography variant="body" color={colors.grey[700]}>
                  Object:
                </Typography>
                <ObjectTypeChip
                  label={
                    objectTypesByName[automation.objectName ?? '']?.label || automation.objectName
                  }
                  objectType={automation.objectName ?? ''}
                />
              </Box>
              <Box display="flex" gap={1}>
                <Typography variant="body" color={colors.grey[700]}>
                  Trigger:
                </Typography>
                <Typography variant="body">{getTriggerOrderLabel(automation)}</Typography>
              </Box>
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography variant="body" color={colors.grey[700]}>
                {`Execute ${automation.type === AutomationType.Assignment ? 'assignments' : 'automations and alerts'}  in this order:`}
              </Typography>
            </Box>
            <Box
              sx={{
                maxHeight: '800px',
                overflow: 'hidden',

                overflowY: 'auto',
              }}
            >
              {displayListOfOrder.map(buildListElements)}
            </Box>
            <DividerLighter sx={{ mb: 2, ml: -3, mr: -3 }} />
            <Box sx={{ display: 'flex', flexDirection: 'row-reverse', gap: 1 }}>
              <Button
                onClick={onSave}
                size="large"
                variant="filled"
                disabled={readonly || changedListsIds?.length === 0}
              >
                Save changes
              </Button>
              <Button onClick={closeOrConfirmClose} size="large" variant="outlined">
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Box>
    </InfoDialog>
  );
};

export default AutomationOrderDialog;
