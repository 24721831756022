export enum DevOpsCenterDeploymentStatusCode {
  NotDeployed,
  VersionMismatch,
  Synced,
  PartiallySynced,
  FullySynced,
  Conflict,
}

export type DevOpsCenterDeploymentStatus =
  | { code: Exclude<DevOpsCenterDeploymentStatusCode, DevOpsCenterDeploymentStatusCode.Conflict> }
  | { code: DevOpsCenterDeploymentStatusCode.Conflict; reason: string };

export enum DevOpsElementType {
  Automation = 'Automations',
  Alert = 'Alerts',
  Assignment = 'Assignment rules',
  DedupMatching = 'Matching & Dedupe',
  Rollup = 'Rollups',
  ScheduledAssignment = 'Scheduled Assignments',
  ScheduledReport = 'Scheduled Reports',
  Field = 'Fields',
}

export const DevOpsElementTypeToDeployPermission = {
  [DevOpsElementType.Automation]: 'deploy:automations',
  [DevOpsElementType.Alert]: 'deploy:alerts',
  [DevOpsElementType.Assignment]: 'deploy:assignments',
  [DevOpsElementType.DedupMatching]: 'deploy:dedupe-matching',
  [DevOpsElementType.Rollup]: 'deploy:rollups',
  [DevOpsElementType.ScheduledAssignment]: 'deploy:assignments',
  [DevOpsElementType.ScheduledReport]: 'deploy:alerts',
  [DevOpsElementType.Field]: 'edit:automations', //fields don't have their own permissions yet, so aligning with restrictions on BE
};
