import { Box, Menu, MenuProps } from '@mui/material';
import { MenuButton } from './MenuButton';
import { Button, IconButton, Tag, Typography, colors } from '@sweep-io/sweep-design';
import usePopoverMenu from '../../../../hooks/usePopover';
import { EnvMenuItems } from './EnvMenuItems';
import { useCallback, useState } from 'react';
import { ManageEnvDialog } from './ManageEnvDialog';
import { useSelector } from 'react-redux';
import { selectConnectedCrmOrgs, selectDefaultCreationEnvironment } from '../environmentsReducer';
import ConnectToSfDialog from '../../get-started/connect-org/ConnectToSfDialog';
import { Cloud } from '@sweep-io/sweep-design/dist/icons';

enum ConnectDialogType {
  REGULAR = 'REGULAR',
  FORCE_PRODUCTION = 'FORCE_PRODUCTION',
}

export const EnvSelector = (
  {
    variant = 'full',
    menuProps,
    allowDeleteLastEnv,
    onChangeEnvCallback,
    disabled,
  }: {
    variant?: 'icon-button' | 'full';
    menuProps?: Pick<MenuProps, 'anchorOrigin' | 'transformOrigin'>;
    allowDeleteLastEnv: boolean;
    disabled?: boolean;
    onChangeEnvCallback?: () => void;
  } = { allowDeleteLastEnv: false },
) => {
  const { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef } = usePopoverMenu();
  const [openedConnectDialogType, setOpenedConnectDialogType] = useState<ConnectDialogType | null>(
    null,
  );
  const selectedEnv = useSelector(selectDefaultCreationEnvironment);
  const environments = useSelector(selectConnectedCrmOrgs);

  const [isEnvDialogOpen, setIsEnvDialogOpen] = useState(false);
  const closeEnvDialog = useCallback(() => setIsEnvDialogOpen(false), [setIsEnvDialogOpen]);

  const onConnectProdClick = () => {
    closePopover();
    setOpenedConnectDialogType(ConnectDialogType.FORCE_PRODUCTION);
  };

  const closeSimpleDialog = useCallback(() => {
    setOpenedConnectDialogType(null);
  }, []);

  const onManageEnvClick = useCallback(() => {
    closePopover();
    setIsEnvDialogOpen(true);
  }, [closePopover]);

  const onConnectToSfClick = useCallback(() => {
    closePopover();
    setOpenedConnectDialogType(ConnectDialogType.REGULAR);
  }, [closePopover]);

  const renderSimpleConnectDialog = () => {
    if (openedConnectDialogType) {
      return (
        <ConnectToSfDialog
          closeDialog={closeSimpleDialog}
          forceProduction={openedConnectDialogType === ConnectDialogType.FORCE_PRODUCTION}
          onFinish={closeSimpleDialog}
        />
      );
    }
  };

  const renderManageEnvDialog = () => {
    if (isEnvDialogOpen) {
      return (
        <ManageEnvDialog
          onClose={closeEnvDialog}
          allowDeleteLastEnv={Boolean(allowDeleteLastEnv)}
        />
      );
    }
  };

  const envName = selectedEnv?.name ?? '';

  const renderTag = () => {
    if (!selectedEnv) {
      return null;
    }

    const label = selectedEnv.isMain ? 'Production' : 'Sandbox';
    const color = selectedEnv.isMain ? colors.mint[100] : colors.sun[100];

    return <Tag color={color} label={label} />;
  };

  const renderButton = () => {
    switch (variant) {
      case 'icon-button':
        return (
          <IconButton variant="flat" ref={anchorRef} onClick={openPopover} disabled={disabled}>
            <Cloud />
          </IconButton>
        );

      case 'full':
        return (
          <MenuButton
            anchorRef={anchorRef}
            onClick={openPopover}
            pressed={isPopoverOpen}
            disabled={disabled}
          >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Typography variant="caption">{envName}</Typography>
              {renderTag()}
            </Box>
          </MenuButton>
        );
    }
  };

  const renderEmptyButton = () => {
    return (
      <Box
        sx={{
          button: {
            height: 24,
            background: 'transparent',
            color: colors.white,
            '&:hover': {
              borderColor: colors.white,
              background: colors.night[700],
            },
            '&:active': {
              borderColor: colors.storm[300],
              color: colors.storm[300],
            },
          },
        }}
      >
        <Button variant="outlined" size="small" onClick={onConnectToSfClick}>
          Connect to Salesforce
        </Button>
      </Box>
    );
  };

  return (
    <Box>
      {environments.length ? renderButton() : renderEmptyButton()}
      <Menu
        id="user-menu"
        anchorEl={anchorElement}
        open={isPopoverOpen}
        onClose={() => closePopover()}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        slotProps={{
          paper: {
            sx: (theme) => ({
              marginTop: '12px',
              width: '280px',
              boxShadow: theme.shadows[7],
            }),
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        {...menuProps}
      >
        <EnvMenuItems
          onConnectProdClick={onConnectProdClick}
          onManageEnvClick={onManageEnvClick}
          close={closePopover}
          onChangeEnvCallback={onChangeEnvCallback}
        />
      </Menu>
      {renderSimpleConnectDialog()}
      {renderManageEnvDialog()}
    </Box>
  );
};
