import { RoleGroupLevelType } from '@server/role-group-interface';
import * as yup from 'yup';

export const roleValidationSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required').min(3),
  description: yup.string().nullable().notRequired(),
  roleGroupLevels: yup.object().shape({
    type: yup.string().required(),
    levels: yup.lazy((value, options) => {
      const type = options.parent?.type;
      if (type === RoleGroupLevelType.AccountBased) {
        return yup.object().shape({
          alerts: yup.string().required(),
          automations: yup.string().required(),
          dedupMatching: yup.string().required(),
          funnels: yup.string().required(),
          rollups: yup.string().required(),
          assignments: yup.string().required(),
        });
      } else {
        return yup
          .array()
          .of(
            yup.object().shape({
              crmOrgId: yup.string().required(),
              levels: yup.object().shape({
                alerts: yup.string().required(),
                automations: yup.string().required(),
                dedupMatching: yup.string().required(),
                funnels: yup.string().required(),
                rollups: yup.string().required(),
                assignments: yup.string().required(),
              }),
            }),
          )
          .required()
          .min(1);
      }
    }),
  }),
});
