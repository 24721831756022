import * as React from "react";
const SvgAgentProcessIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 20, cy: 20, r: 20, fill: "#C7ADFF", style: {
  fill: "#C7ADFF",
  fill: "color(display-p3 0.7818 0.6792 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M8.93887 11.8492C9.35632 10.9853 10.0704 10.3003 10.9508 9.91902L18.4104 6.68844C19.4246 6.24919 20.5754 6.24919 21.5896 6.68843L29.0492 9.91902C29.9296 10.3003 30.6437 10.9853 31.0611 11.8492L34.1589 18.2596C34.6903 19.359 34.6903 20.641 34.1589 21.7404L31.0611 28.1508C30.6437 29.0147 29.9296 29.6997 29.0492 30.081L21.5896 33.3116C20.5754 33.7508 19.4246 33.7508 18.4104 33.3116L10.9508 30.081C10.0704 29.6997 9.35632 29.0147 8.93887 28.1508L5.84106 21.7404C5.30975 20.641 5.30974 19.359 5.84106 18.2596L8.93887 11.8492Z", fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }));
export default SvgAgentProcessIcon;
