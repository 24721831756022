import { TOP_BAR_COMMON_SX } from './aiAgentsConsts';
import { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import useAiAgents from './useAiAgents';
import AgentCard from './AgentCard';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import CreateNewAgentCard from './CreateNewAgentCard';
import usePermission from '../common/permissions/usePermission';
import { AgentType } from '@server/ai';

const AgentsCardsCollapsed = ({
  onSelect,
  toggleExpandButton,
  closeButton,
  onNewAgentClick,
}: {
  onSelect: (agentId: string, agentType: AgentType) => void;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  onNewAgentClick: () => void;
}) => {
  const { agents, agentsIsLoading } = useAiAgents();
  const [isAllowedCreate] = usePermission(['create:ai-agent']);

  return (
    <Stack height="100%" sx={{ backgroundColor: '#ECF8FF' }}>
      <Box
        sx={{
          borderBottom: `1px solid ${colors.grey[300]}`,
          justifyContent: 'flex-end',
          ...TOP_BAR_COMMON_SX,
        }}
      >
        {toggleExpandButton}
        {closeButton}
      </Box>

      <Stack
        sx={{
          overflow: 'hidden',
        }}
      >
        <Stack
          p="24px 16px"
          height="100%"
          gap={3}
          sx={{
            overflow: 'auto',
          }}
        >
          <Typography variant="h2" color={colors.grey[800]}>
            Welcome to your AI Agent Center
          </Typography>

          {agentsIsLoading && (
            <Stack pt={5} flex={1}>
              <CenteredCircularProgress />
            </Stack>
          )}

          {!agentsIsLoading && (
            <>
              {agents?.map((agent) => (
                <AgentCard key={agent.id} agent={agent} onClick={() => onSelect(agent.id, agent.type)} />
              ))}
              {isAllowedCreate && <CreateNewAgentCard key="new" onClick={onNewAgentClick} />}
            </>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgentsCardsCollapsed;
