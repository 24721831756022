import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../reducers/userInfoReducer';
import { useEffect } from 'react';
import useChatsEntryPoint from '../components/aiAgentCenter/useChatsEntryPoint';
import useUserInfo from './useUserInfo';
import { useTags } from '../components/documentation/dependencies/annotations/tags/useTags';

/**
 * Add an effect to any call that should be triggered whenever crmOrgId changes
 */

export const useFetchUponCrmOrgChange = () => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const { fetchChatsAndAgents } = useChatsEntryPoint(); //TODO use directly useAiAgentCenter SWEEP-5978
  const { getUserPermissions } = useUserInfo();
  const { fetchTags } = useTags(crmOrgId ?? '');

  useEffect(() => {
    if (crmOrgId) {
      getUserPermissions(crmOrgId);
    }
  }, [crmOrgId, getUserPermissions]);

  useEffect(() => {
    if (crmOrgId) {
      fetchChatsAndAgents();
    }
  }, [crmOrgId, fetchChatsAndAgents]);

  useEffect(() => {
    if (crmOrgId) {
      fetchTags();
    }
  }, [crmOrgId, fetchTags]);
};
