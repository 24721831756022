import { IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';

import { CreateEditDialogDeployButtons } from './CreateEditDialogDeployButtons';
import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';
import { Box } from '@mui/material';
import { useFeatureToggle } from '../useFeatureToggle';

export interface AutomationsCreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  onCancelClick?: () => any;
  onDeployOrSave: (
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  buttonDisabled?: boolean;
  showCancelButton?: boolean;
  rightSidePermissionContent?: React.ReactNode;
}

export const AutomationsCreateEditDialogHeader = ({
  onDeployOrSave,
  buttonDisabled,
  showCancelButton,
  onCancelClick,
  rightSideContent,
  rightSidePermissionContent,
  ...restProps
}: AutomationsCreateEditDialogHeaderProps) => {
  const { automationsUiButtonPosition } = useFeatureToggle();
  const _rightSideContent = automationsUiButtonPosition ? (
    <>
      {rightSideContent}
      {showCancelButton && (
        <Box sx={{ ml: -1 }}>
          <IconButton variant="flat" onClick={onCancelClick} data-dd-action-name="close">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  ) : (
    <>
      {rightSideContent}
      <CreateEditDialogDeployButtons
        onDeployOrSave={onDeployOrSave}
        confirmButtonDisabled={buttonDisabled}
      />
      {rightSidePermissionContent}
      {showCancelButton && (
        <Box sx={{ ml: -1 }}>
          <IconButton variant="flat" onClick={onCancelClick} data-dd-action-name="close">
            <CloseIcon />
          </IconButton>
        </Box>
      )}
    </>
  );
  return <CreateEditDialogHeaderBase {...restProps} rightSideContent={_rightSideContent} />;
};
