import { useDispatch } from 'react-redux';
import { useFunnelMapApiFacade } from '../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import {
  setFunnelMapsList,
  deleteFunnelMapFromList,
  updateFunnelMapName,
  setIsPinned,
  setLoadingFunnelMapsList,
} from '../../../reducers/funnelMapsReducer';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { funnelMapToFunnelMapPutPayload } from '../../../apis/facades/funnel-map/funnelMapToFunnelMapPutPayload';
import { useCallback } from 'react';
import { appRoutes } from '../../../constants/appRoutes';
import useSweepNavigate from '../../common/useSweepNavigate';
import { telemetry } from '../../../telemetry';
import { useErrorHandling } from '../../../hooks/useErrorHandling';

export const useFunnelMaps = () => {
  const { get_funnelMaps, put_funnelMap, delete_funnelMap, updateIsPinnedApi } =
    useFunnelMapApiFacade();
  const { addNotification } = useSweepNotifications();
  const dispatch = useDispatch();
  const { post_funnelMap } = useFunnelMapApiFacade();
  const { navigate } = useSweepNavigate();
  const { errorHandlingBuilder } = useErrorHandling();
  const fetchFunnelMaps = async ({ includeFunnelsData }: { includeFunnelsData: boolean }) => {
    await errorHandlingBuilder()
      .withErrorNotification('Failed to fetch funnels')
      .withOnError(telemetry.captureError)
      .execute(async () => {
        dispatch(setLoadingFunnelMapsList());
        const funnelMaps = (await get_funnelMaps({ includeFunnelsData })) as FunnelMap[];
        dispatch(setFunnelMapsList({ funnelMapsList: funnelMaps }));
      });
  };

  const renameFunnelMap = async ({
    funnelMap,
    newName,
  }: {
    funnelMap: FunnelMap;
    newName: string;
  }) => {
    try {
      const funnels: any = [];

      Object.keys(funnelMap.funnels).forEach((id) => {
        funnels.push({ funnelId: id, ...funnelMap.funnels[id].position });
      });
      await put_funnelMap(funnelMap.id, {
        ...funnelMapToFunnelMapPutPayload(funnelMap),
        name: newName,
      });
      dispatch(updateFunnelMapName({ funnelMapNewName: newName, funnelMapId: funnelMap.id }));
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const deleteFunnelMap = async (funnelMapId: string) => {
    try {
      await delete_funnelMap(funnelMapId);
      displayNotificationOnDelete('Funnel view was removed', SweepNotificationVariant.Success);
      dispatch(deleteFunnelMapFromList({ funnelMapId }));
    } catch (e) {
      displayNotificationOnDelete('Removal failed', SweepNotificationVariant.Error);
      telemetry.captureError(e);
    }
  };

  const displayNotificationOnDelete = (message: string, variant: SweepNotificationVariant) => {
    addNotification({
      message,
      variant,
    });
  };

  const updateIsPinned = async ({
    funnelMapId,
    isPinned,
  }: {
    funnelMapId: string;
    isPinned: boolean;
  }) => {
    try {
      await updateIsPinnedApi(funnelMapId, { isPinned });
      dispatch(setIsPinned({ funnelMapId, isPinned }));
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const createNewFunnelMap = useCallback(async () => {
    const funnelMap = await post_funnelMap({
      name: 'New Process Visualization',
      funnels: [],
    });

    navigate(`${appRoutes.canvasUnited.route}/${funnelMap.id}`);
  }, [navigate, post_funnelMap]);

  return {
    fetchFunnelMaps,
    renameFunnelMap,
    deleteFunnelMap,
    updateIsPinned,
    createNewFunnelMap,
  };
};
