import { IconButton } from '@sweep-io/sweep-design';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import { useTurnOffCommentsModeFirst } from '../../../canvas-pages/united-canvas/left-menu/useTurnOffCommentsModeFirst';
import { usePanels } from '../../../../panels/PanelsContext';
import { templatesPanelType } from '../dialog/panelTypes';
import { UI_EVENTS } from '../../../../../services/events';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';

interface LeftMenuTemplatesProps {
  crmOrgId: string;
  readonly?: boolean;
  disabled: boolean;
}

export const UnitedLeftMenuTemplates = ({ readonly, disabled }: LeftMenuTemplatesProps) => {
  const sendBiEvent = useSendBiEvent();
  const { isPanelOpen } = usePanels();
  const isTemplatesOpen = isPanelOpen(templatesPanelType);

  const turnOffCommentsModeFirst = useTurnOffCommentsModeFirst();

  const { maybeOpenPanel } = usePanels();

  if (readonly) {
    return null;
  }

  return (
    <RestrictedTooltip
      to={['create:funnels', 'edit:funnels']}
      notAllowedTitle={'To add new funnels, please contact your admin.'}
      allowedTitle="Templates"
      component={(isAllowed) => (
        <IconButton
          variant="flat"
          onClick={() => {
            sendBiEvent({ name: UI_EVENTS.canvasOpenTemplates });
            turnOffCommentsModeFirst(() => {
              maybeOpenPanel({ panelType: templatesPanelType });
            })
          }}
          disabled={!isAllowed || disabled}
          pressed={isTemplatesOpen}
        >
          <Icons.Layout variant="large" />
        </IconButton>
      )}
      placement="bottom"
    />
  );
};
