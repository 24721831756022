import { Box, Stack } from '@mui/material';
import { AGENT_PROMPTS, AI_AGENTS_INFO, AiAgent } from './aiAgentsConsts';
import { colors, Typography } from '@sweep-io/sweep-design';
import useAiAgentChats from './useAiAgentChats';
import { ReactNode } from 'react';

const AgentPrompts = ({
  agent,
  variant,
}: {
  agent: AiAgent;
  variant: 'expanded' | 'collapsed';
}) => {
  const { onConfirmMessage } = useAiAgentChats();
  const { icon, persona, description } = AI_AGENTS_INFO[agent.type];

  return (
    <Stack padding="0 22px">
      <Stack padding="0 30px">
        <AgentInfo
          variant={variant}
          icon={icon}
          persona={persona}
          name={agent.name}
          description={description}
        />
      </Stack>
      <Stack direction="row" justifyContent="center">
        {AGENT_PROMPTS.map((prompt) => (
          <PromptBubble
            key={prompt}
            onClick={() =>
              onConfirmMessage({ message: prompt, agentId: agent.id, agentType: agent.type })
            }
            prompt={prompt}
          />
        ))}
      </Stack>
    </Stack>
  );
};

const PromptBubble = ({ onClick, prompt }: { onClick: () => void; prompt: string }) => {
  return (
    <Box
      sx={{
        cursor: 'pointer',
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: '16px',
        padding: '16px',
        width: '100%',
        textAlign: 'center',
        backgroundColor: colors.white,
        ':hover': {
          borderColor: colors.blue[500],
          '.MuiTypography-root': {
            color: colors.blue[500],
          },
        },
      }}
      onClick={onClick}
    >
      <Typography variant="body-medium" color={colors.grey[700]}>
        {prompt}
      </Typography>
    </Box>
  );
};

const AgentInfo = ({
  icon,
  name,
  persona,
  description,
  variant,
}: {
  icon: ReactNode;
  name: string;
  persona: string;
  description: string;
  variant: 'expanded' | 'collapsed';
}) => {
  const isExpanded = variant === 'expanded';
  return (
    <Stack alignItems="center" pb={isExpanded ? 5 : 4}>
      <Stack maxWidth="568px" alignItems="center" gap={1}>
        <Box
          sx={{
            width: isExpanded ? '64px' : '52px',
            height: isExpanded ? '64px' : '52px',
          }}
        >
          {icon}
        </Box>
        <Stack gap={isExpanded ? 1 : 1.5} alignItems="center" textAlign="center">
          <Stack gap={isExpanded ? 1 : 0.5} alignItems="center" textAlign="center">
            <Typography variant={isExpanded ? 'h1-bold' : 'h4'} color={colors.grey[800]}>
              {name}
            </Typography>
            <Typography variant="caption" color={colors.grey[700]}>
              {persona}
            </Typography>
          </Stack>
          <Typography variant="body" color={colors.grey[900]} textAlign="center">
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AgentPrompts;
