import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import { uniqueId } from '../../lib/uniqueId';

interface AutomationActionPopupMenuProps {
  action?: DeepPartial<AutomationAction>;
  onClearAction?: () => any;
  onAddAction?: (action: Partial<AutomationAction>, isBefore: boolean) => any;
  readonly?: boolean;
  isClearable?: boolean;
  isOnlyAfter?: boolean;
}

export const AutomationActionPopupMenu = ({
  action,
  onClearAction,
  onAddAction,
  readonly,
  isClearable,
  isOnlyAfter,
}: AutomationActionPopupMenuProps) => {
  const options = isOnlyAfter
    ? [
        {
          label: 'Add action after',
          value: 'addAfter',
        },
      ]
    : [
        {
          label: 'Add action before',
          value: 'addBefore',
        },
        {
          label: 'Add action after',
          value: 'addAfter',
        },
      ];

  if (isClearable) {
    options.push({
      label: 'Clear',
      value: 'clear',
    });
  }
  return (
    <>
      {action !== undefined && !readonly && (
        <ActionsMenu
          iconSize={'tiny'}
          actions={options}
          onClick={(action) => {
            switch (action.value) {
              case 'clear':
                onClearAction && onClearAction();
                break;
              case 'addAfter':
                onAddAction &&
                  onAddAction(
                    {
                      _id: uniqueId(),
                      actionParams: undefined,
                      actionType: undefined,
                    },
                    false,
                  );
                break;
              case 'addBefore':
                onAddAction &&
                  onAddAction(
                    {
                      _id: uniqueId(),
                      actionParams: undefined,
                      actionType: undefined,
                    },
                    true,
                  );
                break;
            }
          }}
        />
      )}
    </>
  );
};
