import { Box, Divider, Tooltip } from '@mui/material';
import { useState } from 'react';
import { Button } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectCrmOrgs } from '../../environments/environmentsReducer';
import usePermission from '../../../common/permissions/usePermission';
import { UNTITLED_FUNNEL_MAP_NAME } from '../../../../constants';
import DeployDialog from '../../funnel-map-flow/dialogs/deploy/DeployDialog';
import DeploymentChangesDialog from '../../funnel-map-flow/dialogs/deployment-changes/DeploymentChangesDialog';
import { InvitationsDialog } from '../../funnel-map-flow/dialogs/invitations/InvitationsDialog';
import { RestoreDialog } from '../../funnel-map-flow/dialogs/restore/RestoreDialog';
import { useFunnelMapReadOnly } from '../../funnel-map-flow/read-only/useFunnelMapReadOnly';
import RequestChanges from '../../funnel-map-flow/request-changes/RequestChanges';
import { AutoSave } from '../../funnel-map-flow/auto-save/AutoSave';
import { EnvSelector } from '../../environments/connect-env/EnvSelector';
import { useConfigurationCanvas } from '../../configuration-canvas/public/useConfigurationCanvas';
import { usePanels } from '../../../panels/PanelsContext';
import usePaywall from '../../../dashboard/paywall/usePaywall';
import CanvasDeployPaywallBlock from './CanvasDeployPaywallBlock';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { usePageHelpers } from './usePageHelpers';
import { useSweepRoleGroups } from '../../../common/useSweepRoleGroups';
import { useFunnelMapPage } from '../../funnel-map-flow/useFunnelMapPage';
import { useTurnOffCommentsModeFirst } from './left-menu/useTurnOffCommentsModeFirst';
import { selectUserInfoData } from '../../../../reducers/userInfoReducer';
import AiChatButtonEntryPoint from '../../../aiAgentCenter/AiChatButtonEntryPoint';
import AiAgentCreateButton from '../../../aiAgentCenter/AiAgentCreateButton';
import useAiAgentCenter from '../../../aiAgentCenter/useAiAgentCenter';

export interface FunnelMapRightMenuProps {
  funnelMap: FunnelMap;
  funnelMapEmpty?: boolean;
}

export const CanvasUnitedRightMenu = ({ funnelMap, funnelMapEmpty }: FunnelMapRightMenuProps) => {
  const { onRestoreCallback, restoreFunnelName, versionName, funnelName } = useFunnelMapReadOnly();
  const { maybeCloseActivePanel } = usePanels();
  const { canvasCrmOrgId } = useConfigurationCanvas();
  const { isUserWithRequestToDeployFunnels } = useSweepRoleGroups();
  const { funnelId, snapshotId } = usePageHelpers();
  const { copilot, aiAgentCenter } = useFeatureToggle();
  const { onAiChatClick } = useFunnelMapPage();
  const user = useSelector(selectUserInfoData);
  const disableAi = !!user?.account?.disableAi;
  const turnOffCommentsModeFirst = useTurnOffCommentsModeFirst();
  const lastUpdatedAt = funnelMap.updatedAt;
  const { isCreateAgentActive } = useAiAgentCenter();

  const [isRestorePopupOpen, setIsRestorePopupOpen] = useState(false);
  const [isDeployDialogOpen, setIsDeployDialogOpen] = useState(false);
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);

  const [isAllowDeploy] = usePermission(['create:deploy-job']);

  const orgs = useSelector(selectCrmOrgs);

  const { isPaywallActive } = usePaywall();
  const [isPaywallDialogOpen, setIsPaywallDialogOpen] = useState(false);

  const onDeployClick = () => {
    if (isPaywallActive) {
      setIsPaywallDialogOpen(true);
    } else {
      setIsDeployDialogOpen(true);
    }
  };

  const renderDeployButton = () => {
    if (isUserWithRequestToDeployFunnels(canvasCrmOrgId)) {
      return (
        <Tooltip title="Request changes">
          <span>
            <Button
              size="small"
              onClick={onDeployClick}
              endIconName="SweepBug"
              disabled={isCreateAgentActive}
            >
              Request to deploy
            </Button>
          </span>
        </Tooltip>
      );
    }

    let tooltipText = undefined;
    if (funnelMapEmpty) {
      tooltipText = 'In order to deploy you need to add at least one funnel';
    }

    if (orgs.length === 0) {
      tooltipText = 'In order to deploy you need to connect at least one Salesforce environment';
    }

    if (!isAllowDeploy) {
      tooltipText = `You don't have permissions to deploy`;
    }

    return (
      <Tooltip title={tooltipText}>
        <span>
          <Button
            size="small"
            onClick={onDeployClick}
            disabled={Boolean(tooltipText) || isCreateAgentActive}
            endIconName="SweepBug"
          >
            Deploy
          </Button>
        </span>
      </Tooltip>
    );
  };

  const menuContentEdit = (
    <>
      {lastUpdatedAt && <AutoSave funnelMap={funnelMap} />}

      <InvitationButton
        openInvitationDialog={() => setIsInvitationDialogOpen(true)}
        disabled={isCreateAgentActive}
      />

      <RequestChanges funnelIds={Object.keys(funnelMap.funnelsData)} />
      {renderDeployButton()}

      {isPaywallDialogOpen && (
        <CanvasDeployPaywallBlock closeDialog={() => setIsPaywallDialogOpen(false)} />
      )}
    </>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
        data-testid="funnel-flow-right-menu"
      >
        {copilot && aiAgentCenter && !disableAi && <AiAgentCreateButton />}

        {copilot && !disableAi && (
          <AiChatButtonEntryPoint
            onClick={() => turnOffCommentsModeFirst(onAiChatClick)}
            variant="secondary"
            disabled={isCreateAgentActive}
          />
        )}

        <EnvSelector
          variant="icon-button"
          menuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
            transformOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          allowDeleteLastEnv={false}
          onChangeEnvCallback={maybeCloseActivePanel}
          disabled={isCreateAgentActive}
        />
        <Divider orientation="vertical" variant="middle" flexItem />
        {menuContentEdit}
      </Box>

      {isDeployDialogOpen && (
        <DeployDialog
          open
          setIsOpen={setIsDeployDialogOpen}
          initialCrmOrgId={canvasCrmOrgId}
          funnels={Object.values(funnelMap.funnelsData)}
          funnelMapId={funnelMap.id}
        />
      )}

      <DeploymentChangesDialog />

      {isRestorePopupOpen && lastUpdatedAt && (
        <RestoreDialog
          funnelName={restoreFunnelName}
          onConfirm={() =>
            funnelId &&
            funnelName &&
            snapshotId &&
            onRestoreCallback({ funnelId, funnelName, snapshotId })
          }
          closeDialog={() => setIsRestorePopupOpen(false)}
          versionName={versionName}
          versionDate={lastUpdatedAt}
        />
      )}

      {isInvitationDialogOpen && (
        <InvitationsDialog
          funnelMapId={funnelMap.id}
          funnelMapName={funnelMap.name ?? UNTITLED_FUNNEL_MAP_NAME}
          isOpen={isInvitationDialogOpen}
          onClose={() => setIsInvitationDialogOpen(false)}
        />
      )}
    </>
  );
};

const InvitationButton = ({
  openInvitationDialog,
  disabled,
}: {
  openInvitationDialog: () => void;
  disabled: boolean;
}) => (
  <Button size="small" variant="outlined" onClick={openInvitationDialog} disabled={disabled}>
    Share
  </Button>
);
