import { Box, Divider } from '@mui/material';
import { AutomationsDeployButton } from '../common/deploy-button/AutomationDeployButton';
import usePermission from '../common/permissions/usePermission';
import { useAutomationsContext } from './AutomationsContext';
import { useCallback, useState } from 'react';
import { useValidateAllAndReturnIsValid } from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { PartialAutomationStructure } from './AutomationsForm';
import { clearFirstConditionIfEmpty } from '../common/rule-builder/helpers';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { AutomationType } from '../../types/enums/AutomationType';
import { AutomationUserSettings } from './AutomationUserSettings';
import { AutomationPermissionModeSelector } from './AutomationPermissionModeSelector';

interface AutomationDialogActionsProps {
  isAutomationsFormValid: boolean;
  crmOrgId: string;
  readonly?: boolean;
  onSaveOrCreate: (
    automationJson: AutomationStructureNew,
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
}

export const AutomationDialogActions = ({
  isAutomationsFormValid,
  onSaveOrCreate,
  crmOrgId,
  readonly = false,
}: AutomationDialogActionsProps) => {
  const { automationsOrder } = useFeatureToggle();
  const dataByVariant = useAutomationsContext();
  const { automationJson, automationType } = dataByVariant;
  const { permissions } = dataByVariant;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDedupeMatching =
    automationType === AutomationType.Dedupe || automationType === AutomationType.Matching;
  const [isAllowedBtn, isAllowedDeployBtn] = usePermission([permissions.edit, permissions.deploy]);
  if (!isAllowedBtn) {
    readonly = true;
  }
  readonly = isSubmitting || readonly;

  const validateAllRuleBuilders = useValidateAllAndReturnIsValid();

  const _createOrSaveAutomation = useCallback(
    async (crmOrgIds: string[]) => {
      const isValid = validateAllRuleBuilders();
      if (!isValid) {
        return { isError: 'Invalid rule builder' };
      }
      setIsSubmitting(true);

      const savedAutomation = await onSaveOrCreate(
        _cleanConditionTriggerBeforeSaving(automationJson) as AutomationStructureNew,
        crmOrgIds,
      );
      setIsSubmitting(false);
      return savedAutomation;
    },
    [automationJson, onSaveOrCreate, validateAllRuleBuilders],
  );

  const _cleanConditionTriggerBeforeSaving = (_automationJson: PartialAutomationStructure) => {
    if (_automationJson.automationDetails?.when) {
      const tempEc = _automationJson?.automationDetails?.when?.criteria;
      if (tempEc) {
        const tempNewEc = clearFirstConditionIfEmpty(tempEc);
        if (tempNewEc.criteria.length === 0) {
          return {
            ..._automationJson,
            automationDetails: {
              ..._automationJson.automationDetails,
              when: undefined,
            },
          };
        } else {
          const temp: CriteriaDetails = {
            ..._automationJson.automationDetails?.when,
            criteria: tempNewEc,
          };
          return {
            ..._automationJson,
            automationDetails: {
              ..._automationJson.automationDetails,
              when: temp,
            },
          };
        }
      } else {
        return {
          ..._automationJson,
          automationDetails: {
            ..._automationJson.automationDetails,
            when: undefined,
          },
        };
      }
    }
    return _automationJson;
  };

  const isShowOrder =
    automationJson?.type === AutomationType.Default ||
    automationJson?.type === AutomationType.Alert ||
    automationJson?.type === AutomationType.Assignment;

  const userPermissionContent =
    automationsOrder && isShowOrder ? (
      <AutomationUserSettings crmOrgId={crmOrgId} readonly={readonly} />
    ) : (
      <AutomationPermissionModeSelector />
    );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', gap: 2 }}>
      <AutomationsDeployButton
        onDeployOrSave={_createOrSaveAutomation}
        disabled={!isAutomationsFormValid || ((!isAllowedBtn || readonly) && !isAllowedDeployBtn)}
        isWithPaywall
      />
      {!isDedupeMatching && (
        <>
          <Divider orientation="vertical" variant="fullWidth" flexItem />
          {userPermissionContent}
        </>
      )}
    </Box>
  );
};
