import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import useAiAgentChats from './useAiAgentChats';
import AgentsCardsExpanded from './AgentsCardsExpanded';
import AiAgentCenterSideMenu from './AiAgentCenterSideMenu';
import ChatWithAgentExpanded from './ChatWithAgentExpanded';

const HISTORY_PANEL_WIDTH = '256px';

const AiAgentCenterExpanded = ({
  toggleExpandButton,
  closeButton,
  onNewAgentClick,
}: {
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  onNewAgentClick: () => void;
}) => {
  const { createNewGhostChat, activeChat } = useAiAgentChats();

  return (
    <Stack height="100%" direction="row" position="relative">
      <Stack
        sx={{
          flex: `0 0 ${HISTORY_PANEL_WIDTH}`,
        }}
      >
        <AiAgentCenterSideMenu />
      </Stack>
      <Stack
        sx={{
          flex: 1,
          alignItems: 'center',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {!activeChat && (
          <AgentsCardsExpanded
            onSelect={(agentId, agentType) => createNewGhostChat(agentId, agentType)}
            toggleExpandButton={toggleExpandButton}
            closeButton={closeButton}
            onNewAgentClick={onNewAgentClick}
          />
        )}
        {activeChat && (
          <ChatWithAgentExpanded
            toggleExpandButton={toggleExpandButton}
            closeButton={closeButton}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default AiAgentCenterExpanded;
