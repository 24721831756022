import { Box, Stack } from '@mui/material';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { ChevronLeft } from '@sweep-io/sweep-design/dist/icons';
import AgentInfoSmall from './AgentInfoSmall';
import AiAgentChat from './AiAgentChat';
import { ReactNode, useState } from 'react';
import useAiAgentChats from './useAiAgentChats';
import { AI_AGENTS_INFO, TOP_BAR_COMMON_SX } from './aiAgentsConsts';
import useAiAgents from './useAiAgents';
import { AgentType } from '@server/ai';
import AgentSettingsMenu from './agentSettings/AgentSettingsMenu';

const ChatWithAgentCollapsed = ({
  toggleExpandButton,
  closeButton,
  returnToAgentsList,
}: {
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  returnToAgentsList: () => void;
}) => {
  const { onConfirmMessage, activeChat, disableInput, createNewGhostChat } = useAiAgentChats();
  const { activeAgent, renameAgent } = useAiAgents();
  const [isScrolled, setIsScrolled] = useState(false);

  if (!activeAgent) {
    return null;
  }

  const { icon, persona } = AI_AGENTS_INFO[activeAgent.type];

  const newButton = (
    <Button
      startIconName="Plus"
      variant="outlined"
      onClick={() => createNewGhostChat(activeAgent.id, activeAgent.type)}
      size="small"
    >
      New chat
    </Button>
  );

  const isDocumentationAgent = activeAgent?.type === AgentType.Documentation;
  const isNewChat = activeChat?.data?.aiChatDetails?.messages.length === 0;
  const hideNameAndMenu = isNewChat && !isDocumentationAgent;

  return (
    <Stack height="100%">
      <Box
        sx={{
          ...TOP_BAR_COMMON_SX,
          borderBottom: `1px solid ${colors.grey[300]}`,
          justifyContent: 'space-between',
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          <IconButton variant="flat" size="tiny" onClick={returnToAgentsList}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="body-bold">AI Agents</Typography>
        </Box>

        <Box display="flex" gap={1} alignItems="center">
          {newButton}
          {toggleExpandButton}
          {closeButton}
        </Box>
      </Box>

      {!hideNameAndMenu && (
        <Stack
          sx={{
            padding: '8px 16px 8px',
            borderBottom: isScrolled ? `1px solid ${colors.grey[300]}` : undefined,
            height: isScrolled ? '56px' : '80px',
            justifyContent: 'center',
            transition: 'height 0.1s linear',
          }}
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={isScrolled ? 'center' : 'start'}
          >
            <AgentInfoSmall
              icon={icon}
              persona={persona}
              name={activeAgent.name}
              onEditName={(name) => renameAgent(activeAgent.id, name)}
              isTiny={isScrolled}
              iconSize="28px"
            />
            {!isDocumentationAgent && <AgentSettingsMenu agent={activeAgent} />}
          </Stack>
        </Stack>
      )}

      <AiAgentChat
        messages={activeChat?.data?.aiChatDetails?.messages ?? []}
        onConfirm={(message) =>
          onConfirmMessage({ message, agentId: activeAgent.id, agentType: activeAgent.type })
        }
        isLoadingChat={!!activeChat?.isLoading}
        isLoadingResponse={!!activeChat?.data?.isLoading}
        disableInput={disableInput}
        noteAlignment="left"
        onScroll={() => setIsScrolled(true)}
      />
    </Stack>
  );
};

export default ChatWithAgentCollapsed;
