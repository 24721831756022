import { useCallback } from 'react';
import {
  AiChatDto,
  AiChatMessage,
  ChatShort,
  NewChatResponseDto,
} from '../../components/common/ai-chat/aiChatTypes';
import { useSweepApi } from '../sweep';
import { AgentContextComponent, AgentType } from '@server/ai';
import { AgentFile, AiAgent, NewAgentFile } from '../../components/aiAgentCenter/aiAgentsConsts';

export const useAiAgentApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_agents: useCallback(async () => {
      const { data } = await sweepApi.get<AiAgent[]>(`/ai-agents`);
      return data;
    }, [sweepApi]),
    create_agent: useCallback(
      async (payload: {
        name: string;
        type: AgentType;
        contextComponents: AgentContextComponent[];
      }) => {
        const { data } = await sweepApi.post<AiAgent>(`/ai-agents`, payload);
        return data;
      },
      [sweepApi],
    ),
    rename_agent: useCallback(
      async (agentId: string, payload: { name: string }) =>
        sweepApi.patch<void>(`/ai-agents/${agentId}/name`, payload),
      [sweepApi],
    ),
    edit_agent_instructions: useCallback(
      async (
        agentId: string,
        payload: {
          instructions: string;
        },
      ) => sweepApi.patch<void>(`/ai-agents/${agentId}/instructions`, payload),
      [sweepApi],
    ),
    post_agent_files: useCallback(
      async (
        agentId: string,
        payload: {
          files: NewAgentFile[];
        },
      ) => {
        const { data } = await sweepApi.post<AgentFile[]>(`/ai-agents/${agentId}/files`, payload);
        return data;
      },
      [sweepApi],
    ),
    delete_agent_file: useCallback(
      async (agentId: string, fileId: string) =>
        sweepApi.delete<void>(`/ai-agents/${agentId}/files/${fileId}`),
      [sweepApi],
    ),
    delete_agent: useCallback(
      async (agentId: string) => sweepApi.delete<void>(`/ai-agents/${agentId}`),
      [sweepApi],
    ),
    get_chats: useCallback(async () => {
      const { data } = await sweepApi.get<ChatShort[]>(`/ai-agents/chats`);
      return data;
    }, [sweepApi]),
    get_chat: useCallback(
      async (chatId: string) => {
        const { data } = await sweepApi.get<AiChatDto>(`/ai-agents/chats/${chatId}`);
        return data;
      },
      [sweepApi],
    ),
    rename_chat: useCallback(
      async (chatId: string, payload: { name: string }) => {
        const { data } = await sweepApi.patch<boolean>(`/ai-agents/chats/${chatId}/name`, payload);
        return data;
      },
      [sweepApi],
    ),
    set_isFavorite_chat: useCallback(
      async (chatId: string, payload: { isFavorite: boolean }) => {
        const { data } = await sweepApi.patch<boolean>(
          `/ai-agents/chats/${chatId}/favorite`,
          payload,
        );
        return data;
      },
      [sweepApi],
    ),
    post_newChat: useCallback(
      async (agentId: string, payload: { message: AiChatMessage }) => {
        const { data } = await sweepApi.post<NewChatResponseDto>(
          `/ai-agents/${agentId}/chats`,
          payload,
        );
        return data;
      },
      [sweepApi],
    ),
    patch_sendMessage: useCallback(
      async ({ chatId, payload }: { chatId: string; payload: { message: AiChatMessage } }) => {
        const { data } = await sweepApi.patch<AiChatMessage>(`/ai-agents/chats/${chatId}`, payload);
        return data;
      },
      [sweepApi],
    ),
    delete_chat: useCallback(
      async (chatId: string) => {
        const { data } = await sweepApi.delete<boolean>(`/ai-agents/chats/${chatId}`);
        return data;
      },
      [sweepApi],
    ),
    get_search: useCallback(
      async (searchText: string) => {
        const { data } = await sweepApi.get<AiChatDto[]>(
          `/ai-agents/chats/search?term=${encodeURIComponent(searchText)}`,
        );
        return data;
      },
      [sweepApi],
    ),
  };
};
