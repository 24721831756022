import { Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';

const AiChatInitialFetchMessage = () => {
  return (
    <Stack gap="10px">
      <Typography variant="body" color={colors.grey[800]}>
        We are currently scanning your Salesforce environment. This may take a few minutes.
        <br/>
        <br/>
        When scanning is complete, you can ask any question about your Salesforce.
      </Typography>
    </Stack>
  );
};

export default AiChatInitialFetchMessage;
