import React, { useCallback } from 'react';
import { CanvasContext } from './CanvasContext';
import { Rect, useReactFlow, useViewport } from 'reactflow';

/* Public Api to be exposed */

export const useSweepCanvas = () => {
  const { referencesById, setReferencesMap, hoveredElement } = React.useContext(CanvasContext);
  const rf = useReactFlow();
  const viewport = useViewport();
  const { zoom } = viewport;

  const getRefForPluginButton = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      const ref = referencesById[`${pluginType}-${groupId}`];
      return ref;
    },
    [referencesById],
  );

  const pluginButtonExists = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      return !!getRefForPluginButton(groupId, pluginType)?.current;
    },
    [getRefForPluginButton],
  );

  const clickPluginButton = useCallback(
    (pluginType: PluginTypes, groupId: string) => {
      const ref = getRefForPluginButton(groupId, pluginType);
      if (!ref) {
        console.warn(
          `Plugin button for plugin type ${pluginType} and group id ${groupId} not found`,
        );
        return;
      }
      ref.current?.click();
    },
    [getRefForPluginButton],
  );

  const removeRefId = useCallback(
    (id: string) => {
      setReferencesMap(id, undefined);
    },
    [setReferencesMap],
  );

  const removePluginButton = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      const ref = getRefForPluginButton(groupId, pluginType);
      if (!ref) {
        console.warn(
          `Plugin button for plugin type ${pluginType} and group id ${groupId} not found`,
        );
        return;
      }
      removeRefId(`${pluginType}-${groupId}`);
    },
    [getRefForPluginButton, removeRefId],
  );

  const getIntersectingNodes = useCallback(
    (rect: Rect) => {
      const canvasRef = window.document.getElementById('sweep-canvas');
      const { top: canvasTop, left: canvasLeft } = canvasRef?.getBoundingClientRect() ?? {
        top: 0,
        left: 0,
      };

      const flowPosition = rf.screenToFlowPosition({
        x: rect.x - canvasLeft,
        y: rect.y - canvasTop,
      });

      return rf.getIntersectingNodes(
        {
          ...flowPosition,
          width: rect.width / zoom,
          height: rect.height / zoom,
        },
        true,
      );
    },
    [rf, zoom],
  );

  return {
    clickPluginButton,
    pluginButtonExists,
    removePluginButton,
    hoveredElement,
    getIntersectingNodes,
  };
};
