export enum RoleGroupType {
  SYSTEM = 'SYSTEM',
  USER_CREATED = 'USER_CREATED',
}

export enum RoleGroupLevelType {
  AccountBased = 'AccountBased',
  OrgBased = 'OrgBased',
}

interface RoleGroupBaseInterface {
  id: string;
  name: string;
  description?: string;
  type: RoleGroupType;

  createdAt: Date;
  createdById: string;
  updatedAt: Date;
  updatedById?: string;
}
export type RoleGroupInterface = UserDefinedRoleGroupsInterface | SystemRoleGroupInterface;

interface UserDefinedRoleGroupsAccountBasedLevels {
  type: RoleGroupLevelType.AccountBased;
  levels: RoleLevels;
}

interface UserDefinedRoleGroupsOrgBasedLevels {
  type: RoleGroupLevelType.OrgBased;
  levels: OrgRoleLevels[];
}

export interface OrgRoleLevels {
  crmOrgId: string;
  levels: RoleLevels;
}

export type UserDefinedRoleGroupsLevels =
  | UserDefinedRoleGroupsAccountBasedLevels
  | UserDefinedRoleGroupsOrgBasedLevels;

type SystemRoleGroupInterface = RoleGroupBaseInterface;
export interface UserDefinedRoleGroupsInterface extends RoleGroupBaseInterface {
  roleGroupLevels: UserDefinedRoleGroupsLevels;
}
export enum RoleLevel {
  VIEWER = 'VIEWER',
  REQUEST_TO_DEPLOY = 'REQUEST_TO_DEPLOY',
  DEPLOY = 'DEPLOY',
}

export type RoleLevels = {
  [key in typeof Sections[number]]: RoleLevel;
};

export const Sections = [
  'alerts',
  'automations',
  'dedupMatching',
  'assignments',
  'funnels',
  'rollups',
  'hubspot',
] as const;
