import { Button } from '@sweep-io/sweep-design';
import useAiAgentCenter from './useAiAgentCenter';
import RestrictedTooltip from '../common/permissions/RestrictedTooltip';
import usePermission from '../common/permissions/usePermission';

const AiAgentCreateButton = () => {
  const permissionStr = 'create:ai-agent';
  const [isAllowedBtn] = usePermission([permissionStr]);
  const { setIsAgentCreateActive, toggleIsOpened, isOpened, isCreateAgentActive } = useAiAgentCenter();

  const onClick = () => {
    if (isOpened) {
      toggleIsOpened();
    }
    setIsAgentCreateActive(!isCreateAgentActive);
  };

  return (
    <RestrictedTooltip
      to={[permissionStr]}
      notAllowedTitle={'To create an agent, please contact your admin.'}
    >
      <Button
        disabled={!isAllowedBtn}
        variant="outlined"
        size="small"
        onClick={onClick}
        startIconName="Plus"
        pressed={isCreateAgentActive}
      >
        New Agent
      </Button>
    </RestrictedTooltip>
  );
};

export default AiAgentCreateButton;
