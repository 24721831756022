import { useCallback } from 'react';
import {
  AiChatConfigurationPayload,
  AiChatFieldPayload,
  useCrmOrgsApiFacade,
} from '../../../apis/facades/useCrmOrgsApiFacade';

export const useAiChat = () => {
  const { post_aiConfigurationChat, post_aiFieldChat } = useCrmOrgsApiFacade();

  const onSendConfigurationMessage = useCallback(
    async ({ payload }: { payload: AiChatConfigurationPayload }) => {
      const res = await post_aiConfigurationChat(payload);
      return res;
    },
    [post_aiConfigurationChat],
  );

  const onSendFieldMessage = useCallback(
    async ({ payload }: { payload: AiChatFieldPayload }) => {
      const res = await post_aiFieldChat(payload);
      return res;
    },
    [post_aiFieldChat],
  );

  return {
    onSendFieldMessage,
    onSendConfigurationMessage,
  };
};
