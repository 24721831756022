import { createContext, useState } from 'react';

interface HubspotCampaignCtxType {
  campaignTypeOptions: { name: string; label: string }[];
  campaignStatusOptions: { name: string; label: string }[];
  campaignObjectFields?: SweepFieldInterface[];
  crmOrgId: string;
  hsOrgId: string;
  objectType: string;
  isSaving: boolean;
  setIsSaving: (isSaving: boolean) => void;
  isPristine: boolean;
  setIsPristine: (isPristine: boolean) => void;
}

export const HubspotCampaignCtx = createContext<HubspotCampaignCtxType>({
  campaignTypeOptions: [],
  campaignStatusOptions: [],
  crmOrgId: 'PLEASE_PROVIDE',
  hsOrgId: 'PLEASE_PROVIDE',
  objectType: 'Contact',
  isSaving: false,
  setIsSaving: () => {
    throw new Error('setIsSaving was not initialized');
  },
  isPristine: true,
  setIsPristine: () => {
    throw new Error('setIsPristine was not initialized');
  },
  campaignObjectFields: [],
});

export const HubspotCampaignCtxProvider = ({
  children,
  campaignStatusOptions,
  campaignTypeOptions,
  crmOrgId,
  hsOrgId,
  objectType,
  campaignObjectFields,
}: Omit<HubspotCampaignCtxType, 'setIsSaving' | 'isSaving' | 'isPristine' | 'setIsPristine'> & {
  children: React.ReactNode;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [isPristine, setIsPristine] = useState(true);

  const value = {
    campaignStatusOptions,
    campaignTypeOptions,
    crmOrgId,
    hsOrgId,
    objectType,
    isSaving,
    setIsSaving,
    isPristine,
    setIsPristine,
    campaignObjectFields,
  };

  return <HubspotCampaignCtx.Provider value={value}>{children}</HubspotCampaignCtx.Provider>;
};
