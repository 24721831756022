import { Box, Popover } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { MoreActionsVertical } from '@sweep-io/sweep-design/dist/icons';
import usePopover from '../../../../hooks/usePopover';
import { StyledListItem } from '../../../common/StyledListItem';

export const RemoveOrgBasedRoleLevelGroup = ({
  showButton,
  onDelete,
}: {
  showButton: boolean;
  onDelete: () => void;
}) => {
  const { anchorElement, anchorRef, isPopoverOpen, openPopover, closePopover } = usePopover();

  return (
    <>
      {showButton && (
        <IconButton
          variant="outlined"
          size="small"
          onClick={(event) => openPopover(event)}
          ref={anchorRef}
        >
          <MoreActionsVertical />
        </IconButton>
      )}

      <Popover
        open={isPopoverOpen}
        anchorEl={anchorElement}
        onClose={() => closePopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Box p={1}>
          <StyledListItem
            sx={{ width: '232px', mb: 0 }}
            onClick={() => {
              onDelete();
              closePopover();
            }}
            title={'Remove'}
          />
        </Box>
      </Popover>
    </>
  );
};
