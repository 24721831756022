import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import useEventListener from '../common/useEventListener';
import useAiAgentCenter from './useAiAgentCenter';
import AiAgentNameDialog from './AiAgentNameDialog';
import useAiAgents from './useAiAgents';
import { Node as ReactFlowNode } from 'reactflow';
import {
  AgentType,
  AgentContextComponent,
  ChatCompletionRequestMessageRole,
  AI_PROCESS_OPTIMIZATION_AGENT_RECOMMENDATIONS_USER_PROMPT,
} from '@server/ai';
import { usePanels } from '../panels/PanelsContext';
import { aiAgentCenterPanelType } from '../dashboard/aiAgents/aiAgentCenterPanelType';
import useAiAgentChats from './useAiAgentChats';
import { DateTime } from 'luxon';
import { useSweepCanvas } from '../multi-canvas/useSweepCanvas';
import { useBoxSelection } from './useBoxSelection';
import BoxSelectionLayer from './BoxSelectionLayer';
import { Button } from '@sweep-io/sweep-design';
import { Box } from '@mui/material';
import _groupBy from 'lodash/groupBy';
import { CanvasElementType } from '../multi-canvas/canvasTypes';

const SUPPORTED_NODES_TYPES = [CanvasElementType.REGULAR, CanvasElementType.NURTURING_BUCKET];

const BOX_BORDER_WIDTH = 1;

const getContextFromNodes = (nodes: ReactFlowNode[]) => {
  const relevantNodes = nodes.filter((node) =>
    SUPPORTED_NODES_TYPES.includes(node.type as CanvasElementType),
  );

  const groupedByFunnelId = _groupBy(relevantNodes, (node) => node.parentNode ?? node.parentId);
  const contextComponents: AgentContextComponent[] = [];
  for (const [funnelId, nodes] of Object.entries(groupedByFunnelId)) {
    contextComponents.push({
      funnelId,
      stagesIds: nodes.map((node) => node.id),
    });
  }
  return contextComponents;
};

const SelectionContext = createContext<DOMRect | null>(null);

const AiAgentCreation = () => {
  const [selectedContext, setSelectedContext] = useState<AgentContextComponent[]>([]);
  const [isNameDialogOpened, setIsNameDialogOpened] = useState(false);
  const { isCreateAgentActive, setIsAgentCreateActive, toggleIsOpened, isOpened } =
    useAiAgentCenter();
  const { createChatWithMessage } = useAiAgentChats();
  const { createAgent } = useAiAgents();
  const { maybeOpenPanel } = usePanels();
  const { getIntersectingNodes } = useSweepCanvas();

  const buttonContainerRef = useRef<HTMLDivElement>();

  const selectContainerRef = useRef<HTMLElement | null>(null);
  const {
    selection,
    reset: resetSelectionBox,
    isSelecting,
  } = useBoxSelection({
    container: selectContainerRef,
    disableListenerCb: (e) => {
      return buttonContainerRef.current?.contains(e.target as Element) ?? false;
    },
  }) ?? {};

  const selectedNodes = selection ? getIntersectingNodes(selection) : [];

  const activateHandler = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    resetSelectionBox();

    const context: AgentContextComponent[] = getContextFromNodes(selectedNodes);

    setIsNameDialogOpened(true);
    setSelectedContext(context);
  };

  const finish = useCallback(() => {
    setIsAgentCreateActive(false);
    setSelectedContext([]);
  }, [setIsAgentCreateActive]);

  const onConfirmCreate = async (name: string) => {
    if (selectedContext) {
      const newAgent = await createAgent({
        name,
        contextComponents: selectedContext,
        type: AgentType.ProcessOptimization,
      });
      if (!newAgent) {
        return;
      }

      const createdAt = DateTime.now().toISO() ?? '';
      const newMessage = {
        role: ChatCompletionRequestMessageRole.USER,
        content: AI_PROCESS_OPTIMIZATION_AGENT_RECOMMENDATIONS_USER_PROMPT,
        createdAt,
        hidden: true, //UX decision - not to show the prompt in this flow
      };

      createChatWithMessage({
        message: newMessage,
        agentId: newAgent.id,
        agentType: newAgent.type,
      });

      maybeOpenPanel({
        panelType: aiAgentCenterPanelType,
        onOpen: () => {
          if (!isOpened) {
            toggleIsOpened();
          }
        },
      });
      finish();
    }
  };

  useEffect(() => {
    //upon "create agent" activation, close agents panel
    if (isCreateAgentActive) {
      if (isOpened) {
        toggleIsOpened();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateAgentActive]);

  const onEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (selection) {
          resetSelectionBox();
          return;
        }
        finish();
      }
    },
    [finish, selection, resetSelectionBox],
  );
  useEventListener('keydown', onEscapeHandler);

  const showCursorTooltip = !selection && !isNameDialogOpened && selectedContext.length === 0;
  const showButton = selectedNodes.length > 0 && !isSelecting;

  const buttonRef = useRef<HTMLButtonElement>();
  const buttonBoundingBox = buttonRef?.current?.getBoundingClientRect() ?? {
    height: 0,
    width: 0,
  };

  const buttonTop = selection
    ? selection.y
    + selection.height
    - 2 * buttonBoundingBox.height
    - 2 * BOX_BORDER_WIDTH
    : 0;
  const buttonLeft = selection
    ? selection.x + selection.width / 2 - buttonBoundingBox.width / 2
    : 0;

  return (
    <>
      {isCreateAgentActive && (
        <SelectionContext.Provider value={selection}>
          <BoxSelectionLayer ref={selectContainerRef} showCursorTooltip={showCursorTooltip}>
            <Box
              sx={{
                visibility: showButton ? 'visible' : 'hidden',
                position: 'absolute',
                zIndex: 1,
                top: `${buttonTop}px`,
                left: `${buttonLeft}px`,
              }}
              ref={buttonContainerRef}
            >
              <Button
                ref={buttonRef}
                startIconName="Sparkle"
                endIconName="ArrowRight"
                size="small"
                onClick={activateHandler}
              >
                Activate Agent
              </Button>
            </Box>
          </BoxSelectionLayer>
        </SelectionContext.Provider>
      )}
      {isNameDialogOpened && (
        <AiAgentNameDialog
          onConfirm={onConfirmCreate}
          closeDialog={() => {
            setIsNameDialogOpened(false);
            setSelectedContext([]);
          }}
        />
      )}
    </>
  );
};

export default AiAgentCreation;
