import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SxProps, TableRow as MuiTableRow, Theme } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { Reorder } from '@sweep-io/sweep-design/dist/icons';
import TableRowCells from './TableRowCells';
import { DataTableDraggableColumn, DataTableRow, DataTableVariant } from './TableTypes';
import { dataTableVariants } from './dataTableVariants';

interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableDraggableColumn[];
  sxRowFunction?: (isDragging: boolean) => SxProps<Theme>;
  allowReorder?: boolean;
  isBeingDragged: boolean;
  isOverlay: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableDraggableColumn,
  ) => void;
  actionableButtonsOnHover?: boolean;
  variant: DataTableVariant;
}

export function DraggableTableRow<TRow extends DataTableRow = any>({
  isOverlay,
  row,
  allowReorder,
  sxRowFunction,
  variant,
  onClick,
  isBeingDragged,
  actionableButtonsOnHover,
  ...rowProps
}: RowProps<TRow>) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: row.id,
    disabled: row.disabled,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition || undefined,
  };

  const sxRow = sxRowFunction ? sxRowFunction(Boolean(isBeingDragged)) : {};

  const isRenderEmpty = isBeingDragged && !isOverlay;

  return (
    <MuiTableRow
      ref={setNodeRef}
      data-testid="data-table-row"
      style={style}
      {...attributes}
      sx={{
        minHeight: dataTableVariants[variant].minRowHeight,
        '& .actions': {
          opacity: actionableButtonsOnHover ? 0 : 1,
        },
        '&:hover .actions': {
          opacity: 1,
        },
        ...sxRow,
        animation: row._animation ? row._animation : '',
        cursor: row.isClickable ? 'pointer' : 'default',
        backgroundColor: row.isRowActive ? colors.grey[100] : 'transparent',
      }}
      className="SweepDataTableRow"
    >
      <TableRowCells
        isRenderEmpty={isRenderEmpty}
        row={row}
        allowReorder={!!allowReorder}
        dragHandle={<Reorder {...listeners} color={colors.grey[800]} />}
        variant={variant}
        onClick={
          !!onClick
            ? (
                e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
                column: DataTableDraggableColumn,
              ) => onClick(e, column)
            : undefined
        }
        {...rowProps}
      />
    </MuiTableRow>
  );
}
