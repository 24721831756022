import { AgentFile, AgentFileInvalid } from '../aiAgentsConsts';
import { colors, Typography } from '@sweep-io/sweep-design';
import { Box, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../../reducers/accountUsersReducer';
import { humanizeDate, humanizeDateVariants } from '../../helpers/humanizeDate';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import { DIMENSION_AVATAR_TINY } from '../../../constants';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import FileDottedIcon from './fileDotted.svg?react';
import FileIcon from './file.svg?react';
import FileListItemLayout from './FileListItemLayout';

const CenteredBox = styled(Box)({
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
});

export const InvalidFileListItem = ({
  file,
  onRemove,
}: {
  file: AgentFileInvalid;
  onRemove: () => void;
}) => {
  const icon = (
    <CenteredBox
      sx={{
        backgroundColor: colors.blush[300],
      }}
    >
      <FileDottedIcon />
    </CenteredBox>
  );

  const name = <Typography variant="body-medium">{file.name}</Typography>;
  const description = (
    <Typography variant="caption" color={colors.blush[600]}>
      {file.message}
    </Typography>
  );

  return (
    <FileListItemLayout icon={icon} name={name} description={description} onRemove={onRemove} />
  );
};

export const ValidFileListItem = ({
  file,
  onRemove,
}: {
  file: AgentFile;
  onRemove: () => void;
}) => {
  const user = useSelector(selectUserById(file.createdById));
  const icon = (
    <CenteredBox
      sx={{
        backgroundColor: colors.blue[100],
      }}
    >
      <FileIcon />
    </CenteredBox>
  );

  const name = <Typography variant="body-medium">{file.name}</Typography>;

  const descriptionText = `${file.type} file • Uploaded on ${humanizeDate({
    dateOrTimestamp: file.createdAt,
    variant: humanizeDateVariants.SHORT_WITHOUT_TIME,
  })}${user?.name ? ` by ${user?.name}` : ''}`;

  const descriptionAvatar = user ? (
    <AvatarPicture
      avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
      dimension={DIMENSION_AVATAR_TINY}
      clickable={false}
      isInactive={getIsUserInactive(user.status)}
    />
  ) : null;

  const description = (
    <Box display="flex" alignItems="center" gap={1}>
      <Typography variant="caption" color={colors.grey[800]}>
        {descriptionText}
      </Typography>
      {descriptionAvatar}
    </Box>
  );

  return (
    <FileListItemLayout icon={icon} name={name} description={description} onRemove={onRemove} />
  );
};
