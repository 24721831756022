import { ReactNode } from 'react';
import { Box, Stack } from '@mui/material';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { Delete } from '@sweep-io/sweep-design/dist/icons';

const FileListItemLayout = ({
  icon,
  name,
  description,
  onRemove,
}: {
  icon: ReactNode;
  name: ReactNode;
  description: ReactNode;
  onRemove: () => void;
}) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      height="64px"
      sx={{
        borderBottom: `1px solid ${colors.grey[200]}`,
        '.delete-icon': {
          display: 'none',
        },
        '&:hover': {
          backgroundColor: colors.grey[100],
          '.delete-icon': {
            display: 'flex',
          },
        },
      }}
    >
      <Stack direction="row" alignItems="center" gap={1.5}>
        {icon}
        <Stack gap={1}>
          {name}
          {description}
        </Stack>
      </Stack>
      {onRemove && (
        <Box className="delete-icon" display="flex" alignItems="center" pr={1}>
          <IconButton variant="flat" onClick={onRemove} size="small">
            <Delete />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

export default FileListItemLayout;
