import { Autocomplete, Grid, Popper, TextField } from '@mui/material';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, Tag, colors } from '@sweep-io/sweep-design';
import { forwardRef, ReactNode } from 'react';

interface SelectOption {
  label: string;
  value: string;
}

interface MultiAutocompleteProps {
  options: SelectOption[];
  onDelete: (id: string) => void;
  onSelect: (items: SelectOption[]) => void;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement> & {
      key: any;
    },
    option: SelectOption,
  ) => ReactNode;
  noOptions?: ReactNode;
  selectedOptions?: SelectOption[];
  searchPlaceholder?: string;
}

export const MultiAutocomplete = forwardRef(
  (
    {
      options,
      selectedOptions,
      onDelete,
      onSelect,
      renderOption,
      noOptions,
      searchPlaceholder = 'Search by name',
    }: MultiAutocompleteProps,
    ref: any,
  ) => {
    return (
      <Autocomplete
        disableClearable
        fullWidth
        options={options}
        multiple
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField {...params} ref={ref} placeholder={searchPlaceholder} />
        )}
        renderTags={(values) => (
          <Grid container gap="8px" paddingTop={1} paddingBottom={1} width="auto">
            {values.map(({ label, value: id }) => (
              <Grid key={id} width="auto">
                <Tag label={label} key={id} onDelete={() => onDelete(id)} />
              </Grid>
            ))}
          </Grid>
        )}
        onChange={(event: React.ChangeEvent<unknown>, value: SelectOption[]) => onSelect(value)}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        popupIcon={null}
        noOptionsText={noOptions}
        value={selectedOptions}
        PopperComponent={(props) => {
          const anchorEl = props.anchorEl;
          const getWidth = (): number | undefined => {
            if (anchorEl instanceof HTMLElement) {
              return anchorEl.clientWidth;
            }
            return undefined;
          };

          return (
            <Popper
              {...props}
              sx={{
                width: props.anchorEl ? getWidth() : undefined,
                '.MuiAutocomplete-noOptions': {
                  ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.caption,
                  color: colors.grey[700],
                  width: '100%',
                },
                '& .MuiPaper-root': {
                  width: props.anchorEl ? getWidth() : undefined,
                },
              }}
            />
          );
        }}
        renderOption={renderOption}
      />
    );
  },
);
