import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';
import { AgentFile, AiAgent } from './aiAgentsConsts';

const GHOST_CRM_ORG_ID = 'GHOST_CRM_ORG_ID';

export interface AiAgentsState {
  environments: {
    [crmOrgId: string]: {
      agents?: AiAgent[];
      isLoading: boolean;
    };
  };
}

const initialState: AiAgentsState = {
  environments: {},
};

export const aiAgentsSlice = createSlice({
  name: 'aiAgents',
  initialState,
  reducers: {
    setAgentsLoading: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId } = action.payload;
      state.environments[crmOrgId] = { isLoading: true };
    },
    setAgents: (
      state,
      action: PayloadAction<{
        crmOrgId?: string;
        agents: Omit<AiAgent, 'crmOrgId'>[];
      }>,
    ) => {
      const { crmOrgId, agents } = action.payload;
      const _crmOrgId = crmOrgId ?? GHOST_CRM_ORG_ID;
      const agentsWithOrg = agents.map((agent) => ({ ...agent, crmOrgId: _crmOrgId }));
      state.environments[_crmOrgId] = { agents: agentsWithOrg, isLoading: false };
    },
    addAgent: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agent: AiAgent;
      }>,
    ) => {
      const { crmOrgId, agent } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = { agents: [], isLoading: false };
      }
      if (!state.environments[crmOrgId].agents) {
        state.environments[crmOrgId].agents = [];
      }
      state.environments[crmOrgId].agents.push(agent);
    },
    renameAgent: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
        name: string;
      }>,
    ) => {
      const { crmOrgId, agentId, name } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      const relevantAgent = state.environments[crmOrgId].agents.find(
        (agent) => agent.id === agentId,
      );
      if (!relevantAgent) {
        return;
      }
      relevantAgent.name = name;
    },
    setAgentInstructions: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
        instructions: string;
      }>,
    ) => {
      const { crmOrgId, agentId, instructions } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      const relevantAgent = state.environments[crmOrgId].agents.find(
        (agent) => agent.id === agentId,
      );
      if (!relevantAgent) {
        return;
      }
      relevantAgent.instructions = instructions;
    },
    addAgentFiles: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
        files: AgentFile[];
      }>,
    ) => {
      const { crmOrgId, agentId, files } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      const relevantAgent = state.environments[crmOrgId].agents.find(
        (agent) => agent.id === agentId,
      );
      if (!relevantAgent) {
        return;
      }
      relevantAgent.files = [...(relevantAgent.files ?? []), ...files];
    },
    deleteAgentFile: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
        fileId: string;
      }>,
    ) => {
      const { crmOrgId, agentId, fileId } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      const relevantAgent = state.environments[crmOrgId].agents.find(
        (agent) => agent.id === agentId,
      );
      if (relevantAgent) {
        relevantAgent.files = relevantAgent.files.filter((file) => file.id !== fileId);
      }
    },
    deleteAgent: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        agentId: string;
      }>,
    ) => {
      const { crmOrgId, agentId } = action.payload;
      if (!state.environments[crmOrgId]?.agents) {
        return;
      }
      state.environments[crmOrgId].agents = state.environments[crmOrgId].agents.filter(
        (agent) => agent.id !== agentId,
      );
    },
  },
});

export const {
  setAgents,
  addAgent,
  renameAgent,
  deleteAgent,
  setAgentsLoading,
  setAgentInstructions,
  addAgentFiles,
  deleteAgentFile,
} = aiAgentsSlice.actions;

export const selectAgents = (crmOrgId?: string) => (state: RootState) => {
  return state.aiAgents.environments[crmOrgId ?? GHOST_CRM_ORG_ID]?.agents;
};

export const selectAgentsIsLoading = (crmOrgId?: string) => (state: RootState) => {
  return state.aiAgents.environments[crmOrgId ?? GHOST_CRM_ORG_ID]?.isLoading;
};
export const selectAgentsPerStage =
  (crmOrgId: string, funnelId: string, stageId: string) => (state: RootState) => {
    return state.aiAgents.environments[crmOrgId]?.agents?.filter((agent) =>
      agent.contextComponents?.some(
        (component) => component.funnelId === funnelId && component.stagesIds.includes(stageId),
      ),
    );
  };

export const selectStagesWithActiveAgent = (funnelMap: FunnelMap) => (state: RootState) => {
  const crmOrgId = state.userInfo.data?.preferences?.defaultCreationCrmOrgId;
  if (!crmOrgId || !state.aiAgents.environments[crmOrgId]?.agents) {
    return [];
  }
  const stages = [];
  for (const agent of state.aiAgents.environments[crmOrgId].agents) {
    if (!agent.contextComponents) continue;
    for (const component of agent.contextComponents) {
      const { funnelId, stagesIds } = component;
      if (funnelMap.funnelsData[funnelId]) {
        for (const stage of funnelMap.funnelsData[funnelId]?.funnelDetails.stages) {
          if (stagesIds.includes(stage._stageId)) {
            stages.push(stage._stageId);
          }
        }
      }

      if (funnelMap.thirdPartyFunnelsData[funnelId]) {
        for (const step of funnelMap.thirdPartyFunnelsData[funnelId]?.steps) {
          if (stagesIds.includes(step.id)) {
            stages.push(step.id);
          }
        }
      }
    }
  }
  return stages;
};

export default aiAgentsSlice.reducer;
