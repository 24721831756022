import { Box, MenuList, Stack } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { SlackConnectButton } from './SlackConnectButton';
import { CloudingoLogo, SlackLogo2 } from '../../../icons/generalIcons';
import { HubspotConnectButton } from './HubspotConnectButton';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { CloudingoConnectButton } from './CloudingoConnectButton';
import { IntegrationOption } from './IntegrationsOption';
import { SlackIncludeSandboxesCheckbox } from './SlackIncludeSandboxesCheckbox';
import { HubspotNewLogo } from '../../common/connect-sweep-to/integrationIcons';
import { useWebhooksContext } from '../webhooks/WebhooksContext';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import { useSelector } from 'react-redux';
import { selectCustomIntegrations } from '../../../reducers/integrationsReducer';
import { CustomIntegrationsOption } from './CustomIntegrationsOption';
import usePermission from '../../common/permissions/usePermission';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';

export const IntegrationsTab = () => {
  const { webhooks } = useFeatureToggle();
  const { setWebhooks } = useWebhooksContext();
  const { getCustomIntegrations } = useIntegrations();
  const customIntegrations = useSelector(selectCustomIntegrations);
  const permissionStr = 'create:data-integration:custom-integrations';
  const [isAllowedBtnCreate] = usePermission([permissionStr]);

  useRunOnceWhenTruthy(async () => {
    await getCustomIntegrations();
  }, webhooks);

  return (
    <Stack gap="16px">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h2">Integrations</Typography>
        {webhooks && (
          <RestrictedTooltip
            to={[permissionStr]}
            notAllowedTitle={'To adjust integration settings, please contact your admin.'}
          >
            <Button
              disabled={!isAllowedBtnCreate}
              onClick={() => setWebhooks(true)}
              size="small"
              startIconName="Plus"
              variant="filled"
            >
              Add new
            </Button>
          </RestrictedTooltip>
        )}
      </Box>

      <MenuList
        sx={{
          '&.MuiList-root': {
            padding: 0,
          },
          '.MuiMenuItem-root': {
            padding: 2,
            gap: 2,
            borderBottom: `1px solid ${colors.grey[200]}`,
            margin: 0,
            minHeight: '94px',
          },
        }}
      >
        <IntegrationOption
          name="Slack"
          logo={<SlackLogo2 />}
          description="Connect Slack to keep your GTM teams informed with actionable notifications"
          actionButton={<SlackConnectButton />}
          additionalContent={<SlackIncludeSandboxesCheckbox />}
        />

        <IntegrationOption
          name="Hubspot"
          logo={<HubspotNewLogo />}
          description="Connect HubSpot to visualize funnels and manage campaign enrollments"
          actionButton={<HubspotConnectButton />}
        />
        <IntegrationOption
          isHide={true} // TODO: remove when removing cloudingo integration
          name="Cloudingo"
          logo={<CloudingoLogo />}
          description="Merge duplicates with Cloudingo"
          actionButton={<CloudingoConnectButton />}
        />
        {webhooks &&
          customIntegrations.map((el) => {
            return <CustomIntegrationsOption integration={el} key={el.id} />;
          })}
      </MenuList>
    </Stack>
  );
};
