import { useFlags } from 'launchdarkly-react-client-sdk';
import useQueryParams from '../../hooks/useQueryParams';

export const useFeatureToggle = () => {
  const query = useQueryParams();

  const debugTools = query.has('debugTools');

  // Please remove feature toggles no longer in use when merging an experiment
  // This list should have all currently used feature toggle flags.
  const {
    reportsComingSoonTag,
    orgFactoryReset,
    sweepSupportChat,
    priorValueSlackMessages,
    copilot,
    verifyUrlAction,
    storeChannelIdInSfField,
    userInputsTags,
    salesActivatedTrial,
    annotationsV2,
    expandedDocumentationForFreeUsers,
    assignmentGroupRoles,
    webhooks,
    downloadCsv,
    thirdPartyFunnels,
    relatedObjectsInDocumentation,
    newDocumentationUiFilters,
    customLogicInMatching,
    slackLinkButton,
    hubspotV2Ft2,
    aiAgentDemo,
    aiAgentCenter,
    automationsOrder,
    rolesAndPermissionsPerCrmOrg,
    technicalUsersHomepage,
    automationsUiButtonPosition,
    updateRecordPriorRecord,
    hubspotV2Ft3,
  } = useFlags();

  return {
    debugTools,
    reportsComingSoonTag,
    orgFactoryReset,
    sweepSupportChat,
    priorValueSlackMessages,
    copilot,
    verifyUrlAction,
    storeChannelIdInSfField,
    userInputsTags,
    salesActivatedTrial, //AKA "paywallV2"
    annotationsV2,
    expandedDocumentationForFreeUsers,
    assignmentGroupRoles,
    webhooks,
    downloadCsv,
    thirdPartyFunnels,
    relatedObjectsInDocumentation,
    newDocumentationUiFilters,
    customLogicInMatching,
    slackLinkButton,
    hubspotV2Ft2,
    aiAgentDemo,
    aiAgentCenter,
    automationsOrder,
    rolesAndPermissionsPerCrmOrg,
    technicalUsersHomepage,
    automationsUiButtonPosition,
    updateRecordPriorRecord,
    hubspotV2Ft3,
  };
};
