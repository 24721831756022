import { useCallback, useState } from 'react';
import { WithConfigurationCanvas } from '../../../configuration-canvas/WithConfigurationCanvas';
import {
  GroupLabelEntityType,
  GroupPillEntityType,
  NodeEntityTypes,
  StepEntityType,
  StepPillEntityType,
} from '../../../../multi-canvas/canvasTypes';
import { PanelConsumer, usePanels } from '../../../../panels/PanelsContext';
import { Box } from '@mui/material';
import SweepStagesModel from '../../../../../models/stagesModel';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import { useDispatch, useSelector } from 'react-redux';
import { stagePanelType } from '../../../funnel-map-flow/dialogs/stage/panelType';
import {
  closeStageDialog,
  openStageDialog,
  selectStageDialogFunnelId,
  selectStageDialogStage,
} from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import { StageDialogTabTypes } from '../../../../../types/enums/StageDialogTabTypes';
import { gatePanelType } from '../../../funnel-map-flow/dialogs/gates/gatePanelType';
import { StageDialog } from '../../../funnel-map-flow/dialogs/stage/StageDialog';
import { ConnectedGateDialog } from '../../../funnel-map-flow/dialogs/gates/ConnectedGateDialog';
import noop from 'lodash/noop';
import { useCloseAgentCenterFirst } from '../../../../aiAgentCenter/useCloseAgentCenterFirst';

const useOpenStepDialog = () => {
  const dispatch = useDispatch();
  const { maybeOpenPanel } = usePanels();
  const { canvasFunnelMap } = useConfigurationCanvas();
  const closeAgentCenterFirst = useCloseAgentCenterFirst();

  const openStepDialog = useCallback(
    ({ stepId, funnelId }: { stepId: string; funnelId: string }) => {
      const funnelDetails = canvasFunnelMap.funnelsData[funnelId]?.funnelDetails;
      const stagesModel = new SweepStagesModel(funnelDetails?.stages || []);
      const stage = stagesModel.stageByIdOrUndefined(stepId)?.getStage();

      if (stage) {
        closeAgentCenterFirst(
          maybeOpenPanel({
            panelType: stagePanelType,
            instanceId: stage._stageId,
            onOpen: () => {
              dispatch(
                openStageDialog({
                  stageId: stage._stageId,
                  activeTab: StageDialogTabTypes.GATES,
                  funnelId: funnelId,
                }),
              );
            },
            onClose: () => {
              dispatch(closeStageDialog());
            },
          }),
        );
      }
    },
    [canvasFunnelMap.funnelsData, closeAgentCenterFirst, dispatch, maybeOpenPanel],
  );
  return {
    openStepDialog,
  };
};

export const ReadOnlyCanvasUnitedConfigurationPage = () => {
  const { maybeOpenPanel } = usePanels();
  const { canvasCrmOrgId } = useConfigurationCanvas();
  const { openStepDialog } = useOpenStepDialog();
  const [selectedGateId, setSelectedGateId] = useState<string>();
  const selectedStage = useSelector(selectStageDialogStage);
  const selectedStageDialogFunnelId = useSelector(selectStageDialogFunnelId);

  const onEntityClick = useCallback(
    ({
      entity,
      funnelId,
      stepId,
    }: {
      stepId?: string;
      funnelId: string;
      entity: StepEntityType | StepPillEntityType | GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (entity.type === NodeEntityTypes.Step && stepId && funnelId) {
        openStepDialog({ stepId, funnelId });
        return;
      }
    },
    [openStepDialog],
  );

  const onStageGateClick = useCallback(
    (funnelId: string, stageId: string, exitCriteriaId: string) => {
      maybeOpenPanel({
        panelType: gatePanelType,
        onOpen: () => {
          setSelectedGateId(exitCriteriaId);
        },
        onClose: () => {
          setSelectedGateId(undefined);
        },
        data: {
          funnelId,
          stageId,
          exitCriteriaId,
        },
      });
    },
    [maybeOpenPanel],
  );

  return (
    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: '100%' }}>
      <WithConfigurationCanvas
        visibilityElements={{ type: 'map', visibilityMap: {} }}
        onFunnelStepClick={onEntityClick}
        onFunnelLabelClick={onEntityClick}
        onFunnelLabelPillClick={onEntityClick}
        onStageGateClick={onStageGateClick}
        selectedGateId={selectedGateId}
      >
        {selectedStage && selectedStageDialogFunnelId && (
          <PanelConsumer panelType={stagePanelType} instanceId={selectedStage._stageId}>
            {({ maybeClosePanel, isOpen }) => (
              <>
                {isOpen && (
                  <StageDialog
                    readonly
                    stage={selectedStage}
                    funnelId={selectedStageDialogFunnelId}
                    crmOrgId={canvasCrmOrgId}
                    closeDialog={() => maybeClosePanel()}
                    setIsDirty={noop}
                  />
                )}
              </>
            )}
          </PanelConsumer>
        )}
        <PanelConsumer panelType={gatePanelType}>
          {({ maybeClosePanel, isOpen, data }) => (
            <>
              {data && (
                <ConnectedGateDialog
                  crmOrgId={canvasCrmOrgId}
                  readonly
                  closeDialog={() => maybeClosePanel()}
                  isOpen={isOpen}
                  data={data}
                  setIsDirty={noop}
                />
              )}
            </>
          )}
        </PanelConsumer>
      </WithConfigurationCanvas>
    </Box>
  );
};
