import { useSelector } from 'react-redux';
import {
  selectCampaignsIsLoading,
  selectCampaignsIsLoaded,
  selectCampaignObjectFieldsIsLoading,
  selectCampaignObjectFieldsIsLoaded,
  selectWorkflowsIsLoading,
  selectWorkflowsIsLoaded,
  selectCampaignFieldNamesIsLoading,
  selectCampaignFieldNamesIsLoaded,
} from '../../../../../reducers/hubspotReducer';

export const useAreCampaignsReady = () => {
  const campaignsIsLoading = useSelector(selectCampaignsIsLoading);
  const campaignsIsLoaded = useSelector(selectCampaignsIsLoaded);
  const campaignObjectFieldsIsLoading = useSelector(selectCampaignObjectFieldsIsLoading);
  const campaignObjectFieldsIsLoaded = useSelector(selectCampaignObjectFieldsIsLoaded);
  const workflowsIsLoading = useSelector(selectWorkflowsIsLoading);
  const workflowsIsLoaded = useSelector(selectWorkflowsIsLoaded);
  const campaignFieldNamesIsLoading = useSelector(selectCampaignFieldNamesIsLoading);
  const campaignFieldNamesIsLoaded = useSelector(selectCampaignFieldNamesIsLoaded);

  const areCampaignsReady =
    campaignsIsLoaded &&
    !campaignsIsLoading &&
    campaignObjectFieldsIsLoaded &&
    !campaignObjectFieldsIsLoading &&
    workflowsIsLoaded &&
    !workflowsIsLoading &&
    campaignFieldNamesIsLoaded &&
    !campaignFieldNamesIsLoading;

  return areCampaignsReady;
};
