import { BaseDialog } from '../common/dialogs/BaseDialog';
import { DefaultCardsLayout } from '../common/CardsGridLayout';
import { useSelector } from 'react-redux';
import { selectFunnelMapsIsLoading, selectFunnelMapsList } from '../../reducers/funnelMapsReducer';
import { Stack } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { SearchInput } from '../common/SearchInput';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import { filterItemsBySearch } from '../../lib/filterItemsBySearch';
import { FunnelMapCardBase } from '../pages/funnel-maps/card/FunnelMapCardBase';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { CanvasMode } from '../multi-canvas/canvasTypes';

const ProcessSelectionDialog = ({
  closeDialog,
  onSelect,
}: {
  closeDialog: () => void;
  onSelect: (funnelMapId: string) => void;
}) => {
  const [searchText, setSearchText] = useState('');
  const funnelMaps = useSelector(selectFunnelMapsList);
  const isLoading = useSelector(selectFunnelMapsIsLoading);
  const filteredFunnelMaps = filterItemsBySearch(funnelMaps, searchText, (item) => item.name);

  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{ sx: { width: '1202px', minHeight: '628px', padding: '32px', height: '100%' } }}
    >
      {isLoading && <CenteredCircularProgress />}
      {!isLoading && (
        <Stack gap={2}>
          <Stack direction="row" justifyContent="space-between">
            <Stack gap={0.5}>
              <Typography variant="h2" color={colors.grey[900]}>
                Create new process optimization agent
              </Typography>
              <Typography variant="body" color={colors.grey[900]}>
                To create a new agent, select a process to optimize
              </Typography>
            </Stack>
            <Stack direction="row" gap={1}>
              <SearchInput
                withFixedMagnifyingGlassIcon
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
              />
              <IconButton onClick={closeDialog} variant="flat" size="large">
                <Close />
              </IconButton>
            </Stack>
          </Stack>

          <DefaultCardsLayout>
            {filteredFunnelMaps.map((funnelMap: FunnelMap) => (
              <FunnelMapCardBase
                key={funnelMap.id}
                onClick={() => onSelect(funnelMap.id)}
                funnelMap={funnelMap}
                canvasMode={CanvasMode.PREVIEW3}
                hasRequestedChanges={false}
                isActive={false}
                isPinned={false}
              />
            ))}
          </DefaultCardsLayout>
        </Stack>
      )}
    </BaseDialog>
  );
};

export default ProcessSelectionDialog;
