import {
  BaseCampaignMemberStatus,
  Campaign,
  CampaignBase,
  CampaignFieldNamesForSfdcId,
  CampaignMemberStatus,
} from '../../components/pages/canvas-pages/canvas-hubspot-page/campaign-dialog/campaignTypes';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export const useCampaignsApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_campaigns: useCallback(async (): Promise<Campaign[]> => {
      const response = await sweepApi.get<Campaign[]>('/campaigns');
      return response.data;
    }, [sweepApi]),

    post_campaign: useCallback(
      async (campaign: CampaignBase): Promise<Campaign> => {
        const response = await sweepApi.post('/campaigns', campaign);
        return response.data;
      },
      [sweepApi],
    ),

    get_campaign_fields: useCallback(async () => {
      const response = await sweepApi.get<CampaignFieldNamesForSfdcId[]>('/campaigns/fields');
      return response.data;
    }, [sweepApi]),

    patch_campaign: useCallback(
      async (campaignId: string, campaign: Partial<CampaignBase>): Promise<Campaign> => {
        const response = await sweepApi.patch(`/campaigns/campaign/${campaignId}`, campaign);
        return response.data;
      },
      [sweepApi],
    ),
    delete_campaign: useCallback(
      async (campaignId: string) => {
        await sweepApi.delete(`/campaigns/campaign/${campaignId}`);
      },
      [sweepApi],
    ),
    get_campaign_members_status: useCallback(
      async (campaignId: string): Promise<CampaignMemberStatus[]> => {
        const response = await sweepApi.get<CampaignMemberStatus[]>(
          `/campaigns/campaign-member-status/${campaignId}`,
        );
        return response.data;
      },
      [sweepApi],
    ),
    post_campaign_member_status: useCallback(
      async (campaignMemberStatus: BaseCampaignMemberStatus) => {
        const response = await sweepApi.post<CampaignMemberStatus>(
          `/campaigns/campaign-member-status`,
          campaignMemberStatus,
        );
        return response.data;
      },
      [sweepApi],
    ),
    patch_campaign_member_status: useCallback(
      async ({
        Id,
        HasResponded,
        IsDefault,
      }: Pick<CampaignMemberStatus, 'IsDefault' | 'HasResponded' | 'Id'>) => {
        const payload = {
          IsDefault,
          HasResponded,
        };

        await sweepApi.patch(`/campaigns/campaign-member-status/${Id}`, payload);
      },
      [sweepApi],
    ),
  };
};
