import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import {
  selectDependenciesConfigurationItem,
  selectDocumentationActiveObjectData,
  selectLayoutsByObjectName,
  selectParsedConfigurationItems,
} from '../../../../reducers/documentationReducer';
import { selectEnvironments, selectParsedFields } from '../../../../reducers/global/globalReducer';
import { selectCrmOrgRollups } from '../../../../reducers/global/rollupReducers';
import {
  findLayoutIdx,
  findRuleIdx,
  getConfigurationItemWithRTAttribution,
  addLayoutProperties,
  createConfigurationItemFromRollupField,
  findConfigurationItemIdx,
  createConfigurationItemFromRecordType,
  createConfigurationItemFromSweepField,
  createConfigurationItemFromAutomation,
  createConfigurationItemFromLayout,
  createConfigurationItemFromTag,
  createConfigurationItemFromField,
} from '../../helpers';
import { RecordTypesLabelsByNameAndObjectName } from '../../types';
import { useDocumentation } from '../../useDocumentation';
import { selectTagsList } from '../../../../reducers/tagsReducer';
import { selectAccountUsers } from '../../../../reducers/accountUsersReducer';
import { parseIdToValueAndNameToLabel } from '../annotations/tags/utils';
import { Tag } from '../../../../apis/facades/useUserInputsApiFacade';
import { LayoutData, ParsedRecordType, ParsedRule } from '../../../parser/ParserTypes';
import { RollupForTable } from '../../../pages/rollups/rollupTypes';
import { ParsedFieldsByObject } from '../../../../reducers/global/globalReducerTypes';
import { Emoji, UserInfo } from '../../../../types/UserInfoTypes';
import groupBy from 'lodash/groupBy';
import {
  getObjectNameFromRecordTypeId,
  getRecordTypeNameFromRecordTypeId,
} from '../../../Automations/helper';
import { ConfigurationItem } from '../DependenciesTypes';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import flatten from 'lodash/flatten';

export const useGetConfigurationItem = ({
  crmOrgId,
  recordTypeNamesUsedInCanvas,
}: {
  crmOrgId: string;
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
}) => {
  const selectedDependency = useSelector(selectDependenciesConfigurationItem);
  const { parsedRecordTypes, parsedRules } = useDocumentation();
  const { id, name, objectName } = selectedDependency ?? {};

  const activeObjectElementsRawData = useSelector(selectDocumentationActiveObjectData);
  const objectLayouts = useSelector(selectLayoutsByObjectName(crmOrgId));
  const parsedConfigurationItems = useSelector(selectParsedConfigurationItems(crmOrgId));
  const parsedFields = useSelector(selectParsedFields(crmOrgId));
  const rollups = useSelector(selectCrmOrgRollups(crmOrgId));
  const globalEnvironments = useSelector(selectEnvironments);
  const tags = useSelector(selectTagsList);
  const users = useSelector(selectAccountUsers);
  const _parsedUsers = parseIdToValueAndNameToLabel(users ?? []);

  const { automations, alerts, matching, dedup, assignments, scheduledAssignments, sweepFields } =
    globalEnvironments?.[crmOrgId]?.data ?? {};

  const makeNotUndefined = (items?: AutomationStructureNew[]) => items ?? [];
  const mergedAutomations = useMemo(
    () =>
      groupBy(
        [
          ...makeNotUndefined(automations),
          ...makeNotUndefined(alerts),
          ...makeNotUndefined(assignments),
          ...makeNotUndefined(matching),
          ...makeNotUndefined(dedup),
          ...makeNotUndefined(scheduledAssignments),
        ].flat(),
        'automationId',
      ),
    [automations, alerts, matching, dedup, assignments, scheduledAssignments],
  );

  const allLayouts = useMemo(
    () =>
      objectLayouts?.[objectName ?? '']?.layouts ??
      Object.values(objectLayouts ?? {})
        .map((layouts) => layouts.layouts)
        .flat(),
    [objectLayouts, objectName],
  );

  const item = useMemo(
    () =>
      selectedDependency &&
      getConfigurationItem({
        id,
        name,
        objectName,
        tags,
        _parsedUsers,
        parsedRules,
        allLayouts,
        rollups,
        recordTypeNamesUsedInCanvas,
        parsedFields,
        parsedConfigurationItems,
        parsedRecordTypes: parsedRecordTypes ?? [],
        sweepFields,
        mergedAutomations,
        users,
        activeObjectElementsRawData,
      }),
    [
      id,
      name,
      objectName,
      tags,
      _parsedUsers,
      parsedRules,
      allLayouts,
      rollups,
      recordTypeNamesUsedInCanvas,
      parsedFields,
      parsedConfigurationItems,
      parsedRecordTypes,
      sweepFields,
      mergedAutomations,
      selectedDependency,
      users,
      activeObjectElementsRawData,
    ],
  );

  return item;
};

export const getConfigurationItem = ({
  id,
  name,
  objectName,
  tags,
  _parsedUsers,
  parsedRules,
  allLayouts,
  rollups,
  recordTypeNamesUsedInCanvas,
  parsedFields,
  parsedConfigurationItems,
  parsedRecordTypes,
  sweepFields,
  mergedAutomations,
  users,
  activeObjectElementsRawData,
}: {
  id?: string;
  name?: string;
  objectName?: string;
  _parsedUsers: {
    value: string;
    label: string;
    tagColor: any;
    emoji?: Emoji;
    imageUrl?: string;
  }[];
  tags: Tag[];
  parsedRules: ParsedRule[];
  allLayouts: LayoutData[];
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName;
  rollups?: RollupForTable[];
  parsedFields?: ParsedFieldsByObject;
  parsedConfigurationItems: ConfigurationItem[];
  parsedRecordTypes: ParsedRecordType[];
  sweepFields?: SweepFieldsSummaryMap;
  mergedAutomations: { [id: string]: AutomationStructureNew[] };
  users?: UserInfo[];
  activeObjectElementsRawData?: { [key in DocumentationTabTypes]: ConfigurationItem[] };
}): ConfigurationItem | undefined => {
  if (!id && !name) {
    return;
  }

  const _id = id ?? '';
  const _name = name ?? '';
  const _objectName = objectName ?? '';
  const searchByName = !id;

  const tagIdx = tags.findIndex((tag) => tag.id === _id);
  const user = _parsedUsers?.find((user) => user.value === _id);

  if (tagIdx !== -1 || !!user) {
    const tag = tags[tagIdx];
    return createConfigurationItemFromTag(tag ?? { ...user, id: user?.value });
  }

  const layoutIdx = findLayoutIdx({
    layouts: allLayouts ?? [],
    id: _id,
    name: _name,
    searchByName,
  });

  const layout = allLayouts?.[layoutIdx];

  const elements = flatten(
    Object.entries(activeObjectElementsRawData ?? {}).map(([, values]) => {
      if (!values) {
        return [];
      }
      return values;
    }),
  );

  const elementIdx = findConfigurationItemIdx({
    parsedConfigurationItems: elements,
    id: _id,
    name: _name,
    objectName: _objectName,
    searchByName,
  });

  if (elementIdx !== -1) {
    return elements?.[elementIdx];
  }

  const ruleIdx = findRuleIdx({
    rules: parsedRules,
    id: _id,
    name: _name,
    searchByName,
    objectName: _objectName,
  });

  if (layout) {
    return createConfigurationItemFromLayout(layout, _objectName);
  }

  if (ruleIdx !== -1) {
    const rule = getConfigurationItemWithRTAttribution(
      parsedRules?.[ruleIdx],
      recordTypeNamesUsedInCanvas,
    );
    return addLayoutProperties(rule, layout);
  }

  const rollupIdx = rollups?.findIndex((el) => el.rollupId === id || el.rollupField.id === id);

  if (rollupIdx && rollupIdx !== -1) {
    const rollup = rollups?.[rollupIdx];
    const parsedRollupObjectFields = parsedFields?.[rollup?.objectName ?? ''];

    const { usage, link } = parsedRollupObjectFields?.find((field) => field.name === _name) ?? {};

    return rollup
      ? createConfigurationItemFromRollupField({
          id: rollup.rollupId,
          sweepField: rollup?.rollupField,
          usage,
          link,
        })
      : undefined;
  }

  const parsedObjectFields =
    (parsedFields?.[_objectName ?? ''] ?? parsedFields)
      ? Object.keys(parsedFields)
          .map((objectName) =>
            parsedFields?.[objectName].map((field) => ({ ...field, objectName })),
          )
          .flat()
      : undefined;

  const parsedFieldIdx = parsedObjectFields?.findIndex((field) => field.id === id) ?? -1;

  if (parsedFieldIdx !== -1 && parsedObjectFields?.[parsedFieldIdx]) {
    return createConfigurationItemFromField(parsedObjectFields?.[parsedFieldIdx]);
  }

  const parsedConfigItemIdx = findConfigurationItemIdx({
    parsedConfigurationItems,
    id: _id,
    name: _name,
    objectName: _objectName,
    searchByName,
  });

  if (parsedConfigItemIdx !== -1) {
    const config = parsedConfigurationItems?.[parsedConfigItemIdx];
    return addLayoutProperties(config, layout);
  }

  const rtName = getRecordTypeNameFromRecordTypeId(_id);
  const rtObjectName = getObjectNameFromRecordTypeId(_id);

  const parsedRecordType = parsedRecordTypes?.find(
    (rt) =>
      (rt.name === _name && (_objectName ? _objectName === rt.objectApiName : true)) ||
      (rt.name === rtName && rt.objectApiName === rtObjectName),
  );

  if (!!parsedRecordType) {
    return createConfigurationItemFromRecordType(parsedRecordType);
  }

  const fieldFromSweepElement = sweepFields?.[_id];

  if (fieldFromSweepElement) {
    return createConfigurationItemFromSweepField(fieldFromSweepElement);
  }

  return createConfigurationItemFromAutomation(mergedAutomations[_id]?.[0], users);
};
