import * as React from "react";
const SvgFileDotted = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Icon/FileDotted" }, /* @__PURE__ */ React.createElement("path", { id: "Vector", d: "M6.75 21H5.25C5.05109 21 4.86032 20.921 4.71967 20.7803C4.57902 20.6397 4.5 20.4489 4.5 20.25V17.25", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector_2", d: "M11.25 3H14.25L19.5 8.25V12.75", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector_3", d: "M4.5 6V3.75C4.5 3.55109 4.57902 3.36032 4.71967 3.21967C4.86032 3.07902 5.05109 3 5.25 3H7.5", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector_4", d: "M14.25 3V8.25H19.5", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector_5", d: "M19.5 16.5V20.25C19.5 20.4489 19.421 20.6397 19.2803 20.7803C19.1397 20.921 18.9489 21 18.75 21H18", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector_6", d: "M4.5 9.75V13.5", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { id: "Vector_7", d: "M10.5 21H14.25", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" })));
export default SvgFileDotted;
