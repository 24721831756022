import { useCallback, useState } from 'react';
import AgentInstructionsDialog from './AgentInstructionsDialog';
import AgentFilesDialog from './AgentFilesDialog';
import useAiAgents from '../useAiAgents';
import { AiAgent, NewAgentFile } from '../aiAgentsConsts';

const useAgentSettings = (agent?: AiAgent) => {
  const [isOpenedInstructionsDialog, setIsOpenedInstructionsDialog] = useState(false);
  const [isOpenedFilesDialog, setIsOpenedFilesDialog] = useState(false);
  const { editAgentInstructions, addAgentFiles, deleteFile } = useAiAgents();

  const openInstructionsDialog = useCallback(() => {
    setIsOpenedInstructionsDialog(true);
  }, []);

  const openFilesDialog = useCallback(() => {
    setIsOpenedFilesDialog(true);
  }, []);

  const onInstructionsConfirm = async (instructions: string) => {
    if (agent) {
      await editAgentInstructions(agent.id, instructions);
    }
  };

  const onFilesAdd = async (files: NewAgentFile[]) => {
    if (agent) {
      await addAgentFiles(agent.id, files);
    }
  };

  const onFileRemove = async (fileId: string) => {
    if (agent) {
      await deleteFile(agent.id, fileId);
    }
  };

  const dialogs = (
    <>
      {isOpenedInstructionsDialog && (
        <AgentInstructionsDialog
          closeDialog={() => setIsOpenedInstructionsDialog(false)}
          onConfirm={onInstructionsConfirm}
          initialValue={agent?.instructions ?? ''}
        />
      )}
      {isOpenedFilesDialog && (
        <AgentFilesDialog
          closeDialog={() => setIsOpenedFilesDialog(false)}
          addFiles={onFilesAdd}
          removeFile={onFileRemove}
          files={agent?.files ?? []}
        />
      )}
    </>
  );

  return { openInstructionsDialog, openFilesDialog, dialogs };
};

export default useAgentSettings;
