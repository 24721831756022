import { Box, MenuItem } from '@mui/material';
import {
  ForwardedRef,
  forwardRef,
  Fragment,
  ReactNode,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { SearchInput } from '../SearchInput';
import { Typography } from '@sweep-io/sweep-design';
import StyledTooltip from '../StyledTooltip';
import { SearchSelectItem } from './SearchSelect';
import { SearchSelectListSubheader } from './SearchSelectListSubheader';

export type SearchMenuPanelRef = {
  clearSearch: () => void;
};

export interface SearchMenuPanelProps<T = any> {
  isLoading?: boolean;
  emptyResultsMessage?: string;
  items: SearchSelectItem<T>[];
  value?: unknown;
  searchPlaceholder?: string;
  children?: ReactNode;
  itemRenderer?: (item: SearchSelectItem<T>, search?: string) => React.ReactNode;
  onItemClicked?: (item: SearchSelectItem<T>) => void;
}

const DEFAULT_EMPTY_RESULTS_MESSAGE = 'No results found';

export const SearchMenuPanel = forwardRef<SearchMenuPanelRef, SearchMenuPanelProps>(function <
  T = any,
>(
  {
    isLoading,
    emptyResultsMessage = DEFAULT_EMPTY_RESULTS_MESSAGE,
    items,
    value,
    children,
    searchPlaceholder,
    itemRenderer,
    onItemClicked,
  }: SearchMenuPanelProps<T>,
  ref: ForwardedRef<SearchMenuPanelRef>,
) {
  const [search, setSearch] = useState('');

  const localItems = useMemo(() => {
    return items.filter((item) => {
      if (item.isSection) {
        return false;
      }
      return value === item.value || item.label.toUpperCase().includes(search.toUpperCase());
    });
  }, [items, value, search]);

  const maybeRenderEmptyResults = () => {
    if (emptyResultsMessage && localItems.length === 0) {
      return (
        <Box pl={1} pb={1}>
          <Typography variant="caption">{emptyResultsMessage}</Typography>
        </Box>
      );
    }
  };

  useImperativeHandle(ref, () => {
    return {
      clearSearch() {
        setSearch('');
      },
    };
  }, []);

  return (
    <Fragment>
      {!Boolean(isLoading) && !children && (
        <SearchSelectListSubheader>
          <SearchInput
            withFixedMagnifyingGlassIcon
            placeholder={searchPlaceholder}
            TextFieldProps={{
              sx: { minWidth: '270px' },
              value: search,
              autoFocus: true,
              onChange: (event) => {
                const value = event.target.value;
                setSearch(value);
              },
              onKeyDown: (e) => {
                if (e.key !== 'Escape') {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              },
              onClick: (e) => {
                e.stopPropagation();
              },
            }}
          />
        </SearchSelectListSubheader>
      )}
      {maybeRenderEmptyResults()}
      {!children && <MenuItem value="" hidden />}
      {!children &&
        (localItems || items).map((item) =>
          item.isSection ? (
            <MenuItem key={item.value} value={item.value} disabled>
              <StyledTooltip title={item.tooltip} key={item.value} placement="right">
                <Typography variant="caption-bold">{item.label}</Typography>
              </StyledTooltip>
            </MenuItem>
          ) : (
            <MenuItem
              value={item.value}
              key={item.value}
              autoFocus={item.value === value}
              disabled={item.disabled}
              onClick={onItemClicked ? () => onItemClicked?.(item) : undefined}
            >
              <StyledTooltip
                title={item.tooltip}
                PopperProps={{
                  sx: {
                    zIndex: 999999, // Needs to be higher than the dropdown of the select
                  },
                }}
                placement="right"
              >
                <Box>{itemRenderer ? itemRenderer(item, search) : item.label}</Box>
              </StyledTooltip>
            </MenuItem>
          ),
        )}
      {children}
    </Fragment>
  );
});
