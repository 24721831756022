import { Box } from '@mui/material';
import { Typography, colors, Button, Tag } from '@sweep-io/sweep-design';
import { revertToLatestAssignmentGroupInServer } from '../../../../reducers/assignmentGroupsPageReducer';
import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
} from '../../../common/table/ActionableDataTable';
import { StyledSwitch } from '../../../common/StyledSwitch';
import { useCallback, useEffect, useState } from 'react';
import { Warning as WarningIcon } from '@sweep-io/sweep-design/dist/icons';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { useDispatch } from 'react-redux';
import StyledTooltip from '../../../common/StyledTooltip';
import usePermission from '../../../common/permissions/usePermission';
import {
  DataTableDraggableColumn,
  DataTableRow,
  DataTableVariant,
} from '../../../common/table/TableTypes';
import { AssignmentGroup, AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { IntegerPicker } from './IntegerPicker';
import { Percentage } from '../../../common/Percentage';
import { Permission } from '@server/permissions';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { telemetry } from '../../../../telemetry';
import { TableFilteredEmptyState } from '../../../common/TableFilteredEmptyState';

const TABLE_VARIANT = DataTableVariant.default;
const HEADER_FONT_VARIANT = dataTableVariants[TABLE_VARIANT].headerFontVariant;

const getColumns = (showLimit?: boolean): DataTableDraggableColumn[] => [
  {
    field: 'drag',
    headerName: '',
    showDragHandle: true,
    width: '30px',
  },
  {
    field: 'active',
    headerName: 'Active',
    width: '80px',
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 'auto',
  },
  {
    field: 'limit',
    width: '200px',
    headerName: (
      <Box paddingLeft="9px">
        <Typography variant={HEADER_FONT_VARIANT}>Limit</Typography>
      </Box>
    ),
    hidden: !showLimit,
  },
  {
    field: 'weight',
    headerName: (
      <Box paddingLeft="9px">
        <Typography variant={HEADER_FONT_VARIANT}>Weight distribution</Typography>
      </Box>
    ),
  },
];

interface GroupMembersTableProps {
  groupData: AssignmentGroup;
  filterFunc: (member: AssignmentGroupMember) => boolean;
  onClearFilters: () => void;
}

export const GroupMembersTable = ({
  groupData,
  filterFunc,
  onClearFilters,
}: GroupMembersTableProps) => {
  const {
    optimisticSaveWithDebounceAssignmentGroupData,
    deleteAssignmentGroupMemberAtIdx,
    patchAssignmentGroupMemberActiveStatusAtIdx,
    patchAssignmentGroupMemberWeightAtIdx,
    patchAssignmentGroupMemberLimitAtIdx,
  } = useAssignmentsApiWithReducer();
  const { addNotification } = useSweepNotifications();
  const dispatch = useDispatch();
  const [localMembers, setLocalMembers] = useState(groupData.members);
  const permissionString: Permission[] = ['edit:assignment-groups'];
  const [isAllowedBtn] = usePermission(permissionString);

  const [currentFocusIndex, setCurrentFocusIndex] = useState<number | null>(null);

  useEffect(() => {
    setLocalMembers(groupData.members);
  }, [groupData.members]);

  const withErrorNotificationAndRevert = useCallback(
    async (fn: Function, errorMessage = 'Error Saving Assignment Group') => {
      try {
        await fn();
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: errorMessage,
          variant: SweepNotificationVariant.Error,
        });
        dispatch(revertToLatestAssignmentGroupInServer());
      }
    },
    [addNotification, dispatch],
  );

  const totalEnabledWeights = localMembers?.reduce((acc, curr) => {
    if (curr.membershipActive) {
      acc += curr.weight;
    }
    return acc;
  }, 0);

  const rows =
    localMembers?.map((member, index) => {
      const {
        userId: id,
        name,
        weight,
        membershipActive,
        isUpNext,
        userActive,
        limitValue: limit,
      } = member;
      const disabled = !userActive || !isAllowedBtn;
      const color = disabled ? colors.grey[500] : colors.black;

      const tooltip = disabled
        ? !isAllowedBtn
          ? 'To modify the settings of this assignment group, please contact your admin.'
          : 'Reactivate this user in Salesforce to enable actions within group'
        : '';

      return {
        id,
        index,
        name: (
          <StyledTooltip title={tooltip}>
            <Box
              sx={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                color,
              }}
            >
              <Typography variant="body">{name}</Typography>
              {isUpNext && <Tag label="Up Next" color={colors.mint[100]} />}
            </Box>
          </StyledTooltip>
        ),
        active: (
          <StyledTooltip title={tooltip}>
            <Box>
              <StyledSwitch
                disabled={disabled}
                onChange={(e, checked) =>
                  withErrorNotificationAndRevert(() =>
                    patchAssignmentGroupMemberActiveStatusAtIdx(groupData, index, checked),
                  )
                }
                checked={membershipActive}
              />
            </Box>
          </StyledTooltip>
        ),
        weight: (
          <Box display="flex" alignItems="center" gap={1} width="100%">
            <Box className="show-on-hover">
              <IntegerPicker
                disabled={disabled}
                value={weight}
                onChange={(weight) => {
                  const newWeight = weight === null ? 1 : weight;
                  withErrorNotificationAndRevert(() =>
                    patchAssignmentGroupMemberWeightAtIdx(groupData, index, newWeight),
                  );
                }}
                min={1}
                onFocus={() => {
                  setCurrentFocusIndex(index);
                }}
                onBlur={() => {
                  setCurrentFocusIndex(null);
                }}
              />
            </Box>
            {membershipActive && (
              <Box flex={1}>
                <Percentage numerator={weight} denominator={totalEnabledWeights as number} active />
              </Box>
            )}
          </Box>
        ),
        limit: (
          <Box display="flex" position="relative">
            {!Boolean(limit) && (
              <Box
                className="hide-on-hover"
                sx={{ position: 'absolute', top: '10px', left: '14px', zIndex: 1 }}
              >
                N/A
              </Box>
            )}
            <Box className="show-on-hover" display="flex" gap={'12px'} alignItems="center">
              <IntegerPicker
                disabled={disabled}
                value={limit ?? null}
                onChange={(limit) => {
                  withErrorNotificationAndRevert(() =>
                    patchAssignmentGroupMemberLimitAtIdx(groupData, index, limit ?? null),
                  );
                }}
                min={0}
                zeroAsEmpty
                onFocus={() => {
                  setCurrentFocusIndex(index);
                }}
                onBlur={() => {
                  setCurrentFocusIndex(null);
                }}
              />

              {Boolean(limit) && (
                <Box
                  sx={{
                    '.MuiButtonBase-root': {
                      textAlign: 'left',
                    },
                  }}
                >
                  <Button
                    variant="flat"
                    type="secondary"
                    onClick={() =>
                      withErrorNotificationAndRevert(() =>
                        patchAssignmentGroupMemberLimitAtIdx(groupData, index, null),
                      )
                    }
                  >
                    Remove limit
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        ),
        data: member,
      };
    }) || [];

  const showLimit = Boolean(localMembers.find((member) => member.limitValue));

  const actions = !isAllowedBtn
    ? []
    : [
        {
          type: ActionableDataTableActionTypes.DELETE,
          confirm: true,
          confirmButtonOkLabel: 'Delete',
          confirmButtonCancelLabel: 'Cancel',
          confirmTitle: (id: string, row: DataTableRow) =>
            row.data.userActive
              ? `Remove group member "${row.data.name}" from "${groupData.name}" group?`
              : `Remove non active member Salesforce user?`,
          confirmSubtitle: (id: string, row: DataTableRow) =>
            row.data.userActive
              ? `Removing "${row.data.name}" from the group will adjust the weight distribution among the remaining members.`
              : 'Please note that once members who are not active in Salesforce are removed from the assignment group, they will not ' +
                'be able to be reassigned to any group until they are re-activated in Salesforce.',
          confirmActionIcon: (id: string, row: DataTableRow) => {
            return row.data.userActive ? undefined : <WarningIcon color={colors.blush[500]} />;
          },
        },
      ];

  const columns = getColumns(showLimit);
  const filteredLocalMembers = localMembers.filter(filterFunc);
  const idsToDisplay = filteredLocalMembers.map((member) => member.userId);
  const rowsToDisplay = rows.filter((row) => idsToDisplay.includes(row.id));
  let _sx = {
    '.MuiTableCell-root': {
      '.MuiTypography-root': {
        overflow: 'visible',
      },
    },
    '.MuiTableRow-root:hover': {
      '.MuiOutlinedInput-notchedOutline': {
        borderColor: colors.blue[500],
      },
    },
  };
  if (currentFocusIndex !== null) {
    _sx = {
      ..._sx,
      [`tbody .MuiTableRow-root:nth-child(${currentFocusIndex + 1})`]: {
        backgroundColor: '#F8F9FA',
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: colors.blue[500],
        },
        '.hide-on-hover': {
          display: 'none',
        },
      },
    };
  }

  return (
    <ActionableDataTable
      allowReorder={isAllowedBtn && rowsToDisplay.length === rows.length}
      columns={columns}
      rows={rowsToDisplay}
      sx={_sx}
      onOrderChange={({ sourceIndex, destinationIndex }) => {
        if (!localMembers || !groupData.members) {
          return;
        }

        let newMembers = [...localMembers];

        delete newMembers[sourceIndex];
        if (sourceIndex < destinationIndex) {
          destinationIndex++;
        }
        newMembers.splice(destinationIndex, 0, localMembers[sourceIndex]);
        newMembers = newMembers.filter((member) => member);

        setLocalMembers(newMembers);
        withErrorNotificationAndRevert(() =>
          optimisticSaveWithDebounceAssignmentGroupData({ ...groupData, members: newMembers }),
        );
      }}
      actions={actions}
      onActionClick={({ actionType, row }) => {
        switch (actionType) {
          case ActionableDataTableActionTypes.DELETE:
            withErrorNotificationAndRevert(
              () => deleteAssignmentGroupMemberAtIdx(groupData, row.index),
              'Error deleting group',
            );
            break;
        }
      }}
      sxRowFunction={(isDragging: boolean) => ({
        '& .show-on-hover': {
          button: { opacity: 0 },
          '.MuiOutlinedInput-root fieldset': { borderColor: 'transparent' },
          '.MuiDivider-root': { opacity: 0 },
        },
        '&:hover .hide-on-hover': {
          display: 'none',
        },
        '&:hover .show-on-hover': {
          button: { opacity: isDragging ? 0 : 'initial' },
          '.MuiDivider-root': { opacity: 1 },
        },
      })}
      tableEmptyStateJsx={
        <TableFilteredEmptyState clearFilters={onClearFilters} isFiltered={true} />
      }
      variant={TABLE_VARIANT}
    />
  );
};
