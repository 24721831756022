import * as React from "react";
const SvgAgentDocumentationIcon = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("circle", { cx: 20, cy: 20, r: 20, fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8235 0.5529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.30547 11.978C5.68988 9.15908 8.17047 6.63551 10.9995 7.20259L19.2138 8.84913C19.7328 8.95315 20.2672 8.95315 20.7862 8.84913L29.0005 7.20259C31.8295 6.63551 34.3101 9.15909 33.6945 11.978L32.129 19.1466C32.0062 19.7089 32.0062 20.2911 32.129 20.8534L33.6945 28.022C34.3101 30.8409 31.8295 33.3645 29.0005 32.7974L20.7862 31.1509C20.2672 31.0469 19.7328 31.0469 19.2138 31.1509L10.9995 32.7974C8.17047 33.3645 5.68988 30.8409 6.30547 28.022L7.87096 20.8534C7.99376 20.2911 7.99376 19.7089 7.87096 19.1466L6.30547 11.978Z", fill: "#FFF0D9", style: {
  fill: "#FFF0D9",
  fill: "color(display-p3 1.0000 0.9412 0.8510)",
  fillOpacity: 1
} }));
export default SvgAgentDocumentationIcon;
