import { useCallback, useEffect, useRef, useState } from 'react';
import useEventListener from '../common/useEventListener';

/**
 * Based on this solution:
 * https://codesandbox.io/s/react-area-selection-hook-slggxd
 * That was published here:
 * https://www.reddit.com/r/react/comments/vntlu0/implementing_drag_select_in_react/
 */

interface Coordinates {
  x: number;
  y: number;
}

interface DrawnArea {
  start: undefined | Coordinates;
  end: undefined | Coordinates;
}

interface UseBoxSelectionProps {
  container: React.RefObject<HTMLElement> | undefined;
  disableListenerCb: (event: MouseEvent) => boolean;
}

const boxNode = document.createElement('div');
boxNode.style.position = 'fixed';
boxNode.style.background = 'rgba(0, 173, 255, 0.10)';
boxNode.style.boxShadow = '0px 0px 34px 0px rgba(126, 210, 255, 0.25)';
boxNode.style.border = '1px solid #2FBCFF';
boxNode.style.borderRadius = '4px';
boxNode.style.pointerEvents = 'none';

//TODO complete style

export function useBoxSelection({
  container = { current: document.body },
  disableListenerCb,
}: UseBoxSelectionProps) {
  const boxRef = useRef<HTMLDivElement>(boxNode);
  const boxElement = boxRef;
  const [mouseDown, setMouseDown] = useState<boolean>(false);
  const [selection, setSelection] = useState<DOMRect | null>(null);
  const [drawArea, setDrawArea] = useState<DrawnArea>({
    start: undefined,
    end: undefined,
  });

  const removeBox = useCallback(() => {
    const containerElement = container.current;
    const selectionBoxElement = boxElement.current;
    if (containerElement?.contains(selectionBoxElement)) {
      containerElement.removeChild(selectionBoxElement);
    }
  }, [boxElement, container]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      const containerElement = container.current;
      const selectionBoxElement = boxElement.current;

      if (!containerElement?.contains(selectionBoxElement)) {
        containerElement?.appendChild(selectionBoxElement);
      }
      setDrawArea((prev) => ({
        ...prev,
        end: {
          x: e.clientX,
          y: e.clientY,
        },
      }));
    },
    [boxElement, container],
  );

  const handleMouseDown = useCallback(
    (e: MouseEvent) => {
      if (disableListenerCb && disableListenerCb(e)) {
        return;
      }
      removeBox();
      setSelection(null);

      const containerElement = container.current;
      setMouseDown(true);

      if (containerElement && containerElement.contains(e.target as HTMLElement)) {
        containerElement.addEventListener('mousemove', handleMouseMove);
        setDrawArea({
          start: {
            x: e.clientX,
            y: e.clientY,
          },
          end: undefined,
        });
      }
    },
    [container, handleMouseMove, removeBox, disableListenerCb],
  );

  const handleMouseUp = useCallback(() => {
    const containerElement = container.current;
    containerElement?.removeEventListener('mousemove', handleMouseMove);
    setMouseDown(false);
  }, [container, handleMouseMove]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        removeBox();
      }
    },
    [removeBox],
  );

  useEventListener('keydown', handleKeyDown);

  useEffect(() => {
    const containerElement = container.current;
    if (containerElement) {
      containerElement.addEventListener('mousedown', handleMouseDown);
      containerElement.addEventListener('mouseup', handleMouseUp);

      return () => {
        containerElement.removeEventListener('mousedown', handleMouseDown);
        containerElement.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [container, handleMouseDown, handleMouseUp]);

  useEffect(() => {
    const { start, end } = drawArea;
    if (start && end && boxElement.current) {
      drawSelectionBox(boxElement.current, start, end);
      setSelection(boxElement.current.getBoundingClientRect());
    }
  }, [drawArea, boxElement]);

  const reset = useCallback(() => {
    setSelection(null);
    setDrawArea({
      start: undefined,
      end: undefined,
    });
    setMouseDown(false);
    removeBox();
  }, [removeBox]);

  return { selection, reset, isSelecting: mouseDown };
}

function drawSelectionBox(boxElement: HTMLElement, start: Coordinates, end: Coordinates): void {
  const b = boxElement;
  if (end.x > start.x) {
    b.style.left = start.x + 'px';
    b.style.width = end.x - start.x + 'px';
  } else {
    b.style.left = end.x + 'px';
    b.style.width = start.x - end.x + 'px';
  }

  if (end.y > start.y) {
    b.style.top = start.y + 'px';
    b.style.height = end.y - start.y + 'px';
  } else {
    b.style.top = end.y + 'px';
    b.style.height = start.y - end.y + 'px';
  }
}
