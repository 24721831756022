import { Stack, Box } from '@mui/material';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { Typography, Tag, colors } from '@sweep-io/sweep-design';
import AiAgentScrollArea from './AiAgentScrollArea';
import AiAgentInput from './AiAgentInput';
import { ReactNode } from 'react';
import { AI_AGENTS_INFO } from './aiAgentsConsts';
import { AgentType, ChatCompletionRequestMessageRole } from '@server/ai';
import useAiAgentCenter from './useAiAgentCenter';
import useAiAgents from './useAiAgents';
import AgentPrompts from './AgentPrompts';
import GeneratingChatLoader from './GeneratingChatLoader';

type NoteAlignment = 'left' | 'center';
const DOCUMENTATION_FIRST_MSG = `Ask me any question about your Salesforce, and I'll provide answers by analyzing your configuration, including object relationships, fields, flows, permissions, profiles, errors, and more.`;

const AiAgentChat = ({
  messages,
  onConfirm,
  isLoadingResponse,
  errorMsg,
  disableInput,
  noteAlignment,
  maxWidth,
  searchTermToHighlight,
  addToChatBeginning,
  onScroll,
  isLoadingChat,
}: {
  messages: AiChatMessage[];
  onConfirm: (message: string) => Promise<void>;
  isLoadingResponse: boolean;
  disableInput: boolean;
  errorMsg?: string; //this property should be deprecated
  noteAlignment: NoteAlignment;
  maxWidth?: string;
  searchTermToHighlight?: string;
  addToChatBeginning?: ReactNode;
  onScroll?: () => void;
  isLoadingChat: boolean;
}) => {
  const { isExpanded } = useAiAgentCenter();
  const { activeAgent } = useAiAgents();
  const { assistantAvatar } = AI_AGENTS_INFO[activeAgent?.type ?? AgentType.Documentation];
  const isDocumentationAgent = activeAgent?.type === AgentType.Documentation;
  const introMessage = isDocumentationAgent
    ? {
        createdAt: new Date().toISOString(),
        role: ChatCompletionRequestMessageRole.ASSISTANT,
        content: DOCUMENTATION_FIRST_MSG,
      }
    : undefined;

  const isEmpty = messages.length === 0;

  const promptsJsxIfRelevant =
    isEmpty && activeAgent && !isDocumentationAgent ? (
      <AgentPrompts agent={activeAgent} variant={isExpanded ? 'expanded' : 'collapsed'} />
    ) : null;

  const addToStart = isLoadingChat ? (
    <Stack flex={1} alignSelf="flex-start" alignItems="flex-start" padding="8px 12px">
      {addToChatBeginning}
      <GeneratingChatLoader avatar={assistantAvatar} />
    </Stack>
  ) : (
    addToChatBeginning
  );

  return (
    <Stack
      sx={{
        width: '100%',
        alignItems: 'center',
        overflow: 'auto',
        flex: 1,
      }}
    >
      <AiAgentScrollArea
        messages={isLoadingChat ? [] : messages}
        errorMsg={errorMsg}
        isLoadingResponse={isLoadingChat ? false : isLoadingResponse}
        maxWidth={maxWidth}
        searchTermToHighlight={searchTermToHighlight}
        addToChatBeginning={promptsJsxIfRelevant ?? addToStart}
        assistantAvatar={assistantAvatar}
        introMessage={introMessage}
        onScroll={onScroll}
        centerVertically={!!promptsJsxIfRelevant}
      />
      <Stack
        sx={{
          width: '100%',
          maxWidth,
          backgroundColor: isExpanded ? undefined : '#F7F9FA',
          borderTop: isExpanded ? undefined : `1px solid ${colors.grey[300]}`,
        }}
      >
        <AiAgentInput
          onConfirm={onConfirm}
          isLoading={isLoadingResponse}
          disabled={disableInput || isLoadingChat}
        />
        <Stack p={1.5} pt={0}>
          <ChatNote alignment={noteAlignment} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const ChatNote = ({ alignment }: { alignment: NoteAlignment }) => (
  <Box
    display="flex"
    gap={1}
    pl={alignment === 'left' ? 1.5 : 0}
    alignItems="center"
    justifyContent={alignment === 'left' ? 'start' : 'center'}
  >
    <Tag label="Beta" color={colors.storm[70]} />
    <Typography variant="caption" color={colors.grey[700]}>
      AI bots can make mistakes. Check important info.
    </Typography>
  </Box>
);

export default AiAgentChat;
