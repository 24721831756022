import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { selectWorkflowById } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import {
  WorkflowActionEditRecordDto,
  WorkflowActionType,
  WorkflowBaseDto,
  WorkflowCreateDto,
  WorkflowWhenEventType,
  WorkflowWhenFiltersType,
  WorkflowWhenType,
} from '../types';
import { DialogHeaderTextField } from '../../../../common/create-edit-dialog/DialogHeaderTextField';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import get from 'lodash/get';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { FlexBox } from '../../../../common/FlexBox';
import { HubspotFieldsRuleBuilder } from '../hubspot-rule-builder/HubspotFieldsRuleBuilder';
import { uniqueId } from '../../../../../lib/uniqueId';
import { useHubspotWorkflows } from './useHubspotWorkflows';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { hasOnlyOneCriteriaAndItIsEmpty } from '../../../../common/rule-builder/validateSweepCriteria';
import isEmpty from 'lodash/isEmpty';
import { HubspotHardcodedRuleBuilder } from '../hubspot-rule-builder/HubspotHardcodedRuleBuilder';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { telemetry } from '../../../../../telemetry';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../../common/permissions/usePermission';
import { WorkflowStatusField } from './WorflowStatusField';
import { useHubspot } from '../useHubspot';
import { useRunOnce } from '../../../../common/useRunOnce';
import { SelectorValueTypes } from '../../../../../types/enums/SelectorValueTypes';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { ACTIONS_EVENTS } from '../../../../../services/events';
import { AddConditionsMenuButton } from './AddConditionsMenuButton';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { HubspotListMembershipRuleBuilder } from '../hubspot-rule-builder/HubspotListMembershipRuleBuilder';
import { useFeatureToggle } from '../../../../common/useFeatureToggle';
import { HubspotUpdateRecordFilterAndAction } from './HubspotUpdateRecordFilterAndAction';
import { validateWorkflowForm } from './validateWorkflow';
import { Cloud, Hubspot, Plus } from '@sweep-io/sweep-design/dist/icons';
import { ActionsMenu } from '../../../../common/actions-menu/ActionsMenu';
import { StyledDialogActions } from '../../../../common/StyledDialogActions';

export const workflowWhenOptionLabels = {
  [WorkflowWhenType.EVENT]: 'When an event occurs',
  [WorkflowWhenType.FILTERS]: 'When Filter criteria is met',
};

const DEFAULT_WORKFLOW_NAME = 'New Enrollment Rule';

const labelTagStyle = {
  width: 'fit-content',
  borderRadius: '4px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '0 8px',
};

const newWorkflow = (campaignId: string): DeepPartial<WorkflowCreateDto> =>
  ({
    name: DEFAULT_WORKFLOW_NAME,
    objectName: 'contacts',
    workflowDetails: {
      when: {
        _id: uniqueId(),
      },
      actions: [
        {
          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
          campaignId,
          status: '',
        },
      ],
      onlyEnrollsManually: true,
    },
  }) as WorkflowCreateDto;

const getLabelTag = (label: string, bgColor: string, icon?: React.ReactNode) => {
  return (
    <Box
      sx={{
        ...labelTagStyle,
        background: bgColor,
        gap: 0.5,
      }}
    >
      {icon}
      <Typography variant="caption-bold" textAlign="center" color={colors.white}>
        {label}
      </Typography>
    </Box>
  );
};

const WhenTag = () => {
  return getLabelTag('WHEN', '#FF7A59', <Hubspot color="white" />);
};

const ThenTag = () => {
  return getLabelTag('THEN', '#009DDC', <Cloud color="white" />);
};

const AndThenTag = () => {
  return getLabelTag('AND THEN', colors.gum[500]);
};

const BoxSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const WorkflowDialog = ({
  workflowId,
  campaignId,
  onClose,
  campaignName,
  isDuplicate,
}: {
  workflowId?: string;
  campaignId: string;
  onClose: () => void;
  campaignName: string;
  isDuplicate?: boolean;
}) => {
  const { hubspotV2Ft2 } = useFeatureToggle();
  const [isSaving, setIsSaving] = useState(false);
  const { createHubspotWorkflow, updateHubspotWorkflow } = useHubspotWorkflows();
  const sendBiEvent = useSendBiEvent();

  const baseWorkflow = useSelector(selectWorkflowById(workflowId));
  const { addNotification } = useSweepNotifications();
  const { loadContactHubspotFieldsForConnectedHsOrg, loadMembershipLists } = useHubspot();

  useRunOnce(() => {
    loadContactHubspotFieldsForConnectedHsOrg();
    loadMembershipLists();
  });

  const [editingWorkflow, setCurrentEditingWorkflow] = useState<DeepPartial<WorkflowBaseDto>>(
    () => {
      if (baseWorkflow) {
        const _workflow = cloneDeep({ ...baseWorkflow });
        const id = isDuplicate ? undefined : _workflow.id;
        const name = isDuplicate ? `Copy of ${_workflow.name}` : _workflow.name;

        return {
          id,
          name,
          objectName: _workflow.objectName,
          workflowDetails: { ..._workflow.workflowDetails },
        };
      }

      return newWorkflow(campaignId);
    },
  );
  const [isPristine, setIsPristine] = useState(true);

  // Merge properties recursively to the currentWorkflow.
  const onChangeProperty = useCallback((workflowPartial: DeepPartial<WorkflowBaseDto>) => {
    setCurrentEditingWorkflow((prev) => {
      setIsPristine(false);
      const newWorkflow = { ...prev };
      const when = newWorkflow.workflowDetails?.when;
      if (workflowPartial.workflowDetails?.when?.filter && when?.filter) delete when['filter'];
      if (workflowPartial.workflowDetails?.actions && newWorkflow.workflowDetails?.actions)
        delete newWorkflow.workflowDetails['actions'];

      merge(newWorkflow, workflowPartial);

      return { ...newWorkflow };
    });
  }, []);

  const when = editingWorkflow.workflowDetails?.when;
  const actions = editingWorkflow.workflowDetails?.actions;

  const isInEditMode = Boolean(editingWorkflow.id);

  const deployWorkflowToHubspot = useCallback(async () => {
    setIsSaving(true);
    try {
      const _editingWorkflow = { ...editingWorkflow } as WorkflowBaseDto;

      if (_editingWorkflow?.workflowDetails?.when?.filter?.criteria) {
        if (hasOnlyOneCriteriaAndItIsEmpty(_editingWorkflow.workflowDetails.when.filter.criteria)) {
          _editingWorkflow.workflowDetails.when.filter.criteria = [];
          _editingWorkflow.workflowDetails.when.filter.criteriaLogic = '';
        }
      }

      if (isInEditMode) {
        await updateHubspotWorkflow(_editingWorkflow as Required<WorkflowBaseDto>);
      } else {
        await createHubspotWorkflow(_editingWorkflow as WorkflowCreateDto);
      }
      sendBiEvent({ name: ACTIONS_EVENTS.hubspotEnrollmentRulDeploySuccess });
      setIsSaving(false);
      onClose();
    } catch (e: any) {
      addNotification({
        message: `Deployment to HubSpot Failed`,
        keepOpen: true,
        details: (
          <>
            Please make sure Salesforce integration is enabled in HubSpot. Connect Hubspot to
            Salesforce to deploy campaign enrollment flows directly from Sweep.
            <Button
              variant="link"
              onClick={() =>
                window.open(
                  'https://knowledge.hubspot.com/salesforce/install-the-hubspot-salesforce-integration',
                  '_blank',
                )
              }
            >
              Learn how to set it up.
            </Button>
          </>
        ),
        variant: SweepNotificationVariant.Error,
      });
      telemetry.captureError(e);
      setIsSaving(false);
    }
  }, [
    editingWorkflow,
    isInEditMode,
    sendBiEvent,
    onClose,
    updateHubspotWorkflow,
    createHubspotWorkflow,
    addNotification,
  ]);

  const { openConfirm } = useConfirm();

  const _onClose = useCallback(async () => {
    if (isPristine) {
      onClose?.();
    } else {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (isConfirmed) {
        onClose?.();
      }
    }
  }, [isPristine, onClose, openConfirm]);

  const isValid = validateWorkflowForm(editingWorkflow);
  const [isCreateHubspotOrgsAllowed] = usePermission(['create:hubspot-orgs']);

  const isDisabled = isSaving || !isCreateHubspotOrgsAllowed;

  const maybeRenderFilterCriteriaObjectInfo = () => {
    if (
      when?.whenType === WorkflowWhenType.FILTERS &&
      when.filterType === WorkflowWhenFiltersType.PROPERTY
    ) {
      return (
        <HubspotFieldsRuleBuilder
          onChange={(filter) => {
            const _filter = !isEmpty(filter)
              ? filter
              : {
                  criteria: [
                    {
                      criterionId: uniqueId(),
                      valueType: SelectorValueTypes.LITERAL,
                      _fieldIds: [],
                      fieldType: '',
                      operator: '',
                      value: '',
                    },
                  ],
                  criteriaLogic: '1',
                };
            onChangeProperty({
              workflowDetails: {
                when: {
                  filter: _filter as SweepCondition,
                },
              },
            });
          }}
          sweepCondition={when.filter}
          readOnly={isDisabled}
        />
      );
    }
  };

  const maybeRenderFilterCriteriaMarketingInteractions = () => {
    if (
      when?.whenType === WorkflowWhenType.FILTERS &&
      when.filterType === WorkflowWhenFiltersType.ADS_SEARCH
    ) {
      return (
        <HubspotHardcodedRuleBuilder
          onChange={(filter) => {
            const _filter = !isEmpty(filter)
              ? filter
              : {
                  criteria: [
                    {
                      criterionId: uniqueId(),
                      valueType: SelectorValueTypes.LITERAL,
                    },
                  ],
                  criteriaLogic: '1',
                };
            onChangeProperty({
              workflowDetails: {
                when: {
                  filter: _filter as SweepCondition,
                  filterType: when.filterType as WorkflowWhenFiltersType,
                },
              },
            });
          }}
          eventType={when.filterType || WorkflowWhenFiltersType.PROPERTY}
          readOnly={isDisabled}
          sweepCondition={when.filter}
        />
      );
    }
  };

  const maybeRenderWhenEventOccurs = () => {
    if (when?.whenType === WorkflowWhenType.EVENT) {
      if (!Boolean(when.filter?.criteria.length)) {
        return;
      }
      return (
        <HubspotHardcodedRuleBuilder
          onChange={(filter) => {
            const _filter = isEmpty(filter) ? null : filter;
            onChangeProperty({
              workflowDetails: {
                when: {
                  filter: _filter as any,
                  eventType: when.eventType as WorkflowWhenEventType,
                },
              },
            });
          }}
          eventType={when.eventType || WorkflowWhenEventType.AD_INTERACTION}
          readOnly={isDisabled}
          sweepCondition={when.filter}
        />
      );
    }
  };

  const maybeRenderHasCompletedEventSelect = () => {
    if (when?.whenType === WorkflowWhenType.EVENT) {
      const hasConditions = Boolean(
        editingWorkflow.workflowDetails?.when?.filter?.criteria?.length,
      );
      return (
        <>
          <Select
            value={when?.eventType}
            placeholder="This happens"
            disabled={isDisabled}
            onChange={(e) =>
              onChangeProperty({
                workflowDetails: {
                  when: {
                    eventType: e.target.value as WorkflowWhenEventType,
                    filter: {
                      criteria: [],
                      criteriaLogic: '',
                    },
                  },
                },
              })
            }
          >
            <MenuItem value={WorkflowWhenEventType.AD_INTERACTION}>
              has completed: ad interaction
            </MenuItem>
            <MenuItem value={WorkflowWhenEventType.FORM_SUBMITTED}>
              has completed: form interaction
            </MenuItem>
            <MenuItem value={WorkflowWhenEventType.EMAIL_REPLY}>
              has completed: replied to email
            </MenuItem>
          </Select>
          {!hasConditions && (
            <ActionsMenu
              actions={[
                {
                  label: 'Add conditions',
                  value: 'add-conditions',
                },
              ]}
              onClick={() => {
                onChangeProperty({
                  workflowDetails: {
                    when: {
                      filter: {
                        criteria: [
                          {
                            criterionId: uniqueId(),
                            valueType: SelectorValueTypes.LITERAL,
                            _fieldIds: [],
                            fieldType: '',
                            operator: '',
                            value: '',
                          },
                        ],
                        criteriaLogic: '1',
                      },
                    },
                  },
                });
              }}
              iconVariant="flat"
              MenuButtonIcon={<Plus />}
              iconSize="tiny"
            />
          )}
        </>
      );
    }
  };

  const maybeRenderFilterCriteriaBySelect = () => {
    if (when?.whenType === WorkflowWhenType.FILTERS) {
      return (
        <>
          <Select
            value={when?.filterType}
            placeholder="Filter category"
            disabled={isDisabled}
            onChange={(e) =>
              onChangeProperty({
                workflowDetails: {
                  when: {
                    filterType: e.target.value as WorkflowWhenFiltersType,
                    filter: {
                      criteria: [
                        {
                          criterionId: uniqueId(),
                          valueType: SelectorValueTypes.LITERAL,
                          _fieldIds: [],
                          fieldType: '',
                          operator: '',
                          value: '',
                        },
                      ],
                      criteriaLogic: '1',
                    },
                  },
                },
              })
            }
          >
            <MenuItem value={WorkflowWhenFiltersType.PROPERTY}>Object information</MenuItem>
            <MenuItem value={WorkflowWhenFiltersType.ADS_SEARCH}>Marketing interactions</MenuItem>
            <MenuItem value={WorkflowWhenFiltersType.IN_LIST}>List membership</MenuItem>
          </Select>
        </>
      );
    }
  };

  const maybeRenderListMembershipSelect = () => {
    if (
      when?.whenType === WorkflowWhenType.FILTERS &&
      when.filterType === WorkflowWhenFiltersType.IN_LIST
    ) {
      return (
        <HubspotListMembershipRuleBuilder
          onChange={(filter) => {
            const _filter = isEmpty(filter) ? null : filter;
            onChangeProperty({
              workflowDetails: {
                when: {
                  filter: _filter as any,
                },
              },
            });
          }}
          readOnly={isDisabled}
          sweepCondition={when.filter}
        />
      );
    }
  };

  const updateRecordActions = actions?.filter(
    (action) => action?.actionType === WorkflowActionType.EDIT_RECORD,
  ) as WorkflowActionEditRecordDto[] | undefined;

  const renderAndThenTagAndButton = (renderUpdateRecordAction: boolean, onDelete?: () => void) => {
    return (
      <Stack direction={'row'} spacing={1} alignItems="center">
        <AndThenTag />
        {hubspotV2Ft2 && (
          <AddConditionsMenuButton
            renderAddConditions={renderUpdateRecordAction}
            label="Update record"
            onClick={() => {
              onChangeProperty({
                workflowDetails: {
                  actions: [
                    ...(actions || []),
                    {
                      _id: uniqueId(),
                      actionType: WorkflowActionType.EDIT_RECORD,
                      properties: [
                        {
                          _id: uniqueId(),
                          name: '',
                          value: '',
                        },
                      ],
                    },
                  ],
                },
              });
            }}
            onDelete={onDelete}
          />
        )}
      </Stack>
    );
  };

  const maybeRenderAndThenUpdateRecordActions = () => {
    if (actions && updateRecordActions) {
      return updateRecordActions.map((action) => {
        const currentActionIndex = actions.findIndex((a) => a?._id && a._id === action._id);
        const newActions = [...actions];
        return (
          <>
            {renderAndThenTagAndButton(currentActionIndex == actions.length - 1, () => {
              const currentActionIndex = actions.findIndex((a) => a?._id && a._id === action._id);
              const newActions = [...actions];
              newActions.splice(currentActionIndex, 1);
              onChangeProperty({
                workflowDetails: {
                  actions: newActions,
                },
              });
            })}
            <HubspotUpdateRecordFilterAndAction
              key={action._id}
              action={action}
              onChange={(action) => {
                if (newActions[currentActionIndex] && newActions[currentActionIndex]) {
                  newActions[currentActionIndex] = action;
                }
                onChangeProperty({
                  workflowDetails: {
                    actions: newActions,
                  },
                });
              }}
            />
          </>
        );
      });
    }
  };

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          width: '1032px',
          height: '823px',
          margin: 0,
        },
      }}
      onClose={_onClose}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="flex-end" gap="115px" alignItems="flex-end">
          <DialogHeaderTextField
            value={editingWorkflow.name || ''}
            onChange={(name) => onChangeProperty({ name })}
            placeholder=""
            editDisabled={isDisabled}
            typographyVariant="h3"
          />
          <IconButton onClick={_onClose} variant="flat">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Box display="flex" gap={2} flexDirection="column">
          <BoxSection>
            <WhenTag />
            <Box sx={{ display: 'flex', gap: 1, alignContent: 'center', alignItems: 'center' }}>
              <Select
                value={when?.whenType}
                placeholder="This happens"
                disabled={isDisabled}
                onChange={(e) => {
                  const whenType = e.target.value as WorkflowWhenType;
                  if (whenType === WorkflowWhenType.EVENT) {
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          whenType: WorkflowWhenType.EVENT,
                          filter: null,
                        },
                      },
                    } as any);
                  } else {
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          whenType: WorkflowWhenType.FILTERS,
                          filterType: WorkflowWhenFiltersType.PROPERTY,
                          filter: {
                            _id: uniqueId(),
                            criteria: [
                              {
                                criterionId: uniqueId(),
                                valueType: SelectorValueTypes.LITERAL,
                              },
                            ],
                            criteriaLogic: '1',
                          },
                        },
                      },
                    } as any);
                  }
                }}
              >
                <MenuItem value={WorkflowWhenType.EVENT}>
                  {workflowWhenOptionLabels[WorkflowWhenType.EVENT]}
                </MenuItem>
                <MenuItem value={WorkflowWhenType.FILTERS}>
                  {workflowWhenOptionLabels[WorkflowWhenType.FILTERS]}
                </MenuItem>
              </Select>
              {maybeRenderHasCompletedEventSelect()}
              {maybeRenderFilterCriteriaBySelect()}
            </Box>
            {maybeRenderFilterCriteriaObjectInfo()}
            {maybeRenderFilterCriteriaMarketingInteractions()}
            {maybeRenderWhenEventOccurs()}
            {maybeRenderListMembershipSelect()}
          </BoxSection>
          <BoxSection>
            <ThenTag />
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="body">Set Salesforce Campaign </Typography>
              <Box width="394px">
                <TextField disabled value={campaignName} fullWidth>
                  {campaignName}
                </TextField>
              </Box>
            </Stack>
          </BoxSection>
          <BoxSection>
            {renderAndThenTagAndButton(!Boolean(updateRecordActions?.length))}
            <FlexBox
              sx={{
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant="body">Status is</Typography>
              <WorkflowStatusField
                value={get(editingWorkflow, 'workflowDetails.actions[0].status')}
                onChange={(status) => {
                  onChangeProperty({
                    workflowDetails: {
                      actions: [
                        {
                          _id: uniqueId(),
                          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
                          campaignId,
                          status,
                        },
                      ],
                    },
                  });
                }}
                isDisabled={isDisabled}
                campaignId={campaignId}
              />
            </FlexBox>
            {maybeRenderAndThenUpdateRecordActions()}
          </BoxSection>
        </Box>
      </DialogContent>
      <StyledDialogActions>
        <RestrictedTooltip
          to={['create:hubspot-orgs']}
          notAllowedTitle={'To create Hubspot workflows, please contact your admin'}
        >
          <Button
            onClick={deployWorkflowToHubspot}
            startIconName="Hubspot"
            disabled={!isValid || isDisabled}
            loading={isSaving}
          >
            Deploy to Hubspot
          </Button>
        </RestrictedTooltip>
      </StyledDialogActions>
    </Dialog>
  );
};
