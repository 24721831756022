import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  UserDefinedRoleGroupsInterface,
  RoleGroupType,
  RoleLevels,
  RoleGroupLevelType,
  OrgRoleLevels,
} from '@server/role-group-interface';
import { FormikProps, useFormik } from 'formik';
import { roleValidationSchema } from './validationSchema';
import isEqual from 'lodash/isEqual';
import noop from 'lodash/noop';
import {
  createRoleGroupAccountBasedStructure,
  createRoleGroupOrgBasedStructure,
  translateOrgBasedRoleLevelsIntoMultiOrgsStructure,
} from './utils';
import { selectDefaultCreationCrmOrgId } from '../../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';

export interface RoleGroupForMultipleOrgs {
  crmOrgIds: string[];
  levels: RoleLevels;
}

interface RoleManagerContextType {
  setRoleManager: Dispatch<SetStateAction<boolean>>;
  editRole?: UserDefinedRoleGroupsInterface;
  setEditRole: (role?: UserDefinedRoleGroupsInterface) => void;
  headerText: string;
  isRoleManagerOpen: boolean;
  accountBasedRoleLevels?: RoleLevels;
  orgBasedMultiGroupLevels?: RoleGroupForMultipleOrgs[];
  setOrgBasedMultiGroupLevels: Dispatch<SetStateAction<RoleGroupForMultipleOrgs[] | undefined>>;
  setAccountBasedRoleLevels: Dispatch<SetStateAction<RoleLevels | undefined>>;
}

const RoleManagerContext = createContext<RoleManagerContextType>({
  setEditRole: noop,
  setRoleManager: noop,
  headerText: 'Manage roles',
  isRoleManagerOpen: false,
  setOrgBasedMultiGroupLevels: noop,
  setAccountBasedRoleLevels: noop,
});

export const RoleManagerProvider = ({ children }: { children: React.ReactNode }) => {
  const [editRole, setEditRole] = useState<UserDefinedRoleGroupsInterface>();
  const [accountBasedRoleLevels, setAccountBasedRoleLevels] = useState<RoleLevels>();
  const [orgBasedMultiGroupLevels, setOrgBasedMultiGroupLevels] =
    useState<RoleGroupForMultipleOrgs[]>();

  const headerText = editRole ? editRole.name : 'Manage roles';
  const [isRoleManagerOpen, setRoleManager] = useState(false);

  return (
    <RoleManagerContext.Provider
      value={{
        editRole,
        setEditRole,
        accountBasedRoleLevels,
        orgBasedMultiGroupLevels,
        headerText,
        isRoleManagerOpen,
        setRoleManager,
        setOrgBasedMultiGroupLevels,
        setAccountBasedRoleLevels,
      }}
    >
      {children}
    </RoleManagerContext.Provider>
  );
};

export const useRoleManagerContext = () => {
  const {
    editRole,
    setEditRole,
    headerText,
    isRoleManagerOpen,
    setRoleManager,
    setAccountBasedRoleLevels,
    setOrgBasedMultiGroupLevels,
    accountBasedRoleLevels,
    orgBasedMultiGroupLevels,
  } = useContext(RoleManagerContext);
  const defaultCrmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const [initialEditRole, setInitialEditRole] = useState(editRole);

  const formik: FormikProps<UserDefinedRoleGroupsInterface> = useFormik({
    initialValues: editRole ?? ({} as UserDefinedRoleGroupsInterface),
    validationSchema: roleValidationSchema,
    validateOnChange: true,
    validateOnMount: true,
    onSubmit: () => {},
  });
  const { setFieldValue, resetForm, isValid, values, validateForm } = formik;

  useEffect(() => {
    if (!initialEditRole) {
      const accountBasedStructure = createRoleGroupAccountBasedStructure(
        defaultCrmOrgId ?? '',
      ).levels;

      const orgBasedStructure = createRoleGroupOrgBasedStructure(defaultCrmOrgId ?? '').levels;

      setInitialEditRole(editRole);
      setAccountBasedRoleLevels(
        editRole?.roleGroupLevels?.type === RoleGroupLevelType.AccountBased
          ? (editRole?.roleGroupLevels?.levels ?? accountBasedStructure)
          : accountBasedStructure,
      );

      setOrgBasedMultiGroupLevels(
        translateOrgBasedRoleLevelsIntoMultiOrgsStructure(
          editRole?.roleGroupLevels?.type === RoleGroupLevelType.OrgBased
            ? (editRole?.roleGroupLevels?.levels ?? orgBasedStructure)
            : orgBasedStructure,
        ),
      );
    }
    validateForm();
  }, [
    editRole,
    initialEditRole,
    validateForm,
    defaultCrmOrgId,
    setAccountBasedRoleLevels,
    setOrgBasedMultiGroupLevels,
  ]);

  const updateRole = useCallback(
    (key: string, value: string | RoleLevels) => {
      setEditRole({ ...editRole, [key]: value } as UserDefinedRoleGroupsInterface);
    },
    [editRole, setEditRole],
  );

  const updateRoleLevelGroupType = useCallback(
    (type: RoleGroupLevelType) => {
      const _orgBasedMultiGroupLevels: OrgRoleLevels[] =
        orgBasedMultiGroupLevels
          ?.map((item) => item.crmOrgIds.map((crmOrgId) => ({ crmOrgId, levels: item.levels })))
          .flat() ?? createRoleGroupOrgBasedStructure(defaultCrmOrgId ?? '').levels;

      const _accountBasedRoleLevels: RoleLevels =
        accountBasedRoleLevels ??
        createRoleGroupAccountBasedStructure(defaultCrmOrgId ?? '').levels;

      setEditRole({
        ...editRole,
        roleGroupLevels: {
          type,
          levels:
            type === RoleGroupLevelType.AccountBased
              ? _accountBasedRoleLevels
              : _orgBasedMultiGroupLevels,
        },
      } as UserDefinedRoleGroupsInterface);

      setAccountBasedRoleLevels(_accountBasedRoleLevels);
      setOrgBasedMultiGroupLevels(
        translateOrgBasedRoleLevelsIntoMultiOrgsStructure(_orgBasedMultiGroupLevels),
      );
    },
    [
      editRole,
      setEditRole,
      setAccountBasedRoleLevels,
      setOrgBasedMultiGroupLevels,
      orgBasedMultiGroupLevels,
      accountBasedRoleLevels,
      defaultCrmOrgId,
    ],
  );

  useEffect(() => {
    if (editRole) {
      Object.keys(editRole).forEach((key) => {
        setFieldValue(key, editRole[key as keyof UserDefinedRoleGroupsInterface]).then(() =>
          validateForm(),
        );
      });
    }
  }, [editRole, setFieldValue, validateForm]);

  const _setAccountBasedRoleLevels = useCallback(
    (roleLevels: RoleLevels) => {
      editRole &&
        setEditRole({
          ...editRole,
          roleGroupLevels: { type: RoleGroupLevelType.AccountBased, levels: roleLevels },
        });
      setAccountBasedRoleLevels(roleLevels);
    },
    [editRole, setEditRole, setAccountBasedRoleLevels],
  );

  const _setOrgBasedMultiGroupLevels = useCallback(
    (orgBasedMultiGroupLevels: RoleGroupForMultipleOrgs[]) => {
      const translatedLevels = orgBasedMultiGroupLevels
        .map((multiGroup) =>
          multiGroup.crmOrgIds.map((orgId) => ({ crmOrgId: orgId, levels: multiGroup.levels })),
        )
        .flat();

      editRole &&
        setEditRole({
          ...editRole,
          roleGroupLevels: { type: RoleGroupLevelType.OrgBased, levels: translatedLevels },
        });
      setOrgBasedMultiGroupLevels(orgBasedMultiGroupLevels);
    },
    [editRole, setEditRole, setOrgBasedMultiGroupLevels],
  );

  const goBack = useCallback(() => {
    setEditRole();
    resetForm();
    validateForm();
    setInitialEditRole(undefined);
  }, [setEditRole, resetForm, validateForm]);

  const isSystemRole = editRole?.type === RoleGroupType.SYSTEM;
  const hasChanges = !isEqual(editRole, initialEditRole);
  const _isValid = editRole?.id ? hasChanges && isValid : isValid;
  const displayHasTypeChangedAlert =
    initialEditRole?.id &&
    initialEditRole?.roleGroupLevels?.type === RoleGroupLevelType.OrgBased &&
    editRole?.roleGroupLevels?.type === RoleGroupLevelType.AccountBased;

  return {
    editRole:
      editRole && Object.keys(editRole).length > 0 ? (isSystemRole ? editRole : values) : undefined,
    isValid: _isValid,
    updateRole,
    initializeEditRole: setEditRole,
    goBack,
    headerText,
    isRoleManagerOpen,
    setRoleManager,
    hasChanges,
    displayHasTypeChangedAlert,
    setAccountBasedRoleLevels: _setAccountBasedRoleLevels,
    setOrgBasedMultiGroupLevels: _setOrgBasedMultiGroupLevels,
    accountBasedRoleLevels,
    orgBasedMultiGroupLevels,
    updateRoleLevelGroupType,
  };
};
