import { Button } from '@sweep-io/sweep-design';
import { useFeatureToggle } from '../common/useFeatureToggle';
import CopilotButtonDeprecated from '../dashboard/aiAgents/CopilotButtonDeprecated';

const AiChatButtonEntryPoint = ({
  onClick,
  variant,
  disabled,
}: {
  onClick: () => void;
  variant: 'primary' | 'secondary';
  disabled?: boolean;
}) => {
  const { aiAgentCenter } = useFeatureToggle();
  const buttonVariant = variant === 'primary' ? 'filled' : 'outlined';

  return aiAgentCenter ? (
    <Button
      onClick={onClick}
      variant={buttonVariant}
      size="small"
      startIconName="Sparkle"
      disabled={disabled}
    >
      AI Agents
    </Button>
  ) : (
    <CopilotButtonDeprecated onClick={onClick} variant={variant} />
  );
};

export default AiChatButtonEntryPoint;
