import { useSweepApi } from '../sweep';
import { useCallback } from 'react';
import { ClearbitEnrichment, UserInfo, UserPreferences } from '../../types/UserInfoTypes';
import { AccountOnboardingStep } from '@server/account-onboarding';

export const useUserApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    fetch_current_user: useCallback(async () => {
      const response = await sweepApi.get(`/users`);
      return response.data as UserInfo;
    }, [sweepApi]),
    /**
     * To get permissions BE is using auth guard context that reads from headers,
     * so defaultCreationEnvId has to be accessible to get the right permissions (and sent in the headers)
     */
    get_current_user_permissions: useCallback(
      async (crmOrgId: string) => {
        const response = await sweepApi.get(`/users/permissions`, {
          headers: { currentcrmorgid: crmOrgId },
        });
        return response.data;
      },
      [sweepApi],
    ),
    delete_user: useCallback(
      async (_id: string) => {
        const response = await sweepApi.delete(`/users/${_id}`);
        return response.data as boolean;
      },
      [sweepApi],
    ),
    delete_invitation: useCallback(
      async (_id: string) => {
        const response = await sweepApi.delete(`/users/account/invitations/${_id}`);
        return response.data as boolean;
      },
      [sweepApi],
    ),
    patch_updateOnboardingStatus: useCallback(
      async (status: AccountOnboardingStep) => {
        const res = await sweepApi.patch('/users/account/onboarding', {
          status,
        });
        return res.data;
      },
      [sweepApi],
    ),
    update_userPreferences: useCallback(
      async (updatedPreferences: UserPreferences) => {
        const { getStarted } = updatedPreferences;
        const preferencesForApi = {
          ...updatedPreferences,
          getStarted,
        };
        const res = await sweepApi.put('/users/preferences', preferencesForApi);
        return res.data;
      },
      [sweepApi],
    ),
    post_accountTrial: useCallback(async () => {
      await sweepApi.post('/users/account/trial');
    }, [sweepApi]),

    get_clearbitEnrichment: useCallback(async () => {
      const res = await sweepApi.get('/users/clearbit-enrichment');
      return res.data as ClearbitEnrichment | undefined;
    }, [sweepApi]),
  };
};
