import {
  RoleGroupLevelType,
  RoleLevel,
  UserDefinedRoleGroupsInterface,
} from '@server/role-group-interface';

export const systemRolesDetails: Pick<
  UserDefinedRoleGroupsInterface,
  'name' | 'roleGroupLevels' | 'description'
>[] = [
  {
    name: 'Admin',
    roleGroupLevels: {
      type: RoleGroupLevelType.AccountBased,
      levels: {
        automations: RoleLevel.DEPLOY,
        assignments: RoleLevel.DEPLOY,
        rollups: RoleLevel.DEPLOY,
        dedupMatching: RoleLevel.DEPLOY,
        funnels: RoleLevel.DEPLOY,
        alerts: RoleLevel.DEPLOY,
        hubspot: RoleLevel.DEPLOY,
      },
    },
    description: 'Has full access. Can make edits and deployments within the entire system.',
  },
  {
    name: 'Alert Manager',
    roleGroupLevels: {
      type: RoleGroupLevelType.AccountBased,
      levels: {
        automations: RoleLevel.VIEWER,
        assignments: RoleLevel.VIEWER,
        rollups: RoleLevel.VIEWER,
        dedupMatching: RoleLevel.VIEWER,
        funnels: RoleLevel.VIEWER,
        alerts: RoleLevel.DEPLOY,
        hubspot: RoleLevel.VIEWER,
      },
    },
    description:
      'Full access to alerts, including edits and deployments. Can view other parts of the system.',
  },
  {
    name: 'Collaborator',
    roleGroupLevels: {
      type: RoleGroupLevelType.AccountBased,
      levels: {
        automations: RoleLevel.REQUEST_TO_DEPLOY,
        assignments: RoleLevel.REQUEST_TO_DEPLOY,
        rollups: RoleLevel.REQUEST_TO_DEPLOY,
        dedupMatching: RoleLevel.REQUEST_TO_DEPLOY,
        funnels: RoleLevel.REQUEST_TO_DEPLOY,
        alerts: RoleLevel.REQUEST_TO_DEPLOY,
        hubspot: RoleLevel.VIEWER,
      },
    },
    description: 'Can view, edit and save. Can request to deploy any element.',
  },
  {
    name: 'Viewer',
    roleGroupLevels: {
      type: RoleGroupLevelType.AccountBased,
      levels: {
        automations: RoleLevel.VIEWER,
        assignments: RoleLevel.VIEWER,
        rollups: RoleLevel.VIEWER,
        dedupMatching: RoleLevel.VIEWER,
        funnels: RoleLevel.VIEWER,
        alerts: RoleLevel.VIEWER,
        hubspot: RoleLevel.VIEWER,
      },
    },
    description: 'View access to the entire system.',
  },
];
