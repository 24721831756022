import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { colors } from '@sweep-io/sweep-design';

//This component requires parent to have position: relative

export const OverflowingLoader = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        background: colors.white,
        top: 0,
        width: '100%',
        zIndex: 1,
      }}
    >
      <CenteredCircularProgress />
    </Box>
  );
};
