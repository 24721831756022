import { Stack, Box, DialogTitle, DialogContent } from '@mui/material';
import PlusFile from './plusFile.svg?react';
import { Typography, colors, IconButton } from '@sweep-io/sweep-design';
import FilesList from './FilesList';
import FilesUploadButton from './FilesUploadButton';
import { BaseDialog } from '../../common/dialogs/BaseDialog';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { AgentFile, AgentFileInvalid, NewAgentFile } from '../aiAgentsConsts';
import { useState } from 'react';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { sortByDate } from '../../../utils/sortByDate';
import { SortOrder } from '../../common/types';

const AgentFilesDialog = ({
  closeDialog,
  files,
  addFiles,
  removeFile,
}: {
  closeDialog: () => void;
  files: AgentFile[];
  addFiles: (files: NewAgentFile[]) => Promise<void>;
  removeFile: (fileIdToRemove: string) => void;
}) => {
  const [invalidFiles, setInvalidFiles] = useState<AgentFileInvalid[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onFilesAdd = async (addedFiles: NewAgentFile[]) => {
    setIsLoading(true);
    await addFiles(addedFiles);
    setIsLoading(false);
  };

  const onRemove = (fileIdToRemove: string) => {
    removeFile(fileIdToRemove);
  };

  const onRemoveInvalidFile = (fileIdToRemove: string) => {
    setInvalidFiles(invalidFiles.filter((file) => file.id !== fileIdToRemove));
  };

  const onFilesAddInvalid = (files: AgentFileInvalid[]) => {
    setInvalidFiles(files);
  };

  const renderEmptyState = files.length === 0;
  const sortedFiles = files.toSorted((a, b) =>
    sortByDate(a.createdAt, b.createdAt, SortOrder.DESC),
  );

  return (
    <BaseDialog open={true} onClose={closeDialog} PaperProps={{ sx: { minHeight: '330px' } }}>
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h3">Agent files</Typography>
          <Stack direction="row" gap={1} alignItems="center">
            {!renderEmptyState && (
              <FilesUploadButton
                startIconName="Files"
                onAddValidFiles={onFilesAdd}
                onAddInvalidFiles={onFilesAddInvalid}
              />
            )}
            <IconButton variant="flat" size="large" onClick={closeDialog}>
              <Close />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '24px', width: '906px' }}>
        {renderEmptyState && (
          <EmptyState onAddValidFiles={onFilesAdd} onAddInvalidFiles={onFilesAddInvalid} />
        )}
        {!renderEmptyState && (
          <>
            {isLoading && (
              <Stack pl={1.5} alignItems="start">
                <CenteredCircularProgress circularProgressProps={{ size: 20 }} />
              </Stack>
            )}
            <FilesList
              files={sortedFiles}
              invalidFiles={invalidFiles}
              onRemove={onRemove}
              onRemoveInvalidFile={onRemoveInvalidFile}
            />
          </>
        )}
      </DialogContent>
    </BaseDialog>
  );
};

const EmptyState = ({
  onAddValidFiles,
  onAddInvalidFiles,
}: {
  onAddValidFiles: (agentFiles: NewAgentFile[]) => void;
  onAddInvalidFiles: (agentFiles: AgentFileInvalid[]) => void;
}) => {
  return (
    <Stack p={2} gap={4} alignItems="center">
      <Stack alignItems="center" gap={0.5}>
        <PlusFile />
        <Typography variant="h5" color={colors.grey[800]}>
          Add data, reports, business documents, and more
        </Typography>
        <Typography variant="body" color={colors.grey[800]}>
          Demo Scheduled Optimization can access their content when you chat with the agent
        </Typography>
      </Stack>
      <Box>
        <FilesUploadButton
          startIconName="Plus"
          onAddValidFiles={onAddValidFiles}
          onAddInvalidFiles={onAddInvalidFiles}
        />
      </Box>
    </Stack>
  );
};

export default AgentFilesDialog;
