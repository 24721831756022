export enum ConfigurationType {
  validationRules = 'validationRules',
  approvalProcesses = 'approvalProcesses',
  workflowRules = 'workflowRules',
  flows = 'flows',
  processBuilderFlows = 'processBuilderFlows',
  apexTriggers = 'apexTriggers',
  apexClasses = 'apexClasses',
  fields = 'fields',
  reports = 'reports',
  layouts = 'layouts',
  emailTemplates = 'emailTemplates',
  objects = 'objects',
  sweepAutomation = 'automations',
  sweepAlert = 'alerts',
  sweepDedupe = 'dedup',
  sweepMatching = 'matching',
  sweepAssignment = 'assignments',
  sweepScheduledAssignment = 'scheduledAssignments',
  rollups = 'rollups',
  cpqData = 'cpqData',
  recordTypes = 'recordTypes',
  paths = 'paths',
  relatedObjects = 'relatedObjects',
}

export enum OpenAIConfigurationKeys {
  VALIDATION_RULES = 'validationRules',
  APPROVAL_PROCESSES = 'approvalProcesses',
  WORKFLOW_RULES = 'workflowRules',
  PROCESS_BUILDER_FLOWS = 'processBuilderFlows',
  FLOWS = 'flows',
  LAYOUTS = 'layouts',
  APEX_TRIGGERS = 'apexTriggers',
  APEX_CLASSES = 'apexClasses',
  CPQ_DATA = 'cpqData',
}
