import { useEffect, useRef, useState } from 'react';
import { Box, Fade, ListItemButton, MenuList, Popover, Stack } from '@mui/material';
import keyBy from 'lodash/keyBy';
import { Tag, Typography, colors } from '@sweep-io/sweep-design';
import { TagItem, TextFieldWithTags } from '../TextFieldWithTags';
import { filterItemsBySearch } from '../../../lib/filterItemsBySearch';
import { CreateButton } from './CreateButton';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../../constants';
import { EmojiType } from '../../avatar/avatarTypes';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import InlineAutocompleteEmptyState from './InlineAutocompleteEmptyState';

export interface InlineAutocompleteItem<T = any> {
  value: string;
  label: string;
  labelDecorator?: string;
  emoji?: EmojiType;
  imageUrl?: string;
  tagColor?: string;
  data?: T;
}

export type InlineAutocompleteVariants = 'tags' | 'users';

interface InlineAutocompleteProps<T> {
  items: InlineAutocompleteItem<T>[];
  selectedItemValues: string[];
  onSelectItem?: (item: InlineAutocompleteItem<T>) => void;
  onDeleteItem?: (item: InlineAutocompleteItem<T>) => void;
  onCreate?: (label: string) => void;
  onClick?: (item: InlineAutocompleteItem) => void;
  placeholder?: string;
  createInProgress?: boolean;
  closeOnItemSelection?: boolean;
  emptyState?: {
    text: string;
    buttonText: string;
  };
  headerTxt: string;
  variant?: InlineAutocompleteVariants;
  inputTypographyVariant?: SweepTypographyVariants;
  clearSearchTextOnSelection?: boolean;
  width?: number;
  maxHeight?: number;
  labelTooltip?: string;
  deleteTooltip?: string;
  disableCreate?: boolean;
  showBorder?: boolean;
  fullWidth?: boolean;
}

const getItemLabelWitLabelDecorator = (item: InlineAutocompleteItem) => {
  return item ? `${item.labelDecorator ? item.labelDecorator + ' ' : ''}${item.label}` : '';
};

const INLINE_TEXT_EDITOR_RL_PADDING = 4;

export function InlineAutocomplete<T = any>({
  items,
  selectedItemValues,
  onSelectItem,
  onDeleteItem,
  placeholder = 'Search',
  onCreate,
  onClick,
  createInProgress,
  closeOnItemSelection,
  emptyState,
  headerTxt,
  variant = 'tags',
  inputTypographyVariant,
  clearSearchTextOnSelection,
  width = 300,
  maxHeight,
  labelTooltip,
  deleteTooltip,
  disableCreate,
  fullWidth,
  showBorder,
}: InlineAutocompleteProps<T>) {
  const [currentSearch, setCurrentSearch] = useState('');
  const itemsByKey = keyBy(items, 'value');

  const [isOpen, setIsOpen] = useState(false);
  const inlineTextFieldRef = useRef<HTMLDivElement>(null);

  const nonSelectedItems = items.filter((item) => !selectedItemValues.includes(item.value));

  const filteredItems = filterItemsBySearch(nonSelectedItems, currentSearch, 'label');
  const currentSearchHasExactMatch = items.some((item) => item.label === currentSearch);

  useEffect(() => {
    setCurrentSearch('');
  }, [isOpen]);

  useEffect(() => {
    if (!selectedItemValues.length) {
      setIsOpen(false);
    }
  }, [selectedItemValues.length]);

  const tags: TagItem[] = selectedItemValues
    .filter((value) => Boolean(itemsByKey[value]))
    .map((value) => ({
      value,
      label: getItemLabelWitLabelDecorator(itemsByKey[value]),
      onDelete: () => {
        onDeleteItem && onDeleteItem(itemsByKey[value]);
      },
      color: itemsByKey[value]?.tagColor,
      onClick: onClick
        ? () => {
            onClick(itemsByKey[value]);
          }
        : undefined,
      labelTooltip,
      deleteTooltip,
    }));

  if (createInProgress) {
    tags.push({
      value: 'create-in-progress',
      label: 'Creating...',
      isLoader: true,
    });
  }

  return (
    <Box>
      {selectedItemValues.length === 0 && !!emptyState && (
        <Box ref={inlineTextFieldRef}>
          <InlineAutocompleteEmptyState
            onClick={() => setIsOpen(true)}
            emptyStateTxt={emptyState.text}
            emptyStateButtonTxt={emptyState.buttonText}
          />
        </Box>
      )}
      {(selectedItemValues.length > 0 ? true : !emptyState) && (
        <TextFieldWithTags
          typographyVariant={inputTypographyVariant}
          placeholder={placeholder}
          ref={inlineTextFieldRef}
          tags={tags}
          hideDeleteButton
          readonly={isOpen || createInProgress}
          onClick={() => {
            setIsOpen(true);
          }}
          sx={{
            width: fullWidth ? '100%' : width - INLINE_TEXT_EDITOR_RL_PADDING * 2,
            overflow: 'auto',
            opacity: isOpen ? 0 : 1,
            transition: isOpen ? 'opacity 2s' : '',
            input: {
              display: selectedItemValues.length ? 'none' : undefined,
            },
            '& .MuiInputBase-adornedStart': {
              paddingRight: selectedItemValues.length ? '12px' : '',
              '& > div': {
                pointerEvents: isOpen ? 'all' : 'none',
              },
            },
            '& .MuiInputBase-root': {
              background: 'transparent',
              '&, &.Mui-disabled': {
                backgroundColor: colors.white,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: showBorder ? '' : 'transparent',
                },
              },

              '&:hover, &.Mui-focused': {
                background: colors.white,
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: colors.grey[300],
                },
              },
            },
          }}
        />
      )}
      <Popover
        open={isOpen}
        anchorEl={inlineTextFieldRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        slotProps={{
          paper: {
            sx: {
              marginTop: '-6px',
              width: width,
            },
          },
        }}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 0 }}
        disablePortal
      >
        <Box padding={1} overflow="hidden">
          <Box display="flex" flexDirection="column">
            <TextFieldWithTags
              value={currentSearch}
              tags={tags}
              onChange={(e) => {
                setCurrentSearch(e.target.value);
              }}
              onAddValue={
                disableCreate
                  ? undefined
                  : (value) => {
                      if (currentSearch && !currentSearchHasExactMatch && variant !== 'users') {
                        onCreate && onCreate(value);
                        setCurrentSearch('');
                      }
                    }
              }
              autoFocus
              typographyVariant={inputTypographyVariant}
            />
            <Box
              p={1.5}
              pr={`${INLINE_TEXT_EDITOR_RL_PADDING}px`}
              pl={`${INLINE_TEXT_EDITOR_RL_PADDING}px`}
            >
              <Typography variant="caption-medium" color={colors.grey[700]}>
                {headerTxt}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" flexDirection="column">
            <MenuList
              dense
              sx={{ p: 0, maxHeight: maxHeight ? maxHeight + 'px' : 'auto', overflow: 'auto' }}
            >
              {filteredItems.map((item) => (
                <ListItemButton
                  key={item.value}
                  onClick={() => {
                    closeOnItemSelection && setIsOpen(false);
                    onSelectItem && onSelectItem(item);
                    clearSearchTextOnSelection && setCurrentSearch('');
                  }}
                  sx={{ height: '40px', pl: 0.5 }}
                >
                  {variant === 'users' ? (
                    <Stack direction="row" alignItems="center" gap={1} width="100%">
                      <AvatarPicture
                        avatar={{ emoji: item?.emoji, imageUrl: item?.imageUrl }}
                        dimension={DIMENSION_AVATAR_VERY_SMALL}
                        isInactive={false}
                      />
                      <Typography variant="body">{item.label}</Typography>
                    </Stack>
                  ) : (
                    <Tag label={getItemLabelWitLabelDecorator(item)} color={item.tagColor} />
                  )}
                </ListItemButton>
              ))}
              {!filteredItems.length && (
                <Box p={1} pt={0}>
                  <Typography variant="caption" color={colors.grey[700]}>
                    No results found
                  </Typography>
                </Box>
              )}
            </MenuList>

            {currentSearch &&
              !currentSearchHasExactMatch &&
              variant !== 'users' &&
              !disableCreate && (
                <CreateButton
                  onCreate={onCreate}
                  currentSearch={currentSearch}
                  setIsOpen={setIsOpen}
                />
              )}
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}
