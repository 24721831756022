import { StyledAlert } from './StyledAlert';
import { Warning as WarningIcon } from '@sweep-io/sweep-design/dist/icons';

const AlertPermission = ({
  alertMessage,
  addedStyle,
}: {
  alertMessage: string;
  addedStyle?: any;
}) => {
  return (
    <StyledAlert
      sx={{ ...addedStyle, alignItems: 'center' }}
      icon={<WarningIcon />}
      severity="warning"
    >
      {alertMessage}
    </StyledAlert>
  );
};

export default AlertPermission;
