import { VisibilityMap } from '../../../types/VisibilityTypes';
import { PillsMap } from '../../canvas-pills/canvasPillsReducer';
import {
  SweepCanvasGroup,
  SweepCanvasNode,
  SweepCanvasEdge,
  CanvasElementType,
} from '../../multi-canvas/canvasTypes';
import { HighlightEntities, TransientFunnel } from '../funnelMapCanvasTypes';
import { StageType } from '../../../types/enums/StageType';
import * as pillsHelper from '../pillsHelper';
import { TRANSIENT_GROUP_ID } from '../useFunnelMapElements';
import { useMemo } from 'react';
import { Dictionary } from 'lodash';
import { usePlacingPluginContext } from '../../pages/funnel-map-flow/templates/placing-plugin/PlacingPluginContext';
import { getRecordTypeIdentifier } from '../../pages/configuration-canvas/utils/getRecordTypeIdentifier';
import { SweepCanvasReactFlowEdgeDataType } from '../../multi-canvas/edges/SweepCanvasReactFlowEdgeDataType';
import { FunnelType } from '../../../types/enums/FunnelType';

export const useCalculateSfFunnelsNodes = ({
  funnelMap,
  highlightEntities,
  renderZeroCountPills,
  pills,
  visibilityMap,
  transientFunnel,
  funnelStageMetadata,
  objectTypesByName,
  stagesWithSparkleIcon,
}: {
  funnelMap: FunnelMap;
  highlightEntities?: HighlightEntities[];
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
  visibilityMap: VisibilityMap;
  transientFunnel?: TransientFunnel;
  funnelStageMetadata?: StageMetadata[];
  objectTypesByName: Dictionary<ObjectTypeName>;
  stagesWithSparkleIcon?: string[];
}) => {
  const { configuringPluginType } = usePlacingPluginContext();

  return useMemo(() => {
    const groups: SweepCanvasGroup<FunnelLeadingObject>[] = [];
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];

    const { funnelsData = {}, funnels = {} } = funnelMap;
    const getLeadingObjectForFunnel = (funnelId: string) =>
      funnelsData[funnelId]?.funnelDetails.leadingObject;

    const sanitizedFunnelEntries = Object.entries(funnels).filter(
      ([funnelId]) => funnelsData[funnelId] && !funnels[funnelId].isHidden,
    );

    sanitizedFunnelEntries.forEach(([funnelId, { position }]) => {
      const funnelData: Funnel = funnelsData[funnelId];

      // Groups for the funnels
      const _plugins = funnelsData[funnelId]?.funnelDetails?.plugins || {};
      const plugins = Object.keys(_plugins) as PluginTypes[];

      if (configuringPluginType?.funnelId === funnelId) {
        plugins.push(configuringPluginType.pluginType);
      }

      const objectType = getLeadingObjectForFunnel(funnelId)?.objectName || '';
      const objectLabel = objectTypesByName[objectType]?.label ?? objectType;

      const group = {
        id: funnelId,
        name: funnelData.name,
        position: position || { column: 0, row: 0 },
        objectType,
        objectLabel,
        metadata: funnelData?.funnelDetails.leadingObject || {
          _leadingFieldId: '0',
          _leadingFieldLabels: [],
          fieldName: 'Status',
          objectName: 'Lead',
        },
        plugins,
        pills: pillsHelper.funnelPills({
          funnel: funnelData,
          renderZeroCountPills,
          highlightEntities,
          visibilityMap,
          pills,
          objectType,
          recordTypeId: getRecordTypeIdentifier(funnelData.recordType?.name, objectType),
        }),
      };
      groups.push(group);

      // Nodes for the funnel steps
      funnelData.funnelDetails.stages
        .filter((stage) => stage.stageType !== StageType.LOST)
        .forEach((stage) => {
          const _pills = pillsHelper.stepPills({
            funnelId,
            stage,
            renderZeroCountPills,
            highlightEntities,
            visibilityMap,
            pills,
          });

          const funnelDetail = funnelsData[funnelId].funnelDetails;
          const leadingObject = funnelDetail.leadingObject;

          const metadata =
            funnelStageMetadata &&
            funnelStageMetadata.find(
              (metadata) =>
                metadata.stageName === stage.stageName &&
                metadata.leadingFieldId === leadingObject._leadingFieldId,
            );

          const node: SweepCanvasNode = {
            id: stage._stageId,
            position: {
              column: stage._stageColumnIndex || 0,
              row: stage._branchIndex || 0,
            },
            name: stage.stageName,
            type:
              stage.stageType === 'nurturing'
                ? CanvasElementType.NURTURING_BUCKET
                : CanvasElementType.REGULAR,
            objectType: leadingObject.objectName as ObjectTypeValues,
            parentId: funnelId,
            metadata,
            pills: _pills,
            showSparkleIcon: stagesWithSparkleIcon?.includes(stage._stageId),
          };

          nodes.push(node);

          // Edges for the funnel steps
          stage.exitCriteria.forEach((exitCriteria) => {
            edges.push({
              id: exitCriteria._exitCriteriaId,
              source: stage._stageId,
              target: exitCriteria._nextStageId,
              data: {
                label: `${exitCriteria.criteria?.length}`,
                type: SweepCanvasReactFlowEdgeDataType.CIRCLE,
              },
            });
          });

          if (stage.funnelLinks?.length) {
            stage.funnelLinks.forEach((link) => {
              if (!link.stageId) return;
              edges.push({
                id: `${funnelId}-${stage._stageId}-${link.stageId}`,
                source: stage._stageId,
                target: link.stageId,
                data: { type: SweepCanvasReactFlowEdgeDataType.REMOVABLE, groupConnection: true },
              });
            });
          }
        });
    });

    // Transient funnel
    if (
      transientFunnel &&
      transientFunnel.type === FunnelType.SALESFORCE &&
      transientFunnel.importType === 'create'
    ) {
      const {
        name,
        data: {
          leadingObject: { _leadingFieldId, _leadingFieldLabels, fieldName, objectName },
        },
        position,
      } = transientFunnel;
      const objectLabel = objectTypesByName[objectName]?.label ?? objectName;

      groups.push({
        id: TRANSIENT_GROUP_ID,
        name,
        objectType: objectName,
        objectLabel: objectLabel,
        position: position,
        metadata: {
          _leadingFieldId,
          _leadingFieldLabels,
          fieldName,
          objectName,
        },
        plugins: [],
      });

      if (transientFunnel.data.stage) {
        const node = {
          id: transientFunnel.data.stage._stageId,
          position: { column: 0, row: 0 },
          name: transientFunnel.data.stage.stageName,
          type: CanvasElementType.REGULAR,
          objectType: objectName as ObjectTypeValues,
          parentId: TRANSIENT_GROUP_ID,
          showSparkleIcon: false,
        };
        nodes.push(node);
      }
    }
    return { nodes, edges, groups };
  }, [
    configuringPluginType?.funnelId,
    configuringPluginType?.pluginType,
    funnelMap,
    funnelStageMetadata,
    highlightEntities,
    objectTypesByName,
    pills,
    renderZeroCountPills,
    stagesWithSparkleIcon,
    transientFunnel,
    visibilityMap,
  ]);
};
