import { keyBy, uniq } from 'lodash';
import {
  CampaignFieldNamesForSfdcId,
  Campaign,
  CampaignField,
  CampaignBase,
} from './campaignTypes';
import { longCampaignFieldName, shortCampaignFieldName } from './new/helper';

const defaultFormFields = [
  'Id',
  'Name',
  'IsActive',
  'Type',
  'Status',
  'StartDate',
  'EndDate',
  'ExpectedRevenue',
  'BudgetedCost',
  'ActualCost',
  'ExpectedResponse',
  'NumberSent',
  'ParentId',
  'Description',
];

export const calculateFormFieldsBuilder = ({
  campaignObjectFields,
  campaignSfdcFields,
}: {
  campaignSfdcFields: CampaignFieldNamesForSfdcId[];
  campaignObjectFields: SweepFieldInterface[];
}) => {
  const campaignObjectFieldsById = keyBy(campaignObjectFields, 'id');
  const campaignSfdcFieldsById = keyBy(campaignSfdcFields, 'sfdcId');
  const requiredCampaignObjectFieldNames = campaignObjectFields
    .filter((field) => field.isRequired)
    .map((field) => field.id)
    .filter(Boolean) as string[];

  return (campaign: Campaign | CampaignBase) => {
    const allFieldNames = uniq(
      [
        ...defaultFormFields,
        ...(campaignSfdcFieldsById[campaign.Id]?.fieldNames || []),
        ...requiredCampaignObjectFieldNames,
      ].map(longCampaignFieldName),
    );

    // This adds the default fields
    const campaignFields = allFieldNames
      .map((fieldName) => {
        const field = campaignObjectFieldsById[longCampaignFieldName(fieldName)];

        if (!field) {
          console.error(`Field ${fieldName} not found. There was a default fieldmissing`);
          return null;
        }
        if (field.isCompound) {
          return null;
        }
        const campaignField: CampaignField = {
          name: shortCampaignFieldName(fieldName),
          isRequired: field.isRequired,
          type: field.fieldType as any,
          value: campaign[shortCampaignFieldName(fieldName)],
        };
        return campaignField;
      })
      .filter(Boolean) as CampaignField[];

    return campaignFields;
  };
};
