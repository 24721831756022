import { Box, Stack } from '@mui/material';
import { colors, Typography, Button } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import ConnectToSfDialog from '../../pages/get-started/connect-org/ConnectToSfDialog';
import WithConnectOrgPermission from '../WithConnectOrgPermission';

const AiChatNoOrgConnectedMessage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      {isDialogOpen && (
        <ConnectToSfDialog
          onFinish={closeDialog}
          closeDialog={closeDialog}
          forceProduction={false}
        />
      )}
      <Stack gap="10px">
        <Typography variant="body" color={colors.grey[800]}>
          I’d be happy to answer any question for you, but first, you need to connect Sweep to
          Salesforce.
        </Typography>
        <WithConnectOrgPermission>
          <Box>
            <Button onClick={openDialog} size="tiny" startIconName="Cloud">
              Connect
            </Button>
          </Box>
        </WithConnectOrgPermission>
      </Stack>
    </>
  );
};

export default AiChatNoOrgConnectedMessage;
