import * as React from "react";
const SvgPlusFile = (props) => /* @__PURE__ */ React.createElement("svg", { width: 48, height: 48, viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M37.5 42H10.5C10.1022 42 9.72064 41.842 9.43934 41.5607C9.15804 41.2794 9 40.8978 9 40.5V7.5C9 7.10218 9.15804 6.72064 9.43934 6.43934C9.72064 6.15804 10.1022 6 10.5 6H28.5L39 16.5V40.5C39 40.8978 38.842 41.2794 38.5607 41.5607C38.2794 41.842 37.8978 42 37.5 42Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M28.5 6V16.5H39", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M19.5 28.5H28.5", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M24 24V33", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgPlusFile;
