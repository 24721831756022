import { Box, Stack } from '@mui/material';
import { Button, IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { GroupMembersTable } from './GroupMembersTable';
import { useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { selectAssignmentGroupById } from '../../../../reducers/assignmentGroupsPageReducer';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { GroupMembersSkeletonTable } from './GroupMembersSkeletonTable';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { AssignmentGroup, AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { cloneDeep } from 'lodash';
import { SearchInput } from '../../../common/SearchInput';
import { useRunOnce } from '../../../common/useRunOnce';
import { telemetry } from '../../../../telemetry';
import { ManageMembersDialog } from '../group-settings/ManageMembersDialog';
import { GroupSettingsDialog } from '../group-settings/NewGroupSettingsDialog';
import { ChevronLeft } from '@sweep-io/sweep-design/dist/icons';
import { useRoutingContext } from '../../../routing/RoutingContext';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import { SEARCH_BOX_WIDTH } from '../../../Automations/helper';
import { HORIZONTAL_PADDING } from '../../configuration-canvas-panel/consts';
import pluralize from 'pluralize';

const SelectedGroup = ({
  onBackClick,
  groupId,
  onClose,
  isDialogLayout,
}: {
  onBackClick: () => void;
  groupId: string;
  onClose?: () => void;
  isDialogLayout?: boolean;
}) => {
  const { addNotification } = useSweepNotifications();
  const [membersDialogOpen, setMembersDialogOpen] = useState(false);
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const { selectedGroupSearch, setSelectedGroupSearch } = useRoutingContext();
  const assignmentGroupData = useSelector(selectAssignmentGroupById(groupId));
  const [isAllowedBtn] = usePermission(['edit:assignment-groups']);

  const closeSettingsDialog = useCallback(() => {
    setSettingsDialogOpen(false);
  }, []);

  const closeMembersDialog = useCallback(() => setMembersDialogOpen(false), []);

  const { getAssignmentGroupData, saveAssignmentGroupData } = useAssignmentsApiWithReducer();

  const writableAssignmentGroupData = useMemo(
    () => cloneDeep(assignmentGroupData),
    [assignmentGroupData],
  );

  const partialSave = useCallback(
    async (assignmentGroup: Partial<AssignmentGroup>, resetAllMembersLimit?: number) => {
      if (!writableAssignmentGroupData) {
        return;
      }
      try {
        await saveAssignmentGroupData(
          { ...writableAssignmentGroupData, ...assignmentGroup },
          resetAllMembersLimit,
        );
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error Saving Assignment Group',
          variant: SweepNotificationVariant.Error,
        });
      }
    },
    [addNotification, writableAssignmentGroupData, saveAssignmentGroupData],
  );

  useRunOnce(() => {
    (async function () {
      try {
        await getAssignmentGroupData(groupId);
      } catch (e) {
        telemetry.captureError(e);
        addNotification({
          message: 'Error Loading Assignment Groups',
          variant: SweepNotificationVariant.Error,
        });
      }
    })();
  });

  const additionalSx = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1,
  };

  return (
    <Stack
      sx={{
        padding: isDialogLayout ? undefined : `0 ${HORIZONTAL_PADDING}px`,
        gap: 2,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <Box display="flex" justifyContent="space-between" gap={10} alignItems="center">
        <Box display="flex" gap={1} flex={1} overflow="hidden">
          <Box display="flex" gap={0.5} alignItems="center">
            <IconButton variant="flat" onClick={onBackClick} size="tiny">
              <ChevronLeft />
            </IconButton>
            <Box sx={{ width: '40px', height: '40px' }}>
              <AvatarPicture
                dimension={40}
                avatar={writableAssignmentGroupData?.avatar}
                isInactive={false}
              />
            </Box>
          </Box>
          <Stack justifyContent="center" overflow="hidden">
            <Typography variant="h3">{writableAssignmentGroupData?.name}</Typography>
            {writableAssignmentGroupData?.description && (
              <TruncatedTextTooltip variant="body" color={colors.grey[700]}>
                {writableAssignmentGroupData?.description}
              </TruncatedTextTooltip>
            )}
          </Stack>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Box>
            <RestrictedTooltip
              to={['edit:assignment-groups']}
              notAllowedTitle={
                'To modify the settings of this assignment group, please contact your admin.'
              }
            >
              <Button
                disabled={!isAllowedBtn}
                onClick={() => setSettingsDialogOpen(true)}
                variant="outlined"
                size="small"
              >
                Group settings
              </Button>
            </RestrictedTooltip>
          </Box>
          <Box>
            <RestrictedTooltip
              to={['edit:assignment-groups']}
              notAllowedTitle={
                'To modify the settings of this assignment group, please contact your admin.'
              }
            >
              <Button
                disabled={!isAllowedBtn}
                onClick={() => setMembersDialogOpen(true)}
                size="small"
              >
                Manage members
              </Button>
            </RestrictedTooltip>
          </Box>
          {isDialogLayout && (
            <IconButton variant="flat" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          position: 'relative',
          pointerEvents: 'none',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        {!writableAssignmentGroupData && <GroupMembersSkeletonTable />}
        {writableAssignmentGroupData && (
          <>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
              height="100%"
              sx={{ pointerEvents: 'all' }}
            >
              <Box sx={additionalSx}>
                <Typography variant="body" color={colors.grey[700]}>
                  {pluralize('Group Member', writableAssignmentGroupData?.members.length, true)}
                </Typography>
                <Box width={SEARCH_BOX_WIDTH}>
                  <SearchInput
                    withFixedMagnifyingGlassIcon
                    TextFieldProps={{
                      value: selectedGroupSearch,
                      placeholder: 'Search',
                      onChange: (e) => {
                        setSelectedGroupSearch(e.target.value);
                      },
                    }}
                    onClearButtonClick={() => setSelectedGroupSearch('')}
                    variant="small"
                  />
                </Box>
              </Box>
              {writableAssignmentGroupData && (
                <GroupMembersTable
                  groupData={writableAssignmentGroupData}
                  filterFunc={(member: AssignmentGroupMember) =>
                    member.name.toLowerCase().includes(selectedGroupSearch.toLowerCase())
                  }
                  onClearFilters={() => setSelectedGroupSearch('')}
                />
              )}
            </Box>

            {writableAssignmentGroupData && membersDialogOpen && (
              <ManageMembersDialog
                assignmentGroup={writableAssignmentGroupData}
                onClose={closeMembersDialog}
                onSave={partialSave}
              />
            )}
            {writableAssignmentGroupData && settingsDialogOpen && (
              <GroupSettingsDialog
                assignmentGroup={writableAssignmentGroupData}
                onClose={closeSettingsDialog}
                onSave={partialSave}
              />
            )}
          </>
        )}
      </Box>
    </Stack>
  );
};

export default SelectedGroup;
