import { Box, Card, Stack } from '@mui/material';
import { AI_AGENTS_INFO, AiAgent } from './aiAgentsConsts';
import AgentInfoSmall from './AgentInfoSmall';
import { colors, Typography } from '@sweep-io/sweep-design';

const AgentCard = ({ agent, onClick }: { agent: AiAgent; onClick: () => void }) => {
  const { icon, persona, tags, description } = AI_AGENTS_INFO[agent.type];
  return (
    <Card onClick={onClick} sx={{ borderColor: '#E4F2F8', overflow: 'visible' }}>
      <Stack p={2} gap={2}>
        <AgentInfoSmall icon={icon} name={agent.name} persona={persona} iconSize="40px" />
        <Typography variant="body">{description}</Typography>
        <Stack direction="row" gap={1}>
          {tags.map((tag) => (
            <Box
              key={tag}
              sx={{
                padding: '0 4px',
                height: '24px',
                border: `1px solid ${colors.grey[300]}`,
                borderRadius: '2px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography variant="caption" ellipsis>
                {tag}
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Card>
  );
};

export default AgentCard;
