import { Tabs } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { CampaignFields } from '../campaignTypes';
import { EnrollmentRulesTab } from '../EnrollmentRulesTab';
import { DialogContent, DialogTitle } from '@mui/material';
import { StatusesTab } from '../StatusesTab';
import EmptyState from '../../../../../common/empty-state/EmptyState';
import CampaignMemberStatusImg from '../svg/campaign-member-status-img.svg?react';
import { EditableHubspotCampaignForm } from './EditableHubspotCampaignForm';
import { campaignFieldsToCampaign } from './helper';
import { useEditableHubspotCampaignForm } from './useEditableHubspotCampaignForm';

interface HubspotCampaignContentProps {
  initialCampaignFields?: CampaignFields;
}

const tabs = [
  {
    label: 'Campaign Settings',
    value: 'settings',
  },
  {
    label: 'Campaign Member Status',
    value: 'statuses',
  },
  {
    label: 'Enrollment Rules',
    value: 'rules',
  },
];

export const HubspotCampaignContent = ({ initialCampaignFields }: HubspotCampaignContentProps) => {
  const [tab, setTab] = useState<'settings' | 'rules' | 'statuses'>('settings');

  const {
    editingCampaign,
    handleCampaignChange,
    handleCampaignDeployCreate,
    isSaving,
    editingCampaignId,
  } = useEditableHubspotCampaignForm({
    onCampaignCreate: () => {
      setTab('statuses');
    },
    initialCampaignFields,
  });

  const _editingCampaign = campaignFieldsToCampaign(editingCampaign);

  const maybeRenderCampaignForm = () => {
    if (tab === 'settings') {
      return (
        <EditableHubspotCampaignForm
          campaignFields={editingCampaign}
          onChange={handleCampaignChange}
          isSaving={isSaving}
          onDeploy={handleCampaignDeployCreate}
        />
      );
    }
  };

  const maybeRenderStatusesTab = () => {
    if (tab === 'statuses') {
      if (!editingCampaignId) {
        return (
          <DialogContent>
            <EmptyState
              titleLine="Deploy campaign to manage statuses"
              image={<CampaignMemberStatusImg />}
              height="100%"
            />
          </DialogContent>
        );
      }

      return <StatusesTab campaignId={editingCampaignId} />;
    }
  };

  const maybeRenderRulesTab = () => {
    if (tab === 'rules') {
      return (
        <EnrollmentRulesTab
          campaignId={_editingCampaign?.Id}
          campaignName={_editingCampaign?.Name || ''}
          campaignIsActive={_editingCampaign?.IsActive}
        />
      );
    }
  };

  return (
    <>
      <DialogTitle p={3}>
        <Tabs
          tabs={tabs}
          value={tab}
          onChange={(e, tab) => {
            setTab(tab as 'settings' | 'rules');
          }}
        />
      </DialogTitle>
      {maybeRenderCampaignForm()}
      {maybeRenderStatusesTab()}
      {maybeRenderRulesTab()}
    </>
  );
};
