import { UserInfo } from '../../../types/UserInfoTypes';
import { ConfigurationItem } from '../dependencies/DependenciesTypes';
import { SWEEP_ELEMENTS } from '../dependencies/helpers';
import { ConfigurationType } from '../dependencies/types';
import { automationTypeToConfigurationType } from '../helpers';
import { AutomationStructureWithParentType, RecordTypesLabelsByNameAndObjectName } from '../types';

export const getCardListFromConfigurationItem = (
  configurationItems: ConfigurationItem[],
  parentObjectName?: string,
) => {
  return configurationItems.map((configurationItem) => {
    const referencedObjects =
      configurationItem.referencedObjects?.filter((obj) => obj !== parentObjectName) ?? [];

    return {
      id: configurationItem.id,
      dependencyType: SWEEP_ELEMENTS.includes(configurationItem.parentType as ConfigurationType)
        ? (automationTypeToConfigurationType(configurationItem.type as AutomationType) ?? '')
        : configurationItem.type,
      parentType: configurationItem.parentType as ConfigurationType,
      name: configurationItem.name,
      label: configurationItem.label,
      objectName:
        configurationItem.objectApiNames?.[0] ??
        configurationItem.objectName ??
        parentObjectName ??
        '',
      referencedObjects,
      changedAt: configurationItem.updatedAt,
      changedBy: configurationItem.updatedBy,
      isActive: !!configurationItem.isActive,
    };
  });
};

export const getCardListFromParsedRecordTypes = (
  parsedRecordTypes: ConfigurationItem[],
  parentObjectName: string,
  recordTypeNamesUsedInCanvas: RecordTypesLabelsByNameAndObjectName,
) => {
  return parsedRecordTypes.map((rt) => ({
    id: rt.id,
    parentType: ConfigurationType.recordTypes,
    dependencyType: ConfigurationType.recordTypes,
    description: rt.description,
    name: rt.name,
    label: rt.label,
    link: rt.link,
    objectName: parentObjectName,
    changedAt: rt.updatedAt,
    changedBy: rt.updatedBy,
    withHideButton: !!recordTypeNamesUsedInCanvas?.[parentObjectName]?.[rt.name],
    isActive: !!rt.isActive,
    referencedObjects: [],
  }));
};

export const getCardListFromAutomationStructure = (
  automations: AutomationStructureWithParentType[],
  users?: UserInfo[],
) => {
  return automations.map((automation) => ({
    id: automation.automationId,
    dependencyType: automation.type,
    parentType: automation.parentType,
    name: automation.name,
    label: automation.name,
    objectName: automation.objectName,
    changedAt: automation.updatedAt,
    changedBy: users?.find((user) => user.id === automation.updatedById)?.name,
    isActive: true,
  }));
};

export const getRecordTypeNames = ({
  funnelsData = {},
  recordTypesData = {},
}: {
  funnelsData: FunnelsData;
  recordTypesData: RecordTypesData;
}) => {
  const recordTypesByObjectName: RecordTypesLabelsByNameAndObjectName = {};

  Object.values(funnelsData).forEach((funnel) => {
    const { name, label } = funnel?.recordType ?? {};
    const objectName = funnel?.recordType?.objectName;
    const _label = label ?? name;

    if (objectName) {
      if (!recordTypesByObjectName[objectName]) {
        recordTypesByObjectName[objectName] = { [name]: _label };
      } else {
        recordTypesByObjectName[objectName][name] = _label;
      }
    }
  });

  Object.values(recordTypesData).forEach((rt) => {
    const { name, objectName, label } = rt ?? {};
    const _label = label ?? name;

    if (objectName) {
      if (!recordTypesByObjectName[objectName]) {
        recordTypesByObjectName[objectName] = { [name]: _label };
      } else {
        recordTypesByObjectName[objectName][name] = _label;
      }
    }
  });

  return recordTypesByObjectName;
};

export const translateSortKeyToConfigurationProp = (sortKey: string) => {
  switch (sortKey) {
    case 'status':
      return 'isActive';
    default:
      return sortKey;
  }
};
