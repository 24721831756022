import { Box, Stack, Typography } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { useRoleManagerContext } from './RoleManagerContext';
import { CreateEditDialogHeader } from '../../common/create-edit-dialog/CreateEditDialogHeader';
import {
  RoleGroupLevelType,
  RoleGroupType,
  RoleLevel,
  UserDefinedRoleGroupsInterface,
} from '@server/role-group-interface';
import { useRoleGroups } from './useRoleManager';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { useCallback } from 'react';

const createNewRole = () =>
  ({
    id: '',
    name: 'New role',
    type: RoleGroupType.USER_CREATED,
    description: '',
    roleGroupLevels: {
      type: RoleGroupLevelType.AccountBased,
      levels: {
        automations: RoleLevel.VIEWER,
        alerts: RoleLevel.VIEWER,
        assignments: RoleLevel.VIEWER,
        funnels: RoleLevel.VIEWER,
        rollups: RoleLevel.VIEWER,
        dedupMatching: RoleLevel.VIEWER,
        hubspot: RoleLevel.VIEWER,
      },
    },
  }) as Partial<UserDefinedRoleGroupsInterface>;

export const RoleManagerHeader = () => {
  const {
    goBack,
    editRole,
    hasChanges,
    headerText,
    setRoleManager,
    updateRole,
    initializeEditRole,
  } = useRoleManagerContext();

  const { isSavingUser } = useRoleGroups();
  const { openConfirm, onCancel, onConfirm } = useConfirm();

  const readonlyMode = editRole?.type === RoleGroupType.SYSTEM;

  const onDialogClose = useCallback(() => {
    goBack();
    onConfirm();
  }, [onConfirm, goBack]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      maxWidth="calc(100% - 8px)"
    >
      {!readonlyMode && editRole && (
        <CreateEditDialogHeader
          noCreateEditButtons
          value={editRole.name}
          placeholder={'Enter role name...'}
          onChange={(value) => updateRole('name', value)}
          onBackClick={async () => {
            if (!hasChanges && !isSavingUser) {
              goBack();
              return;
            }

            const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);

            if (isConfirmed) {
              onDialogClose();
            } else {
              onCancel();
            }
          }}
        />
      )}

      {(readonlyMode || !editRole) && (
        <>
          <Stack direction="row" alignItems="center" gap={1}>
            <IconButton
              variant="flat"
              onClick={() => {
                editRole && goBack();
                !editRole && setRoleManager(false);
              }}
            >
              <ArrowLeft />
            </IconButton>

            <Typography variant="h2">{headerText}</Typography>
          </Stack>

          {!editRole && (
            <Box>
              <Button
                startIconName="Plus"
                size="small"
                onClick={() =>
                  initializeEditRole(createNewRole() as UserDefinedRoleGroupsInterface)
                }
              >
                Add role
              </Button>
            </Box>
          )}
        </>
      )}
    </Stack>
  );
};
