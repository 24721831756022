import { Box, DialogContent, Grid, Stack } from '@mui/material';
import { Button, Switch, Typography } from '@sweep-io/sweep-design';
import { Fragment, useCallback, useContext } from 'react';
import { DialogHeaderTextField } from '../../../../../common/create-edit-dialog/DialogHeaderTextField';
import { HubspotCampaignCtx } from '../HubspotCampaignCtx';
import { CampaignField, CampaignFields } from '../campaignTypes';
import RestrictedTooltip from '../../../../../common/permissions/RestrictedTooltip';
import AlertPermission from '../../../../../common/AlertPermission';
import usePermission from '../../../../../common/permissions/usePermission';
import { cloneDeep, keyBy } from 'lodash';
import { campaignFieldsToCampaign, longCampaignFieldName } from './helper';
import { StyledDialogActions } from '../../../../../common/StyledDialogActions';
import { AddCampaignFieldsButton } from './AddCampaignFiedsButton';
import { SweepFieldFormElement } from './SweepFieldFormElement';

const validateCampaignFields = (campaignFields: CampaignFields) => {
  const invalidField = campaignFields.find((field) => field.isRequired && !field.value);
  return !invalidField;
};

const HubspotCampaignFormActions = ({
  campaignFields,
  onDeploy,
  isSaving = true,
  onChangeCampaignFields,
}: {
  campaignFields: CampaignFields;
  onChangeCampaignFields: (campaignFields: CampaignField[]) => any;
  onDeploy: () => any;
  isSaving?: boolean;
}) => {
  const _isSaving = Boolean(isSaving);
  const [isCreateCampaignAllowed] = usePermission(['create:campaigns']);

  const isValid = validateCampaignFields(campaignFields);

  const isDisabled = _isSaving || !isCreateCampaignAllowed;

  return (
    <>
      <AddCampaignFieldsButton campaignFields={campaignFields} onChange={onChangeCampaignFields} />

      <RestrictedTooltip
        to={['create:campaigns']}
        notAllowedTitle={'To create Hubspot campaigns, please contact your admin'}
      >
        <Button
          onClick={onDeploy}
          size="large"
          disabled={!isValid || isDisabled}
          loading={_isSaving}
        >
          Deploy to Production
        </Button>
      </RestrictedTooltip>
      {!isCreateCampaignAllowed && (
        <AlertPermission
          alertMessage={`Your account doesn't have permission to edit this campaign. Please contact your admin to get the permission.`}
          addedStyle={{
            width: '470px',
            position: 'absolute',
            right: '8px',
            top: '98px',
            zIndex: '1000',
          }}
        />
      )}
    </>
  );
};

export const EditableHubspotCampaignForm = ({
  campaignFields,
  onChange,
  isSaving = true,
  onDeploy,
}: {
  campaignFields: CampaignFields;
  onChange: (campaignFields: CampaignFields) => any;
  isSaving?: boolean;
  onDeploy: () => any;
}) => {
  const _isSaving = Boolean(isSaving);
  const { campaignObjectFields: sweepCampaignObjectFields } = useContext(HubspotCampaignCtx);
  const campaign = campaignFieldsToCampaign(campaignFields);

  const [isCreateCampaignAllowed] = usePermission(['create:campaigns']);
  const sweepCampaignObjectFieldsById = keyBy(sweepCampaignObjectFields, 'id');

  const onChangeProperty = useCallback(
    function <T extends number | string | boolean = number | string | boolean>(fieldName: string) {
      return (value: T) => {
        const fieldIdx = campaignFields.findIndex((field) => field.name === fieldName);
        if (fieldIdx === -1) {
          throw new Error('Field not found');
        }

        const newCampaign = cloneDeep(campaignFields);
        newCampaign[fieldIdx].value = value;

        onChange(newCampaign);
      };
    },
    [campaignFields, onChange],
  );
  const isDisabled = _isSaving || !isCreateCampaignAllowed;

  const renderField = (campaignField: CampaignField) => {
    const sweepField = sweepCampaignObjectFieldsById[longCampaignFieldName(campaignField.name)];
    if (!sweepField) {
      throw new Error('Field not found');
    }
    return (
      <Grid item xs={6}>
        <SweepFieldFormElement
          field={sweepField}
          onChange={({ value }) =>
            onChangeProperty(campaignField.name)(value as string | number | boolean)
          }
          isDisabled={isDisabled}
          label={sweepField.sweepFieldName || sweepField.sfFieldName || ''}
          campaignField={campaignField}
        />
      </Grid>
    );
  };
  return (
    <>
      <DialogContent>
        <Stack gap={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box pr="120px">
                <DialogHeaderTextField
                  value={campaign.Name}
                  onChange={onChangeProperty<string>('Name')}
                  placeholder="Name this campaign"
                  editDisabled={isDisabled}
                  maxLength={80}
                  typographyVariant="h3"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" gap={1}>
                <Switch
                  checked={campaign.IsActive}
                  onChange={(e, checked) => onChangeProperty('IsActive')(checked)}
                  disabled={isDisabled}
                />
                <Typography variant="body">Active</Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2.5}>
            {campaignFields
              .filter((campaignField) => !['Name', 'IsActive', 'Id'].includes(campaignField.name))
              .map((field) => (
                <Fragment key={field.name}>{renderField(field)}</Fragment>
              ))}
          </Grid>
        </Stack>
      </DialogContent>
      <StyledDialogActions sx={{ justifyContent: 'space-between' }}>
        <HubspotCampaignFormActions
          campaignFields={campaignFields}
          isSaving={isSaving}
          onDeploy={onDeploy}
          onChangeCampaignFields={onChange}
        />
      </StyledDialogActions>
    </>
  );
};
