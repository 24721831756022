import { SweepFieldTypes } from '../../../../../types/enums/SweepFieldTypes';

export interface BaseCampaignField {
  name: string;
  isRequired: boolean;
  type: SweepFieldTypes;
}

interface StringCampaignField extends BaseCampaignField {
  type:
    | SweepFieldTypes.Text
    | SweepFieldTypes.TextArea
    | SweepFieldTypes.LongTextArea
    | SweepFieldTypes.Email
    | SweepFieldTypes.Url
    | SweepFieldTypes.Picklist
    | SweepFieldTypes.MultiselectPicklist
    | SweepFieldTypes.Lookup
    | SweepFieldTypes.FormulaText
    | SweepFieldTypes.Date
    | SweepFieldTypes.DateTime
    | SweepFieldTypes.Time
    | SweepFieldTypes.FormulaDate
    | SweepFieldTypes.FormulaDateTime
    | SweepFieldTypes.FormulaTime
    | SweepFieldTypes.Summary
    | SweepFieldTypes.Hierarchy
    | SweepFieldTypes.MasterDetail
    | SweepFieldTypes.Address
    | SweepFieldTypes.Territory
    | SweepFieldTypes.Geolocation
    | SweepFieldTypes.AutoNumber
    | SweepFieldTypes.Name
    | SweepFieldTypes.Unknown
    | SweepFieldTypes.Combobox
    | SweepFieldTypes.EncryptedText
    | SweepFieldTypes.Phone;

  value: string | null;
}

export interface NumberCampaignField extends BaseCampaignField {
  type: SweepFieldTypes.Number | SweepFieldTypes.Percent | SweepFieldTypes.Currency;
  value: number | null;
}
export interface BooleanCampaignField extends BaseCampaignField {
  type: SweepFieldTypes.Checkbox | SweepFieldTypes.FormulaCheckbox;
  value: boolean | null;
}

export type CampaignField = StringCampaignField | NumberCampaignField | BooleanCampaignField;
export type CampaignFields = CampaignField[];

export interface CampaignFieldNamesForSfdcId {
  sfdcId: string;
  fieldNames: string[];
}

export interface Campaign extends CampaignBase {
  Id: string;
  LastModifiedDate: string;
  CreatedDate: string;
}
export interface CampaignBase {
  Name: string;
  ParentId?: string;
  Type?: string;
  Status?: string;
  StartDate?: string;
  EndDate?: string;
  ExpectedRevenue?: number;
  BudgetedCost?: number;
  ActualCost?: number;
  ExpectedResponse?: string;
  IsActive?: boolean;
  NumberSent?: number;
  Description?: string;
  fields?: CampaignField[];
  [key: string]: any;
}

export const CampaignKeys = [
  'ActualCost',
  'BudgetedCost',
  'Description',
  'EndDate',
  'ExpectedResponse',
  'ExpectedRevenue',
  'Id',
  'IsActive',
  'Name',
  'NumberSent',
  'ParentId',
  'StartDate',
  'Status',
  'Type',
  'CreatedDate',
  'LastModifiedDate',
  'fields',
] as const;

export type BaseCampaignMemberStatus = {
  Label: string;
  HasResponded: boolean;
  IsDefault: boolean;
  CampaignId: string;
};

export interface CampaignMemberStatus extends BaseCampaignMemberStatus {
  Id: string;
}
