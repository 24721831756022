import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { Switch } from '@sweep-io/sweep-design';
import SweepSelect from '../../../../../common/SweepSelect';
import { DateSelector } from '../../../../../common/rule-builder/selectors/DateSelector';
import { TextFieldSelector } from '../../../../../common/rule-builder/selectors/TextFieldSelector';

import { selectCampaigns } from '../../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { CampaignField } from '../campaignTypes';
import { SweepFieldTypes } from '../../../../../../types/enums/SweepFieldTypes';
import { SearchSelect } from '../../../../../common/search-select/SearchSelect';
import { InlineAutocomplete } from '../../../../../common/InlineAutocomplete/InlineAutocomplete';
import FieldLabel from '../../../../../common/FieldLabel';

const SfCampaignSearchSelect = ({
  campaignId,
  onChange,
  id,
  disabled,
}: {
  id?: string;
  campaignId?: string;
  onChange: (campaignId: string) => void;
  disabled?: boolean;
}) => {
  const sfCampaigns = useSelector(selectCampaigns);

  return (
    <SearchSelect
      id={id}
      label="Parent Campaign"
      items={(sfCampaigns || [])?.map((campaign) => ({
        label: campaign.Name,
        value: campaign.Id,
      }))}
      onChange={(item) => {
        onChange(item.value);
      }}
      SelectProps={{
        value: campaignId,
        placeholder: 'Choose Campaign',
      }}
      FormControlProps={{ fullWidth: true }}
      valueRenderer={(item) => item?.label}
      disabled={disabled}
    />
  );
};

export const SweepFieldFormElement = ({
  campaignField,
  onChange,
  isDisabled,
  label,
  id,
  field,
}: {
  field: SweepFieldInterface;
  campaignField: CampaignField;
  isDisabled?: boolean;
  onChange: (newCampaignField: CampaignField) => void;
  label: string;
  id?: string;
}) => {
  const { value, name: fieldName, type } = campaignField;

  switch (type) {
    case SweepFieldTypes.Lookup: {
      if (fieldName === 'ParentId') {
        return (
          <SfCampaignSearchSelect
            id="hubspot-campaign-parent"
            campaignId={value as string}
            onChange={(campaignId) => {
              onChange({ ...campaignField, value: campaignId });
            }}
            disabled={isDisabled}
          />
        );
      }
      return (
        <TextFieldSelector
          id={id}
          shouldBeDisabled={isDisabled}
          onChange={(data) => onChange({ ...campaignField, value: data })}
          label={label}
          fullWidth
          initialValue={value}
          isRequired={field.isRequired}
        />
      );
      break;
    }

    case SweepFieldTypes.LongTextArea:
    case SweepFieldTypes.TextArea:
    case SweepFieldTypes.Text:
      const multiline = [SweepFieldTypes.LongTextArea, SweepFieldTypes.TextArea].includes(type);
      return (
        <TextFieldSelector
          id={id}
          shouldBeDisabled={isDisabled}
          onChange={(data) => onChange({ ...campaignField, value: data })}
          label={label}
          fullWidth
          initialValue={value}
          InputProps={{
            multiline,
          }}
          isRequired={field.isRequired}
        />
      );
    case SweepFieldTypes.Percent:
    case SweepFieldTypes.Currency:
    case SweepFieldTypes.Number:
      return (
        <TextFieldSelector
          id={id}
          shouldBeDisabled={isDisabled}
          onChange={(data) => onChange({ ...campaignField, value: data })}
          selectorType="number"
          label={label}
          fullWidth
          initialValue={value}
          isRequired={field.isRequired}
        />
      );
    case SweepFieldTypes.Date:
      return (
        <DateSelector
          initialValue={value || undefined}
          onChange={(newValue) => onChange({ ...campaignField, value: newValue })}
          shouldBeDisabled={isDisabled}
          label={label}
          isRequired={field.isRequired}
        />
      );
    case SweepFieldTypes.Checkbox:
      return (
        <Box display="flex" gap={1} alignItems={'center'}>
          <Switch
            checked={value || undefined}
            onChange={(e, checked) => onChange({ ...campaignField, value: checked })}
            disabled={isDisabled}
          />
          <FieldLabel label={label} id={id} markLabelAsRequired={field.isRequired} />
        </Box>
      );
    case SweepFieldTypes.Picklist:
      return (
        <SweepSelect
          label={label}
          FormControlProps={{
            fullWidth: true,
          }}
          SelectProps={{
            placeholder: label,
            value,
            onChange: (event: SelectChangeEvent<unknown>) =>
              onChange({ ...campaignField, value: event.target.value as string }),
          }}
          disabled={isDisabled}
          markLabelAsRequired={field.isRequired}
        >
          {field.properties.valueSet?.map((option) => (
            <MenuItem key={option.fullName} value={option.fullName}>
              {option.label}
            </MenuItem>
          ))}
        </SweepSelect>
      );
      break;

    case SweepFieldTypes.MultiselectPicklist: {
      const existingItems: string[] = (value || '').split(';');

      return (
        <Box>
          <FieldLabel label={label} id={id} markLabelAsRequired={field.isRequired} />
          <InlineAutocomplete
            items={
              field.properties.valueSet?.map((v) => ({
                label: v.label,
                value: v.fullName,
              })) || []
            }
            selectedItemValues={existingItems}
            headerTxt={''}
            onSelectItem={(item) => {
              onChange({ ...campaignField, value: [...existingItems, item.value].join(';') });
            }}
            onDeleteItem={(item) => {
              onChange({
                ...campaignField,
                value: existingItems.filter((i) => i !== item.value).join(';'),
              });
            }}
            disableCreate
            showBorder
            fullWidth
          />
        </Box>
      );
    }

    default:
      return (
        <TextFieldSelector
          id={id}
          shouldBeDisabled={isDisabled}
          onChange={(data) => onChange({ ...campaignField, value: data })}
          label={label}
          fullWidth
          initialValue={value}
          isRequired={field.isRequired}
        />
      );
  }
  return null;
};
