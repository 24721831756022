import type { Dictionary } from 'lodash';
import { SweepCanvasNodePosition } from '../helpers/calculateHandlePositionsBasedOnCoords';
import { DropInvalidReason } from '../useCalculateDragAndDrop';
import { SweepCanvasReactFlowEdgeDataType } from '../edges/SweepCanvasReactFlowEdgeDataType';
import { EditableLabelValidationFunction } from '../../common/EditableLabel';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import { ContextZoomType } from '../SweepCanvas';
import { LogoDevBrand } from '../../../apis/facades/useLogoDevApiFacade';
export enum NodeEntityTypes {
  Step = 'Step',
  StepPill = 'StepPill',
  Group = 'Group',
  GroupPill = 'GroupPill',
  GroupLabel = 'GroupLabel',
  GhostStep = 'GhostStep',
  GhostStepButton = 'GhostStepButton',
  GroupEditButton = 'GroupEditButton',
  GroupOverlay = 'GroupOverlay',
}

interface NodeEntity {
  type: NodeEntityTypes;
}

export interface StepEntityType extends NodeEntity {
  type: NodeEntityTypes.Step;
}
export interface StepPillEntityType extends NodeEntity {
  type: NodeEntityTypes.StepPill;
  canvasPillType: CanvasPillTypes;
}

export interface GroupPillEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupPill;
  canvasPillType: CanvasPillTypes;
}

export interface GroupLabelEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupLabel;
}
export interface GhostStepEntityType extends NodeEntity {
  type: NodeEntityTypes.GhostStep;
}

export interface GhostStepButtonEntityType extends NodeEntity {
  type: NodeEntityTypes.GhostStepButton;
}

export interface GroupEntityType extends NodeEntity {
  type: NodeEntityTypes.Group;
}

export interface GroupEditButtonEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupEditButton;
}

export interface GroupOverlayEntityType extends NodeEntity {
  type: NodeEntityTypes.GroupOverlay;
}

export type SweepCanvasEntities =
  | StepEntityType
  | StepPillEntityType
  | GroupPillEntityType
  | GroupLabelEntityType
  | GhostStepEntityType
  | GhostStepButtonEntityType
  | GroupEntityType
  | GroupEditButtonEntityType
  | GroupOverlayEntityType;

export interface OnNodeClickProps {
  id: string;
  parentId?: string;
  entity: SweepCanvasEntities;
  event: React.MouseEvent;
}

export enum CanvasElementType {
  REGULAR = 'REGULAR',
  EDIT = 'EDIT',
  EDIT_NB = 'EDIT_NB',
  NURTURING_BUCKET = 'NURTURING_BUCKET',
  DROP_ZONE_NODE = 'DROP_ZONE_NODE',
  GROUP_DROP_ZONE_NODE = 'GROUP_DROP_ZONE_NODE',
  GROUP = 'GROUP',
  GROUP_LABEL = 'GROUP_LABEL',
  GROUP_OVERLAY = 'GROUP_OVERLAY',
  GHOST_NODE = 'GHOST_NODE',
}

export enum CanvasEdgeConnectionType {
  Bezier = 'Bezier',
  HorizontalDetour = 'HorizontalDetour',
  VerticalDetour = 'VerticalDetour',
}

export enum CanvasPillTypes {
  automation = 'automation',
  playbookAlert = 'playbookAlert',
  assignmentRule = 'assignmentRule',
  scheduledAssignment = 'scheduledAssignment',
  validationRule = 'validationRule',
  sfdcAutomation = 'sfdcAutomation',
  apexTrigger = 'apexTrigger',
  groupSfdcAutomation = 'groupSfdcAutomation',
  groupValidationRule = 'groupValidationRule',
  groupApex = 'groupApex',
  groupAutomation = 'groupAutomation',
  groupDeduplication = 'groupDeduplication',
  groupMatching = 'groupMatching',
  groupAssignmentRule = 'groupAssignmentRule',
  groupPlaybookAlert = 'groupPlaybookAlert',
  groupAggregated = 'groupAggregated',
  groupScheduledAssignment = 'groupScheduledAssignment',
  groupHubspotEnrollment = 'groupHubspotEnrollment',
}

export interface CanvasPill {
  type: CanvasPillTypes;
  amount: number;
  highlight?: boolean;
}

export interface SweepCanvasNode {
  id: string;
  position: SweepCanvasNodePosition;
  parentId?: string;
  type?: CanvasElementType;
  name: string;
  objectType: ObjectTypeValues;
  invalidDropReason?: DropInvalidReason;
  originStepName?: string;
  metadata?: StageMetadata;
  halfNode?: boolean;
  pills?: CanvasPill[];
  hideNbButton?: boolean;
  showSparkleIcon?: boolean;
}

export interface GhostNodeData {
  name: string;
  objectType: ObjectTypeValues;
  halfNode?: boolean;
  parentId: string;
  onNodeClick?: (props: OnNodeClickProps) => any;
  showConnectButton: boolean;
}

export interface RFNodeData {
  name: string;
  type?: string;
  objectType: ObjectTypeValues;
  onAddNodeClick?: (id: string, branchType: 'branch' | 'nb') => any;
  onAddSourceClick: (id: string) => any;
  onAddTargetClick: (id: string) => any;
  onEditNameConfirm?: (id: string, nodeName: string) => any;
  onEditNameCancel?: (id: string, parentId: string, isConfirmed?: boolean) => any;
  editNameValidateClbk: (parentId: string) => EditableLabelValidationFunction | null;
  onNodeClick?: (props: OnNodeClickProps) => any;
  onPillClick?: (props: OnNodeClickProps) => any;
  onConnectClick?: (id: string, parentId: string, evt: React.MouseEvent<HTMLButtonElement>) => any;
  readonly?: boolean;
  dropInvalidReason?: DropInvalidReason;
  isHighlighted?: boolean;
  showButtonsOnHighlight?: boolean;
  hasSourceButton: boolean;
  hasTargetButton: boolean;
  sweepAutomationsAmount?: number;
  sweepPlaybookAlertsAmount?: number;
  sweepAssignmentRulesAmount?: number;
  validationRulesAmount?: number;
  sfdcAutomationsAmount?: number;
  apexTriggersAmount?: number;
  isInGroupMouseMoveMode: boolean;
  metadata?: StageMetadata;
  holdNodeHighlighted?: boolean;
  parentId: string;
  halfNode?: boolean;
  mapForecastCategoryValueToLabel?: (value: string) => string;
  pills?: CanvasPill[];
  disableStepClick?: boolean;
  contextZoomType?: ContextZoomType;
  visibilityMap: VisibilityMap;
  hideNbButton?: boolean;
  showSparkleIcon?: boolean;
}

export interface SweepCanvasGroup<T = any> {
  id: string;
  position: SweepCanvasNodePosition;
  name: string;
  objectType: string;
  objectLabel: string;
  objectLabelLogoDevBrand?: LogoDevBrand;
  description?: string;
  metadata?: T;
  plugins?: PluginTypes[];
  pills?: CanvasPill[];
  showEditButton?: boolean;
  startingIcon?: 'hubspot';
}

export interface SweepCanvasEdgeData {
  type: SweepCanvasReactFlowEdgeDataType;
  label?: string;
  connectionType?: CanvasEdgeConnectionType;
  connectionIdx?: number;
  groupConnection?: boolean;
}

export interface SweepCanvasEdge {
  id: string;
  source: string;
  target: string;
  data: SweepCanvasEdgeData;
}

export interface NodeChangeMoveNode {
  parentId?: string;
  nodeId: string;
  oldPosition: SweepCanvasNodePosition;
  newPosition: SweepCanvasNodePosition;
}

export interface NodeChangeMoveNodeType {
  nodesToMove: NodeChangeMoveNode[];
}

export interface NodeChangeMoveGroupType {
  groupsToMove: NodeChangeMoveNode[];
}

export interface NodeChangeEscapeFromShadowStepType {
  nodeId: string;
  parentId: string;
}

export interface NodeChangeRemoveGroupType {
  groupId: string;
}

export type SweepCanvasEdgeTransformations = Dictionary<SweepCanvasEdge>;
export type SweepCanvasNodeTransformations = Dictionary<SweepCanvasNode>;
export type SweepCanvasGroupTransformations = Dictionary<SweepCanvasGroup>;

export enum CanvasMode {
  DEFAULT = 'DEFAULT',
  PREVIEW1 = 'PREVIEW1', //basic step view structure (eg. no funnel/step name displayed)
  PREVIEW2 = 'PREVIEW2', //detailed step information (eg. with step name displayed) with zoom controls
  PREVIEW3 = 'PREVIEW3', //detailed step information (eg. with step name displayed) without zoom controls
  FIT_NODES_PREVIEW = 'FIT_NODES_PREVIEW',
}

export const DEFAULT_CANVAS_ZOOM = 1;
export const DEFAULT_CANVAS_MIN_ZOOM = 0.1;
export const DEFAULT_PREVIEW_MIN_ZOOM = 0.01;
