import { useCallback, useContext, useEffect, useState } from 'react';
import { HubspotCampaignCtx } from '../HubspotCampaignCtx';
import { CampaignFields } from '../campaignTypes';
import { isEqual } from 'lodash';
import { getCampaignFieldValue } from './helper';
import useSendBiEvent from '../../../../../../hooks/useSendBiEvent';
import { SweepNotificationVariant } from '../../../../../../reducers/notificationsReducer';
import { ACTIONS_EVENTS } from '../../../../../../services/events';
import { telemetry } from '../../../../../../telemetry';
import { useSweepNotifications } from '../../../../../notifications/useSweepNotifications';
import { useCampaigns } from '../useCampaigns';
import { calculateFormFieldsBuilder } from '../useCalculateCampaignFormFields';
import {
  selectCampaignFieldNamesData,
  selectCampaignObjectFieldsData,
} from '../../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';

export const useEditableHubspotCampaignForm = ({
  onCampaignCreate,
  initialCampaignFields,
}: {
  initialCampaignFields?: CampaignFields;
  onCampaignCreate: () => void;
}) => {
  const campaignObjectFields = useSelector(selectCampaignObjectFieldsData);
  const campaignSfdcFields = useSelector(selectCampaignFieldNamesData);

  const [lastSavedCampaign, setLastSavedCampaign] = useState<CampaignFields>(() => {
    return (
      initialCampaignFields ||
      calculateFormFieldsBuilder({
        campaignObjectFields,
        campaignSfdcFields,
      })({ Name: '' })
    );
  });
  const [editingCampaign, setEditingCampaign] = useState<CampaignFields>(lastSavedCampaign);
  const editingCampaignId = getCampaignFieldValue<string>(editingCampaign, 'Id');

  const { addNotification } = useSweepNotifications();
  const sendBiEvent = useSendBiEvent();
  const { createCampaign, updateCampaign } = useCampaigns();

  const [isSaving, setIsSaving] = useState(false);

  const { setIsPristine } = useContext(HubspotCampaignCtx);

  useEffect(
    () => setIsPristine(isEqual(editingCampaign, lastSavedCampaign)),
    [editingCampaign, lastSavedCampaign, setIsPristine],
  );

  const handleCampaignChange = useCallback((newCampaignFields: CampaignFields) => {
    setEditingCampaign(newCampaignFields);
  }, []);

  const handleCampaignDeployCreate = useCallback(async () => {
    setIsSaving(true);
    let savedCampaign: CampaignFields;
    const name = getCampaignFieldValue<string>(editingCampaign, 'Name');
    if (!name) {
      throw new Error('Campaign name is required');
    }
    try {
      const _editingCampaign = editingCampaign.filter(
        (field) => field.value !== null && field.value !== undefined,
      );
      if (editingCampaignId) {
        await updateCampaign(editingCampaignId, {
          Name: name,
          fields: _editingCampaign,
        });

        savedCampaign = [...editingCampaign];
      } else {
        const createdCampaign = await createCampaign({
          Name: name,
          fields: _editingCampaign,
        });

        savedCampaign = [...editingCampaign];
        const idIndex = savedCampaign.findIndex((field) => field.name === 'Id');
        if (idIndex !== -1) {
          savedCampaign[idIndex].value = createdCampaign.Id;
        } else {
          throw new Error('Id field is missing');
        }
        onCampaignCreate();
      }
      setLastSavedCampaign(savedCampaign);
      setEditingCampaign(savedCampaign);

      sendBiEvent({ name: ACTIONS_EVENTS.salesforceCampaignDeploySuccess });
    } catch (error: any) {
      telemetry.captureError('Error saving campaign', error);
      addNotification({
        message: `Fail to save campaign`,
        keepOpen: true,
        details: error.response.data.message,
        variant: SweepNotificationVariant.Error,
      });
    }

    setIsSaving(false);
  }, [
    addNotification,
    createCampaign,
    editingCampaign,
    editingCampaignId,
    onCampaignCreate,
    sendBiEvent,
    updateCampaign,
  ]);

  return {
    editingCampaign,
    handleCampaignChange,
    handleCampaignDeployCreate,
    isSaving,
    editingCampaignId,
  };
};
