import { useMemo } from 'react';
import { useCampaigns } from './campaign-dialog/useCampaigns';
import { useSelector } from 'react-redux';
import { selectCampaignObjectFieldsData } from '../../../../reducers/hubspotReducer';
import { useRunOnce } from '../../../common/useRunOnce';

type Options = {
  name: string;
  label: string;
}[];

export const useLoadSfCampaignFieldsForHubspot = () => {
  const { getCampaignObjectFields } = useCampaigns();

  const campaignObjectFields = useSelector(selectCampaignObjectFieldsData);

  useRunOnce(() => {
    getCampaignObjectFields();
  });

  const { campaignStatusOptions, campaignTypeOptions } = useMemo(() => {
    const campaignTypeField = campaignObjectFields.find((field) => field.id === 'Campaign.Type');
    const campaignStatusField = campaignObjectFields.find(
      (field) => field.id === 'Campaign.Status',
    );

    let campaignTypeOptions: Options = [];

    if (campaignTypeField?.properties?.valueSet) {
      campaignTypeOptions = campaignTypeField.properties.valueSet.map(({ label, fullName }) => ({
        name: fullName,
        label,
      }));
    }

    let campaignStatusOptions: Options = [];
    if (campaignStatusField?.properties?.valueSet) {
      campaignStatusOptions = campaignStatusField.properties.valueSet.map(
        ({ label, fullName }) => ({
          name: fullName,
          label,
        }),
      );
    }

    return {
      campaignTypeOptions,
      campaignStatusOptions,
    };
  }, [campaignObjectFields]);

  return { campaignTypeOptions, campaignStatusOptions, campaignObjectFields };
};
