import { Campaign, CampaignFields } from '../campaignTypes';

export const shortCampaignFieldName = (fieldName: string = '') =>
  fieldName.replace('Campaign.', '');

export const longCampaignFieldName = (fieldName: string = '') =>
  `Campaign.${shortCampaignFieldName(fieldName)}`;

export const campaignFieldsToCampaign = (fields: CampaignFields) => {
  return fields.reduce((acc, field) => {
    acc[shortCampaignFieldName(field.name)] = field.value;
    return acc;
  }, {} as Campaign);
};

export function getCampaignField(campaignFields: CampaignFields, field: string) {
  const campaignField = campaignFields.find((f) => f.name === field);
  return campaignField;
}

export function getCampaignFieldValue<
  T extends number | string | boolean = number | string | boolean,
>(campaignFields: CampaignFields, field: string) {
  const campaignField = getCampaignField(campaignFields, field);
  return campaignField?.value as T | undefined;
}
