import { Box, Stack } from '@mui/material';
import { colors, Typography, Button } from '@sweep-io/sweep-design';
import { useState } from 'react';
import PinkButton from '../../dashboard/paywall/PinkButton';
import ArrowRightSmall from './arrowRightSmall.svg?react';
import { telemetry } from '../../../telemetry';
import { PaywallSource } from '../../dashboard/paywall/paywallTypes';
import usePaywall, { PaywallAction } from '../../dashboard/paywall/usePaywall';
import { useRunOnceWhenTruthy } from '../useRunOnceWhenTruthy';

const AiChatPaywallBlockMessage = () => {
  const { paywallAction, openIntercomForUpgrade, startTrial, sendEncounterPaywallEvent, ctaCallback } =
    usePaywall();
  const [isLoading, setIsLoading] = useState(false);

  useRunOnceWhenTruthy(() => {
    sendEncounterPaywallEvent({
      source: PaywallSource.AI_CHAT,
    });
  }, paywallAction !== PaywallAction.NONE);

  const onActivateClick = async () => {
    setIsLoading(true);
    try {
      await startTrial(PaywallSource.AI_CHAT);
    } catch (e) {
      telemetry.captureError(e);
    }
    setIsLoading(false);
  };

  //TODO change to !isPaywallActive SWEEP-5282
  if (!paywallAction || paywallAction === PaywallAction.NONE) {
    return null;
  }

  //TODO change to isPaywallActive SWEEP-5282
  if (paywallAction === PaywallAction.TALK_TO_SALES) {
    return (
      <Stack gap="10px">
        <Typography variant="body" color={colors.grey[800]}>
          To get answers from Sweep’s AI Assistant, activate your free trial now.
        </Typography>
        <Box>
          <Button
            onClick={() => ctaCallback(PaywallSource.AI_CHAT)}
            size="small"
          >
            <Box display="flex" alignItems="center" gap="6px">
              <Typography variant="caption-bold">Talk to us</Typography>
              <ArrowRightSmall />
            </Box>
          </Button>
        </Box>
      </Stack>
    );
  }

  const isStartTrial = paywallAction === PaywallAction.START_TRIAL;
  const text = isStartTrial
    ? `I'd be happy to answer that for you, but first, you need to activate your free trial.`
    : `I'd be happy to answer that for you, but first, you need to upgrade.`;

  return (
    <Stack gap="10px" p={1.5}>
      <Typography variant="body" color={colors.grey[800]}>
        {text}
      </Typography>
      <PinkButton
        onClick={isStartTrial ? onActivateClick : openIntercomForUpgrade}
        loading={isLoading}
        size="tiny"
      >
        <Box display="flex" alignItems="center" gap="6px">
          <Typography variant="caption-bold">
            {isStartTrial ? 'Start free trial' : 'Upgrade'}
          </Typography>
          <ArrowRightSmall />
        </Box>
      </PinkButton>
    </Stack>
  );
};

export default AiChatPaywallBlockMessage;
