import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { selectShouldRefresh } from '../../reducers/global/globalReducer';
import { useCallback, useEffect } from 'react';
import { useFetchGlobal } from './useFetchGlobal';
import { useParams } from 'react-router';

const useGlobalDataFetcherListener = () => {
  const { crmOrgId: paramsCrmOrgId } = useParams<{ crmOrgId: string }>();
  const currentDefaultCreationEnvId = useSelector(selectDefaultCreationEnvironment)?.id;
  const crmOrgId = paramsCrmOrgId ?? currentDefaultCreationEnvId;
  const shouldRefresh = useSelector(selectShouldRefresh(crmOrgId ?? ''));
  const { fetchGlobal } = useFetchGlobal();

  const fetchData = useCallback(
    (crmOrgId: string) => {
      fetchGlobal({ crmOrgId });
    },
    //fetchGlobal has to be in the dep array, or it is called with outdated properties (SWEEP-4670)
    [fetchGlobal],
  );

  //run on mount and every crmOrgId change
  useEffect(() => {
    if (crmOrgId) {
      fetchData(crmOrgId);
    }
  }, [crmOrgId, fetchData]);

  //run on every shouldRefresh
  useEffect(() => {
    if (crmOrgId && shouldRefresh) {
      fetchData(crmOrgId);
    }
  }, [crmOrgId, shouldRefresh, fetchData]);
};

export default useGlobalDataFetcherListener;
