import { Button } from '@sweep-io/sweep-design';
import { styled } from '@mui/material';
import { AgentFileInvalid, NewAgentFile } from '../aiAgentsConsts';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { uniqueId } from '../../../lib/uniqueId';
import { AgentFileAllowedExtension } from '@server/ai';
import { humanizeFileSize } from '../../helpers/humanizeFileSize';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const MAX_FILE_SIZE = 100 * 1024; // 100KB

const readFileContent = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => resolve(event.target?.result);
    reader.onerror = (error) => reject(error);
    reader.readAsText(file);
  });
};

const FilesUploadButton = ({
  startIconName,
  onAddValidFiles,
  onAddInvalidFiles,
}: {
  startIconName: string;
  onAddValidFiles: (agentFiles: NewAgentFile[]) => void;
  onAddInvalidFiles: (agentFiles: AgentFileInvalid[]) => void;
}) => {
  const user = useSelector(selectUserInfoData);
  const onAdd = async (fileList: FileList | null) => {
    if (!fileList) {
      return;
    }
    const files = Array.from(fileList);
    const validFiles = files.filter((file) => file.size <= MAX_FILE_SIZE);
    const invalidFiles = files.filter((file) => file.size > MAX_FILE_SIZE);

    const validFilesContents = await Promise.all(validFiles.map(readFileContent));

    const agentFilesValid: NewAgentFile[] = validFiles.map((file, index) => {
      const pointIndex = file.name.lastIndexOf('.');
      const fileName = pointIndex !== -1 ? file.name.slice(0, pointIndex) : file.name;
      const fileType = pointIndex !== -1 ? file.name.slice(pointIndex + 1) : '';
      return {
        name: fileName,
        type: fileType as AgentFileAllowedExtension,
        createdAt: new Date().toISOString(),
        createdById: user?.id ?? '',
        content: typeof validFilesContents[index] === 'string' ? validFilesContents[index] : '',
      };
    });

    const agentFilesInvalid: AgentFileInvalid[] = invalidFiles.map((file) => {
      const pointIndex = file.name.lastIndexOf('.');
      const fileName = pointIndex !== -1 ? file.name.slice(0, pointIndex) : file.name;
      return {
        id: uniqueId(),
        name: fileName,
        message: `File upload failed. The file size must not exceed ${humanizeFileSize(MAX_FILE_SIZE)}.`,
      };
    });
    if (agentFilesValid.length > 0) {
      onAddValidFiles(agentFilesValid);
    }
    if (agentFilesInvalid.length > 0) {
      onAddInvalidFiles(agentFilesInvalid);
    }
  };

  const supportedExtensions = Object.values(AgentFileAllowedExtension)
    .map((ext) => '.' + ext)
    .join(',');

  return (
    // @ts-ignore  TODO add "files upload button" to the design system
    <Button startIconName={startIconName} component="label">
      Add files
      <VisuallyHiddenInput
        type="file"
        accept={supportedExtensions}
        multiple
        onChange={(e) => onAdd(e.target.files)}
      />
    </Button>
  );
};

export default FilesUploadButton;
