import Box from '@mui/material/Box';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import Markdown from 'react-markdown';

export const MarkdownWrapper = ({
  content,
  component = 'div',
  color,
  removeMargins,
}: {
  content: ReactNode;
  component?: 'div' | 'span';
  color?: string;
  removeMargins: boolean; //margins are not needed in case there's no background color
}) => {
  return (
    <Box
      component={component}
      sx={{
        color: color ?? colors.grey[800],
        fontFamily: "'Inter', sans-serif",
        '&>*:first-child': {
          marginTop: removeMargins ? 0 : undefined,
        },
        '&>*:last-child': {
          marginBottom: removeMargins ? 0 : undefined,
        },
        '& code': {
          wordBreak: 'break-word',
          background: colors.grey[200],
        },
        '& p, & a ': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
          wordBreak: 'break-word',
        },
        '& em, & hr, & pre,  & li, & ol, & ul': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
          wordBreak: 'break-word',
        },
        '& strong': {
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body-bold']?.fontWeight,
        },
        '& h1': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1-bold']?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1-bold']?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1-bold']?.fontWeight,
        },
        '& h2': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h2-bold']?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h2-bold']?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h2-bold']?.fontWeight,
        },
        '& h3': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontWeight,
        },
        '& h4': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontWeight,
        },
        '& h5': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.h4?.fontWeight,
        },
        '& h6': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontSize,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.lineHeight,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontWeight,
        },
      }}
    >
      {typeof content === 'string' && <Markdown children={content} />}
      {typeof content !== 'string' && <>{content}</>}
    </Box>
  );
};
