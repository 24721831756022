import { Tabs } from '@sweep-io/sweep-design';
import { useCallback, useContext, useEffect, useState } from 'react';
import { HubspotCampaignForm } from './HubspotCampaignForm';
import { CampaignBase, Campaign } from './campaignTypes';
import { EnrollmentRulesTab } from './EnrollmentRulesTab';
import { useCampaigns } from './useCampaigns';
import { isEqual } from 'lodash';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';
import { telemetry } from '../../../../../telemetry';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { DialogContent, DialogTitle } from '@mui/material';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { ACTIONS_EVENTS } from '../../../../../services/events';
import { StatusesTab } from './StatusesTab';
import EmptyState from '../../../../common/empty-state/EmptyState';
import CampaignMemberStatusImg from './svg/campaign-member-status-img.svg?react';

interface HubspotCampaignContentProps {
  initialCampaign?: FormCampaign;
}

const emptyCampaign: CampaignBase = {
  Name: '',
};

const tabs = [
  {
    label: 'Campaign Settings',
    value: 'settings',
  },
  {
    label: 'Campaign Member Status',
    value: 'statuses',
  },
  {
    label: 'Enrollment Rules',
    value: 'rules',
  },
];

export type FormCampaign = Campaign | (CampaignBase & { Id?: string });

export const HubspotCampaignContent = ({ initialCampaign }: HubspotCampaignContentProps) => {
  const [lastSavedCampaign, setLastSavedCampaign] = useState<FormCampaign>(
    initialCampaign || emptyCampaign,
  );
  const [editingCampaign, setEditingCampaign] = useState<FormCampaign>(lastSavedCampaign);

  const { addNotification } = useSweepNotifications();
  const sendBiEvent = useSendBiEvent();
  const { createCampaign, updateCampaign } = useCampaigns();
  const [tab, setTab] = useState<'settings' | 'rules' | 'statuses'>('settings');

  const [isSaving, setIsSaving] = useState(false);

  const { setIsPristine } = useContext(HubspotCampaignCtx);

  useEffect(
    () => setIsPristine(isEqual(editingCampaign, lastSavedCampaign)),
    [editingCampaign, lastSavedCampaign, setIsPristine],
  );

  const handleCampaignChange = useCallback((partialCampaign: Partial<CampaignBase>) => {
    setEditingCampaign((workflow) => ({ ...workflow, ...partialCampaign }));
  }, []);

  const handleCampaignDeployCreate = useCallback(async () => {
    setIsSaving(true);
    let savedCampaign: Campaign;
    try {
      if (editingCampaign.Id) {
        await updateCampaign(editingCampaign.Id, editingCampaign as Campaign);
        savedCampaign = editingCampaign as Campaign;
      } else {
        savedCampaign = await createCampaign(editingCampaign);

        setTab('statuses');
      }
      setLastSavedCampaign(savedCampaign);
      setEditingCampaign(savedCampaign);

      sendBiEvent({ name: ACTIONS_EVENTS.salesforceCampaignDeploySuccess });
    } catch (error: any) {
      telemetry.captureError('Error saving campaign', error);
      addNotification({
        message: `Fail to save campaign`,
        keepOpen: true,
        details: error.response.data.message,
        variant: SweepNotificationVariant.Error,
      });
    }

    setIsSaving(false);
  }, [editingCampaign, sendBiEvent, updateCampaign, createCampaign, addNotification]);

  const maybeRenderCampaignForm = () => {
    if (tab === 'settings') {
      return (
        <HubspotCampaignForm
          campaign={editingCampaign}
          onChange={handleCampaignChange}
          isSaving={isSaving}
          onDeploy={handleCampaignDeployCreate}
        />
      );
    }
  };

  const maybeRenderStatusesTab = () => {
    if (tab === 'statuses') {
      if (!editingCampaign.Id) {
        return (
          <DialogContent>
            <EmptyState
              titleLine="Deploy campaign to manage statuses"
              image={<CampaignMemberStatusImg />}
              height="100%"
            />
          </DialogContent>
        );
      }

      return <StatusesTab campaignId={editingCampaign.Id} />;
    }
  };

  const maybeRenderRulesTab = () => {
    if (tab === 'rules') {
      return (
        <EnrollmentRulesTab
          campaignId={editingCampaign?.Id}
          campaignName={editingCampaign?.Name || ''}
          campaignIsActive={editingCampaign?.IsActive}
        />
      );
    }
  };

  return (
    <>
      <DialogTitle p={3}>
        <Tabs
          tabs={tabs}
          value={tab}
          onChange={(e, tab) => {
            setTab(tab as 'settings' | 'rules');
          }}
        />
      </DialogTitle>
      {maybeRenderCampaignForm()}
      {maybeRenderStatusesTab()}
      {maybeRenderRulesTab()}
    </>
  );
};
