import { ListSubheader, styled } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

export const SearchSelectListSubheader = styled(ListSubheader)(() => ({
  display: 'flex',
  alignItems: 'center',
  lineHeight: 1,
  borderColor: colors.grey[300],
  margin: '0px -8px',
  padding: '14px 15px 15px',
  position: 'sticky',
  gap: '12px',
}));
