import { Menu, MenuItem } from '@mui/material';
import { AiAgent } from '../../../../aiAgentCenter/aiAgentsConsts';
import useAiAgentChats from '../../../../aiAgentCenter/useAiAgentChats';

const AiAgentsMenu = ({
  isOpen,
  aiAgents,
  anchorElement,
  closeMenu,
  onItemSelect,
}: {
  isOpen: boolean;
  aiAgents: AiAgent[];
  anchorElement: HTMLButtonElement;
  closeMenu: () => void;
  onItemSelect: () => void;
}) => {
  const { createNewGhostChat } = useAiAgentChats();

  const onAgentClick = (agent: AiAgent) => {
    closeMenu();
    createNewGhostChat(agent.id, agent.type);
    onItemSelect();
  };

  return (
    <Menu
      open={isOpen}
      anchorEl={anchorElement}
      onClose={closeMenu}
      slotProps={{
        paper: {
          sx: {
            width: '311px',
          },
        },
      }}
    >
      {aiAgents.map((agent) => (
        <MenuItem key={agent.id} onClick={() => onAgentClick(agent)}>
          {agent.name}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default AiAgentsMenu;
