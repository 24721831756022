import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';
import { Typography } from '@sweep-io/sweep-design';
import { useFunnelMaps } from '../pages/funnel-maps/useFunnelMaps';
import { useCloseAgentCenterFirst } from './useCloseAgentCenterFirst';

const CreateFunnelDialog = ({ closeDialog }: { closeDialog: () => void }) => {
  const { createNewFunnelMap } = useFunnelMaps();
  const closeAgentCenterFirst = useCloseAgentCenterFirst();

  const onConfirm = () => {
    closeAgentCenterFirst(createNewFunnelMap);
  };

  return (
    <ConfirmDialog
      isOpen={true}
      onCancel={closeDialog}
      handleClose={closeDialog}
      title="Create a new funnel"
      titleVariant="h2"
      PaperProps={{
        sx: {
          width: '467px',
        },
      }}
      dialogContentSx={{
        paddingTop: '4px',
        paddingBottom: '28px',
      }}
      showOnlyConfirm
      showCloseButton
      confirmText="Create new funnel"
      onConfirm={onConfirm}
    >
      <Typography variant="body">
        To create a process optimization agent, start by building a new funnel that outlines your
        business processes.
      </Typography>
    </ConfirmDialog>
  );
};

export default CreateFunnelDialog;
